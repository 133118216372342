<!-- <div class="container">
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-header">
                <h6 class="m-b-20 text-uppercase text-left">Analytics Dashboard</h6>
</div>
</div></div></div></div> -->
<div class="container">
    <div class="row">
        <div class="col-md-4 col-xl-3">
            <div class="card bg-c-blue order-card">
                <div class="card-block">
                    <h6 class="m-b-20 text-uppercase">Total Prospects</h6>
                    <h2 class="text-right"><i class="fa fa-id-card  f-left" aria-hidden="true"></i>
                        <span>486</span></h2>
                   
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3">
            <div class="card bg-c-green order-card">
                <div class="card-block">
                    <h6 class="m-b-20 text-uppercase">Active Customer</h6>
                    <h2 class="text-right"><i class="fa fa-user  f-left" aria-hidden="true"></i>
                        <span>486</span></h2>
                   
                </div>
            </div>
        </div>
        
        
        <div class="col-md-4 col-xl-3">
            <div class="card bg-c-pink order-card">
                <div class="card-block">
                    <h6 class="m-b-20 text-uppercase">Prospect Assigned</h6>
                    <h2 class="text-right"><i class="fa fa-id-card-o  f-left" aria-hidden="true"></i>
                        <span>486</span></h2>
                    
                </div>
            </div>
        </div>
	</div>
</div>


<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <div echarts [options]="BarCharts" class="echart" id="echarts"></div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <div echarts [options]="PieCharts" class="echart" id="echarts"></div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-lg-6">
                    <h6 class="card-description aligright mb-0">Recent Customers <span class="text-info">(MTD : 31)</span></h6>
                </div>
                <div class="col-lg-4">
                    <input type="text" placeholder="Search" class="form-control" aria-label="..."></div>
                    <div class="col-lg-2"> <button class="btn btn-secondary btn-fw btn-sm"><i class="fa fa-search
                      "></i>Search</button></div>
                </div>
                
        </div>
      <div class="card-body">
      
      
            
             

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                   Name
                </th>
                
                <th>
                    Phone Number
                </th>
                <th>
                 City
                </th>
                <th>
  Type
                </th>
                <th>
Program
                </th>
                <th>
                View
                          </th>
              </tr>
            </thead>
            <tbody>
              <tr >
           
                <td>
               <a> Walmart</a> 
                </td>
                <td>
                    343-323-3345
                </td>
                <td>
Florida
                </td>
                <td>
                 Client & Customer
                </td>
                <td>
            Unlimited Program
         
                </td>
                <td>
                    <button type="submit" class="btn btn-success btn-fw  btn-sm">Details</button>
                </td>
                
              </tr>
              <tr >
           
                <td>
               <a> Walmart</a> 
                </td>
                <td>
                    343-323-3345
                </td>
                <td>
Florida
                </td>
                <td>
                 Client & Customer
                </td>
                <td>
            Unlimited Program
         
                </td>
                <td>
                    <button type="submit" class="btn btn-success btn-fw  btn-sm">Details</button>
                </td>
                
              </tr>
              <tr >
           
                <td>
               <a> Walmart</a> 
                </td>
                <td>
                    343-323-3345
                </td>
                <td>
Florida
                </td>
                <td>
                 Client & Customer
                </td>
                <td>
            Unlimited Program
         
                </td>
                <td>
                    <button type="submit" class="btn btn-success btn-fw  btn-sm">Details</button>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>        </div>