import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './service/auth.service';
import { UserService } from './service/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  userDetail:string;
  title = 'SalesCRM';
  public href:string;
  public activetabstatus:number;
  currenturl: string;
  constructor(public router: Router,public user:UserService,public auth:AuthService) {}

  ngOnInit() {
    this.activetabstatus = 0;
    this.currenturl=window.location.href;
    localStorage.setItem('currenturlactive', this.currenturl);
    console.log('url',window.location.href,this.currenturl);
   this.router.events.subscribe((url:any) => {if (url.url) {
     this.href=url.url.toString().trim();

 if(this.href.includes('/mangeuser')){

  this.activetabstatus =2
  
  
  }
else  if (this.href.includes('/dashboard'))
  {
  this.activetabstatus =1
  }
  else if(this.href.includes('/forms')){
  
  this.activetabstatus =2
  
  }
  
  else if(this.href.includes('/prospectmanage')){
  
  this.activetabstatus =3
  
  }
  
  else if(this.href.includes('/prospectedit')){
  
  this.activetabstatus =3
  
  }
  
  else if(this.href.includes('/prospectcust')){
  
  this.activetabstatus =4
  
  }
  
  else if(this.href.includes('/prospectform')){
  
  this.activetabstatus =4
  
  }
  
  else if(this.href.includes('/reports')){
  
  this.activetabstatus =5
  
  }
  
  else if(this.href.includes('/conversion')){
  
    this.activetabstatus =7
    
    }
  
  else if(this.href.includes('/permission')){
  
  this.activetabstatus =6
  
  }
  else if(this.href.includes('/proscust')){
  
    this.activetabstatus =9
    
    }
    else if(this.href.includes('/proscustform')){
  
      this.activetabstatus =9
      
      }

    else if(this.href.includes('/spreport')){
  
      this.activetabstatus =8
      
      }
     console.log('  this.href',  this.href);
    } });
    console.log(' aft this.href',  this.href);

    //     if(navigator.onLine){
    //   alert("You are Online")
    //  }
    //   else {
    //    alert("You are Offline")
    //     }
}
logout(){
  window.location.assign('/');
  localStorage.clear();
  window.sessionStorage.clear();
  // this.router.navigate(['/']);
  // localStorage.clear();
  // window.sessionStorage.clear();
  this.router.navigate(['/']);
}
isHomeRoute() {
  return this.router.url === '/mangeuser';
}
}
