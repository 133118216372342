import { Component, OnInit } from '@angular/core';
//import * as echarts from 'echarts';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  BarCharts: any = {};
  PieCharts: any = {};
  constructor() { }

  ngOnInit(): void {
    //var chart = echarts.init(document.getElementById('echarts'));
   
//  chart.setOption(this.BarCharts);
this.BarChart()
this.PieChart();
  }
  BarChart(){
    this.BarCharts = {
      title: {
          text: 'SALES PERFORMANCE',
      },
      tooltip: {
          trigger: 'axis'
      },
      legend: {
        show:false,
          data: ['P', 'C']
      },
      toolbox: {
          show: false,
       
      },
      calculable: false,
      xAxis: [
          {
              type: 'category',
              data: ['Sales Person 1', 'Sales Person 2']
          }
      ],
      yAxis: [
          {
              type: 'log',
              name:'Year',
              axisTick: {show: true},
               nameLocation:'center',
               nameTextStyle: { padding:10},
            data: [2012, 2013, 2014, 2015, 2016],
             
          }
      ],
      series: [
          {
              name: 'P',
              type: 'bar',
              data: [50, 60],
              label: {
                normal: {
                  show: true,
                  position: 'top',
                      formatter: 'P ({c})',//The template variables are {a}, {b}, {c}, {d}, which respectively represent series name, data name, data value, and percentage. {d}The data will calculate the percentage based on the value
                  textStyle: {
                    align : 'center',
                    baseline : 'middle',
                    color: 'rgb(102, 102, 102)',
                    fontSize : 14,
                      fontWeight : 'bolder'
                  },
                },
              },
          },
          {
              name: 'C',
              type: 'bar',
              data: [25, 20],
              label: {
                normal: {
                  show: true,
                  position: 'top',
                      formatter: 'C ({c})',//The template variables are {a}, {b}, {c}, {d}, which respectively represent series name, data name, data value, and percentage. {d}The data will calculate the percentage based on the value
                  textStyle: {
                    align : 'center',
                    baseline : 'middle',
                    color: 'rgb(102, 102, 102)',
                    fontSize : 14,
                      fontWeight : 'bolder'
                  },
                },
              },
          }
      ]
  };
  }
PieChart(){
  this.PieCharts = {
    title: {
        text: 'PROSPECT CONVERSION RATIO',
        left: 'left'
    },
    tooltip: {
        trigger: 'item'
    },
    legend: {
        orient: 'vertical',
        left: 'right',
    },
    series: [
        {
            name: 'PROSPECT CONVERSION RATIO',
            type: 'pie',
            radius: '50%',
            data: [
                {value: 1048, name: 'ACTIVE'},
                {value: 735, name: 'INACTIVE'},
                {value: 580, name: 'WAITING'},
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};
}
}
