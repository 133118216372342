import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,Validators,FormArray} from '@angular/forms';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import {ExcelService} from 'src/app/service/excel.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
  dataDisplay: number
  dropValue:Number=null;
  p: any = 1;
  count: Number = 30;
  myForm: FormGroup;
  myForms: FormGroup;
  minDate:any;
  maxDate:any;
  name = 'Angular 6';
data:any;
  date: Date;
  data_trans: any;
  data1: any;
  programlist: any;
  xl: number=0;
  SearchDisableButton: boolean = false;
  constructor(private http: HttpClient,public excelService:ExcelService,private toastr: ToastrService,public datepipe: DatePipe) { }

  

  ngOnInit(): void {
    
    this.maxDate = new Date();
    this.getDropdown();
    this.program();
    var d = new Date();
    
    // var startdate = moment();
    // startdate = startdate.subtract(342, "days");
    // this.minDate = startdate.format("-MM-DD");
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 1, 11, 1);
    this.maxDate=new Date(currentYear-1 , 11, 31);
    console.log('min_date',this.minDate);
    console.log('max_date',this.maxDate);
    var year = d.getFullYear()-1;
    var month = d.getMonth()+1;
    var date1=1;
    var g = new Date(year, month, date1)
    console.log("fromdate", g)
    

    
    this.date=new Date();
    let latest_date =this.datepipe.transform(this.date, 'MM-dd-yyyy');
var c=this.datepipe.transform(g, 'MM-dd-yyyy');



    this.myForm = new FormGroup({    
      'fromdate': new FormControl(c),
      'todate': new FormControl(latest_date),
      'SearchBy': new FormControl((''), Validators.required),
      'value': new FormControl((''), Validators.required),
     });
     console.log(this.myForm)     
  
this.searchfunction('')
}

printPage() {
  window.print();
}

callapi_source(namee)
{
  
  //var body =   JSON.stringify(this.registerForm.value,null,4);
  var body =   namee;
  console.log('xx',body);
  
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Reports/GetReportsDetailsUsingSearch' , body, { headers: headerOptions });

}
pageChange($event) {
  // this.p = $event;
   this.Next_Button(this);
 }
 pageChange1($event) {
   // this.p = $event;
    this.Pre_Button(this);
  }
data_: any = [];
searchfunction(e){
  if(this.xl!=1){
  this.dataDisplay=1;

  localStorage.setItem("reportsearch",(this.myForm.value.value));
this.myForm.get('SearchBy').value
  var value =this.myForm.value;
  value['PerPageSize']=this.count;
  value['NoofPages']=1;
  value['Index']=1;
  // value['Index']=this.p;
  console.log('request######3',value);
  var key = "AllData";
delete value[key];
console.log('request#####',value);
  // {"fromdate":"2020-11-01","todate":"2021-10-28","SearchBy":'Name',"value":"HONOLULU"}
  localStorage.setItem("exalparam",JSON.stringify(value));
 console.log('filterIDddd',this.myForm.value.value)
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=1;
  if(response.status==1){
  this.dataDisplay=0;
;
  console.log('response data',response)
  console.log('oo',response.Data.Legal_Name)
  this.data =  response.Data
  // this.dropValue=null;
  console.log('ooqwqe1',this.data);
  this.SearchDisableButton=true;
 this.data_trans=response.Data[response.Data.length-1].Searchcount;
  }
  else{
    this.dataDisplay=0; 
    this.toastr.error('Search Using Text Or From Dropdown');
  }
})

  }
  else{
    this.xl=0
  }
}
Next_Button(e){
  this.dataDisplay=1;
  this.p=parseInt(this.p)+1;

this.myForm.get('SearchBy').value
  var value =this.myForm.value;
  value['value']=localStorage.getItem("reportsearch")
  value['PerPageSize']=this.count;
  value['NoofPages']=1;
 
  value['Index']=this.p;
  // {"fromdate":"2020-11-01","todate":"2021-10-28","SearchBy":'Name',"value":"HONOLULU"}

 console.log('filterID filterID',value)
this.callapi_source(value).subscribe((response: any) => {
  if(response.status==1){
  this.dataDisplay=0;
  
  console.log('response data',response)
  console.log('oo',response.Data.Legal_Name)
  this.data =  response.Data
  // this.dropValue=null;
  console.log('oo',this.data);
  }
  else{
    this.dataDisplay=0; 
   // this.toastr.error('Search Using Text Or From Dropdown');
  }
})


}
Pre_Button(e){
  this.dataDisplay=1;
  this.p=parseInt(this.p)-1;

this.myForm.get('SearchBy').value
  var value =this.myForm.value;
  value['value']=localStorage.getItem("reportsearch")
  value['PerPageSize']=this.count;
  value['NoofPages']=1;
 
  value['Index']=this.p;
  // {"fromdate":"2020-11-01","todate":"2021-10-28","SearchBy":'Name',"value":"HONOLULU"}

 console.log('filterID filterID',value)
this.callapi_source(value).subscribe((response: any) => {
  if(response.status==1){
  this.dataDisplay=0;

  console.log('response data',response)
  console.log('oo',response.Data.Legal_Name)
  this.data =  response.Data
  // this.dropValue=null;
  console.log('oo',this.data);
  }
  else{
    this.dataDisplay=0; 
   // this.toastr.error('Search Using Text Or From Dropdown');
  }
})


}
dropdown: any = [];
xll(){
 
}
exportAsXLSX():void {
  this.xl=1;
  this.dataDisplay=1;
  localStorage.setItem("reportsearch",(this.myForm.value.value));
  this.myForm.get('SearchBy').value
    var value =this.myForm.value;
    value['PerPageSize']=this.count;
    value['NoofPages']=1;
   
    value['Index']=this.p;
  value['AllData']=''
  this.callapi_source(value).subscribe((response: any) => {
    this.dataDisplay=1;
    if(response.status==1){
    this.dataDisplay=0;
    this.data_= response.Data;
    this.excelService.exportAsExcelFile(this.data_, 'Excel');
    console.log('report',response)

   } })

 
}
getDropdown(){
this.dropdownApi().subscribe((response: any) => {
  if (response.msg=='Successful') {
    this.dropdown=response.salesperson;
    console.log('response data',response);
  }
})


}
dropdownApi()
{
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GetSalesPerson?Role=salesperson&userid=SPUserid');
}

programapi()
{
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Customer/GetProgramList');
}
program(){
  this.programapi().subscribe((response: any) => {
    if (response.msg=='Successful') {
      this.programlist=response.programlist;
      console.log('response data program', response);
    }
  })
}
reset(){
  window.location.reload(); 
  this.dropValue=null;
  localStorage.removeItem('exalparam');
   
 }
}
