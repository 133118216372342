import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { UserService } from 'src/app/service/user.service';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted = false;
  loader:boolean;
  UserName: any;
  Password: any;
  Password1: string;
  mobile: any;
  password: any;
  constructor(private http: HttpClient, public user: UserService, private cookieService: CookieService, public authservice: AuthService, private toastr: ToastrService, private formBuilder: FormBuilder, private router: Router) { }
  loginForm: FormGroup;
  rememberMe1: boolean;
  cookieUserName:any;
  cookieUserPassword:any;
  ngOnInit(): void {
    if (this.authservice.getToken()) {
      if(this.user.GetAccess().RoleID=="1"){
        this.router.navigate(['/mangeuser']);
      }
      if(this.user.GetAccess().RoleID=="2"){
        this.router.navigate(['/conversion']);
      }
      if(this.user.GetAccess().RoleID=="3"){
        this.router.navigate(['/proscust']);
      }
      if(this.user.GetAccess().RoleID=="4"){
        this.router.navigate(['/prospectcust']);
      }
    }
    this.submitted = false;
    this.loginForm = this.formBuilder.group({
      mobile: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe1:['', Validators.required]

    });
    this.loginForm.get('mobile').setValue(this.cookieService.get('cookieUserName'));
    this.loginForm.get('password').setValue(this.cookieService.get('cookieUserPassword'));

  }
  callapi_source(namee) {

    //var body =   JSON.stringify(this.CustomerData.value,null,4);
    var body = namee;
    console.log('xx', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospectcustomer/ClientProspectsAutoPopulate', body, { headers: headerOptions });
  }
  get f() { return this.loginForm.controls; }

  login() {
    this.submitted = true;
   
    let mobile=this.f.mobile.value;
 let password=this.f.password.value;
 if (!mobile && !password) {
   return;
 }
  this.loader=true;
    this.authservice.userAuthentication(
      mobile ,
     password
    )
      .subscribe(data => {
        console.log('login data',data)
        if (data.access_token) {
          this.authservice.saveToken(data.access_token);
        //  localStorage.setItem('userToken', data.access_token);
          this.GetUserClaims();
         setTimeout(() =>  1000);
          this.loader=false;
        }
        if (this.loginForm.value.rememberMe1 == true) {
          console.log('set rm');
          // set cookies

          this.cookieService.set('cookieUserName',this.loginForm.value.mobile);
          this.cookieService.set('cookieUserPassword', this.loginForm.value.password);
        } else {
          console.log('not set rm');
          this.cookieService.deleteAll();
        }
      },
      error => {
        let er=JSON.parse(error._body);
          this.toastr.warning(er.error_description, '');
        this.loader=false;
      })
  }

  GetUserClaims() {
    this.authservice.GetUserClaims()
      .subscribe((data: any) => {
        if (data) {
          console.log('GetUserClaims',data);
          let str=JSON.stringify(data);
          console.log('str',str);
          localStorage.setItem('userDetails',str);
          this.toastr.success('Welcome ', data.Name+ '\n' +data.Lname);
          console.log('accc',this.user.GetAccess().User_id);
          this.cookieService.set('cookieUserName_pros',this.loginForm.value.mobile);
          this.cookieService.set('cookieUserPassword_pros', this.loginForm.value.password);

          // this.router.navigate(['auth/login']);
        // if (data.user_type !=1 ) { //super admin , dealer admin
        //   console.log('admin');
        //   this.setcookie(data);
        //   this.router.navigate(['/admin']);
        //   localStorage.setItem('withoutveryfy','false');
        // }else { //user
        //   console.log('dealer');
        //   localStorage.setItem('withoutveryfy','false');
        //   this.setcookie(data);
        console.log(this.user.GetAccess().RoleID)
          if (localStorage.getItem('currenturlactive').includes("currentPage=1")) {
            const url = localStorage.getItem('currenturlactive');
            const segments = url.split('/');
            console.log("segments", segments);
            var a = segments[3];
            var b = a.split("&")
            for (let i = 0; i < b.length; i++) {
              const splitResult = b[i].split('=');
              b[i] = splitResult[1] ? splitResult[1] : '';
            }
            console.log("bvalue",b );
            let js = { 'Docket_No': b[0], 'customerid': b[1] };
            this.callapi_source(js).subscribe((response: any) => {
              this.router.navigate(['/prospectform'], { queryParams: { Docket_No: b[0], customerid: b[1], index: b[2], itemsPerPage: b[3], currentPage: b[4] , salescrm: b[5], tab:b[6],page:b[7]} });
            })
          }
          else {
        if(this.user.GetAccess().RoleID=="1"){
          this.router.navigate(['/mangeuser']);
        }
        if(this.user.GetAccess().RoleID=="2"){
          this.router.navigate(['/conversion']);
        }
        if(this.user.GetAccess().RoleID=="3"){
          this.router.navigate(['/proscust']);
        }
        if(this.user.GetAccess().RoleID=="4"){
          this.router.navigate(['/prospectcust']);
            }
        }

  // this.router.navigate(['/dashboard']);
  // console.log("yuaaaaaa for testing");
  //   console.log(this.user.GetAccess().RoleID);

        // }
        this.loader=false;
        }
        // else if (data.Status_id==2) {
        //   this.toastr.success(data.Status, '');
        //   localStorage.setItem('withoutveryfy','true');
        //   this.router.navigate(['auth/verify-otp']);
        //   this.setcookie(data);
        // }else if (data.Status_id==3) {
        //   localStorage.setItem('withoutveryfy','true');
        //   this.router.navigate(['auth/verify-otp']);
        //   this.toastr.success(data.Status, '');
        //   this.setcookie(data);
        // }

      },
        error => {
          this.loader=false;
          this.toastr.warning(error,'');
        })
  }
  setAppCookies() {
    this.cookieUserName = this.cookieService.get('cookieUserName');
    this.cookieUserPassword = this.cookieService.get('cookieUserPassword');
    if (this.cookieUserName != '') {
      this.loginForm.value.mobile = this.cookieUserName;
      this.loginForm.value.password = this.cookieUserPassword;
      this.rememberMe1 = true;
    } else {
      this.mobile = '';
      this.password = '';
      this.rememberMe1 = false;
    }

  }

  loginB(){
    let mobile=this.f.mobile.value;
    let password=this.f.password.value;
    console.log('mob',mobile)

    console.log('pass',password)
    if (mobile==='superAdmin' && password=="123") {
      localStorage.setItem('role',"1");
      this.router.navigate(['/dashboard']);

    }else if (mobile==='admin' && password=="123") {
      localStorage.setItem('role',"2");
      this.router.navigate(['/prospectmanage']);

    }
    else if (mobile==='sp' && password=="123") {
      localStorage.setItem('role',"3");
      this.router.navigate(['/prospectmanage']);

    }
    else if (mobile==='customer' && password==="123") {
      localStorage.setItem('role',"4");
      this.router.navigate(['/prospectcust']);

    }
  }
}
