import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, NgForm, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { BuiltinTypeName, identifierModuleUrl } from '@angular/compiler';
import { INT_TYPE } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/service/user.service';
import { PaginatePipe, PaginationControlsComponent } from 'ngx-pagination';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
@Component({
  selector: 'app-conversion',
  templateUrl: './conversion.component.html',
  styleUrls: ['./conversion.component.scss']
})
export class ConversionComponent implements OnInit {
  check: Number = 0;
  searchValue: string = '';
  dropValue1: string = '';
  submitted = false;
  ers = false;
  i: Number;
  p: any = 0;
  show: number;
  showw: number;
  count: Number = 30;
  swag: number;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  search1: FormGroup;
  radio: FormGroup;
  searchvalue: any;
  search: any;
  sear: number = 0;
  radiobtnchk: number;
  s: Number;
  namee: any;
  selected: any = [];
  selected1: any = [];
  checkbox: any;
  NameTit: string;
  datacheck: Number = 0;
  filterID: string;
  mandatorycheck: string = "0";
  checkboxes: any[] = [
    { name: ' Herman Beck', value: ' John', name2: 'Nicky', checked: true },

  ]
  filter = {
    active: 1
  }
  Sp: string = '';
  data: any;
  data_trans: any;
  Name: any;

  data1: any;
  dat: any;
  userid: string;
  tempsearchval: any;
  dataDisplay: number;
  btn2filter: string;
  useid: any;
  Assignto: string;
  assign: number;
  checkradio: number;
  checkradioo: number;
  checkradio1: number;
  checkradioo1: number;
  dNo: any;
  var: any;
  vari: number;
  checkradio3: number;
  checkradioo3: number;
  to: number;
  ss: number;
  error:string="";
  bind1: string;
  radiobutton:any = 0;
  radiobutton2:any = 0;
  constructor(public user: UserService, private router: Router, private toastr: ToastrService, private route: ActivatedRoute, private formBuilder: FormBuilder, private http: HttpClient) { }

  ngOnInit(): void {
    this.ss = 0;
    this.sear = 0;
    this.btn2filter = 'TypeFilter'
    this.checkradioo = 0;
    this.checkradioo1 = 0;
    this.checkradioo3 = 0;
    this.submitted = false;
    this.CheckAllOptions();
    $(".selectall").click(function () {
      $(".individual").prop("checked", $(this).prop("checked"));
    });
    console.log('c', this.checkboxes)

    this.registerForm = this.formBuilder.group({

      search: ['', Validators.required],




    });
    this.search1 = this.formBuilder.group({
      searchh: ['1', Validators.required],
      SearchBy: ['3', Validators.required]
    });
    this.radio = this.formBuilder.group({
      radiobutton: ['', Validators.required],
      radiobutton2: ['', Validators.required]

    });
    this.radio = this.formBuilder.group({
      radiobutton: ['', Validators.required],
      radiobutton2: ['', Validators.required]

    });

    this.filterID = 'AllData';
    this.Search('3');
    // this.Next_button('');
    //  this.bind=(localStorage.getItem("searchtext5"));
  }
  get f() { return this.registerForm.controls; }
  btnClick() {
    //alert("2");
    this.searchfunction(this.tempsearchval);
    //  this.toastr.success(' Successfully Assigned');
    window.location.reload();
    if (this.check == 0 && this.data_trans.length <= 5) {
      window.location.reload();
    }
  }
  cancel() {
    window.location.reload();
  }
  selectCheck(i) {
    this.ss = 1
    // this.assign = this.data_trans[i].AssignedTo;
    console.log('asss', this.assign);
    let val = [];
    let val1 = [];
    setTimeout(() => {
      $('input[class="individual"]:checked').each(function () {
        //console.log($(this).val());
        val.push($(this).val());
        val1.push($(this).val());
        console.log('val', ($(this).val()));
      });
      console.log('what', val);
      this.selected = val;
      this.selected1 = val1;
    }, 1000);

  }
  selectCheckALL(eve) {
    console.log(eve)
    if (eve.target.checked) {
      let arr = [];
      let arr1 = [];

      this.check = 0;
      if (this.data_trans) {

        this.data_trans.forEach(val => arr.push(val.Docket_No));
        this.data_trans.forEach(val => arr1.push(val.AssignedTo));
        this.selected = arr;
        this.selected1 = arr;
      }
    } else {
      this.selected = [];
      this.selected1 = [];
    }
  }
  SetFilter(arg, id) {
    // this.registerForm.get('search').setValue('');
    this.filter = { active: arg };
    this.filterID = id;
    localStorage.setItem("fildername", this.filterID);

  }
  CheckAllOptions() {
    if (this.checkboxes.every(val => val.checked == true))
      this.checkboxes.forEach(val => { val.checked = false });
    else
      this.checkboxes.forEach(val => { val.checked = true });
  }

  callapi_source(namee) {

    //var body =   JSON.stringify(this.registerForm.value,null,4);
    var body = namee;
    console.log('xx', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/RawData/GetCompanyDetailsUsingSearch', body, { headers: headerOptions });

  }

  searchfunction(e) {
    // this.bind=(localStorage.getItem("searchtext5"));
    this.dataDisplay = 1;
    this.tempsearchval = e;

    localStorage.setItem("searchtext5", this.registerForm.value.search);

    if (this.registerForm.value.search == undefined || this.registerForm.value.search.length == '') {
      this.ers = true;

    }
    else {
      this.ers = false;
    }
    //this.registerForm.value.search={};
    this.submitted = true;
    var value = {};
    value[this.filterID] = (this.filter.active == 10 || this.filter.active == 11) ? this.registerForm.value.search : '';
    value['PerPageSize'] = this.count;
    value['NoofPages'] = 1;

    value['Index'] = 1;

    this.callapi_source(value).subscribe((response: any) => {


      if (response.status == 1) {
        this.data_trans = response.Data;
        this.data_ = response.Data;
        this.dataDisplay = 0;


        console.log('Assignnnn', this.Assignto)
        console.log('response broke', response)

        //console.log('ll',this.registerForm1.value)
        this.registerForm.get('search').setValue(localStorage.getItem("searchtext5"));

      }
      else {
        this.dataDisplay = 0;


        this.toastr.warning("Select Valid data");


      }

    })





  }
  pageChange($event) {
    // this.p = $event;
    this.Next_button(this);
  }
  pageChange1($event) {
    // this.p = $event;
    this.pre_button(this);
  }
  refreshPage() {
    location.reload();
  }
  Next_button(e) {
    this.p = parseInt(this.p) + 1;
    this.dataDisplay = 1;
    this.tempsearchval = e;

    //this.registerForm.value.search={};
    this.submitted = true;
    var value = {};
    // if(localStorage.getItem("searchh1")!=null){
    //   this.filterID= localStorage.getItem("btn2filter1");
    //   value[this.filterID] =localStorage.getItem("searchh1");
    //   alert('hi');
    //   alert(this.filterID);
    //   alert(localStorage.getItem("searchh1"));
    //   console.log('000000000000000',value);
    // }
    // if(localStorage.getItem("searchh1")==null){
    //   value[this.btn2filter]=this.search1.value.searchh;
    //   alert('else');
    // }
    value[this.btn2filter] = this.search1.value.searchh;
    if (localStorage.getItem("searchtext5") != null) {
      // alert('hi');
      this.filterID = localStorage.getItem("fildername");
      value[this.filterID] = localStorage.getItem("searchtext5");
    }
    if (localStorage.getItem("searchtext5") == null) {
      // alert('else');
      value[this.filterID] = (this.filter.active == 10 || this.filter.active == 11) ? this.registerForm.value.search : '';
    }


    value['PerPageSize'] = this.count;
    value['NoofPages'] = 1;

    value['Index'] = this.p;
    if (this.filter.active != 10 && this.filter.active != 11) {
      this.callapi_source(value).pipe(
        catchError(error => {
          // Handle the error here
          document.getElementById("poppupForRawdata").click()
          console.error('Error occurred:', error);
          return of({ status: 0 }); // Assuming status 0 represents an error
        })
      ).subscribe((response: any) => {


        if (response.status == 1) {
          this.data_trans = response.Data;
          this.data_ = response.Data;
          this.dataDisplay = 0;

          if (response.Data[0].AssignedTo == 'UnAssign') {
            this.radio.value.radiobutton = null;
            this.radio.value.radiobutton2 = null;
            this.Assignto = 'UnAssign'
            this.vari = 1;

          } else if (response.Data[0].AssignedTo == 'Credit Slsp') {
            this.Assignto = 'Credit Slsp'
            this.vari = 2;
          }
          else if (response.Data[0].AssignedTo == 'Collect Slsp') {
            this.Assignto = 'Collect Slsp'
            this.vari = 3;
          }
          else if (response.Data[0].AssignedTo == 4) {
            this.Assignto = 'Assigned'
          }


          console.log('response data', this.data_trans)
          console.log('response broke', response)

          //console.log('ll',this.registerForm1.value)
          this.registerForm.get('search').setValue(this.registerForm.value.Name);

        }
        else {
          this.dataDisplay = 0;
          document.getElementById("poppupForRawdata").click()
          // this.toastr.error('An error occurred while fetching data');
        }

      })
    }

    if (this.filter.active == 10 || this.filter.active == 11) {
      this.dataDisplay = 1;
      this.callapi_source(value).pipe(
        catchError(error => {
          // Handle the error here
          document.getElementById("poppupForRawdata").click()
          console.error('Error occurred:', error);
          return of({ status: 0 }); // Assuming status 0 represents an error
        })
      ).subscribe((response: any) => {


        if (response.status == 1) {
          this.dataDisplay = 0;
          this.data_trans = response.Data;
          this.data_ = response.Data;


          console.log('response data', this.data_trans)
          console.log('response broke', response);
          this.p = 1;
          this.submitted = false;
          //console.log('ll',this.registerForm1.value)

          this.registerForm.get('search').setValue(this.registerForm.value.Name);


          // var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
          // return this.http.get<any>(environment.apiURL + 'api/Prospect/ProspectAssigned?userid='+this.registerForm.value.userid+'/McNumber='+response.Docket_No)

          //   .subscribe((response: any) => {

          //     this.data_trans= response.Data;
          //     console.log('assign',response)
          //   })

          // if(response.Brok_Auth=='A'){
          //   this.dat=['Broker',[]]
          //   console.log('cc',this.dat)
          // }
          // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
          //   this.dat=['carrier',[]]
          //   console.log('oo',this.dat)
          // }
        }
        else {
          this.dataDisplay = 0;
          document.getElementById("poppupForRawdata").click()
          //this.toastr.error('An error occurred while fetching data');
        }
      })
    }



  }
  pre_button(e) {
    this.p = parseInt(this.p) - 1;
    this.dataDisplay = 1;
    this.tempsearchval = e;
    //this.registerForm.value.search={};
    this.submitted = true;
    var value = {};
    value[this.btn2filter] = this.search1.value.searchh;
    value[this.filterID] = (this.filter.active == 10 || this.filter.active == 11) ? this.registerForm.value.search : '';
    value['PerPageSize'] = this.count;
    value['NoofPages'] = 1;

    value['Index'] = this.p;
    if (this.filter.active != 10 && this.filter.active != 11) {
      this.callapi_source(value).subscribe((response: any) => {


        if (response.status == 1) {
          this.data_trans = response.Data;
          this.data_ = response.Data;
          this.dataDisplay = 0;



          console.log('response data', this.data_trans)
          console.log('response broke', response)

          //console.log('ll',this.registerForm1.value)
          this.registerForm.get('search').setValue(this.registerForm.value.Name);

        }
        else {
          this.dataDisplay = 0;
          this.toastr.warning("Select Valid data");
        }

      })
    }

    if (this.filter.active == 10 || this.filter.active == 11) {
      this.dataDisplay = 1;
      this.callapi_source(value).subscribe((response: any) => {


        if (response.status == 1) {
          this.dataDisplay = 0;
          this.data_trans = response.Data;
          this.data_ = response.Data;


          console.log('response data', this.data_trans)
          console.log('response broke', response);

          this.submitted = false;
          //console.log('ll',this.registerForm1.value)

          this.registerForm.get('search').setValue(this.registerForm.value.Name);


          // var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
          // return this.http.get<any>(environment.apiURL + 'api/Prospect/ProspectAssigned?userid='+this.registerForm.value.userid+'/McNumber='+response.Docket_No)

          //   .subscribe((response: any) => {

          //     this.data_trans= response.Data;
          //     console.log('assign',response)
          //   })

          // if(response.Brok_Auth=='A'){
          //   this.dat=['Broker',[]]
          //   console.log('cc',this.dat)
          // }
          // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
          //   this.dat=['carrier',[]]
          //   console.log('oo',this.dat)
          // }
        }
        else {
          this.dataDisplay = 0;
          this.toastr.warning("Select Valid data");
        }
      })
    }



  }
  GetAccess() {
    let userDetails = localStorage.getItem('userDetails');
    return JSON.parse(userDetails);
  }
  gettabledata(i, name) {
    console.log(i);
    this.dNo = this.data_trans[i].Docket_No;
    console.log('dog', this.dNo)
    this.assign = this.data_trans[i].AssignedTo;
    // alert(this.assign)
    if (this.assign == 2) {
      this.checkradio = 1;
      // alert('yes')
    } else if (this.assign == 3) {
      this.checkradio1 = 2;
      // alert('No');
    } else if (this.assign == 1) {
      //alert('No0')
      this.checkradio3 = 3;

    }

    console.log('as', this.assign);
    this.NameTit = name;
  }

  gettabledata1(i) {
    console.log(i);
    if (this.swag == 1) {
      this.checkradioo3 = 3;

    }
    if (this.swag == 2) { this.checkradioo = 1; }
    if (this.swag == 3) { this.checkradioo1 = 2; }

    if (this.assign == 2) {
      this.checkradioo = 1;
      // alert('2')
    }
    if (this.assign == 3) {
      this.checkradioo1 = 2;
      // alert('3')
    }
    if (this.assign == 1) {
      this.checkradioo3 = 3;
      // alert('1')
    }

    console.log('as', this.assign);
    // this.NameTit=name;
  }
  callapi_assign(i) {
    // console.log('uu',p)
    // var t =(p-1)*count + (i);

    // console.log('kk', t)

    console.log('userrrris (P-1)*5 + (R+1)', this.user.GetAccess().User_id)
    console.log(i);
    console.log('rr',)
    console.log("cc", (this.data_trans[i]) * 5)

    //console.log('jj',this.data_trans[sa-1].Docket_No)
    //var dNo = this.data_trans[i].Docket_No;

    let arr = [];
    this.data_trans.forEach(val => arr.push(val.Docket_No));
    console.log('c', arr)
    var str = arr.join();
    console.log("str : " + str);




    if (this.checkradio == 1 && this.checkradio1 == 2) {
      this.radiobtnchk = 2;

      // alert( this.radiobtnchk);
    }
    else {
      this.radiobtnchk = 1;

      // alert( this.radiobtnchk);
    }

    if (this.radio.value.radiobutton == 1 || this.radio.value.radiobutton2 == 2) {
      this.radiobtnchk = 1;
      if (this.radio.value.radiobutton == 1) {
        this.radio.value.radiobutton = this.user.GetAccess().User_id
      }
      else {
        this.radio.value.radiobutton = 0;
      }
      if (this.radio.value.radiobutton2 == 2) {
        this.radio.value.radiobutton2 = this.user.GetAccess().User_id
      }
      else {
        this.radio.value.radiobutton2 = 0;
      }

      var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.get<any>(environment.apiURL + 'api/Prospect/ProspectAssigned?McNumber=' + this.dNo + '-' + this.assign + '&' + 'userid=' + this.radio.value.radiobutton + '&' + 'cluserid=' + this.radio.value.radiobutton2)
    }
    else {
      this.error = "Select any one role";

      // if (this.radiobtnchk == 1) {
      //   this.toastr.warning('Select any ');
      // } else {
      //   this.toastr.error('Select any one role');
      // }


    }
    console.log('radiiiooo', this.radio.value);


  }
  assignbuttonclick(i, name, p, count) {
    console.log('uu', p)
    var t = (p - 1) * 5 + (i);
    var dNo = this.data_trans[t].Docket_No;
    console.log('kk', t)
    console.log('kk333', dNo)
    this.NameTit = name;

    this.callapi_assign(i).subscribe((response: any) => {
      // this.data_trans= response.Data;
      if (response.status == 1) {
        this.show = 1
        this.to = 1
        this.toastr.success('Assigned Successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        // alert('show')
      }
      else {
        // alert('show false')
        this.show = 0;
        this.to = 1
        this.toastr.error(response.msg);
      }

      console.log('assign', response)
    })
  }

  callapi_assign1(i) {
    console.log('userrrris', this.user.GetAccess().User_id)
    console.log('tyy', this.selected);
    console.log('tyy', this.selected1);
    var dNo;
    var ass;
    if (i == 0) {
      dNo = this.selected;
      ass = this.selected1;
    } else {
      dNo = i;
      ass = i;
    }

    if (this.radio.value.radiobutton == 1 || this.radio.value.radiobutton2 == 2) {
      if (this.radio.value.radiobutton == 1) {
        this.radio.value.radiobutton = this.user.GetAccess().User_id
      }
      else {
        this.radio.value.radiobutton = 0;
      }
      if (this.radio.value.radiobutton2 == 2) {
        this.radio.value.radiobutton2 = this.user.GetAccess().User_id
      }
      else {
        this.radio.value.radiobutton2 = 0;
      }
      console.log('g', dNo);

      var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.get<any>(environment.apiURL + 'api/Prospect/ProspectAssigned?McNumber=' + dNo + '&' + 'userid=' + this.radio.value.radiobutton + '&' + 'cluserid=' + this.radio.value.radiobutton2)
    } else {
      // this.toastr.error('Select any one role');
      this.error = "Select any one role";


    }
  }
  assignbuttonclick1(i) {



    this.callapi_assign1(i).subscribe((response: any) => {
      $('.selectallcheck').prop('checked', false);
      // this.data_trans= response.Data;
      console.log('assign11', response)
      if (response.status == 1) {
        this.showw = 1
        this.toastr.success('Assigned Successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        // alert('showw')
      }
      else {
        // alert('showw false')
        this.to = 1
        this.toastr.error(response.msg);
      }
    })

  }

  modo(value: string) {
    switch (value) {
      case "1":
        //  alert('case1');
        this.swag = 1;
        this.checkradioo3 = 3;
        break;
      case "2":
        this.swag = 2;
        this.checkradioo == 1
        // alert('case2');
        break;
      case "3":
        this.swag = 3;
        this.checkradioo1 == 2
        // alert('case3');
        break;
    }
  }

  data_: any = [];
  Search(drop) {

    this.dataDisplay = 1;
    this.sear = 1
    this.data_trans = this.data_;
    var value = {};
    if (drop == '' && this.search1.value.searchh.length != 0) {
      this.toastr.warning("Select Valid data");
    }


    if (drop == '1') {

      if (this.search1.value.searchh == '' || this.search1.value.searchh == null) {

        this.mandatorycheck = "1";

      }
      else {

        this.mandatorycheck = "0";
      }
      this.btn2filter = 'TypeFilter'
      value['TypeFilter'] = this.search1.value.searchh;
      // this.data_trans=this.data_trans.filter(x => x.BCType.toLowerCase().includes(search.toLowerCase()));    
    }

    if (drop == '2') {
      if (this.search1.value.searchh == '') {

        this.mandatorycheck = "1";

      }
      else {

        this.mandatorycheck = "0";
      }
      this.btn2filter = 'NameFilter'
      value['NameFilter'] = this.search1.value.searchh;
    }
    if (drop == '3') {
      this.btn2filter = 'AssignedToFilter'
      value['AssignedToFilter'] = this.search1.value.searchh;
    }
    localStorage.setItem("btn2filter1", (this.btn2filter));
    localStorage.setItem("searchh1", (this.search1.value.searchh));

    value[this.filterID] = (this.filter.active == 10 || this.filter.active == 11) ? this.registerForm.value.search : '';
    value['PerPageSize'] = this.count;
    value['NoofPages'] = 1;

    value['Index'] = 1;
    this.p = 1;
    //  this.bind1=(localStorage.getItem("searchh1"));
    this.search1.get('searchh').setValue(localStorage.getItem("searchh1"));
    if (this.filter.active != 10 && this.filter.active != 11) {
      this.callapi_source(value).subscribe((response: any) => {


        if (response.status == 1) {
          this.data_trans = response.Data;
          this.data_ = response.Data;
          this.dataDisplay = 0;



          console.log('response data', this.data_trans)
          console.log('response broke', response)

          //console.log('ll',this.registerForm1.value)
          this.registerForm.get('search').setValue(this.registerForm.value.Name);

        }
        else {
          this.dataDisplay = 0;
          this.toastr.error('Failed');
        }

      })
    }

    if (this.filter.active == 10 || this.filter.active == 11) {
      this.dataDisplay = 1;
      value[this.filterID] = localStorage.getItem("searchtext5");
      this.callapi_source(value).subscribe((response: any) => {


        if (response.status == 1) {
          this.dataDisplay = 0;
          this.data_trans = response.Data;
          this.data_ = response.Data;


          console.log('response data', this.data_trans)
          console.log('response broke', response);

          this.submitted = false;
          //console.log('ll',this.registerForm1.value)

          this.registerForm.get('search').setValue(this.registerForm.value.Name);


          // var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
          // return this.http.get<any>(environment.apiURL + 'api/Prospect/ProspectAssigned?userid='+this.registerForm.value.userid+'/McNumber='+response.Docket_No)

          //   .subscribe((response: any) => {

          //     this.data_trans= response.Data;
          //     console.log('assign',response)
          //   })

          // if(response.Brok_Auth=='A'){
          //   this.dat=['Broker',[]]
          //   console.log('cc',this.dat)
          // }
          // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
          //   this.dat=['carrier',[]]
          //   console.log('oo',this.dat)
          // }
        }
        else {
          this.dataDisplay = 0;
          this.toastr.error('Failed');
        }
      })
    }



  }
  resetfunct() {
    var value = {};
    value[this.filterID] = (this.filter.active == 10 || this.filter.active == 11) ? this.registerForm.value.search : '';
    value['PerPageSize'] = this.count;
    value['NoofPages'] = 1;

    value['Index'] = 1;

    this.callapi_source(value).subscribe((response: any) => {


      if (response.status == 1) {
        this.data_trans = response.Data;
        this.data_ = response.Data;
        this.dataDisplay = 0;


        console.log('Assignnnn', this.Assignto)
        console.log('response broke', response)

        //console.log('ll',this.registerForm1.value)
        this.registerForm.get('search').setValue(this.registerForm.value.Name);

      }
      else {
        this.dataDisplay = 0;
        this.toastr.error('Failed');
      }

    })
    localStorage.removeItem('searchtext5');
    localStorage.removeItem('fildername');
    localStorage.removeItem('btn2filter1');
    localStorage.removeItem('searchh1');
  }
  reset() {
    localStorage.removeItem('searchtext5');
    localStorage.removeItem('fildername');
    localStorage.removeItem('btn2filter1');
    localStorage.removeItem('searchh1');
    this.resetfunct();
    window.location.reload();
  }
  reset1() {
    localStorage.removeItem('searchtext5');
    localStorage.removeItem('fildername');
    localStorage.removeItem('btn2filter1');
    localStorage.removeItem('searchh1');
    this.resetfunct();
    window.location.reload();
  }
  searnull() {
    // this.search1.value.searchh = '';
    this.dropValue1 = '';
    this.sear = 0;
  }
}
