import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup,ReactiveFormsModule, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/service/user.service';
@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})



export class ManageUserComponent implements OnInit {
 searchValue:string = '';
 dropValue:Number=0;
  data_trans: any = [];
  data_: any = [];
  data: any;
  y: any;
  deleteuser: number;

  constructor(private toastr: ToastrService ,public user:UserService,private router: Router,private route: ActivatedRoute, private formBuilder: FormBuilder,private http: HttpClient) { }
  ngOnInit(): void {
    this.getuser('0');
   
  }
  btnClick= function () {
    this.router.navigateByUrl('/forms');
};

callapi_source(Userid)
  {

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/User/GetUser' );
  
  }

  getuser(Userid){
    let resultsarray:[];
    this.callapi_source(Userid).subscribe((response: any) => {
     
      this.data_trans= response.Data;
      this.data_= response.Data;




    })
    
  
  }
  callapiDelete(Userid)
  {

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/User/DeleteUser?userid=' + Userid );
  
  }
  status(Userid,type)
  {

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/User/InActiveUser?userid=' + Userid+'&activestatus=' +type );
  
  }

delete(Userid){
  this.callapiDelete(Userid).subscribe((response: any) => {
    if(response.Message=="User Deleted successfully"){
    this.toastr.success('User Deleted successfully');
    }
    else{
      this.toastr.warning(response.Message);
    }
    this.getuser('0');
  })

}
StatusChange(Userid,type){
console.log('',type);
let chabge=type.target.checked?1:0;
  this.status(Userid,chabge).subscribe((response: any) => {
    this.toastr.success(response.Info);
    this.getuser('0');
  })

}
dlete:any;
onclickdelete(Userid){
  this.dlete=Userid;

 
}
Search(drop,search){
  if(search==''){
    this.toastr.error('Search By Text');
  }
  this.data_trans=this.data_;
  console.log('aberfr',drop,this.data_trans);
  if (drop=='1') {
    this.data_trans=this.data_trans.filter(x => x.Firstname.toLowerCase().includes(search.toLowerCase()));    
  }else{
    this.data_trans=this.data_trans.filter(x => x.Role.toLowerCase().includes(search.toLowerCase()));    

  }


console.log('data_trans',drop,this.data_trans);

}
reset(){
  window.location.reload(); 
//   this.data_trans=this.data_;
//  this.searchValue = null;
//   this.dropValue = 0;
  
}
}
