<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title" style="font-size: 16px;">Raw Data Management</h4><span id="poppupForRawdata" data-toggle="modal" data-target="#exampleModal" ></span>
      <button  routerLink='/prospectmanage' class="position-absolute left-50 btn btn-success mr-2" style="background:  #0c7cd5;min-width: 120px;margin-left: 82%!important;margin-top:-27px;"><< Back</button>
    </div>
    <div class="card-body">
       <p class="card-description">
Filters:
      </p>
     
          <div class="panel">
          

  <div class="d-flex justify-content-center flex-wrap filter">
    <div class="container">
      <div class="row carrierrow">
          <button [ngClass]="filter.active==2 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(2,'Carrier_500')"  class="btn btn-sm" id=Carrier_500>Carriers 500+</button>
          <button [ngClass]="filter.active==3 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(3,'Carrier_250to499')" class="btn btn-sm" id=Carrier_250to499>Carriers 250-499</button>   
          <button [ngClass]="filter.active==4 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(4,'Carrier_100to249')" class="btn btn-sm" id=Carrier_100to249>Carriers 100-249</button> 
          <button [ngClass]="filter.active==5 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(5,'Carrier_75to99')" class="btn btn-sm" id=Carrier_75to99>Carriers 75-99</button>
          <button [ngClass]="filter.active==6 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(6,'Carrier_50to74')" class="btn btn-sm" id=Carrier_50to74>Carriers 50-74</button>
          <button [ngClass]="filter.active==7 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(7,'Carrier_35to49')" class="btn btn-sm" id=Carrier_35to49>Carriers 35-49</button>   
          <button [ngClass]="filter.active==8 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(8,'Carrier_20to34')" class="btn btn-sm" id=Carrier_20to34>Carriers 20-34</button> 
          <button [ngClass]="filter.active==9? 'btn-primary' : 'btn-default'" (click)="SetFilter(9,'Carrier_10to19')" class="btn btn-sm" id=Carrier_10to19>Carriers 10-19</button>
        </div>
        <div class="row carrierrow2">
          <button [ngClass]="filter.active==10 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(10,'Name')" class="btn btn-sm" id=Name>Name</button>   
          <button [ngClass]="filter.active==11 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(11,'Docket_No')" class="btn btn-sm" id=Docket_No>MC#</button> 
          <button [ngClass]="filter.active==12? 'btn-primary' : 'btn-default'" (click)="SetFilter(12,'Brokers')" class="btn btn-sm" id=Brokers>Brokers</button>
          <button [ngClass]="filter.active==13 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(13,'Carrier_Canadian')" class="btn btn-sm" id=Carrier_Canadian>Carriers  Canadian</button>
          <button [ngClass]="filter.active==14 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(14,'Carrier_AllDOT')" class="btn btn-sm" id=Carrier_AllDOT>Carriers ALL DOT Files</button>
        
        </div>
        </div>
        </div>
         <form class="form-sample w-100 container-fluid row justify-content-center" [formGroup]="registerForm"  (ngSubmit)="searchfunction(this)" >

<div class="col-lg-9 mt-4">  <div  *ngIf="filter.active==10 || filter.active==11" > 
  <input style="width: 78%;"type="text" placeholder="Search"  formControlName="search" class="form-control"   aria-label="..."  [ngClass]="{ 'is-invalid ': submitted && ers }"  >
    
  <div *ngIf="submitted && ers" class="invalid-feedback">
    <div *ngIf="ers" style="
    margin-left: 8px;">Search By Text</div>
</div>
  <!-- <div *ngIf="submitted && f.search.errors" class="invalid-feedback">
    <div *ngIf="f.search.errors.required">Search By Text</div>
</div> -->
</div></div>
<div class="col-lg-2"> <button style="position: relative;
  left: -122%;" class="btn btn-secondary btn-fw btn-sm mt-4 " ><i class="fa fa-search
  "></i>Search</button>
 
  
  <button  (click)="reset1()" class="btn btn-outline-secondary btn-sm" style="position: relative;top: -30px;left:18px;" ><i class="fa fa-refresh
      "></i>Refresh</button> 
    </div>
</form>
  </div>
</div>
     
  </div>
</div>
</div>
<div *ngIf="dataDisplay==1"> <div class="d-flex justify-content-center ">



  <div class="spinner-border " role="status" style="position: relative;
  left: -36px;">
  
  <span class="sr-only" id="loading"></span>

</div>

</div> <div class="d-flex justify-content-center mb-3"></div><div class="spinner_overlay"></div> </div>
<div  *ngIf="Sp=='1'" ><button type="button" class="btn btn-success btn-fw  btn-sm" (click)="CheckAllOptions()" style="    background-color: cornflowerblue;">Assigned To All</button></div>
<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
  <div class="card">
    <div class="card-body">
      <!-- <h4 class="card-title" style="font-size: 16px;">Manage Users</h4> -->
      <!-- <p class="card-description">
        Add class
        <code>.table-striped</code>
      </p> -->
      
       
          <div class="row">
        <form class="form-sample w-100" [formGroup]="search1"  (ngSubmit)="Search(drop.value)" style="margin-bottom: 15px;">
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <div style="display: flex;gap: 10px;align-items: center;">
              <div style="display: flex;justify-content: space-between;align-items: center;gap: 3px;">
                <input [disabled]="(sear==1  && (search1.get('searchh').value=='3'||search1.get('searchh').value=='2'||search1.get('searchh').value=='1'))? false :true" (click)="selectCheck(i)" type="checkbox" class="selectall" style="top:0 !important;margin: 0 !important;"><p class="selectallcheckpara" style="margin:0 !important;">SELECT ALL</p>
              </div>
              <div>
                <select style="width: max-content !important;" #drop class="form-control drpdwn" formControlName="SearchBy" (ngModelChange)='searnull()' >
                  <!-- <option value="0">Select</option> -->
                  <option value="" disabled selected hidden>Select</option>
                  <option value="1">Classification Code</option>
                  <option value="2">Name</option>
                  <option value="3">Assigned To</option>
                </select>
              </div>
              <div>
                <input  *ngIf="search1.get('SearchBy').value!='3'" type="text" placeholder="Search" formControlName="searchh" class="form-control" aria-label="..."  [(ngModel)]="dropValue1"  >
                <span style="font-size: 80%; color: #dc3545;" *ngIf="mandatorycheck=='1' && search1.get('SearchBy').value!='3'">Search by text</span>
                <select *ngIf="search1.get('SearchBy').value=='3'" formControlName="searchh" class="form-control" (change)="modo($event.target.value)" >
                  <option value="" disabled selected hidden>Select</option>
                  <option value="1">UnAssigned</option>
                  <option  value="2">Credit Slsp</option>
                  <option value="3">Collect Slsp</option>
                  
                 
                </select>
              </div>
              <button class="btn btn-secondary btn-sm mr-1" ><i class="fa fa-search
                "></i>Search</button>

            </div>
            <div style="display: flex;gap: 10px;align-items: center;">
                <button (click)="reset()" class="btn btn-outline-secondary btn-sm mr-1" ><i class="fa fa-refresh
                  "></i>Reset</button>
                  <button  [disabled]="(sear==1  && (search1.get('searchh').value=='3'||search1.get('searchh').value=='2'||search1.get('searchh').value=='1'))? false :true" (click)="gettabledata1(i)" type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModal1">Assign All</button>
            </div>

          </div>
        <!-- <div class="col-lg-2">
          <h6 class="card-description aligright mb-0" >Search by</h6>
      </div> -->
          <!-- <div class="col-lg-2">
                        <select #drop class="form-control drpdwn" formControlName="SearchBy" (ngModelChange)='searnull()' >
                        
                          <option value="" disabled selected hidden>Select</option>
                          <option value="1">Classification Code</option>
                          <option value="2">Name</option>
                          <option value="3">Assigned To</option>
                        </select>
                      </div>
                      <div class="col-sm-2">

                       
                      </div> -->
                      <!-- <div class="col-sm-4">
             
                      </div> -->
                 
              

                
       
   
              <!-- <button *ngIf="selected.length>=2" type="button"   class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModal1" style="min-width: 120px;">Assign All</button>
            
            <button *ngIf="selected.length<=1"  type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModal1">Assign All</button> -->
      
          
        
      
</form>

          <div *ngIf="selected?.length!=0||ss==1">
          <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModal1Labe" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                  text-align: center;
                  left: 11px;
                  width: 100%;">Do you want to assign?</h5>
                  <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
               
                   
                </div>
                <div class="modal-body">
                  <div class="row">
                    <form class="form-sample w-100 container-fluid" style="    padding-left: 104px;
                    padding-right: 104px;" [formGroup]="radio">
                    <div class="col-sm-6">

                      
                      <div class="form-radio" >
                        <label>
                           
                          <input   type="radio" value="1"  formControlName="radiobutton" [(ngModel)]="radiobutton" [checked]="checkradioo == 1 ? true : false">Credit Slsp </label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                    
                      <div class="form-radio" >
                        <label  >
                          <input type="radio" value="2"  formControlName="radiobutton2" [(ngModel)]="radiobutton2" [checked]="checkradioo1 == 2 ? true : false"> Collect Slsp </label>
                      </div>
                    </div>
                    <div class="col-sm-12" *ngIf="(radiobutton == 0 && radiobutton2 == 0 )" style="text-align: center;">
                        <span style="color: #dc3545;">{{error}}</span>
                    </div>
                    </form>
                   </div>
                </div>
                
                <!-- <div class="modal-body">
                  <p class="modal-title" id="exampleModalLabel1" style="margin-left: 100px;"></p>

                </div> -->
                <div class="modal-footer">
                  <a  style="    width: 65px !important;
                  position: relative !important;
                  left: -1px !important;
                  top: 3px !important;" type="button" (click)="assignbuttonclick1(0)" href="/conversion" class="trigger-btn  btn btn-success btn-sm confrimwidt  btn btn-success btn-block okbtn">Confirm</a>
                  <!--<button id="#myModal" type="button" class="  btn btn-success btn-sm confrimwidt"  data-toggle="modal" data-target="#confirmModal">Confirm</button>-->
                  <button type="button"  (click)="btnClick()"    class="btn btn-danger btn-sm canclwidt"  data-dismiss="modal" style="min-width: 68px;position: relative;
                  left: -143px;">Cancel</button>
                </div>
                <span style="display: none;" id="close_modal" data-dismiss="modal" data-toggle="modal"></span>
              </div>
            </div>
          </div>
        
        </div>
        <div *ngIf="showw==1">
        <div  id="myModal" class="modal fade">
          <div class="modal-dialog modal-confirm">
            <div class="modal-content modal-cont">
              <div class="modal-header">
                <div class="icon-box">
                  <i class="material-icons">&#xE876;</i>
                </div>				
                <h4 class="modal-title w-100">Successfully Asssigned</h4>	
              </div>
              
              <div class="modal-footer">
                <button (click)="btnClick()" class="btn btn-success btn-block okbtn" data-dismiss="modal">OK</button>
              </div>
            </div>
          </div>
        </div>  </div>
    <div *ngIf="selected?.length==0||ss!=1">
            <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModal1Labe" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel1" style="margin-left: 100px;">Select atleast one raw data!</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                
                  </div>
                 
                  <div class="modal-footer">
                   
                 
                    <button type="button"  class="trigger-btn  btn btn-success btn-sm confrimwidt  btn btn-success btn-block okbtn"  data-dismiss="modal" style="min-width: 68px; background-color: green;margin-left: 303px;">OK</button>
                  </div>
                </div>
              </div>
            </div></div>


<!-- Modal HTML -->
<!-- <div id="myModal" class="modal fade">
<div class="modal-dialog modal-confirm">
<div class="modal-content modal-cont">
<div class="modal-header">
<div class="icon-box">
  <i class="material-icons">&#xE876;</i>
</div>				
<h4 class="modal-title w-100">Successfully Asssigned</h4>	
</div>

<div class="modal-footer">
<button (click)="btnClick()" class="btn btn-success btn-block okbtn" data-dismiss="modal">OK</button>
</div>
</div>
</div>
</div>    -->
          
          
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
               Name
              </th>
              <th>
               MC#
              </th>
              <th>
                Classification Code
              </th>
              <th>
          Phone Number
              </th>
              <th>
           Location 
              </th>
              <th>
                AssignedTo
              </th>
              <th >    
              Action
             </th>
                                        
            </tr>
          </thead>
          <tbody >
          
            <!-- | paginate: { itemsPerPage: count, currentPage: p } -->
            
            <tr *ngFor="let item of data_trans ; let i=index" >
              <td  >
            <input  [disabled]="(sear==1  && (search1.get('searchh').value=='3'||search1.get('searchh').value=='2'||search1.get('searchh').value=='1'))? false :true" (change)="selectCheck(i)" [name]="item.Docket_No" value={{item.Docket_No}}-{{item.AssignedTo}} type="checkbox" class="individual"/>   {{  item.Legal_Name  }} 
              </td>
            
              <td>
                {{item.Docket_No }}
              </td>
              <td >
              {{item.Brok_Carr}}
              </td>
              <td>
                {{item.Bus_Phone }}
       
              </td>
              <td>
                {{item.Bus_City }}
              </td>
              <td >
                <button *ngIf="item.AssignedTo==2" type="button"   class="btn btn-success btn-assignedto">
                  S
                 </button>
                 <button  *ngIf="item.AssignedTo==3" type="button"   class="btn btn-success btn-assignedto">
                  C
                </button>
                <button  *ngIf="item.AssignedTo==1" type="button"   class="btn  btn-unassigned">
                  U
                 </button>
                 <button  *ngIf="item.AssignedTo==4" type="button"   class="btn  btn-unassigned">
                  U
                 </button>
                <!-- {{item.AssignedTo}} -->
            </td>
              <td >
                   <!-- Button trigger modal -->
                   <button type="button" (click)="gettabledata(i,item.Legal_Name)"  class="btn btn-primary " data-toggle="modal" data-target="#exampleModal">
                    Assign
                  </button>
                  
                  <!-- Modal -->
                  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          
                          <h5 class="modal-title"   style="position: relative;
                          text-align: center;
                          left: 11px;width: 100%;;
                         "  id="exampleModalLabel" >{{NameTit}}</h5>
                          <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                       <div class="modal-body">
                        <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">Do you want to assign?</h5>

                       </div>
                       <div class="row">
                        <form class="form-sample w-100 container-fluid" [formGroup]="radio">
                        <div class="col-sm-6" style="position: relative;
                        left: 114px;">

                          
                          <div class="form-radio" >
                            <label>
                               
                              <input   type="radio" value="1"  formControlName="radiobutton" [(ngModel)]="radiobutton"  [checked]="checkradio == 1 ? true : false">Credit Slsp </label>
                          </div>
                        </div>
                        <div class="col-sm-6">
                        
                          <div class="form-radio" >
                            <label  >
                              <input type="radio" value="2"  formControlName="radiobutton2" [(ngModel)]="radiobutton2"  [checked]="checkradio1 == 2 ? true : false"> Collect Slsp </label>
                          </div>
                        </div>
                        <div class="col-sm-12" *ngIf="(radiobutton == 0 && radiobutton2 == 0)" style="text-align: center;">
                          <span style="color: #dc3545;">{{error}}</span>
                      </div>
                        </form>
                       </div>

                        <div class="modal-footer">
                         <a  type="button" (click)="assignbuttonclick(i,item.Legal_Name,p,count)" href="/conversion"  class="trigger-btn  btn btn-success btn-sm confrimwidt  btn btn-success btn-block okbtn1" >Confirm</a>
                          <!--<button id="#myModal" type="button" class="  btn btn-success btn-sm confrimwidt"  data-toggle="modal" data-target="#confirmModal">Confirm</button>-->
                          <button type="button" (click)="cancel()" class="btn btn-danger btn-sm canclwidt"  data-dismiss="modal" style="min-width: 67px;">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                 


<!-- Modal HTML -->
<div *ngIf="show==1" >
 <div  id="myModal" class="modal fade">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content modal-cont">
			<div class="modal-header">
				<div class="icon-box">
					<i class="material-icons">&#xE876;</i>
				</div>				
				<h4 class="modal-title w-100">Successfully Asssigned</h4>	
			</div>
			
			<div class="modal-footer">
				<button (click)="btnClick()" class="btn btn-success btn-block okbtn" data-dismiss="modal">OK</button>
			</div>
		</div>
	</div>
</div>    </div>


                      <!---newconfrim popup starts-->
              </td>
      
            </tr>
          
          </tbody>
           <tr *ngIf="data_trans?.length==0 ||data_trans==[0]" class="w-100 text-center ">
            <td class="noData">No Data Available..!</td>
          </tr>
        </table>
      </div>
      </div>
      <div *ngIf="data_trans?.length>=30">
       
      <div class="text-right">
        <!-- <pagination-controls (pageChange)="p = $event"    ></pagination-controls> -->
          <button  [disabled]="p<2"  class="btn btn-info btn-sm  " (click)="pageChange1($event)"  ><<</button>&nbsp;
          <button  class="btn btn-info btn-sm " (click)="pageChange($event)"  >>></button>
      </div>
    </div>
   
   
 </div>
</div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         
         <h5 class="modal-title"   style="position: relative;
         text-align: center;
         left: 11px;width: 100%;;
        "  id="exampleModalLabel" >Data Fetching Error</h5>
       </div>
      <div class="modal-body">
       <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;font-size: medium;">Oops! Something went wrong. It seems there was an issue in fetching the data. To resolve this, please click the Refresh button.</h5>

      </div>
      <div class="row">
   
      </div>

       <div class="modal-footer">
       
         <!--<button id="#myModal" type="button" class="  btn btn-success btn-sm confrimwidt"  data-toggle="modal" data-target="#confirmModal">Confirm</button>-->
         <button type="button" (click)="refreshPage()"  class="btn btn-success  btn-sm canclwidtbut"   style="min-width: 67px;">Refresh</button>
       </div>
     </div>
   </div>
</div>
