

<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
  <div class="card">
    <div class="card-header filters">
      <h4 class="card-title" style="font-size: 16px;">ProspectCustomer Management</h4><span id="poppup" data-toggle="modal" data-target="#exampleModal" ></span>
      <button routerLink="/prospectedit" class="position-absolute left-50 btn btn-success mr-2" 
                  style=" margin-left: 82%;
                  margin-top: -27px;
                  font-size: smaller;
                  background-color: #0c7cd5;" [queryParams]="{Add:0,procus:0}">+Add Prospect</button>
    </div>
      <div class="card-body filterbody" >
        <p class="card-description">
          Filters:
                  </p>
                  <div class="panel filterss" >
          

                    <div class="d-flex justify-content-center flex-wrap filter">
                      <button  [routerLink]="['/conversion']"  class="btn btn-sm" id=Raw-Data>Raw Data Menu</button>
                      <button  [ngClass]="filter.active==0 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(0,'Company_Name')"  class="btn btn-sm" id=Company_Name>Company Name</button>
                      <button  [ngClass]="filter.active==2 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(2,'DBA_Name')"  class="btn btn-sm" id=DBA_Name>DBA</button>
                      <button  [ngClass]="filter.active==3 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(3,'Docket_No')"  class="btn btn-sm" id=Docket_No>MC#</button>
                      <button  [ngClass]="filter.active==4 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(4,'State')"  class="btn btn-sm" id=State>State</button>
                    <!-- <button  [ngClass]="filter.active==5 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(5,'Report')"  class="btn btn-sm" id=Report>Reports Ordered</button>-->
                    <!-- <button  [ngClass]="filter.active==6 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(6,'customer')"  class="btn btn-sm" id=customer>Customer Anniv'ry</button> -->
                    <!--  <button  [ngClass]="filter.active==7 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(7,'client')"  class="btn btn-sm" id=client>Client Anniv'ry</button>-->
                      <button  [ngClass]="filter.active==8 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(8,'Brokers')"  class="btn btn-sm"  id=Brokers>Brokers</button>
                      <button  [ngClass]="filter.active==9 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(9,'Carrier')"  class="btn btn-sm" id=Carrier>Carrier </button>
                      <!-- <button  [ngClass]="filter.active==10 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(10,'Suspended')"  class="btn btn-sm" id=Suspended>Suspended</button> -->
                
                      <button  [ngClass]="filter.active==11 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(11,'ProgramType')"  class="btn btn-sm" id=ProgramType>Program Search</button>
                      <button  [ngClass]="filter.active==12 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(12,'ProgramExpire')"  class="btn btn-sm" id=ProgramExpire>Program Expire</button>
                     <!-- <button  [ngClass]="filter.active==13 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(13,'credit')"  class="btn btn-sm" id=credit>Credit Remaining</button>-->
                      <button  [ngClass]="filter.active==14 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(14,'RecallDate')"  class="btn btn-sm" id=RecallDate> Customer Care Recall</button>
                        
                      <button [ngClass]="filter.active==15 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(15,'CreditRecall')" class="btn btn-sm" id=CreditRecall >Credit Recall</button>
                      <!-- <button [ngClass]="filter.active==16 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(16,'CollectRecall')" class="btn btn-sm" id=CollectRecall>Collect Recall</button> -->
                      <button [ngClass]="filter.active==17 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(17,'Suspended')" class="btn btn-sm" id=NotContacted >Suspended</button>
                    
                  
                   
                  </div>
                  <form class="form-sample w-100 container-fluid row justify-content-center" [formGroup]="registerForm"  (ngSubmit)="searchfunction(this)" >
                  <div class="row justify-content-center" >
          <div class="col-lg-7 mt-4"> 
             <div   *ngIf="filter.active!=8 && filter.active!=9 && filter.active!=14 && filter.active!=10 && filter.active!=12  && filter.active!=15 && filter.active!=16 && filter.active!=17 && fil  || filter.active==4 "> 
            <input type="text" placeholder="Search" formControlName="search" [(ngModel)]="text" class="form-control" aria-label="..." style="    display: block;
            margin-left: -79px;
            width: 392px;" [ngClass]="{ 'is-invalid ': submitted && ers }"  >
    
    <div *ngIf="submitted && ers" class="invalid-feedback">
      <div *ngIf="ers" style="
      margin-left: 8px;">Search By Text</div>
  </div>
                </div> 
		<div   *ngIf="  filter.active==10"> 
       
        <mat-form-field  appearance="fill">
        
          <input  style="background-color: white;" formControlName="search"   matInput [matDatepicker]="dp4" style="margin-bottom: 6px;"  disabled>
          <mat-datepicker-toggle style="color: #17a2b8;" matSuffix [for]="dp4"></mat-datepicker-toggle>
          <mat-datepicker #dp4 disabled="false"></mat-datepicker>
        </mat-form-field>
          
          <div *ngIf="submitted && ers" class="invalid-feedback">
            <div *ngIf="ers && filter.active!=1">Search By Text</div>
        </div>
                      </div>
                      <form [formGroup]="date">
                      <div   *ngIf=" filter.active==12"> 
                        <div class="col-lg-3 mr-2" style="margin-top: -18px;">
                          <label>From Date</label>
                          <mat-form-field appearance="fill">
                            <input matInput readonly [matDatepicker]="picker" formControlName="from"
                          (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                             <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            
                         
                            </div>
                               
                        <div class="col-lg-3" style="margin-left: 100px;margin-top: -18px;">
                                <label>To Date</label>
                                <mat-form-field appearance="fill">
                                  <input matInput readonly [matDatepicker]="picker1" formControlName="to"
                                 (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)">
                                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                   <mat-datepicker #picker1></mat-datepicker>
                                   </mat-form-field>
                                  
                              
                            </div>
                      </div>
                      <div   *ngIf="filter.active==14||filter.active==15||filter.active==16||filter.active==17"> 
                        <div class="col-lg-3 mr-2" style="
                        margin-left: 51px;
                    margin-top: -18px;">
                          <label>From Date</label>
                          <mat-form-field appearance="fill">
                            <input matInput readonly [matDatepicker]="picker"   formControlName="from1"
                          (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                             <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            
                         
                            </div>
                               
                        <div class="col-lg-3" style="margin-left: 134px;margin-top: -18px;">
                                <label>To Date</label>
                                <mat-form-field appearance="fill">
                                  <input matInput readonly [matDatepicker]="picker1" formControlName="to1"
                                 (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)">
                                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                   <mat-datepicker #picker1></mat-datepicker>
                                   </mat-form-field>
                                  
                              
                            </div>
                      </div>
                      </form>
                    </div>
                    <div  *ngIf="filter.active ==12"  style="margin-top:15px;">
                    <div class="col-lg-2"> 
                      <button type="submit" class="btn btn-secondary btn-fw btn-sm mt-4" style="    margin-left: -58px; ">
                      <i class="fa fa-search"></i>Search</button>
                      <button (click)="reset1()" class="btn btn-outline-secondary btn-sm" style="     position: relative;
                      left: 80px;
                      top: -32px;" ><i class="fa fa-refresh
                        "></i>Refresh</button>
                      </div>
                      </div>
                    <div  *ngIf="filter.active!=12">
                      <div class="col-lg-2">
          <div  *ngIf="filter.active!=8 && filter.active!=9"> 
            <button class="btn btn-secondary btn-fw btn-sm mt-4"  ><i class="fa fa-search
            "></i>Search</button>
           
          </div></div> <button  *ngIf="filter.active!=8 && filter.active!=9 " (click)="reset1()" class="btn btn-outline-secondary btn-sm" style="    position: relative;
          left: 120px;;
          top: 24px;" ><i class="fa fa-refresh
            "></i>Refresh</button></div>
            <div  *ngIf="filter.active==8 ||filter.active==9  " class="col-lg-2"> <button class="btn btn-secondary btn-fw btn-sm mt-4"  style="margin-left: 
             110px;"><i class="fa fa-search
                          "></i>Search</button>
                          <button (click)="reset1()" class="btn btn-outline-secondary btn-sm" style="     position: relative;
                          left: 262px;
                          top: -32px;" ><i class="fa fa-refresh
                            "></i>Refresh</button>
                          
                        </div>
          </div>
          </form>
         
  </div>
</div>
     
  </div>
</div>
</div>
<div *ngIf="dataDisplay==1"> <div class="d-flex justify-content-center ">



  <div class="spinner-border" role="status" style="position: relative;
  left: -61px;top:-15px;">
  
  <span class="sr-only" id="loading"></span>

</div>

</div> <div class="d-flex justify-content-center mb-3"></div><div class="spinner_overlay"></div> </div>
<div class="row">
  <div class="col-lg-12 grid-margin stretch-card" style="padding:14px;
      padding-bottom:0px;margin-top:-28px;">
  <div class="card">
    <!-- <div class="card-header">
      <h4 class="card-title" style="font-size: 16px;">Manage Users</h4>
     
    </div> -->
      <div class="card-body">
        <div class="row justify-content-start">
          <form class="form-sample w-100 container-fluid row justify-content-center" [formGroup]="search1"  (ngSubmit)="Search(drop.value)" >
            <div class="row justify-content-start">
             <div class="col-lg-2">
                 <h6 class="card-description aligright mb-0" style="position: relative;
                 left: -53px;" >Search by</h6>
             </div>
                 <div class="col-lg-2">
                                   <select #drop class="form-control drpdwn" formControlName="SearchBy"   style="    position: relative;
                                   left: -77px;
                                   width: 103px;">
                             <!-- <option value="0">Select</option> -->
                             <option value="" disabled selected hidden>Select</option>
                             <option value="1">Classification Code</option>
                             <option value="2">Name</option>
                             <option value="3">Customer Care ReCall  </option>
                             <option value="4">Credit Recall </option>
                             <!-- <option value="5">Collect Recall </option> -->
                             <option value="6">State  </option>
                             <option value="7">Customer Rep  </option>
                             <option value="8">Sales Person</option>
                             <option value="9">Status</option>
                           </select>
                             </div>
                             <div class="col-sm-4">

       <div *ngIf="search1.get('SearchBy').value!='3'&& search1.get('SearchBy').value!='4'&& search1.get('SearchBy').value!='5' && search1.get('SearchBy').value!='9'">
                               <input  type="text" placeholder="Search" formControlName="searchh" #searchInput class="form-control search searchbox" aria-label="..." >
                               <span style="font-size: 80%; color: #dc3545;" *ngIf="mandatorycheck==1">Search by text</span>
                             </div>

                             <div *ngIf="search1.get('SearchBy').value=='9'" >
                             <select class="form-control search searchbox" formControlName="searchh" >
                              <option value="" disabled selected hidden>Select</option>
                              <option value="Prospect">Prospect</option>
                              <option value="Customer">Customer</option>
                              >
                            </select>
                          </div>

                             <form [formGroup]="date">
                            <div *ngIf="search1.get('SearchBy').value=='3'|| search1.get('SearchBy').value=='4'||search1.get('SearchBy').value=='5'"> 
                             <div class="col-lg-3 mr-2" style="    margin-left: -130px;">
                               <label style="    font-weight: 500;
                               font-size: 13px;
                               position: relative;
                               top: 7px;
                               left: 7px;">From</label>
                               <mat-form-field appearance="fill"  style="    position: relative;
                               text-align: left;
                               top: -37px;
                               left: 63px;
                               width: 141px;">
                                 <input matInput readonly [matDatepicker]="picker" formControlName="from1"
                               (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)">
                                 <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                  <mat-datepicker #picker></mat-datepicker>
                                 </mat-form-field>
                                 
                              
                                 </div>
                                    
                             <div class="col-lg-3" style="    margin-left: 127px;">
                                     <label style="    font-weight: 500;
                                     font-size: 12px;
                                     position: relative;
                                     top: 7px;
                                     left: -24px;">To</label>
                                     <mat-form-field appearance="fill" style="      position: relative;
                                     text-align: left;
                                     top: -37px;
                                     left: 9px;
                                     width: 141px;">
                                       <input matInput readonly [matDatepicker]="picker1" formControlName="to1"
                                      (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)">
                                       <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                       
                                   
                                 </div>
                           </div></form>
                          </div>
                             <div class="col-sm-2">
                     <button   *ngIf="search1.get('SearchBy').value=='3'" class="btn btn-secondary btn-sm" style="    position: relative;
                     left: 48px;"><i class="fa fa-search
                        "></i>Search</button>
   
                        <button *ngIf="search1.get('SearchBy').value!='3'"  class="btn btn-secondary btn-sm" style="  position: relative;left: 3px;width: 118px;"><i class="fa fa-search
                           "></i>Search</button>
                        
   
                       </div>
                       <div class="col-sm-2" style="    position: relative;
                       left: 22px;">
                         <button (click)="reset1()" class="btn btn-outline-secondary btn-sm" ><i class="fa fa-refresh
                           "></i>Reset</button>
                       </div>
         </div>
                       </form>
      </div>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th (click)="sortt(21,'Name')" id=Name >
             Client/Customer Name <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
              </th>
              
              <th (click)="sortt(22,'MCNumber')" id=MCNumber >
                MC# <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort  fa-lg fontz"></i>
              </th>
              <th (click)="sortt(23,'Code')" id=Code>
                Classification Code <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
              </th>
              <th (click)="sortt(24,'CCRecall')" id=CCRecall >
              Customer Care Recall <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
              </th>
              <th (click)="sortt(25,'CreditRecall')" id=CreditRecall >
                Credit Recall <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
              </th>
              <!-- <th (click)="sortt(26,'CollectRecall')" id=CollectRecall >
                CollRecall  <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
              </th> -->
              <th (click)="sortt(31,'Suspended')" id=Suspended >
                Suspended  <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
              </th>
               <th (click)="sortt(27,'State')" id=State >
                  State <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
               </th>
              <th (click)="sortt(28,'PhoneNumber')" id=PhoneNumber >
                Phone <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
               </th>
                <th (click)="sortt(29,'StartDate')" id=StartDate >
                     Start Date <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                 </th>
                 <th (click)="sortt(30,'Trucks')" id=Trucks >
                 Truck# <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
              </th>
              <th (click)="sortt(32,'Status')" id=Status >  
                Status <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
             </th>
                                               
                                                            
            </tr>
          </thead>
          <tbody>
            <!-- | paginate: { itemsPerPage: count, currentPage: p } -->
              <tr  *ngFor="let item of data_trans; let i=index" > 
         
                  <td>
                    <a routerLink="/proscustform" (click)="currentpage()"  [queryParams]="{Docket_No:item.Docket_No,prosid:item.prosid,index:i,itemsPerPage:count,currentPage:p,AS:item.AccountStatus,TN:item.Tablename,cusid:item.customerid,totalCount:data_trans.length}"> {{item.Legal_Name}}</a> 
                  </td>
                  <td>
                    {{item.Docket_No}}
                  </td>
                  <td>
                    {{item.BCCode}}
                  </td>
                  <td>
                   {{item.CCRecall}}
                  </td>
                  <td>
                  {{item.CreditRecall}}
           
                  </td>
                  <!-- <td>
                    {{item.CollRecall}}
                   </td> -->
                   <td>{{item.Suspended}}</td>
                   <td class="statecenter">
                   {{item.Bus_State}}
            
                   </td>
                  
                  <td>
                    {{item.Bus_Phone}}
                  </td>
                  <td>
                   {{item.StartDate}}
         
                </td>
                <td>
                  {{item.trucks}}
        
               </td>
          <td>{{item.AccountStatus}}</td>
                </tr>
             
          </tbody>
          <tr *ngIf="data_trans.length==0" class="w-100 text-center ">
            <td ></td>
            <td class="noData" style="    margin-left: -280px;">No Data Available..!</td>
          </tr>
        </table>
      </div>
      <div >
    
      <div class="text-right" >
        <label class="recordfound" style="text-align:left;float: left;">Total  number of records found : {{resleng}}</label> 
        <div >
        <button  [disabled]="p<2"  class="btn btn-info btn-sm  " (click)="pageChange1($event)"  ><<</button>&nbsp;
          <button  [disabled]="data_trans.length <=30" class="btn btn-info btn-sm " (click)="pageChange($event)"  >>></button></div>
        <!-- <pagination-controls (pageChange)="p = $event"    ></pagination-controls> -->
      </div>
    </div>
    </div>
  </div>
</div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         
         <h5 class="modal-title"   style="position: relative;
         text-align: center;
         left: 11px;width: 100%;;
        "  id="exampleModalLabel" >Data Fetching Error</h5>
       </div>
      <div class="modal-body">
       <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;font-size: medium;">Oops! Something went wrong. It seems there was an issue in fetching the data. To resolve this, please click the Refresh button.</h5>

      </div>
      <div class="row">
   
      </div>

       <div class="modal-footer" style="justify-content: center !important">
       
         <!--<button id="#myModal" type="button" class="  btn btn-success btn-sm confrimwidt"  data-toggle="modal" data-target="#confirmModal">Confirm</button>-->
         <button type="button" (click)="refreshPage()"  class="btn btn-success  btn-sm canclwidt"   style="min-width: 67px;">Refresh</button>
       </div>
     </div>
   </div>
</div>