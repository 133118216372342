<form [formGroup]="Customer" id="fome-reload"  (ngSubmit)="onSubmit1()" class="forms-sample">
  <div class="row">
  
      <div class="col-md-12 grid-margin stretch-card">
        
        <div class="card">
          <!-- custinfo -->
          <div id="custinfoid" class=" card-header custinfo" >
            <h6 class="card-title a1 hd1" style="font-size: 1rem;"> Customer/Client Info:<span *ngIf="CustomerData?.formfileds" style="font-weight: bold;font-size: 17px;"> {{name}}&nbsp;{{CustomerData?.formfileds[0]?.Docket_No}}</span> 
             <div style="float: right;"> <span>
            <button  [disabled]="displaynext!=1 || savedisable==1" type="submit"   class="btn btn-success mr-2 btn-sm" style="   position: relative;
              top: -7px;
              left: 23px;"><i class="mdi mdi-file-document" ></i>Save</button>
              <!-- <button *ngIf="userDetail!='3'" type="button" class="btn btn-primary mr-2 btn-sm">Save & Convert to Customer</button> -->
            
            
              
              
              <button  [disabled]="g==1 || displaynext!=1 ||savedisable==1" (click)="getprevious()"  type="button" class="btn btn-success mr-2 btn-sm" style="    position: relative;
              top: -7px;
              left: 23px;">Previous</button>
              <button   [disabled]="diablenext==true || displaynext!=1 ||savedisable==1"   (click)="getnext()"  type="button" class="btn btn-success mr-2 btn-sm" style="    position: relative;
              top: -7px;
              left: 23px;">Next</button>




          <!-- <button  [disabled]="g2==1 || g==1"  (click)="sampleprevious()"   type="button" class="btn btn-success mr-2 btn-sm" style="    position: relative;
          top: -7px;
          left: 23px;">Previous</button>
          <button    [disabled]="g1==1" (click)="samplenext()"   type="button" class="btn btn-success mr-2 btn-sm" style="    position: relative;
          top: -7px;
          left: 23px;">Next</button> -->
              
             <button  [disabled]="savedisable==1"   type="button"  (click)="button1()" class="btn btn-secondary mr-2 btn-sm" style="    position: relative;
              top: -7px;
              left: 23px;" >Back to Customer/Client</button>
             
              <button  [disabled]="savedisable==1"  type="button" (click)="button2()" class="btn btn-secondary mr-2 btn-sm" style="    position: relative;
              top: -7px;
              left: 23px;" *ngIf="dropcheck ==1">Back to Recall</button>
        
            </span></div></h6> 
         </div>
          <div class="card-body" style="    margin-top: 15px;">
            <!-- <h4 class="card-title">Basic form</h4> -->
           
              <div class="tab"  style="border: 1px solid #ced4da;padding-bottom: 37px;">
                <div class="row" style="margin-top: 20px;">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Name</label>
                    <div class="col-sm-4 positionn">
                      <input  formControlName="Legal_Name" type="text" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.Legal_Name.errors }" placeholder=""/>
                      <div *ngIf="submitted && f.Legal_Name.errors" class="invalid-feedback">
                        <div *ngIf="f.Legal_Name.errors.required">  <sup>*</sup>Name is required</div>
                    </div>
                    </div>
                    
                        <label class="col-sm-3 col-form-label">Cust Type</label>
                        

                  </div>
                  
                </div>
                
                <div class="col-md-6">
                  
                    <div class="form-group row">
                      
                        <div class="col-sm-4 s1"  >
                          
                          <!-- <hlo> -->
                            <!-- <div ng-class="{'my-disable':user.GetAccess().RoleID==1}"> -->
                          <select  [attr.disabled]="user.GetAccess().RoleID==3 ||user.GetAccess().RoleID==2 ? '': null" formControlName="clicus" [(ngModel)]="Clicus" class="form-control" placeholder="">
                            <option value="" disabled selected hidden>Select</option>
                            <option value="Client">Client</option>
                            <option value="Customer">Customer</option>
                            <option value="Client/ Customer">Client/ Customer</option>
                           
                          </select>
                            <!-- </div> -->
                         
                          </div>

                          <label class="col-sm-3 col-form-label">Truck#</label>
                          <div class="col-sm-4">
                            
              

                            <input  formControlName="trucks"  type="number"  class="form-control" placeholder="" />
                          </div>
                        <!-- <label class="col-sm-3 col-form-label">CC Recall</label>
                <div class="col-sm-4" style="margin-right: 60px;"> -->
                  <!-- <mat-form-field  appearance="fill" (click)="picker.open()">
                    <input readonly matInput [matDatepicker]="picker" 
                    placeholder="mm/dd/yyyy" (click)="picker.open()" [value]="d" [(ngModel)]="d"
                    formControlName="CCRecall" (dateInput)="d" dateFormat="mm/dd/yy">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field> -->
                  <!-- <mat-form-field appearance="outline" (click)="picker.open()">
                    <input readonly matInput matInput [matDatepicker]="picker"
                    placeholder="mm/dd/yyyy" (click)="picker.open()" [value]="d" [(ngModel)]="d"
                    formControlName="CCRecall" (dateInput)="d" dateFormat="mm/dd/yy">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field> -->
                  <!-- <input formControlName="CCRecall"   type="text" class="form-control" placeholder="MM-dd-yyyy"/> -->
                  <!-- <input  formControlName="CCRecall" type="date" class="form-control" placeholder=""/> -->
                <!-- </div> -->
                
                   
              
                    </div>
              </div>
            </div>
           
            <div class="row" style="    margin-top: 1rem;">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">DBA</label>
                  <div class="col-sm-4 positionn">
                    <input  formControlName="DBAName" type="text" class="form-control" placeholder=""/>
                  </div>
                  
                      <label class="col-sm-3 col-form-label">Cust ID</label>
                      

                </div>
            
              </div>
              <div class="col-md-6">
                  <div class="form-group row">
                      
                      <div class="col-sm-4 s1"  >

                        <input [attr.disabled]="user.GetAccess().RoleID!=1 ? '': null || user.GetAccess().RoleID!=2 ? '': null || user.GetAccess().RoleID!=3 ? '': null || user.GetAccess().RoleID!=4 ? '': null"    formControlName="CustID"  [(ngModel)]="CustID" type="number" class="form-control" placeholder="" />
                        
                      
                      </div>

                      <label class="col-sm-3 col-form-label">Credit / Analysis</label>
                 
              <div class="col-sm-4">
                
               
                  <input disabled formControlName="creditscore" type="text" class="form-control" placeholder=""/>
          
               </div>
              
                 
            
                  </div>
            </div>
          </div>
            <div class="row" style="    margin-bottom: 2rem;">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Phone #</label>
                  <div class="col-sm-4 positionn">
                    <!-- [attr.disabled]="user.GetAccess().RoleID!=1 ? '': null"  -->
                    <!-- <input  formControlName="Docket_No" [(ngModel)]="doc" type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Docket_No.errors }" placeholder="" disabled/> -->
                 
                    <input   formControlName="Bus_Phone" type="text" appPhoneMask class="form-control" placeholder="" maxlength="14"/>
                  </div>
                  
                      <label class="col-sm-3 col-form-label">Web ID</label>

                </div>
            
              </div>
              <div class="col-md-6">
                  <div class="form-group row">
                      
                      <div class="col-sm-4 s1">
                        <input [attr.disabled]="user.GetAccess().RoleID!=1 ? '': null || user.GetAccess().RoleID!=2 ? '': null || user.GetAccess().RoleID!=3 ? '': null || user.GetAccess().RoleID!=4 ? '': null" formControlName="WebID" type="number" class="form-control" placeholder="" />
                       
                        </div>
                 
                      <label class="col-sm-3 col-form-label">Payment Trends</label>
                      <div class="col-sm-4">
                        <input disabled formControlName="paytrend" type="text" class="form-control" placeholder=""/>
                        </div>
                  </div>
            </div>
          </div>
      
        <div class="row" style="margin-top: -29px;">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Toll Free</label>
              <div class="col-sm-4 positionn">
                <input  formControlName="Bus_Toll" type="text" appPhoneMask class="form-control" placeholder="" maxlength="14"/>
              </div>
              <label class="col-sm-3 col-form-label">PIN</label>
                

            </div>
        
          </div>
          <div class="col-md-6">
              <div class="form-group row">
                  
                  <div class="col-sm-4 s1">
                    <input  formControlName="Pin"  [(ngModel)]="Pin" type="text" class="form-control" placeholder="" maxlength = "10"/>
                    <!-- <input  formControlName="Mail_Fax" type="text" class="form-control" placeholder="e.g. Accounts Payable"/> -->
                    </div>
             
                    <label class="col-sm-3 col-form-label">Credit SLSP</label>
                    <div class="col-sm-4"> 

                      <select    formControlName="Userid" class="form-control" style="width: 103px;" [(ngModel)]="d4"  >
                        <option *ngFor="let item of elements" [value]="item.SPUserid">{{item.AllActive}}</option>
                      </select>
                      <input  type="text"  [(ngModel)]="initials" formControlName="Initials" class="form-control inputselect" placeholder="" />




                        <!-- <input  formControlName="CollectSlsp" type="text" class="form-control" placeholder=""/> -->
                    
                      </div>
        
              </div>
        </div>
      </div>
      <div *ngIf=" dataDisplay==1 &&  this.disply!=1"> <div class="d-flex justify-content-center ">



        <div class="spinner-border " role="status" style="margin-top: -50px;">
        
        <span class="sr-only" id="loading"></span>
        
        </div>
        
        </div> <div class="d-flex justify-content-center mb-3"> </div><div class="spinner_overlay"></div> </div>
      <div class="row" style="    margin-top: -23px;
      margin-bottom: -18px;">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">FAX #</label>
            <div class="col-sm-4 positionn">
              <input  formControlName="Bus_Fax"  type="text" appPhoneMask class="form-control" placeholder="" maxlength="14" />
             
            </div>
            
                <label class="col-sm-3 col-form-label">Classification Code</label>

          </div>
      
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                
                <div class="col-sm-4 s1">
               

                  <select formControlName="BCCodeID" class="form-control"    [ngClass]="{ 'is-invalid': submitted && f.Brok_Carr.errors }" >
                  
                    <option *ngFor="let item of classdrop" value={{item.Codeid}}>{{item.Code}}</option>
                  </select>
                  </div>
           
                <label class="col-sm-3 col-form-label">Collect SLSP</label>
                <div class="col-sm-4">

                  <select    formControlName="clUserid" class="form-control"  [(ngModel)]="d5" style="width: 103px;"  [ngClass]="{ 'is-invalid': submitted && f.clUserid.errors }">
                    <option *ngFor="let item of elements1" [value]="item.CLUserid">{{item.AllActive}}</option>
                  </select>
                 <input  type="text"  formControlName="CollectInitials" class="form-control inputselect" [(ngModel)]="clinitials" placeholder="" />
                 <div style="    position: relative;
                 top: -32px;" *ngIf="submitted && f.clUserid.errors " class="invalid-feedback ">
                  <sup>*</sup>Select One Person
                </div> 

                 
                   
                </div>
            </div>
      </div>
    </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">MC #</label>
            
            <div class="col-sm-4 positionn">
         
                
              <input *ngIf="show==0" type="text"  formControlName="Docket_No" [(ngModel)]="doc"  class="form-control"   placeholder="" disabled  style="width: 102px;" />
                       
              <select *ngIf="show==1" formControlName="Docket_Nodrp" class="form-control" [(ngModel)]="split"  [ngClass]="{ 'is-invalid': submitted && f.Docket_No1.errors }" style="width: 67px;margin-left: -61px;" >
        
                <option value="MC" >MC</option>
                <option value="MX" >MX</option>
                <option value="FF" >FF</option>

              </select>
             
              <input *ngIf="show==1" type="text" (keypress)="keyPressNumbers($event)"  [(ngModel)]="splitted" formControlName="Docket_Notxt" style="width: 104px;position: relative;left: 11px;top: -32px;" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.Docket_No.errors }" placeholder=""  maxlength="8"/>
             
             
              <a *ngIf="show==0"  href="javascript:void(0);" (click)="editfnct5()" style="    position: absolute;
              left: 122px;
              top: 3px;
              width: -5px;
              font-size: smaller;">Edit</a>


             <a *ngIf="show==1"  href="javascript:void(0);" (click)="cancel()" style="    position: absolute;
              left: 133px;
              top: 3px;
              width: -5px;
              font-size: smaller;">cancel</a>
           
           
           
              <div *ngIf="submitted && f.Docket_No.errors" class="invalid-feedback">
                <div *ngIf="f.Docket_No.errors.required"> <sup>*</sup> MC is required</div>
            </div>
             
          </div>
          
              <label class="col-sm-3 col-form-label">Classification Type
              </label>

        </div>
    
      </div>
      <div class="col-md-6">
          <div class="form-group row">
              
              <div class="col-sm-4 s1 ">
                <select formControlName="Brok_Carr" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.Brok_Carr.errors }" >
                  
                  <option *ngFor="let item of tt" value={{item.Typeid}}>{{item.Type}}</option>
                </select>
                  <div  *ngIf="submitted && f.Brok_Carr.errors " class="invalid-feedback d-inline-block">
                  <sup>*</sup>Select One Type
                  </div>
     
                </div>
                <label class="col-sm-3 col-form-label">Cust Care Rep</label>
                <div class="col-sm-4">

       <!-- <input  formControlName="CR_Name" type="text" class="form-control" placeholder=""/> -->
       <select style="  width: 151px;"   formControlName="ccUserid" class="form-control"  [(ngModel)]="d6"  >
        <option *ngFor="let item of elements2" value={{item.CCUserid}}>{{item.AllActive}}</option>
      </select>
      <!-- <div style="position: relative;
      top: -1px;" *ngIf="submitted && f.ccUserid.errors " class="invalid-feedback ">
            <sup>*</sup>Select One Person
          </div> -->
                 

                 



                 
                   
                </div>
<!--          
                <label class="col-sm-3 col-form-label">Collection Recall</label>
              <div class="col-sm-4">
                <mat-form-field appearance="none" (click)="picker2.open()" class="collectrecalll">
                  <input  style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px;"  matInput [matDatepicker]="picker2"
                  placeholder="mm/dd/yyyy" (click)="picker2.open()"   [(ngModel)]="diss1" (dateInput)="diss1"
                  formControlName="CollRecall" dateFormat="mm/dd/yy">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
           
          </div> -->
          </div>
    </div>
  </div>
  <div class="row" *ngIf="show==1" style="margin-top:-29px;">
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Email Id</label>
        <div class="col-sm-4 positionn">
          <input  formControlName="Email" type="email" class="form-control" placeholder=""/>
        
        </div>
        
            <label class="col-sm-3 col-form-label">
              Other Location</label>
            

      </div>
  
    </div>
    <div class="col-md-6">
        <div class="form-group row">
            
            <div class="col-sm-4 s1"  >
           
              <input  formControlName="otherloc" type="text" class="form-control" placeholder=""/>
        
            
            </div>
          
              <label class="col-sm-3 col-form-label">
                Website</label>
            
          
              <div class="col-sm-4">
                <input  formControlName="Website"  [(ngModel)]="website" type="text" class="form-control" placeholder=""/>
              </div>

        </div>
  </div>
</div> 
<div class="row" *ngIf="show==0 ">
  <div class="col-md-6">
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Email Id</label>
      <div class="col-sm-4 positionn">
        <input  formControlName="Email" type="email" class="form-control" placeholder=""/>
      
      </div>
      
          <label class="col-sm-3 col-form-label">
            Other Location</label>
          

    </div>

  </div>
  <div class="col-md-6">
      <div class="form-group row">
          
          <div class="col-sm-4 s1"  >
         
            <input  formControlName="otherloc" type="text" class="form-control" placeholder=""/>
      
          
          </div>
        
            <label class="col-sm-3 col-form-label">
              Website</label>
          
        
            <div class="col-sm-4">
              <input  formControlName="Website"  [(ngModel)]="website" type="text" class="form-control" placeholder=""/>
            </div>

      </div>
</div>
</div>
  
  <!-- <div class="row" style="margin-top: -18px;">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">CC Recall</label>
            
            <div class="col-sm-4 positionn">
              <mat-form-field appearance="none" (click)="picker.open()"  class="ccrecalll">
                <input style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px; "  matInput [matDatepicker]="picker" 
                placeholder="mm/dd/yyyy" (click)="picker.open()"   [(ngModel)]="diss"
                formControlName="CCRecall" (dateInput)="diss" dateFormat="mm/dd/yy">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
        </div>
        <label class="col-sm-3 col-form-label">Credit Recall </label>
      </div>  
    </div>
    <div class="col-md-6">
        <div class="form-group row">
            <div class="col-sm-4 s1">
              
        
    <mat-form-field appearance="none" (click)="picker1.open()" class="creditrecalll">
            <input style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px;"   matInput [matDatepicker]="picker1"
            placeholder="mm/dd/yyyy" (click)="picker1.open()"   [(ngModel)]="diss2"
            formControlName="CreditRecall" (dateInput)="diss2" dateFormat="mm/dd/yy">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field> 
                

          </div>
          
           
        </div>
    </div>
  </div> -->

     
 <div *ngIf="user.GetAccess().RoleID==1 || user.GetAccess().RoleID==4||user.GetAccess().RoleID==2">
     <div class="row" style="    margin-top:6px;margin-bottom: -44px">
          <div class="col-md-12">
        <div class="form-group row">
            <div class="col-sm-6 " style="margin-top: 10px; margin-left: -15px">
                <label class="col-sm-3 col-form-label">Billing Type</label>
            
    </div>
            
         
         
          <div class="col-sm-2" style="margin-left: -38%">
            <div class="form-check" >
              <label><input type="checkbox" [(ngModel)]="c" formControlName="CreditCard" onclick="return false"> Credit Card</label>
            </div>
                </div>
          
                <div class="col-sm-2  ">
                  <div class="form-check" >
                    <label ><input type="checkbox" [(ngModel)]="ach"  formControlName="ACH" onclick="return false"> ACH</label>
                  </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-check">
                          <label ><input type="checkbox"  [(ngModel)]="check"  formControlName="Cheque" onclick="return false">Check</label>
                        </div>
                            </div>
              
            </div>
          </div>
    </div>
    <!-- <div class="col-md-6">
      <div class="form-group row">
        <div class="col-sm-4 s1">
          <div class="form-check" style="margin-left: -80px;">
            <label ><input type="checkbox" formControlName="Cheque" onclick="return false">Cheque</label>
          </div>
        </div>
      </div>
    </div> -->

            
    </div>
    
  <!-- <div *ngIf="user.GetAccess().RoleID!=1 && user.GetAccess().RoleID!=4 && user.GetAccess().RoleID!=2">
      <div class="row" style="margin-top: -55px;">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label" style=" top: 37px;">FAX#</label>
            <div class="col-sm-4 s1">

              <input  formControlName="Bus_Fax" type="text" appPhoneMask class="form-control" placeholder="" style="    margin-left: 16px;" maxlength="14" style="position: relative;
              top: 36px;
              left: 16px;"/>
              
              </div>
          </div>
        </div>
       
        
      </div>
      
      
     

              
      </div>-->
      
    </div>
      
        
            <h6 style="margin-left: 5px;margin-top: 5px;">Physical Address </h6>
            <div class="tab"  style="border: 1px solid #ced4da;">
           <div class="row" style="margin-top: 20px;">
            <div class="col-md-7">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Address</label>
                <div class="col-sm-4 ">
                    <input  formControlName="Bus_Address" type="text" style="margin-left: -44px;
                    width: 220px;"  class="form-control"   placeholder=""/>
                    <!-- <div *ngIf="submitted && f.Bus_Address.errors" class="invalid-feedback">
                      <div *ngIf="f.Bus_Address.errors.required"> <sup>*</sup> Address is required</div>
                  </div> -->
                </div>
                <label class="col-sm-4 col-form-label">City / State / zip</label>

              </div>
          
            </div>
            <div class="col-md-5">
                <div class="form-group row">
                   
                    <div class="col-sm-4 s1">
                        <input  formControlName="Bus_City" type="text" class="form-control"   placeholder=""/>
                        <!-- <div *ngIf="submitted && f.Bus_City.errors" class="invalid-feedback">
                          <div *ngIf="f.Bus_City.errors.required">  <sup>*</sup>City is required</div>
                      </div> -->
                      </div>
               
                    
                    <div class="col-sm-4" style="margin-left: 15px;">
                      <input  formControlName="Bus_State" type="text" class="form-control othersip"   placeholder="" maxlength="2" [ngModel]="inputvalue" 
                      (ngModelChange)="inputvalue = $event.toUpperCase()"/>
                      <!-- <select formControlName="Bus_State" class="form-control" (ngModelChange)='county()'  [(ngModel)]="optionValue">
                          
                        <option >Others</option>
                         <option *ngFor="let item of t" [value]="item" >{{item}}</option>
                   
                      </select> -->
                      <!-- <div class="invalid-feedback d-inline-block" *ngIf="submitted && f.Bus_State.errors">
                        <sup>*</sup>Select One State
                      </div> -->
                      </div>
                  
                  <div class="col-sm-4">
                    <input  formControlName="Zip" type="text" [(ngModel)]="zip" class="form-control" placeholder=" "maxlength = "10"/>
                    <!-- <div *ngIf="submitted && f.Zip.errors" class="invalid-feedback">
                      <div *ngIf="f.Zip.errors.required">  <sup>*</sup>zip is required</div>
                  </div> -->
                  </div>
                </div>
          </div>
        </div>
            <div class="row">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Country</label>
                    <div class="col-sm-4 positionn">
                      <select class="form-control"    formControlName="Bus_Country">
    <option value="US">USA </option>
    <option  value="CA">CN</option>

  </select>
                            <!-- <div *ngIf="submitted && f.Bus_Country.errors" class="invalid-feedback">
                              <div *ngIf="f.Bus_Country.errors.required"> <sup>*</sup> Country is required</div>
                          </div> -->
                    </div>
                    
                     
                  </div>
              
                </div>
                <div class="col-md-5">
                  <div class="form-group row">
                     
                      <div class="col-sm-3 others"   *ngIf="optionValue == 'Others' || dropval == 1">
                         
                          <!-- <div *ngIf="submitted && f.Bus_City.errors" class="invalid-feedback">
                            <div *ngIf="f.Bus_City.errors.required"> <sup>*</sup> City is required</div>
                        </div> -->
                      </div>
                 
                      
                      </div>
                     
                        <!-- <div class="invalid-feedback d-inline-block" *ngIf="submitted && f.Bus_State.errors">
                          <sup>*</sup>Select One State
                        </div> -->
                      
                        </div>
               
            </div>
            </div>
            <h6 style="margin-left: 5px;margin-top: 5px;">Mailing Address </h6>
           
            <div class="tab"  style="border: 1px solid #ced4da;">
            <div class="row"style="margin-top: 20px;">
              <div class="col-md-7">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">  Address</label>
                  <div class="col-sm-4 ">
                    <input  formControlName="Mail_Address" [(ngModel)]="MailAddress" type="text" style="margin-left: -44px;
                    width: 220px;"  class="form-control" placeholder=""/>
                  </div>
                  <label class="col-sm-4 col-form-label">City / State / zip</label>

                </div>
            
              </div>
              <div class="col-md-5">
                <div class="form-group row">
                   
                    <div class="col-sm-4 s1">
                      <input  formControlName="Mail_City"  [(ngModel)]="MailCity" type="text" class="form-control" placeholder=""/>
                      </div>
                    
                    
                    <div class="col-sm-4" style="margin-left: 15px;">
                      <input  formControlName="Mail_State" [(ngModel)]="MailState" type="text" class="form-control othersip"   placeholder="" maxlength="2" [ngModel]="inputvalue1" 
                      (ngModelChange)="inputvalue1 = $event.toUpperCase()"/>
                      <!-- <input  formControlName="Mail_State"  [(ngModel)]="MailState" type="text" class="form-control" placeholder=""/> -->
                      <!-- <select formControlName="Mail_State" class="form-control"   [(ngModel)]="MailState" (ngModelChange)='county()' [(ngModel)]="optionValue1">
                        <option >Others</option>
                        <option *ngFor="let item of t" [value]="item" >{{item}}</option>
                   
                      </select> -->
                   
                      <!-- <div class="invalid-feedback d-inline-block" *ngIf="submitted && f.Bus_State.errors">
                        <sup>*</sup>Select One State
                      </div> -->
                    </div>
                  
                  <div class="col-sm-4">
                    <input  formControlName="Mail_Zip"  [(ngModel)]="MailZip" type="text" class="form-control" placeholder="" maxlength = "10"/>
                  </div>
               
                </div>
          </div>
          </div>

         
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Country</label>
              <div class="col-sm-4 positionn">
                <!-- <input class="form-control"  type="text"  formControlName="Mail_Country"> -->
                <select class="form-control"    formControlName="Mail_Country" [(ngModel)]="MailCountry">
                  <option value="US">USA </option>
                  <option  value="CA">CN</option>
              
                </select>
                                         </div>
                                         <button (click)="copybilling()" type="button" class="btn btn-primary mr-2 btn-sm" style="width: 142px;margin-left: 22px;">Copy Billing Address</button>

            </div>
           
          </div>
          <div class="col-md-6 others2"   *ngIf="optionValue1 == 'Others' || dropval1 == 1">
            
            <!-- <div *ngIf="submitted && f.Bus_City.errors" class="invalid-feedback">
              <div *ngIf="f.Bus_City.errors.required"> <sup>*</sup> City is required</div>
          </div> -->
        </div>
         
      </div>
          </div>
          <br>
          <div class="tab"  style="border: 1px solid #ced4da;">
           
           <div class="row" style="margin-top: 18px;">
             <div class="col-md-6">
               <div class="form-group row">
                <label class="col-sm-3 col-form-label">Contact</label>
                 
                 <div class="col-sm-4 positionn">
                  <input  formControlName="contact" type="text" class="form-control" placeholder=""/>
             </div>
             <!-- <label class="col-sm-3 col-form-label"> Collection Recall </label> -->
                     <label class="col-sm-3 col-form-label">Credit Sales Recall</label>
           </div>  
         </div>
         <div class="col-md-6">
             <div class="form-group row">
                 <div class="col-sm-4 s1">
                  <mat-form-field appearance="none" (click)="picker1.open()" class="creditrecalll">
                    <input style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px;"   matInput [matDatepicker]="picker1"
                    placeholder="mm/dd/yyyy" (click)="picker1.open()"   [(ngModel)]="diss2"
                    formControlName="CreditRecall" (dateInput)="diss2" dateFormat="mm/dd/yy">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field> 
              
                  <!-- <mat-form-field appearance="none" (click)="picker2.open()" class="collectrecalll">
                    <input  style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px;"  matInput [matDatepicker]="picker2"
                    placeholder="mm/dd/yyyy" (click)="picker2.open()"   [(ngModel)]="diss1" (dateInput)="diss1"
                    formControlName="CollRecall" dateFormat="mm/dd/yy">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field> -->
                     
               
               </div>

               <!-- <label class="col-sm-3 col-form-label">Customer Care Recall</label> -->
               <label class="col-sm-3 col-form-label">Customer Care Recall</label>
               <div class="col-sm-4">
                <mat-form-field appearance="none" (click)="picker.open()"  class="ccrecalll">
                  <input style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px; "  matInput [matDatepicker]="picker" 
                  placeholder="mm/dd/yyyy" (click)="picker.open()"   [(ngModel)]="diss"
                  formControlName="CCRecall" (dateInput)="diss" dateFormat="mm/dd/yy">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                <!-- <mat-form-field appearance="none" (click)="picker.open()"  class="ccrecalll">
                  <input style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px; "  matInput [matDatepicker]="picker" 
                  placeholder="mm/dd/yyyy" (click)="picker.open()"   [(ngModel)]="diss"
                  formControlName="CCRecall" (dateInput)="diss" dateFormat="mm/dd/yy">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field> -->
             
             <!-- <div *ngIf="user.GetAccess().RoleID==3">
               <mat-form-field appearance="fill" (click)="pickers.open()" class="suspendedd">
                 <input [attr.disabled]="user.GetAccess().RoleID!=1 ? '': null"  readonly matInput [matDatepicker]="pickers"
                 placeholder="mm/dd/yyyy" (click)="pickers.open()" 
                 formControlName="Suspended"  dateFormat="mm/dd/yy" disabled> 
                 <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>
                 <mat-datepicker #pickers></mat-datepicker>
                 </mat-form-field>
           </div> -->
           </div>
                
             </div>
         </div>
         
       </div>
       <!-- <div class="row">
         <div class="col-md-6" style="margin-top: -20px;">
           <div class="form-group row">
             <label class="col-sm-3 col-form-label">Contact</label>
             
             <div class="col-sm-4 positionn">
               <input  formControlName="contact" type="text" class="form-control" placeholder=""/>
                 
 
           </div>
           
             
 
         </div>
     
       </div>
       
   </div> -->
  </div>
  <h6 style="margin-left: 5px;margin-top: 16px;">Product/Program</h6>
    
  <div class="tab"  style="border: 1px solid #ced4da;">
    <br>
  <div class="row" style="    margin-bottom: -1rem;">
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Select a Program</label> 
        <div class="col-sm-4 positionn">
          <select  formControlName="product" class="form-control" [(ngModel)]="SelectedProductList"
          (change)="ProgramAPICall($event.target.value)">
         <option value="">Select</option>
        <option *ngFor="let item of productlist" value="{{item}}">{{item}}</option>
     </select>
           
        </div>
        <label class="col-sm-3 col-form-label col2align">Select a Product</label>
       
      </div>
  
    </div>
    <div class="col-md-6">
        <div class="form-group row">
            
          <div class ="col-sm-4 s1">
            <select   formControlName="program" class="form-control" [(ngModel)]="SelectedProgramList"
            (change)="PriceAPICall()"
             >
              <option value="">Select</option>
             <option *ngFor="let item of programlist">{{item.Programlist}}</option> 
            </select>
            
          </div>
          <label class="col-sm-3 col-form-label">Select a Price</label>
          <div class ="col-sm-4">
            <select   formControlName="program" class="form-control" [(ngModel)]="SelectedPriceList"
              (change)="selectProgram()"
             >
              <option value="">Select</option>
             <option *ngFor="let item of pricelist">${{item.price}}</option> 
            </select>
            
          </div>

          
            </div>
        </div>
  </div>
  <br>
  <div class="row" >
    <div  class ="col-sm-7"></div>
    <div class ="col-sm-5">
    <button (click)="sendinvoice()" [disabled]="Makepayment" type="button" class="btn btn-primary mr-2 btn-sm col2align"

    style="width: 105px;    margin-left: 39px;" value="send email">Send Contract</button>



    <button (click)="addprogram()" type="button" class="btn btn-primary mr-2 btn-sm col2align"

    style="width: 95px;    margin-left: 39px;" value="">+ Add</button>
  </div>
  </div>
  <br>
  </div>
        </div>
        <div *ngIf=" sendInvoice===1"> <div class="d-flex justify-content-center ">



          <div class="spinner-border " role="status" style="margin-top: -50px;">
          
          <span class="sr-only" id="loading"></span>
          
          </div>
          
          </div> <div class="d-flex justify-content-center mb-3">
             <h7 > Please wait, the contract is being emailed to the customer</h7></div><div class="spinner_overlay"></div> </div>
      </div>  </div>
      </div>
    
      <div class="row" style="margin-top: -20px;">
  
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-header hd2" style="padding:27px; padding-bottom:21px;">
                <div class="row">
                  <div class="col-sm-12" style="margin-top: -20px;">
                  
                    
                    <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                      <li  class="nav-item" >
                        <a class="nav-link active"  id="pills-Close-tab" data-toggle="pill" href="#pills-Close" role="tab" aria-controls="pills-Close" aria-selected="true" (click)="dialoguetabchecking();closee=1">Dialogue</a>
                      </li>
                      <li   class="nav-item">
                        <a class="nav-link" id="pills-Competition-tab" data-toggle="pill" href="#pills-Competition" role="tab" aria-controls="pills-Competition" aria-selected="true" (click)="compeditiontabchecking();compeditionn=1">Competition</a>
                      </li>
                      <li  class="nav-item">
                        <a class="nav-link" id="pills-Marketing-tab" data-toggle="pill" href="#pills-Marketing" role="tab" aria-controls="pills-Marketing" aria-selected="true" (click)="marketingtabchecking();markett=1">Marketing</a>
                      </li>
                     <!-- <li (click)="reset()"  class="nav-item">
                        <a class="nav-link " id="pills-TCRED-tab" data-toggle="pill" href="#pills-TCRED" role="tab" aria-controls="pills-TCRED" aria-selected="true">TCRED Reps</a>
                      </li>-->
                    
                      <li  class="nav-item">
                        <a class="nav-link " id="pills-Contact-tab" data-toggle="pill" href="#pills-Contact" role="tab" aria-controls="pills-Contact" aria-selected="true" (click)="contacttabchecking();contactt=1">Contact</a>
                        </li>
                        <li   class="nav-item">
                          <a class="nav-link " id="pills-Programs-tab" data-toggle="pill" href="#pills-Programs" role="tab" aria-controls="pills-Programs" aria-selected="true" (click)="program();programm=1">Programs</a>
                        </li>
                    </ul>
                  </div>
                      </div>    
              </div>
              <div class="card-body">
                <!-- <h4 class="card-title">Basic form</h4> -->
     
      <div class="row" style="margin-top: -20px;">
          <div class="col-md-12">
         
          <div class="tab-content mt-3" id="pills-tabContent" style="border: 1px solid #ced4da;">
         
         
         
              <div *ngIf="checkval=='3'" class="tab-pane fade" id="pills-TCRED" role="tabpanel" aria-labelledby="pills-TCRED-tab">
                <button  type="button" (click)="addRow('TCRED')" class="btn btn-info btn-sm float-right m-2" [disabled]='Row==0'><i class="fa fa-plus"></i> Add</button>
                <button  type="button"  (click)="redirecttohttptcobapp()" class="btn btn-secondary btn-sm float-right m-2"> Back to IG</button>
                <div class="table-responsive">
                  <table  class="table table-striped thnone">
                    <thead>
                      <tr>
                      
                        <th>TCR</th>
                        <th>Department</th>
                        <th>Transcredit_Rep</th>
                    <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                     
                      <tr id="TCRED{{i}}" *ngFor="let item of CustomerData?.tcrep; let i=index" >
                         
                       
                        <td>
               
                          <span *ngIf="i!=Row">
                            {{item?.initials}}
                          </span>
                          <span *ngIf="i==Row">
                            <p style="font-weight: bold;">{{user.GetAccess()?.Name[0]}}{{user.GetAccess()?.Lname[0]}}</p>
                            <!-- <input id="initials" [value]="item.initials" type="text" class="form-control" placeholder="TCR"/> -->
                            
                          </span>
                        </td>
                        <td>
                       
                          <span *ngIf="i!=Row">
                            {{item?.dep}}
                          </span>
                          <span *ngIf="i==Row">
                            <input id="dep" [value]="item.dep" type="text" class="form-control" placeholder="Department"/>
                            
                          </span>
                        </td>
                        <td>
                   
                          <span *ngIf="i!=Row">
                            {{item?.repname}}
                          </span>
                          <span *ngIf="i==Row">
                            <input id="repname" [value]="item.repname" type="text" class="form-control" placeholder="Transcredit_Rep"/>
                            
                          </span>
                        </td>
                        <td>
                          <button  *ngIf="i!=Row" type="button" (click)="edit(item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                          <button [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('TCRED',i,item)" *ngIf="i==Row" type="button"><i class="fa fa-save"></i> Save</button>
                          <button class="btn btn-warning btn-sm " (click)="reset()" *ngIf="i==Row" type="button"><i class="fa fa-times"></i> Cancel</button>

                        </td>
  
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
  
     
          

          



              <div *ngIf="checkval=='0'" class="tab-pane fade show active in " id="pills-Close" role="tabpanel" aria-labelledby="pills-Close-tab">
                 
                <button  type="button" (click)="addRow('closeforms')" class="btn btn-info btn-sm float-right m-2" [disabled]='Row==0'><i class="fa fa-plus"></i> Add</button>
<button  type="button"  (click)="redirecttohttptcobapp()" class="btn btn-secondary btn-sm float-right m-2"> Back to IG</button>              
                <div class="table-responsive">
                  
                  <table  class="table table-striped thnone">
                    <thead>
                      <tr>
                        <th style="width:5%">
                          TCR
                        </th>
                        
                        <th style="width:20%">
                           Date of Contact
                        </th>
                        <!-- <th *ngIf="isCustomer">
                          Call Activity
                       </th> -->
                        <th>
                           
                           Dialogue
                        </th>
                        <th>
                       Recall Purpose
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      <tr id="closeforms{{i}}" *ngFor="let item of CustomerData?.closeforms; let i=index" >
                   
                        <td [ngStyle]="{'color': (item.auto === 1) ? getColorBasedOnText(item.dialogue) : ''}">
                          <span *ngIf="i!=Row">
                            {{item?.tcr}}
                          </span>
                          <span *ngIf="i==Row">
                            <input id="tcr" [value]="item.tcr" type="text" class="form-control" placeholder=""  style="width:63px;"  disabled/>
                            <!-- <p style="font-weight: bold;">{{user.GetAccess()?.Name[0]}}{{user.GetAccess()?.Lname[0]}}</p> -->
                          </span>
                        </td>
                        <td [ngStyle]="{'color': (item.auto === 1) ? getColorBasedOnText(item.dialogue) : ''}">
               
                          <span *ngIf="i!=Row">
                            {{item?.condate}}
                          </span>
                          <span *ngIf="i==Row">
                            <mat-form-field *ngIf="datepic=='1'" appearance="fill">
                              <input matInput [matDatepicker]="picker123" [value]="item.condate"   placeholder="mm/dd/yyyy" id="condate"  
                               >
                                <mat-datepicker-toggle matSuffix [for]="picker123"></mat-datepicker-toggle>
                                <mat-datepicker #picker123></mat-datepicker>
                                </mat-form-field>
                            <mat-form-field *ngIf="datepic=='2'" appearance="fill">
                              <input matInput [matDatepicker]="picker23" [value]="dis.value" id="condate"  
                               >
                                <mat-datepicker-toggle matSuffix [for]="picker23"></mat-datepicker-toggle>
                                <mat-datepicker #picker23></mat-datepicker>
                                </mat-form-field>
                            
                          </span>
                        </td>
                        <!-- <td *ngIf="isCustomer" class="cboxList{{i}}">
                          <div class="form-check" style="width:200px;">
                            <label >
                              <input [disabled]='i!=Row' class="cbox" value="voice" [checked]='item.voice' type="checkbox" > Voice Mail </label>
                          </div>
                          <div class="form-check">
                            <label >
                              <input [disabled]='i!=Row' class="cbox" value="wrongcon" [checked]='item.wrongcon' type="checkbox" checked > Wrong Contact </label>
                          </div>
                          <div class="form-check">
                            <label >
                              <input [disabled]='i!=Row' class="cbox" value="callback" [checked]='item.callback' type="checkbox" checked >Call Back</label>
                          </div>
                          <div class="form-check">
                            <label >
                              <input [disabled]='i!=Row' class="cbox" value="noint" [checked]='item.noint' type="checkbox" checked >No Interest </label>
                          </div>
                          <div class="form-check">
                            <label >
                              <input [disabled]='i!=Row' class="cbox" value="comp" [checked]='item.comp' type="checkbox" checked> Competitor </label>
                          </div>
                       </td> -->
                      <td [ngStyle]="{'color': (item.auto === 1) ? getColorBasedOnText(item.dialogue) : ''}">
                        <span *ngIf="i!=Row">
                          {{item?.dialogue}}
                        </span>
                        <span *ngIf="i==Row">
                          <!-- <input id="dialogue" [value]="item.dialogue"  type="text" class="form-control formm" placeholder="Dialogue" style="width:203px;"/> -->
                          <textarea id="dialogue" [value]="item.dialogue" type="text" class='form-control formm auto-expand'  placeholder="Dialogue" rows='3'></textarea>
                        </span>
                      </td>
                      <td [ngStyle]="{'color': (item.auto === 1) ? getColorBasedOnText(item.dialogue) : ''}">
                        <span *ngIf="i!=Row">
                          {{item?.recall}}
                        </span>
                        <span *ngIf="i==Row">
                          <input id="recall" [value]="item.recall" type="text" class="form-control" placeholder="Recall Purpose"/>
                          
                        </span>
                      </td>
                      <td style="    white-space: nowrap!important;">
                       
                        <button  *ngIf="i!=Row &&  item.auto!== 1" type="button" (click)="edit('closeforms',item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                        <button  [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('closeforms',i,item)" *ngIf="i==Row" type="button"><i class="fa fa-save"></i> Save</button>
                        <button class="btn btn-warning btn-sm " (click)="reset()" *ngIf="i==Row" type="button"><i class="fa fa-times"></i> Cancel</button>

                      </td>

                      </tr>
                    
                    </tbody>      
                  </table>
                </div>
  
              </div>
        

              <div id="compeditionbox" class="Modal-newcompetition  container-fluid-well"  cdkDrag cdkDragRootElement=".cdk-overlay-pane"  *ngIf="compeditionn==1 &&  dialoguesavedisable==0 ||compedition=='1' &&  dialoguesavedisable==0 "  onclick="focuscompedition()">
                <div class="modal-header" cdkDragHandle>
                <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                            text-align: center;
                            left: 11px;
                            width: 100%;
                            top: -3px;
                            font-size: inherit;">Competition</h5>
                           <button  type="button" (click)="addRow('competition')" class="add-btn btn btn-info btn-sm float-right m-2" [disabled]='showcompetition==true'><i class="fa fa-plus"></i> Add</button>
                            <button  *ngIf="popcompetition==0" type="button" (click)="cancel('13');compeditionn=0||compedition='0'" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <button  *ngIf="popcompetition==1" type="button" (click)="cancelWithAlert('Competition');"  class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                              <span aria-hidden="true">&times;</span>
                            </button>
                         
                            
                          </div> 
                        
               
                          <div  class="scl-hdr tab-pane fade show  in" id="pills-Competition" role="tabpanel" aria-labelledby="pills-Competition-tab">

                            <div class="table-responsive">
                            <table  class="table table-striped thnone">
                              <thead>
                                <tr>
                                 
                                  <th>Date</th>
                                  
                                  <th>Competitor</th>
                                  <th>Service & Cost</th>
                                  <th>Expires</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="competition{{i}}" *ngFor="let item of CustomerData?.competition; let i=index" >
                                 
                                  <td>
                         
                                    <span *ngIf="i!=Row2">
                                      {{item?.compdate}}
                                    </span>
                                    <span *ngIf="i==Row2">
                                      <mat-form-field *ngIf="datepic=='1'" appearance="fill">
                                        <input matInput [matDatepicker]="picker124" [value]="item.compdate"  placeholder="mm/dd/yyyy" id="compdate"  
                                         >
                                          <mat-datepicker-toggle matSuffix [for]="picker124"></mat-datepicker-toggle>
                                          <mat-datepicker #picker124></mat-datepicker>
                                          </mat-form-field>
                                      <mat-form-field *ngIf="datepic=='3'" appearance="fill">
                                        <input matInput [matDatepicker]="picker24" [value]="dis1.value" id="compdate"  
                                         >
                                          <mat-datepicker-toggle matSuffix [for]="picker24"></mat-datepicker-toggle>
                                          <mat-datepicker #picker24></mat-datepicker>
                                          </mat-form-field> 
                                      
                                    </span>
                                  </td>
                                  
                                  <td>
                             
                                    <span *ngIf="i!=Row2">
                                      {{item?.competitor}}
                                    </span>
                                    <span *ngIf="i==Row2">
                                      <input id="competitor" [value]="item.competitor" type="text" class="form-control" placeholder="Competitor"/>
                                      
                                    </span>
                                  </td>
                                  <td>
                                   
                                    <span *ngIf="i!=Row2">
                                      {{item?.sercost}}
                                    </span>
                                    <span *ngIf="i==Row2">
                                      <input id="sercost" [value]="item.sercost" type="number" class="form-control" placeholder="Service & Cost"/>
                                      
                                    </span>
                                  </td>
                                  <td>
                                  
                                    <span *ngIf="i!=Row2">
                                      {{item?.expdate}}
                                    </span>
                                    <span *ngIf="i==Row2">
                                      <mat-form-field *ngIf="datepic=='1'" appearance="fill">
                                        <input matInput [matDatepicker]="picker125" [value]="item.expdate"  placeholder="mm/dd/yyyy" id="expdate"  
                                         >
                                          <mat-datepicker-toggle matSuffix [for]="picker125"></mat-datepicker-toggle>
                                          <mat-datepicker #picker125></mat-datepicker>
                                          </mat-form-field>
                                      <mat-form-field *ngIf="datepic=='3'" appearance="fill">
                                        <input matInput [matDatepicker]="picker25" [value]="dis2.value" id="expdate"  
                                         >
                                          <mat-datepicker-toggle matSuffix [for]="picker25"></mat-datepicker-toggle>
                                          <mat-datepicker #picker25></mat-datepicker>
                                          </mat-form-field> 
                                      
                                    </span>
                                  </td>
                                  <td style="word-break: break-word;
                                  white-space: nowrap!important;
                                  text-align: justify !important;">
                                    <button  *ngIf="i!=Row2" type="button" (click)="edit('competition',item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                                    <button [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('competition',i,item)" *ngIf="i==Row2" type="button"><i class="fa fa-save"></i> Save</button>
                                    <button class="btn btn-warning btn-sm " (click)="reset2()" *ngIf="i==Row2" type="button"><i class="fa fa-times"></i> Cancel</button>
            
                                  </td>
            
                                </tr>
                              </tbody>
                            </table>
                          </div>
              
                          </div>
        
        
        
              </div>


              <div id="marketbox" class="Modal-newmarket  container-fluid-well" cdkDrag cdkDragRootElement=".cdk-overlay-pane" *ngIf="markett==1 &&  dialoguesavedisable==0 ||market=='1' &&  dialoguesavedisable==0 " onclick="focusmarket()">
                <div class="modal-header" cdkDragHandle>
                <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                            text-align: center;
                            left: 11px;
                            width: 100%;
                            top: -3px;
                            font-size: inherit;">Marketing</h5>

  <button  type="button" (click)="addRow('Marketing')" class="add-btn btn btn-info btn-sm float-right m-2" [disabled]='showmarket==true'><i class="fa fa-plus"></i> Add</button>
                            <button *ngIf="popmarket==0" type="button" (click)="cancel('14');markett=0||market='0'" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <button *ngIf="popmarket==1" type="button" (click)="cancelWithAlert('Marketing');" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                              <span aria-hidden="true">&times;</span>
                            </button>
                         
                          
                          </div> 
                        
               
                          <div  class="scl-hdr tab-pane fade show  in" id="pills-Marketing" role="tabpanel" aria-labelledby="pills-Marketing-tab">
                          
            
                            <div class="table-responsive">
                              <table  class="table table-striped thnone">
                                <thead>
                                  <tr>
                                    
                                    <th>Date</th>
                                    <th>Delivered Via</th>
                                    <th>Marketing or Demos Completed</th>
                                
                                <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                 
                                  <tr id="Marketing{{i}}" *ngFor="let item of CustomerData?.marketing; let i=index" >
                                     
                                    
                                    <td>
                           
                                      <span *ngIf="i!=Row3">
                                        {{item?.markdate}}
                                      </span>
                                      <span *ngIf="i==Row3">
                                        <mat-form-field *ngIf="datepic=='1'" appearance="fill">
                                          <input matInput [matDatepicker]="picker126" [value]="item.markdate"  placeholder="mm/dd/yyyy" id="markdate"  
                                           >
                                            <mat-datepicker-toggle matSuffix [for]="picker126"></mat-datepicker-toggle>
                                            <mat-datepicker #picker126></mat-datepicker>
                                            </mat-form-field>
                                        <mat-form-field *ngIf="datepic=='4'" appearance="fill">
                                          <input matInput [matDatepicker]="picker26" [value]="dis3.value" id="markdate"  
                                           >
                                            <mat-datepicker-toggle matSuffix [for]="picker26"></mat-datepicker-toggle>
                                            <mat-datepicker #picker26></mat-datepicker>
                                            </mat-form-field> 
                                      </span>
                                    </td>
                                    <td>
                                   
                                      <span *ngIf="i!=Row3">
                                        {{item?.delivered}}
                                      </span>
                                      <span *ngIf="i==Row3">
                                        <input id="delivered" [value]="item.delivered" type="text" class="form-control" placeholder="Delivered Via"/>
                                        
                                      </span>
                                    </td>
                                    <td>
                               
                                      <span *ngIf="i!=Row3">
                                        {{item?.markcomp}}
                                      </span>
                                      <span *ngIf="i==Row3">
                                        <input id="markcomp" [value]="item.markcomp" type="text" class="form-control" placeholder="Marketing or Demos Completed"/>
                                        
                                      </span>
                                    </td>
                                   
                                   
                                    <td>
                                      <button  *ngIf="i!=Row3" type="button" (click)="edit('Marketing',item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                                      <button [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('Marketing',i,item)" *ngIf="i==Row3" type="button"><i class="fa fa-save"></i> Save</button>
                                      <button class="btn btn-warning btn-sm cancelbtn1" (click)="reset3()" *ngIf="i==Row3" type="button"><i class="fa fa-times"></i> Cancel</button>
            
                                    </td>
              
                                  </tr>
                                </tbody>
                              </table>
                            </div>
              
              
                          </div>
        
        
        
              </div>


              <div  id="contactbox" class="Modal-newcontact  container-fluid-well" cdkDrag cdkDragRootElement=".cdk-overlay-pane" *ngIf="contactt==1 &&  dialoguesavedisable==0 ||contact=='1' &&  dialoguesavedisable==0 " onclick="focuscontact()">
                <div class="modal-header" cdkDragHandle>
                <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                            text-align: center;
                            left: 11px;
                            width: 100%;
                            top: -3px;
                            font-size: inherit;">Contact</h5>

<button  type="button" (click)="addRow('contact')" class="add-btn btn btn-info btn-sm float-right m-2" [disabled]='Row4==0'><i class="fa fa-plus"></i> Add</button>
                            <button *ngIf="popcontact==0" type="button" (click)="cancel('12');contactt=0||contact='0'" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <button *ngIf="popcontact==1" type="button" (click)="cancelWithAlert('Contact');" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                              <span aria-hidden="true">&times;</span>
                            </button>
                         
                          </div> 
                        
               
                          <div class="scl-hdr tab-pane fade show  in" id="pills-Contact" role="tabpanel" aria-labelledby="pills-Contact-tab">
                         
            
                            <div  class="table-responsive">
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    
                                    
                                    <th>
                                   Contact Name
                                    </th>
                                    <th>
                                  Position
                                    </th>
                                    <th>
                              Phone
                                    </th>
                                    
                                            <th>
                                              Extension
                                                    </th>
                                                    <th>
                                                      Fax
                                                            </th>
                                                            <th>
                                                              Email
                                                                    </th>
                                                                    <th>
                                                                      Toll Free
                                                                            </th>
                                                                            <th>
                                                                             Action
                                                                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                             
                                  <tr id="contact{{i}}" *ngFor="let item of CustomerData?.contactform; let i=index" >
                                   
                                    
                                    <td>
                           
                                      <span *ngIf="i!=Row4">
                                        {{item?.name}}
                                      </span>
                                      <span *ngIf="i==Row4">
                                        <input id="name" [value]="item.name" type="text" class="form-control" placeholder="Contact Name"/>
                                        
                                      </span>
                                    </td>
                                    <td>
                                   
                                      <span *ngIf="i!=Row4">
                                        {{item?.position}}
                                      </span>
                                      <span *ngIf="i==Row4">
                                        <input id="position" [value]="item.position" type="text" class="form-control" placeholder="Position"/>
                                        
                                      </span>
                                    </td>
                                    <td style="width: 119px;">
                               
                                      <span *ngIf="i!=Row4">
                                        {{item?.phone}}
                                      </span>
                                      <span *ngIf="i==Row4">
                                        <input id="phone" [value]="item.phone" type="tel"  class="form-control" placeholder="Phone" maxlength="10" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
                                        
                                      </span>
                                    </td>
                                   
                                    <td>
                                    
                                      <span *ngIf="i!=Row4">
                                        {{item?.extension}}
                                      </span>
                                      <span *ngIf="i==Row4">
                                        <input id="extension" [value]="item.extension" type="text" class="form-control" placeholder="Extension"/>
                                        
                                      </span>
                                    </td>
                                    <td style="width: 119px;">
                                     
                                      <span *ngIf="i!=Row4">
                                        {{item?.fax}}
                                      </span>
                                      <span *ngIf="i==Row4">
                                        <input  id="fax" [value]="item.fax" type="tel" class="form-control" placeholder="Fax" maxlength="10"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
                                        
                                      </span>
                                    </td>
                                    <td>
                                   
                                      <span *ngIf="i!=Row4">
                                        {{item?.email}}
                                      </span>
                                      <span *ngIf="i==Row4">
                                        <input id="email" [value]="item.email" type="text" class="form-control" placeholder="Email"/>
                                        
                                      </span>
                                    </td>
                                    <td style="width: 119px;">
                                     
                                      <span *ngIf="i!=Row4">
                                        {{item?.toll}}
                                      </span>
                                      <span *ngIf="i==Row4">
                                        <input id="toll" [value]="item.toll" type="tel" class="form-control" placeholder="Toll Free" maxlength="10"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
                                        
                                      </span>
                                    </td>
                                    <td style="word-break: break-word;
                                    white-space: nowrap!important;
                                    text-align: justify !important;">
                                      <button  *ngIf="i!=Row4" type="button" (click)="edit('contact',item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                                      <button [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('contact',i,item)" *ngIf="i==Row4" type="button"><i class="fa fa-save"></i> Save</button>
                                      <button class="btn btn-warning btn-sm " (click)="reset4()" *ngIf="i==Row4" type="button"><i class="fa fa-times"></i> Cancel</button>
            
                                    </td>
            
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
        
        
        
              </div>


              <div  id="programbox" class="Modal-newprogram  container-fluid-well" cdkDrag cdkDragRootElement=".cdk-overlay-pane" *ngIf="programm==1 &&  dialoguesavedisable==0 ||programing=='1' &&  dialoguesavedisable==0 " onclick="focusprogram()" >
                <div class="modal-header" cdkDragHandle>
                <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                            text-align: center;
                            left: 11px;
                            width: 100%;
                            top: -3px;
                          font-size: inherit;">Program</h5>

<button  type="button" (click)="addRow('Programs')" class="add-btn btn btn-info btn-sm float-right m-2" [disabled]='Row4==0'><i class="fa fa-plus"></i> Add</button>
                            <button  type="button" (click)="cancel('15');programm=0||programing='0'" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <!-- <button *ngIf="pop==1" type="button" (click)="cancelWithAlert('Program');" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                              <span aria-hidden="true">&times;</span>
                            </button> -->
                         
                          </div> 
                        
               
                          <div class="scl-hdr tab-pane fade show  in" id="pills-Programs" role="tabpanel" aria-labelledby="pills-Programs-tab">
                         
            
                            <div  class="table-responsive">
                               <table  class="table table-striped thnone">
                                <thead>
                                  <tr style="margin-top: -5px;">
                                    <th>Created Date</th>
                                    <th>Program Type</th>
                                    <th>Products</th>
                                    <th>Expiration</th>
                                    <th>Price</th>
                                   
              
                                    <th>New</th>
                                    <th>Update</th>
                                    <th>INFILE</th>   <th>eREPT</th>
                                    <th>COL%</th> 
                                    <th>Status</th>
                                    <th *ngIf="user.GetAccess().RoleID==1 || user.GetAccess().RoleID==4|| user.GetAccess().RoleID==2">Action</th>
                                    
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr id="Programs{{i}}" *ngFor="let item of CustomerData?.programs; let i=index" >
                                   
                                    <td style="    white-space: normal!important;">
                                      <span *ngIf="i!=Row">
                                        {{item?.pgmdate}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="pgmdate" [value]="item.pgmdate"  type="date" class="form-control dateofcontact" placeholder="Date"/>
                                      </span>
                                    </td>
                                    <td style="    white-space: normal!important;">
                           
                                      <span *ngIf="i!=Row">
                                        {{item?.pgmtype}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="pgmtype" [value]="item.pgmtype" type="text" class="form-control" placeholder="Program Type"/>
                                        
                                      </span>
                                    </td>
                                    <td style="    white-space: normal!important;">
                                   
                                      <span *ngIf="i!=Row">
                                        {{item?.products}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="products" [value]="item.products" type="text" class="form-control" placeholder="Products"/>
                                        
                                      </span>
                                    </td>
                                    <td style="    white-space: normal!important;">
                               
                                      <span *ngIf="i!=Row">
                                        {{item?.expdate}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="expdate" [value]="item.expdate" type="date" class="form-control dateofcontact" placeholder="Expiration"/>
                                        
                                      </span>
                                    </td>
            
                                    <td style="    white-space: normal!important;">
                                      <span *ngIf="i!=Row">
                                        ${{item?.price}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="yearly" [value]="item.yearly"  type="text" class="form-control" placeholder="Yearly"/>
                                      </span>
                                    </td>
                                   
                                    <td style="    white-space: normal!important;">
                                   
                                      <span *ngIf="i!=Row">
                                        ${{item?.new}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="new" [value]="item.new" type="text" class="form-control" placeholder="New"/>
                                        
                                      </span>
                                    </td>
                                    <td style="    white-space: normal!important;">
                               
                                      <span *ngIf="i!=Row">
                                        ${{item?.update}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="update" [value]="item.update" type="text" class="form-control" placeholder="Update"/>
                                        
                                      </span>
                                    </td>
            
                                    <td style="    white-space: normal!important;">
                                   
                                      <span *ngIf="i!=Row">
                                        ${{item?.online}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="online" [value]="item.online" type="text" class="form-control" placeholder="Online"/>
                                        
                                      </span>
                                    </td>
                                    <td style="    white-space: normal!important;">
                               
                                      <span *ngIf="i!=Row">
                                        ${{item?.report}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="report" [value]="item.report" type="text" class="form-control" placeholder="eREPT"/>
                                        
                                      </span>
                                    </td>
            
                                    <td style="    white-space: normal!important;">
                                      <span *ngIf="i!=Row">
                                        {{item?.coll}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="coll" [value]="item.coll"  type="text" class="form-control" placeholder="COL%"/>
                                      </span>
                                    </td>
                                   
                                    <td style="    white-space: normal!important;">
                                      <span *ngIf="i!=Row">
                                        {{item?.status}}
                                      </span>
                                      <span *ngIf="i==Row">
                                        <input id="status" [value]="item.status"  type="text" class="form-control" placeholder="Status"/>
                                      </span>
                                    </td>
            
                                    
                                    <td style="    white-space: normal!important;">
                                      <button  *ngIf="user.GetAccess().RoleID==1 || user.GetAccess().RoleID==4|| user.GetAccess().RoleID==2" type="button" (click)="edit_p(item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                                      <button [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('Programs',i,item)" *ngIf="i==Row" type="button"><i class="fa fa-save"></i> Save</button>
                                      <button class="btn btn-warning btn-sm " (click)="reset()" *ngIf="i==Row" type="button"><i class="fa fa-times"></i> Cancel</button>
            
                                    </td>
              
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
        
        
        
              </div>
        
        
        
              </div>


            </div></div>
      </div>      
              </div>
            </div>
          </div>
      
      <div class="d-flex justify-content-center mb-3">
          <button  [disabled]="displaynext!=1 ||  savedisable==1"  type="submit"   class="btn btn-success mr-2 btn-sm"><i class="mdi mdi-file-document"></i>Save</button>
          <!-- <button *ngIf="userDetail!='3'" type="button" class="btn btn-primary mr-2 btn-sm">Save & Convert to Customer</button> -->

          <button  [disabled]="g==1 || displaynext!=1 ||  savedisable==1" (click)="getprevious()"  type="button" class="btn btn-success mr-2 btn-sm">Previous</button>
          <button    [disabled]="diablenext==true || displaynext!=1 ||  savedisable==1"   (click)="getnext()"  type="button" class="btn btn-success mr-2 btn-sm">Next</button>
          
          <!-- <button  [disabled]="g2==1 || g==1"  (click)="sampleprevious()"   type="button" class="btn btn-success mr-2 btn-sm">Previous</button>
          <button    [disabled]="g1==1" (click)="samplenext()"   type="button" class="btn btn-success mr-2 btn-sm">Next</button> -->

          
          <button  [disabled]="savedisable==1"  type="button" class="btn btn-secondary mr-2 btn-sm" (click)="button1()" >Back to Customer/Client</button>
         
          <button   [disabled]="savedisable==1"  type="button" class="btn btn-secondary mr-2 btn-sm" *ngIf="dropcheck ==1" (click)="button2()">Back to Recall</button>

      </div>
  </form>
