<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
  <div class="card">
    <div class="card-header" style="padding:12px;">
      <h4 class="card-title" style="font-size: 16px;"> TransCredit Customer -  Annual Activity Report </h4>

    </div>
    <div class="card-body" style="padding-top:10x;">
      <p class="card-description">
        Filters:
                </p>
               
      <div class="panel">
      <!-- <p class="card-description">
        Add class
        <code>.table-striped</code>
      </p> -->
      <form [formGroup]="myForm" (ngSubmit)="searchfunction(this)">
      <div class="row justify-content-center">
          <!-- <div class="col-lg-2">
              <h6 class="card-description aligright mb-0">Search by</h6>
          </div> -->
             
              <div class="col-lg-12 ml-4" style="
              
                  display: flex;
                  flex-wrap: wrap;">
                <label style="position: relative;top: 9px;">From </label>
                <!-- <mat-form-field appearance="fill"> -->
                <input  type="text" formControlName="fromdate" placeholder="Search" class="form-control"  style="    width: 11%;
                position: relative;
                left: 7px;
                top: 9px;" disabled>
                  <!-- <input  formControlName="fromdate"  [min]="minDate" [max]="maxDate" matInput  style="margin-bottom: 6px;" disabled> -->
                  <!-- <mat-datepicker-toggle style="color: #17a2b8;" matSuffix [for]="dp3"></mat-datepicker-toggle>
                  <mat-datepicker #dp3 disabled="false"></mat-datepicker> -->
                <!-- </mat-form-field> -->
                <label style="    position: relative;top: 9px;left: 15px;">To </label>
                <!-- <mat-form-field appearance="fill"> -->
                  <!-- <mat-label>To Date</mat-label> -->
                  <input  type="text" formControlName="todate" placeholder="Search" class="form-control" style="    width: 11%;
                  position: relative;
                  left: 21px;
                  top: 9px;" disabled>
                  <!-- <input  formControlName="todate"  [min]="minDate" [max]="maxDate" matInput  style="margin-bottom: 6px;" disabled> -->
                  <!-- <mat-datepicker-toggle style="color: #17a2b8;" matSuffix [for]="dp4"></mat-datepicker-toggle>
                  <mat-datepicker #dp4 disabled="true"></mat-datepicker> -->
                <!-- </mat-form-field> -->
                <label> </label>
                <select formControlName="SearchBy" class="form-control searchbyy" style="width: 125px;">
                  <option value="" disabled selected hidden>Select</option>
                  <option value="Name">Customer Name</option>
                  <option value="Userid">Sales person</option>
                  <option value="Program">Program</option>
                </select>
                <input *ngIf="myForm.get('SearchBy').value=='Name'" type="text" formControlName="value" placeholder="Search" class="form-control" aria-label="..." style="
                           width: 125px;
                           top: 9px;
    position: relative;
    left: 58px;"  [(ngModel)]="dropValue" >
                <select *ngIf="myForm.get('SearchBy').value=='Userid'" formControlName="value" class="form-control" style="     width: 128px;
                position: relative;
                left: 63px;
                top: 9px;">
                  <option value="" disabled selected hidden>Select</option>
                  <option *ngFor="let item of dropdown" [value]="item.SPUserid">{{item.salesperson}}</option>
                </select>


                <select *ngIf="myForm.get('SearchBy').value=='Program'" formControlName="value" class="form-control" style="     width: 128px;
                position: relative;
                left: 63px;
                top: 9px;">
                  <option value="" disabled selected hidden>Select</option>
                  <option *ngFor="let item of programlist" >{{item}}</option>
                </select>


                <button type="submit" class="btn btn-secondary  mt-4" style="      width: 89px;height: 35px; position: relative;top: -17px; left: 70px; ">
                  <i class="fa fa-search"></i>Search</button>
                <button  printSectionId="print-section"  class="btn btn-secondary  mt-4"  style="width: 75px;height: 35px;position: relative;top: -17px;left: 80px;background-color: #286090;border-color: #286090;" ngxPrint>
                  PRINT</button>
                  <button     class="btn btn-secondary  mt-4"  style="height: 35px;position: relative;top: -17px;        margin-left: 89px; background-color: #fc1153;border-color: #fc1153;width: 75px;" (click)="exportAsXLSX()" >
                    XLSX</button>
                    <button (click)="reset()"class="btn btn-outline-secondary btn-sm" style="height: 34px;position: relative;top: 8px; left: 9px; width: 68px;float: right; " ><i class="fa fa-refresh
                        "></i>Reset</button>





































                    <!-- <input type="date"   placeholder="Search" class="form-control 10" formControlName="fromdate" aria-label="..."/> -->
                  </div><!-- /input-group -->
                     






                  <!-- codee endsssssss -->
            <!-- /input-group -->
                <!-- /.col-lg-6 -->
                   
                </div><!-- /.col-lg-6 -->
                
    
               
                  
                     
               </form>

                <div *ngIf="dataDisplay==1"> <div class="d-flex justify-content-center ">



                  <div class="spinner-border " role="status" style="position: relative;
                  left: -61px;top:-2px;">
                  
                  <span class="sr-only" id="loading"></span>
            
                </div>
                
                </div> <div class="d-flex justify-content-center mb-3"></div><div class="spinner_overlay"></div> </div>
            <div class="row">
                <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
      <div id="print-section"  class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
                Customer
              </th>
              
           
              <th>
               Sales Person
              </th>
              <th>
    Total Reports Ordered
              </th>
              <th>
   Jan
              </th>
              <th>
              Feb
                        </th>
                        <th>
                       March
                                </th>
                                <th>
                                April
                                         </th>
                                         <th>
                                        May
                                                 </th>
                                                 <th>
                               June
                                                           </th> <th>
                                                            July
                                                                                        </th> <th>
                                                                                          Aug
                                                                                                                      </th> <th>
                                                                                                                        Sept
                                                                                                                                                    </th> <th>
                                                                                                                                                      Oct
                                                                                                                                                                                  </th> <th>
                                                                                                                                                                                    Nov
                                                                                                                                                                                                                </th> <th>
                                                                                                                                                                                                                  Dec
                                                                                                                                                                                                                                              </th>
            </tr>
          </thead>
          <tbody>
            <!-- | paginate: { itemsPerPage: count, currentPage: p } -->
            <tr *ngFor="let item of data">
              <td>{{item.Legal_Name}}</td>
              <td>{{item.SP_Name}}</td>
              <td class="text-center">{{item.TotalRep}}</td>
              <td>{{item.Jan}}</td>
              <td>{{item.Feb}}</td>
              <td>{{item.Mar}}</td>
              <td>{{item.Apr}}</td>
              <td>{{item.May}}</td>
              <td>{{item.Jun}}</td>
              <td>{{item.Jul}}</td>
              <td>{{item.Aug}}</td>
              <td>{{item.Sep}}</td>
              <td>{{item.Oct}}</td>
              <td>{{item.Nov}}</td>
              <td>{{item.Dec}}</td>

            </tr>
          </tbody>
          <tr *ngIf="data.length==0" class="w-100 text-center ">
            <td class="noData" style="margin-top: -11px; margin-left: 90px!important;">No Data Available..!</td>
          </tr>
        </table>
      </div>
      <div class="text-right">
        <!-- <pagination-controls (pageChange)="p = $event"    ></pagination-controls> -->
        <div>
        <button  [disabled]="p<2 || SearchDisableButton"  class="btn btn-info btn-sm  " (click)="pageChange1($event)"  ><<</button>&nbsp;
        <button [disabled]="data.length <=29" class="btn btn-info btn-sm " (click)="pageChange($event)"  >>></button></div>
      </div>
    </div>
  </div>
</div>
</div>
