
<form class="forms-sample" id="fome-reload" [formGroup]="Prospects"  (ngSubmit)="onSubmit3()">
  <div class="row">
  
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          
          <div id="custinfoid" class=" card-header custinfo" >
            <h4 class="card-title a1"> Prospects Info:<span *ngIf="ProspectsData?.formfileds" style="font-weight: bold;font-size: 17px;width:100%"> {{name}}&nbsp;{{ProspectsData?.formfileds[0]?.Docket_No}}</span>
              <!-- {{ (ProspectsData?.formfileds[0]?.Legal_Name.length>20)? (ProspectsData?.formfileds[0]?.Legal_Name | slice:0:20)+'..':(ProspectsData?.formfileds[0]?.Legal_Name) }} _ {{ProspectsData?.formfileds[0]?.Docket_No}} -->
              <div style="float: right;">
              <span>
              <button *ngIf= "Add!=0" [disabled]="displaynext!=1 ||  savedisable==1" style="    position: relative;
              top: -6px;
              left: 14px;"   type="submit"  (click)="onSubmit1()" class="btn btn-success mr-2 btn-sm"><i class="mdi mdi-file-document"></i>Save</button>

<button *ngIf= "Add==0" style="position: relative;
top: -6px;
left: 14px;"   type="submit"  (click)="onSubmit1()" class="btn btn-success mr-2 btn-sm"><i class="mdi mdi-file-document"></i>Save</button>
       
       <button [disabled]="savedisable==1" *ngIf= "user.GetAccess().RoleID!=3 && Add!=0"  type="submit"  (click)="onSubmit2()" class="btn btn-primary mr-2 btn-sm" style="    position: relative;
       top: -6px;
       left: 14px;">Save & Convert to Customer</button>



              <button style="    position: relative;
              top: -6px;
              left: 14px;"  *ngIf="Add!=0"  [disabled]="g==1 || displaynext!=1 || savedisable==1" (click)="getprevious()"  type="button" class="btn btn-success mr-2 btn-sm" >Previous</button>
               <button style="    position: relative;
               top: -6px;
               left: 14px;"  *ngIf="Add!=0 "  [disabled]="diablenext==true || displaynext!=1 || savedisable==1"  (click)="getnext()"  type="button" class="btn btn-success mr-2 btn-sm" >Next</button>
    

<!-- 
               <button   [disabled]="g2==1 || g==1"  (click)="sampleprevious()"   type="button" class="btn btn-success mr-2 btn-sm" style="    position: relative;
               top: -6px;
               left: 14px;">Previous</button>
               <button  [disabled]="g1==1"  (click)="samplenext()"   type="button" class="btn btn-success mr-2 btn-sm" style="    position: relative;
               top: -6px;
               left: 14px;">Next</button> -->



              <button [disabled]="savedisable==1" *ngIf="Add!=0"   (click)="button1()"   type="button" class="btn btn-secondary mr-2 btn-sm" style="    position: relative;
              top: -6px;
              left: 14px;">Back  to Prospects</button>
            </span>
            <button [disabled]="savedisable==1"  *ngIf="Add!=0 && dropcheck ==1"  (click)="button2()"  type="button" class="btn btn-secondary mr-2 btn-sm" style="    position: relative;
            top: -6px;
            left: 14px;" >Back  to recall</button> 
             <button  [disabled]="savedisable==1" *ngIf="Add==0" routerLink="/prospectmanage" type="button" class="btn btn-secondary mr-2 btn-sm" style="    position: relative;
             top: -6px;
             left: 14px;" >Back to Prospects</button>            
             </div> 
             
            </h4> 
           
             
           
            
      
          </div>
          <div class="card-body paddingdiv1" >
            <!-- <h4 class="card-title">Basic form</h4> -->
  
                      <div class="tab"  style="border: 1px solid #ced4da;">
                        <div class="row" style="margin-top: 20px;   ">
                          <div class="col-md-6">
                            <div class="form-group row">
                            
                              
                              <label class="col-sm-3 col-form-label">Name</label>  
                              <div class="col-sm-4 positionn">
                                <input type="text"  formControlName="Legal_Name"  class="form-control"  style="    margin-bottom: -4px;" [ngClass]="{ 'is-invalid': submitted && f.Legal_Name.errors }" placeholder=""/>
                                <div *ngIf="submitted && f.Legal_Name.errors" class="invalid-feedback" >
                                  <div *ngIf="f.Legal_Name.errors.required">  <sup>*</sup>Name is required</div>
                              </div>
                            </div>
                            <label class="col-sm-4 col-form-label col2align">MC #</label>
                            </div>
                        <!-- <br> -->
                          </div>
                          
                            <div class="col-md-6">
                              <div class="form-group row">
                                <div class="col-sm-4 s1">
          
                                  <div *ngIf="Add!=0">
          
                                    <input *ngIf="showw==0" type="text"  formControlName="Docket_No" [(ngModel)]="dockt"  class="form-control"   placeholder="" disabled  style="width: 102px;" />
                                   
                                    <select *ngIf="showw==1" formControlName="Docket_Nodrp" class="form-control"  [(ngModel)]="split" [ngClass]="{ 'is-invalid': submitted && f.Docket_No1.errors }" style="width: 67px;margin-left: -61px;" >
                              
                                      <option value="MC" >MC</option>
                                      <option value="MX" >MX</option>
                                      <option value="FF" >FF</option>
            
                                    </select>
                                   
                                    <input *ngIf="showw==1" type="text" (keypress)="keyPressNumbers($event)"  formControlName="Docket_Notxt"   [(ngModel)]="splitted" style="width: 104px;position: relative;left: 11px;top: -32px;" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.Docket_No.errors }" placeholder="" maxlength="8"/>
                                   
                                   
                                    <a *ngIf="showw==0"  href="javascript:void(0);" (click)="editfnct5()" style="    position: absolute;
                                    left: 122px;
                                    top: 3px;
                                    width: -5px;
                                    font-size: smaller;">Edit</a>
            
            
                                   <a *ngIf="showw==1"  href="javascript:void(0);" (click)="cancel()" style="    position: absolute;
                                    left: 133px;
                                    top: 3px;
                                    width: -5px;
                                    font-size: smaller;">cancel</a>
            
            
                               
                                   </div>
            
                                    <div *ngIf="Add==0">
                                      <select *ngIf="show==0" formControlName="Docket_No1" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.Docket_No1.errors }" style="width: 67px;margin-left: -61px;" >
                              
                                        <option value="MC" >MC</option>
                                        <option value="MX" >MX</option>
                                        <option value="FF" >FF</option>
             
                                      </select>
                                      <!-- <div *ngIf="submitted && f.Docket_No1.errors" class="invalid-feedback">
                                        <div *ngIf="f.Docket_No1.errors.required"> <sup>*</sup> MC is required</div>
                                    </div> -->
                                      <input *ngIf="show==0" type="text" (keypress)="keyPressNumbers($event)"  formControlName="Docket_No" style="width: 104px;position: relative;left: 11px;top: -32px;" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.Docket_No.errors }" placeholder="" [(ngModel)]="txt" maxlength="8" />
            
                                      
                                      <input *ngIf="show==1" type="text" (keypress)="keyPressNumbers($event)"  formControlName="Docket_No" style="width: 102px;" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.Docket_No.errors }" placeholder=""  disabled/>
                                  
                                    <!-- disabled -->
                                    <!-- <div *ngIf="submitted && f.Docket_No.errors" class="invalid-feedback">
                                      <div *ngIf="f.Docket_No.errors.required"> <sup>*</sup> MC is required</div>
                                  </div> -->
                                 
                                  <a *ngIf="show==0"  href="javascript:void(0);" (click)="generateMC()" style="    position: absolute;
                                  left: 133px;
                                  top: 3px;
                                  width: -5px;
                                  font-size: smaller;">Generate</a>
                                   
                                   <a *ngIf="show==1"  href="javascript:void(0);" (click)="editfnct()" style="    position: absolute;
                                   left: 122px;
                                   top: 3px;
                                   width: -5px;
                                   font-size: smaller;">Edit</a>
            
                                </div>
          
          
          
                                </div>
                                <label class="col-sm-3 col-form-label">Other Location
                                </label>
                                <div class="col-sm-4">
                                  <input  formControlName="otherloc" type="text" class="form-control"  placeholder="" />
                
              <div *ngIf="Prospects.get('contact').invalid && (Prospects.get('contact').dirty || Prospects.get('contact').touched)" style="color:red;font-size: 13px;">Please Enter Letters</div>
                  <!-- <div *ngIf="submitted && f.Bus_Country.errors" class="invalid-feedback">
                    <div *ngIf="f.Bus_Country.errors.required">Country is required</div>
              </div> -->
                                
                            </div>
                                 
                                  </div>
                              </div>
                      </div>
              <div  class="row"   *ngIf="Add!=0 && showw==0 " style="    margin-top: 8px;" >
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">DBA</label>
                    <div class="col-sm-4 positionn">
         
                      <input  formControlName="DBAName" type="text"  class="form-control" placeholder=""/>
                        <div *ngIf="submitted && f.DBAName.errors" class="invalid-feedback">
                          <div *ngIf="f.DBAName.errors.required">Phone is required</div>
                    </div>
                    </div>
                    <label class="col-sm-3 col-form-label col2align">
                      Classification Code</label>
  
                  </div>
              
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        
                        <div class ="col-sm-4 s1">
                          <select formControlName="BCCodeID" class="form-control"    [ngClass]="{ 'is-invalid': submitted && f.Brok_Carr.errors }">
                  
                            <option *ngFor="let item of classdrop" value={{item.Codeid}}>{{item.Code}}</option>
                          </select>


                           
                        </div>
                        <label class="col-sm-3 col-form-label">Credit SLSP </label>
                        <div class="col-sm-4">

                          <input *ngIf="Add!=0"  disabled  formControlName="SP_Name" type="text" [(ngModel)]="sp" class="form-control" placeholder=""/>
                          <input *ngIf="Add==0"  disabled  type="text" formControlName="Name" class="form-control" placeholder=""/>
                          <div  class="invalid-feedback">
                            <div >Credit SLSP is required</div>
                      </div>


                           
                        </div>
                    </div>
              </div>
              
            </div>
            <div  class="row" *ngIf="Add!=0 && showw==1"  style="margin-top: -21px;">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">DBA</label>
                  <div class="col-sm-4 positionn">
       
                    <input  formControlName="DBAName" type="text"  class="form-control" placeholder=""/>
                      <div *ngIf="submitted && f.DBAName.errors" class="invalid-feedback">
                        <div *ngIf="f.DBAName.errors.required">Phone is required</div>
                  </div>
                  </div>
                  <label class="col-sm-3 col-form-label col2align">
                    Classification Code</label>

                </div>
            
              </div>
              <div class="col-md-6">
                  <div class="form-group row">
                      
                      <div class ="col-sm-4 s1">
                        <select formControlName="BCCodeID" class="form-control"    [ngClass]="{ 'is-invalid': submitted && f.Brok_Carr.errors }">
                
                          <option *ngFor="let item of classdrop" value={{item.Codeid}}>{{item.Code}}</option>
                        </select>


                         
                      </div>
                      <label class="col-sm-3 col-form-label">Credit SLSP </label>
                      <div class="col-sm-4">

                        <input *ngIf="Add!=0"  disabled  formControlName="SP_Name" type="text" [(ngModel)]="sp" class="form-control" placeholder=""/>
                        <input *ngIf="Add==0"  disabled  type="text" formControlName="Name" class="form-control" placeholder=""/>
                        <div  class="invalid-feedback">
                          <div >Credit SLSP is required</div>
                    </div>


                         
                      </div>
                  </div>
            </div>
            
          </div>
          <div  class="row" *ngIf="Add==0 && show==0 " style="  margin-top: -21px;">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">DBA</label>
                <div class="col-sm-4 positionn">
     
                  <input  formControlName="DBAName" type="text"  class="form-control" placeholder=""/>
                    <div *ngIf="submitted && f.DBAName.errors" class="invalid-feedback">
                      <div *ngIf="f.DBAName.errors.required">Phone is required</div>
                </div>
                </div>
                <label class="col-sm-3 col-form-label col2align">
                  Classification Code</label>

              </div>
          
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    
                    <div class ="col-sm-4 s1">
                      <select formControlName="BCCodeID" class="form-control"    [ngClass]="{ 'is-invalid': submitted && f.Brok_Carr.errors }">
              
                        <option *ngFor="let item of classdrop" value={{item.Codeid}}>{{item.Code}}</option>
                      </select>


                       
                    </div>
                    <label class="col-sm-3 col-form-label">Credit SLSP </label>
                    <div class="col-sm-4">

                      <input *ngIf="Add!=0"  disabled  formControlName="SP_Name" type="text" [(ngModel)]="sp" class="form-control" placeholder=""/>
                      <input *ngIf="Add==0"  disabled  type="text" formControlName="Name" class="form-control" placeholder=""/>
                      <div  class="invalid-feedback">
                        <div >Credit SLSP is required</div>
                  </div>


                       
                    </div>
                </div>
          </div>
          
        </div>
        <div  class="row"  *ngIf="Add==0 && show==1">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">DBA</label>
              <div class="col-sm-4 positionn">
   
                <input  formControlName="DBAName" type="text"  class="form-control" placeholder=""/>
                  <div *ngIf="submitted && f.DBAName.errors" class="invalid-feedback">
                    <div *ngIf="f.DBAName.errors.required">Phone is required</div>
              </div>
              </div>
              <label class="col-sm-3 col-form-label col2align">
                Classification Code</label>

            </div>
        
          </div>
          <div class="col-md-6">
              <div class="form-group row">
                  
                  <div class ="col-sm-4 s1">
                    <select formControlName="BCCodeID" class="form-control"    [ngClass]="{ 'is-invalid': submitted && f.Brok_Carr.errors }">
            
                      <option *ngFor="let item of classdrop" value={{item.Codeid}}>{{item.Code}}</option>
                    </select>


                     
                  </div>
                  <label class="col-sm-3 col-form-label">Credit SLSP </label>
                  <div class="col-sm-4">

                    <input *ngIf="Add!=0"  disabled  formControlName="SP_Name" type="text" [(ngModel)]="sp" class="form-control" placeholder=""/>
                    <input *ngIf="Add==0"  disabled  type="text" formControlName="Name" class="form-control" placeholder=""/>
                    <div  class="invalid-feedback">
                      <div >Credit SLSP is required</div>
                </div>


                     
                  </div>
              </div>
        </div>
        
      </div>
         
          
            <div class="row" style="    margin-bottom: -1rem;">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Phone #</label>
                  <div class="col-sm-4 positionn">
       
                      <input  formControlName="Bus_Phone"  type="text" appPhoneMask class="form-control" id="phone-number" placeholder="" maxlength="14"  />
                      <!-- <div *ngIf="submitted && f.Bus_Phone.errors" class="invalid-feedback">
                        <div *ngIf="f.Bus_Phone.errors.required">Phone is required</div>
                  </div> -->
                  </div>
                  <label class="col-sm-3 col-form-label col2align">Classification Type</label>

                </div>
            
              </div>
              <div class="col-md-6">
                  <div class="form-group row">
                      
                    <div class="col-sm-4 s1">

                      <select formControlName="Brok_Carr" class="form-control"  style="    margin-bottom: -4px;" [ngClass]="{ 'is-invalid': submitted && f.Brok_Carr.errors }" >
                            
                        <option *ngFor="let item of tt" value={{item.Typeid}}>{{item.Type}}</option>
                      </select>
    
    
                      <div class="invalid-feedback d-inline-block" *ngIf="submitted && f.Brok_Carr.errors" >
                        <sup>*</sup>Select One Type
                      </div>

                   
                    </div>
                      <label class="col-sm-3 col-form-label">Collect SLSP</label>
                      <div class="col-sm-4">

                        <input *ngIf="Add==0"  disabled  type="text" formControlName="Name" style="width: 103px;"  class="form-control" placeholder=""/>
                        <select  *ngIf="Add!=0"  formControlName="clUserid" class="form-control"  [(ngModel)]="d5" style="width: 103px;"  [ngClass]="{ 'is-invalid': submitted && f.clUserid.errors }">
                          <option *ngFor="let item of elements" [value]="item.CLUserid">{{item.AllActive}}</option>
                        </select>
                       <input   type="text"   formControlName="CollectInitials" class="form-control inputselect" [value]="clinitials"  [(ngModel)]="clinitials" placeholder="" >
                       <input *ngIf="Add==0"  type="text"   formControlName="CollectInitials" class="form-control inputselect"    placeholder=""  disabled>
                       <div style="    position: relative;
                       top: -32px;" *ngIf="submitted && f.clUserid.errors " class="invalid-feedback ">
                        <sup>*</sup>Select One Person
                      </div> 

                    
                      </div>
                  </div>
            </div>
            
          </div>
          <div class="row" style="margin-bottom: 7px;">
            <div class="col-md-6">
              <div class="form-group row">
              
                
                <label class="col-sm-3 col-form-label">Toll Free</label>  
                <div class="col-sm-4 positionn">
                  <input  formControlName="Bus_Toll" type="text" appPhoneMask class="form-control"  id="phone-number" placeholder="" maxlength="14" />
                  <!-- <div *ngIf="submitted && f.Bus_Toll.errors" class="invalid-feedback">
                    <div *ngIf="f.Bus_Toll.errors.required">Toll Free Number is required</div>
              </div> -->

                  

                  
              </div>
              <label class="col-sm-3 col-form-label col2align">Truck#</label>
              </div>
          
            </div>
            
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-sm-4 s1">

                    <input  formControlName="trucks" type="number"  class="form-control"  id="trucks" placeholder="" />
                  
                    <!-- <div *ngIf="submitted && f.Mail_Fax.errors" class="invalid-feedback">
                      <div *ngIf="f.Mail_Fax.errors.required">Fax Contact  is required</div>
                </div> -->
                  </div>
                  <label class="col-sm-3 col-form-label" style="margin-top: -1px;">Website</label>

                  <div class="col-sm-4" style=" margin-bottom: -28px;"   >
                 
                    <input  formControlName="Website" type="text" class="form-control" placeholder="" />
                    <!-- <div *ngIf="submitted && f.Website.errors" class="invalid-feedback">
                      <div *ngIf="f.Website.errors.required">Website is required</div>
                </div> -->
                <!-- <div *ngIf="Prospects.get('Website').invalid && (Prospects.get('Website').dirty || Prospects.get('Website').touched)" style="color:red;font-size: 13px;">Please enter valid website</div>
                 -->
                </div>
                   
                    </div>
              </div>
          
          </div>
        
          <div *ngIf=" dataDisplay1==1 &&  this.disply!=1 "> <div class="d-flex justify-content-center ">



            <div class="spinner-border " role="status" style="margin-top: -50px;">
            
            <span class="sr-only" id="loading"></span>
            
            </div>
            
            </div> <div class="d-flex justify-content-center mb-3"> </div><div class="spinner_overlay"></div> </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
              
                
                <label class="col-sm-3 col-form-label">Fax #</label>  
                <div class="col-sm-4 positionn">
                  <input  formControlName="Bus_Fax" type="text" appPhoneMask class="form-control" placeholder="" maxlength="14" />
                  <div *ngIf="submitted && f.Bus_Fax.errors" class="invalid-feedback">
                    <div *ngIf="f.Bus_Fax.errors.required">FAX Number is required</div>
                    </div>
                  <!-- <div *ngIf="submitted && f.Bus_Country.errors" class="invalid-feedback">
                    <div *ngIf="f.Bus_Country.errors.required">Country is required</div>
              </div> -->
              </div>
              <label class="col-sm-3 col-form-label col2align">
                Credit / Analysis</label>
              </div>
            
            </div>
            
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-sm-4 s1">
                    <input disabled formControlName="creditscore" type="text" class="form-control" placeholder=""/>
                  </div>
                  <label class="col-sm-3 col-form-label">
                    Suspended</label>
                  <div class="col-sm-4">

                    <input  disabled type="text" formControlName="Suspended"  [(ngModel)]="Suspended"  class="form-control" placeholder=""  />
                    <div *ngIf="submitted && f.Bus_Fax.errors" class="invalid-feedback">
                      <div *ngIf="f.Bus_Fax.errors.required">Suspended is required</div>
                      </div> 
                  
                  </div>
                   
                    </div>
              </div>
          
          </div>



          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
              
                <!-- <div class="col-sm-4 positionn">
                  <input type="text"  formControlName="Legal_Name"  class="form-control"  style="    margin-bottom: -4px;" [ngClass]="{ 'is-invalid': submitted && f.Legal_Name.errors }" placeholder=""/>
                  <div *ngIf="submitted && f.Legal_Name.errors" class="invalid-feedback" >
                    <div *ngIf="f.Legal_Name.errors.required">  <sup>*</sup>Name is required</div>
                </div> -->
                <label class="col-sm-3 col-form-label">Email Id</label>  
                <div class="col-sm-4 positionn">
                  <input  formControlName="Email" type="email" class="form-control"  style="    margin-bottom: -4px;" [ngClass]="{ 'is-invalid': (!Makepayment && submitted && f.Email.errors )}" placeholder="" />
                   <div *ngIf="!Makepayment && submitted && f.Email.errors" class="invalid-feedback">
                    <div *ngIf="f.Email.errors.required">Email is required</div>
              </div>
               <!-- <div *ngIf="Prospects.get('Email').invalid && (Prospects.get('Email').dirty || Prospects.get('Email').touched)" style="color:red;font-size: 13px;">Please enter valid email</div> -->
                 
           
              </div>
              <label class="col-sm-3 col-form-label col2align">Payment Trends</label>
              </div>
          
            </div>
            
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-sm-4 s1">
                    <input  disabled type="text" formControlName="paytrend"   class="form-control" placeholder=""  />
                 
                  </div>
                  <label class="col-sm-3 col-form-label " >
                    Out of business</label>  
   
                  <div class="col-sm-4 " >

                    <div *ngIf="Add!=0" style="    position: relative;
                    left: 120px;
                    top: -31px;">
                   <label style="position: relative; left: -48px; top: 43px;"><input type="radio"  value="1" formControlName="OB" [checked]="ob == true ? true : false" > yes </label>
                   <label style=" position: relative;left:-159px;top:44px;"> <input type="radio" value="2" [(ngModel)]="ob" formControlName="OB" [value]="ob" [checked]="ob == false ? true : false"  > No </label>
                  </div>
                  <div *ngIf="Add==0" style="    position: relative;
                  left: 120px;
                  top: -31px;">
                 <label style="position: relative; left: -121px; top: 43px;"><input type="radio"  value="1" formControlName="OB"  disabled  > yes </label>
                 <label style="position: relative; left: -92px; top: 46px;"> <input type="radio"  [checked]="true"  > No </label>
                </div>
                
                    </div>
                 
                   
                    </div>
              </div>
          
          </div>


          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
              
                
                <label class="col-sm-3 col-form-label">Fax Contact</label>  
                <div class="col-sm-4 positionn">
                  <input  formControlName="Mail_Fax" type="text"  class="form-control"   placeholder="" />
                  
                 
           
              </div>
              <label class="col-sm-3 col-form-label col2align">Suspended Reason</label>
              </div>
          
            </div>
            
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-sm-12 s1">

            
                    <input   type="text" class="form-control" formControlName="SuspendedReason"  [(ngModel)]="suspendreason" placeholder="" style="width:460px" disabled />
                    <!-- <div *ngIf="submitted && f.Website.errors" class="invalid-feedback">
                      <div *ngIf="f.Website.errors.required">Website is required</div>
                </div> -->
                <!-- <div *ngIf="Prospects.get('Website').invalid && (Prospects.get('Website').dirty || Prospects.get('Website').touched)" style="color:red;font-size: 13px;">Please enter valid website</div>
                 -->
                 
                  </div>      
                    </div>
              </div> 
          
          </div>
        
      
          </div>
          <h6 style="margin-left: 5px;margin-top: 16px;">Physical Address </h6>
            <div class="tab"  style="border: 1px solid #ced4da;">
            <div class="row" style="margin-top: 20px;">
              <div class="col-md-7">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Address</label>
                  <div class="col-sm-4 ">
                      <input  formControlName="Bus_Address"  type="text" class="form-control"  style="    margin-left: -50px;
                      width: 220px;"placeholder=""/>
                      <!-- <div *ngIf="submitted && f.Bus_Address.errors" class="invalid-feedback">
                        <div *ngIf="f.Bus_Address.errors.required"> <sup>*</sup> Address is required</div>
                    </div> -->
                  </div>
                  <label class="col-sm-4 col-form-label col2align">City / State / zip</label>

                </div>
            
              </div>
              <div class="col-md-5">
                  <div class="form-group row">
                     
                      <div class="col-sm-4 s1">
                          <input  formControlName="Bus_City" type="text" class="form-control"   placeholder=""/>
                          <!-- <div *ngIf="submitted && f.Bus_City.errors" class="invalid-feedback">
                            <div *ngIf="f.Bus_City.errors.required"> <sup>*</sup> City is required</div>
                        </div> -->
                      </div>
                 
                      <div class="col-sm-3" style="margin-left: 15px;">
                        <input  formControlName="Bus_State" type="text" class="form-control"   placeholder="" maxlength = "2" [ngModel]="inputvalue" 
                        (ngModelChange)="inputvalue = $event.toUpperCase()"/>
                        <!-- <select formControlName="Bus_State" class="form-control" (ngModelChange)='county()' [(ngModel)]="optionValue">
                          <option >Others</option>
                          <option *ngFor="let item of t" [value]="item"  >{{item}}</option>
                         
                        </select> -->
                     
                        <!-- <div class="invalid-feedback d-inline-block" *ngIf="submitted && f.Bus_State.errors">
                          <sup>*</sup>Select One State
                        </div> -->
                      
                        </div>
                    
                    <div class="col-sm-4">
                      <input   formControlName="Zip" type="text" class="form-control"   placeholder="" maxlength = "10"/>
                      <!-- <div *ngIf="submitted && f.Zip.errors" class="invalid-feedback">
                        <div *ngIf="f.Zip.errors.required">zip is required</div>
                  </div> -->
                    </div>
                  </div>
            </div>
          </div>
              <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                    
                      
                      <label class="col-sm-3 col-form-label">Country</label>  
                      <div class="col-sm-4 positionn">
                        <select class="form-control"    formControlName="Bus_Country">
      <option value="US">USA </option>
      <option  value="CA">CN </option>

    </select>
                        <!-- <div *ngIf="submitted && f.Bus_Country.errors" class="invalid-feedback">
                          <div *ngIf="f.Bus_Country.errors.required">Country is required</div>
                    </div> -->
                    </div>
                 
                    </div>
                
                  </div>
                  
                     <div class="col-md-5">
                  <div class="form-group row">
                     
                      <div class="col-sm-3 others"  *ngIf="optionValue == 'Others' || dropval == 1">
                          <!-- <input  formControlName="Bus_State" type="text" class="form-control"   placeholder=""/> -->
                       
                      </div>
                 
                      
                      </div>
                     
                        <!-- <div class="invalid-feedback d-inline-block" *ngIf="submitted && f.Bus_State.errors">
                          <sup>*</sup>Select One State
                        </div> -->
                      
                        </div>
                
              </div>
             </div>
              <h6 style="margin-left: 5px;margin-top: 5px;">Mailing Address </h6>
            <div class="tab"  style="border: 1px solid #ced4da;">
              <div class="row" style="margin-top: 20px;">
                <div class="col-md-7">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label"> Address</label>
                    <div class="col-sm-4 ">
                      <input  formControlName="Mail_Address" type="text" class="form-control" style="    margin-left: -50px;
                      width: 220px;" placeholder=""/>
                      <!-- <div *ngIf="submitted && f.Mail_Address.errors" class="invalid-feedback">
                        <div *ngIf="f.Mail_Address.errors.required">Mailing Address is required</div>
                  </div> -->
                    </div>
                    <label class="col-sm-4 col-form-label col2align">City / State / zip</label>

                  </div>
              
                </div>
                <div class="col-md-5">
                    <div class="form-group row">
                       
                        <div class="col-sm-4 s1 " >
                          <input  formControlName="Mail_City" type="text" class="form-control " placeholder=""/>
                          <!-- <div *ngIf="submitted && f.Mail_City.errors" class="invalid-feedback">
                            <div *ngIf="f.Mail_City.errors.required">City is required</div>
                        </div> -->
                      </div>
                        
                        <div class="col-sm-3" style="    margin-left: 17px;">
                          <input  formControlName="Mail_State" type="text" class="form-control othersip"   placeholder="" maxlength="2" [ngModel]="inputvalue1" 
       (ngModelChange)="inputvalue1 = $event.toUpperCase()"/>
                          <!-- <input  formControlName="Mail_State" type="text" class="form-control" placeholder="" style="margin-left:56px;"/> -->
                          <!-- <select formControlName="Mail_State" class="form-control" (ngModelChange)='county()'  [(ngModel)]="optionValue1">
                            <option >Others</option>
                            <option *ngFor="let item of t" [value]="item" >{{item}}</option>
                       
                          </select> -->
                          <!-- <div *ngIf="submitted && f.Mail_State.errors" class="invalid-feedback">
                            <div *ngIf="f.Mail_State.errors.required">State is required</div>
                      </div> -->
                        
                          </div>
                      
                      <div class="col-sm-4">
                        <input  formControlName="Mail_Zip" type="text" class="form-control " placeholder="" maxlength = "10"/>
                        <!-- <div *ngIf="submitted && f.Mail_Zip.errors" class="invalid-feedback">
                          <div *ngIf="f.Mail_Zip.errors.required">zip is required</div>
                    </div> -->
                    </div>
                   
                    </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                
                  
                  <label class="col-sm-3 col-form-label">Country</label>  
                  <div class="col-sm-4 positionn">
                                       <!-- <input class="form-control"  type="text"  formControlName="Mail_Country"> -->
                                                 <select class="form-control"    formControlName="Mail_Country">
                  <option value="US">USA </option>
                  <option  value="CA">CN</option>
              
                </select>
                                              <!-- <div *ngIf="submitted && f.Mail_Country.errors" class="invalid-feedback">
                                                <div *ngIf="f.Mail_Country.errors.required"> <sup>*</sup> Country is required</div>
                                            </div> -->
                   
                </div>
                <div class="col-sm-1" >
                  <button (click)="copybilling()" type="button" class="btn btn-primary mr-2 btn-sm col2align" style="width: 142px;    margin-left: 39px;">Copy Billing Address</button>
</div>

              
                </div>
            
              </div>
              <div class="col-md-6 others2"   *ngIf="optionValue1 == 'Others' || dropval1==1">
              
                <!-- <div *ngIf="submitted && f.Bus_City.errors" class="invalid-feedback">
                  <div *ngIf="f.Bus_City.errors.required"> <sup>*</sup> City is required</div>
              </div> -->
              </div>
              </div>
            
          </div>
          <br>
          <div class="tab"  style="border: 1px solid #ced4da;">
          <div class="row" >
            
            <div class="col-md-6">
              <div class="form-group row"  style="margin-top: 18px;">
                <label class="col-sm-3 col-form-label">Contact</label> 
   
                <div class="col-sm-4 positionn">
                  <input  formControlName="contact"  type="text" class="form-control"  placeholder=""  [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"/>
                  <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
                    <div *ngIf="f.contact.errors.required">Contact is required</div>
              </div>
              <div *ngIf="Prospects.get('contact').invalid && (Prospects.get('contact').dirty || Prospects.get('contact').touched)" style="color:red;font-size: 13px;">Please Enter Letters</div>
                </div>
                <!-- <label class="col-sm-4 col-form-label col2align">Collection Recall</label> -->
                <label class="col-sm-3 col-form-label">Credit Sales Recall</label>  
              </div>       
            </div>
            
              <div class="col-md-6">
                <div class="form-group row" style="margin-top: 18px;">
                  <div class="col-sm-4 s1">
                    <mat-form-field appearance="none" (click)="picker1.open()"  class="creditrecalll">
                      <input  style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px;"  matInput [matDatepicker]="picker1" 
                      placeholder="mm/dd/yyyy" (click)="picker1.open()"  [(ngModel)]="diss2"
                      formControlName="CreditRecall" (dateInput)="diss2" dateFormat="mm/dd/yy">
                      <mat-datepicker-toggle style="    margin-left: -29px!important;" matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>
                    <!-- <mat-form-field appearance="none" (click)="picker2.open()"  class="collectrecalll">
                      <input style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px;"  matInput [matDatepicker]="picker2" 
                      placeholder="mm/dd/yyyy" (click)="picker2.open()"  [(ngModel)]="diss1"
                      formControlName="CollRecall"  (dateInput)="diss1"  dateFormat="mm/dd/yy">
                      <mat-datepicker-toggle style="    margin-left: -29px!important;" matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                      </mat-form-field> -->
                      
                     
                  </div>
                  <!-- <label class="col-sm-3 col-form-label" >Customer Care Recall</label> -->
                  <label class="col-sm-3 col-form-label" >Customer Care Recall</label>
                  <div class="col-sm-4">
                    <!-- <mat-form-field appearance="none" (click)="picker.open()"  class="ccrecalll">
                      <input style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px;"  matInput [matDatepicker]="picker" 
                      placeholder="mm/dd/yyyy" (click)="picker.open()"  [(ngModel)]="diss"
                      formControlName="CCRecall" (dateInput)="diss" dateFormat="mm/dd/yy">
                      <mat-datepicker-toggle style="margin-left: -29px!important;" matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field> -->
                      <!-- <div *ngIf="submitted && f.CCRecall.errors" class="invalid-feedback">
                        <div *ngIf="f.CCRecall.errors.required">Website is required</div>
                  </div> -->
                  <mat-form-field appearance="none" (click)="picker.open()"  class="ccrecalll">
                    <input style=" border-bottom:1px solid gray; border-left:0px;border-right:0px;border-top:0px;"  matInput [matDatepicker]="picker" 
                    placeholder="mm/dd/yyyy" (click)="picker.open()"  [(ngModel)]="diss"
                    formControlName="CCRecall" (dateInput)="diss" dateFormat="mm/dd/yy">
                    <mat-datepicker-toggle style="margin-left: -29px!important;" matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                
                </div>
                </div>
               
              
              </div>
          
        </div>
        <!-- <div class="row">
          <div class="col-md-6">
            <div class="form-group row" style="margin-top: -20px;">
            
              
              <label class="col-sm-3 col-form-label">Contact</label>  
              <div class="col-sm-4 positionn">
                <input  formControlName="contact"  type="text" class="form-control"  placeholder=""  [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"/>
                <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
                  <div *ngIf="f.contact.errors.required">Contact is required</div>
            </div>
            <div *ngIf="Prospects.get('contact').invalid && (Prospects.get('contact').dirty || Prospects.get('contact').touched)" style="color:red;font-size: 13px;">Please Enter Letters</div>
                <div *ngIf="submitted && f.Bus_Country.errors" class="invalid-feedback">
                  <div *ngIf="f.Bus_Country.errors.required">Country is required</div>
            </div> 
            </div>
           
            </div>
        
          </div>
          
            
        
        </div> -->
      </div>
      <br>
      <h6 style="margin-left: 5px;margin-top: 16px;">Product/Program</h6>
    
      <div class="tab"  style="border: 1px solid #ced4da;">
        <br>
      <div class="row" style="    margin-bottom: -1rem;">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Select a Program</label> 
            <div class="col-sm-4 positionn">
              <select  formControlName="product" class="form-control" [(ngModel)]="SelectedProductList"
              (change)="ProgramAPICall($event.target.value)">
             <option value="">Select</option>
            <option *ngFor="let item of productlist" value="{{item}}">{{item}}</option>
         </select>
               
            </div>
            <label class="col-sm-3 col-form-label col2align">Select a Product</label>
           
          </div>
      
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                
              <div class ="col-sm-4 s1">
                <select   formControlName="program" class="form-control" [(ngModel)]="SelectedProgramList"
                (change)="PriceAPICall()"
                 >
                  <option value="">Select</option>
                 <option *ngFor="let item of programlist">{{item.Programlist}}</option> 
                </select>
                
              </div>
              <label class="col-sm-3 col-form-label">Select a Price</label>
              <div class ="col-sm-4">
                <select   formControlName="program" class="form-control" [(ngModel)]="SelectedPriceList"
                  (change)="selectProgram()"
                 >
                  <option value="">Select</option>
                 <option *ngFor="let item of pricelist">${{item.price}}</option> 
                </select>
                
              </div>

              
                </div>
            </div>
      </div>
      <br>
      <div class="row" >
        <div  class ="col-sm-7"></div>
        <div class ="col-sm-5">
        <button (click)="sendinvoice()" [disabled]="Makepayment" type="button" class="btn btn-primary mr-2 btn-sm col2align"

        style="width: 105px;    margin-left: 39px;" value="send email">Send Contract</button>



        <button (click)="addprogram()" type="button" class="btn btn-primary mr-2 btn-sm col2align"

        style="width: 95px;    margin-left: 39px;" value="">+ Add</button>
      </div>
      </div>
      <br>
      </div>
      



              
                
               
                

              

      <!-- <div class="row">
        <div class="col-md-6">
          <div class="form-group row" style="margin-top: -20px;">
          
            
            <label class="col-sm-3 col-form-label">Contact</label>  
            <div class="col-sm-4 positionn">
              <input  formControlName="contact"  type="text" class="form-control"  placeholder=""  [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"/>
              <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
                <div *ngIf="f.contact.errors.required">Contact is required</div>
          </div>
          <div *ngIf="Prospects.get('contact').invalid && (Prospects.get('contact').dirty || Prospects.get('contact').touched)" style="color:red;font-size: 13px;">Please Enter Letters</div>
              <div *ngIf="submitted && f.Bus_Country.errors" class="invalid-feedback">
                <div *ngIf="f.Bus_Country.errors.required">Country is required</div>
          </div> 
          </div>
         
          </div>
      
        </div>
        
          
      
      </div> -->

        </div>
        <div *ngIf="dataDisplay===1"> <div class="d-flex justify-content-center ">



          <div class="spinner-border " role="status" style="margin-top: -50px;">
          
          <span class="sr-only" id="loading"></span>
          
          </div>
          
          </div> <div class="d-flex justify-content-center mb-3">
             <h7 >  {{ dataDisplay === 1 && contactsave ==1 ? 'Please wait, the contract is being emailed to the Prospect' : 
             'Please wait, the page will be redirected to CreditOps' }}</h7></div><div class="spinner_overlay"></div> </div>
        </div>
      </div>  </div>
     
     
   
      
      <div class="row" style="margin-top: -20px;">
  
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-header hd2 paddingdiv">
                <div class="row" >
                  <div class="col-sm-12" style="margin-top: -20px;">
                  
                      <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                          <li  class="nav-item">
                            <!-- <a class="nav-link active" id="pills-Close-tab" data-toggle="pill" href="#pills-Close" role="tab" aria-controls="pills-Close" aria-selected="true" (click)="dialoguetabchecking()" data-toggle="modal" data-target="#exampleModal1">Dialogue</a> -->
                         
                            <!-- <a class="nav-link   nw-btn "data-toggle="pill" href="#pills-Close" role="tab" aria-controls="pills-Close"  id="newmodalBtn" aria-selected="true" (click)="dialoguetabchecking();closee=1">Dialogue</a> -->
                            <a  class="nav-link active "   id="pills-Close-tab" data-toggle="pill" href="#pills-Close" role="tab" aria-controls="pills-Close" aria-selected="true" (click)="dialoguetabchecking();closee=1">Dialogue</a>
                          </li>
                          <li    class="nav-item">
                            <a  class="nav-link "   id="pills-Competition-tab" data-toggle="pill" href="#pills-Competition" role="tab" aria-controls="pills-Competition" aria-selected="true" (click)="compeditiontabchecking();compeditionn=1">Competition</a>
                          </li>
                          <li   class="nav-item">
                            <a class="nav-link" id="pills-Marketing-tab" data-toggle="pill" href="#pills-Marketing" role="tab" aria-controls="pills-Marketing" aria-selected="true" (click)="marketingtabchecking();markett=1">Marketing</a>
                          </li>
                          <!--<li (click)="reset()"  class="nav-item">
                            <a class="nav-link " id="pills-TCRED-tab" data-toggle="pill" href="#pills-TCRED" role="tab" aria-controls="pills-TCRED" aria-selected="true">TCRED Reps</a>
                          </li>-->
                        
                          <li   class="nav-item">
                              
                              <a class="nav-link " id="newmodalBtn1" data-toggle="pill" href="#pills-Contact" role="tab" aria-controls="pills-Contact" aria-selected="true" (click)="contacttabchecking();contactt=1">Contact</a>
                            </li>
                           <li   class="nav-item">
                              <a class="nav-link " id="pills-Programs-tab" data-toggle="pill" href="#pills-Programs" role="tab" aria-controls="pills-Programs" aria-selected="true" (click)="program();programm=1">Programs</a>
                            </li>
                        </ul>
                  </div>
                      </div>    
              </div>
             
                <!-- <h4 class="card-title">Basic form</h4> -->
     
      <div class="row">
          <div class="col-md-12" style="margin-top: -20px;">
         
          <div class="tab-content mt-3" id="pills-tabContent" style="border: 1px solid #ced4da; margin-top: -20px;" >
            
             
           
            <div *ngIf="checkval=='3'" class="tab-pane fade" id="pills-TCRED" role="tabpanel" aria-labelledby="pills-TCRED-tab">
              <button  type="button" (click)="addRow('TCRED')" class="btn btn-info btn-sm float-right m-2" [disabled]='Row==0'><i class="fa fa-plus"></i> Add</button>
              <div class="table-responsive">
                <table  class="table table-striped thnone">
                  <thead>
                    <tr>
                      
                      <th>TCR</th>
                      <th>Department</th>
                      <th>Transcredit_Rep</th>
                  <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                   
                    <tr id="TCRED{{i}}" *ngFor="let item of ProspectsData?.tcrep; let i=index" >
                       
                      
                      <td>
             
                        <span *ngIf="i!=Row">
                          {{item?.initials}}
                        </span>
                        <span *ngIf="i==Row">
                          <!-- <p style="font-weight: bold;">{{user.GetAccess()?.Name[0]}}{{user.GetAccess()?.Lname[0]}}</p> -->
                          <input id="initials" [value]="item.initials" type="text" class="form-control" placeholder="" disabled/>
                          
                        </span>
                      </td>
                      <td>
                     
                        <span *ngIf="i!=Row">
                          {{item?.dep}}
                        </span>
                        <span *ngIf="i==Row">
                          <input id="dep" [value]="item.dep" type="text" class="form-control" placeholder="Department"/>
                          
                        </span>
                      </td>
                      <td>
                 
                        <span *ngIf="i!=Row">
                          {{item?.repname}}
                        </span>
                        <span *ngIf="i==Row">
                          <input id="repname" [value]="item.repname" type="text" class="form-control" placeholder="Transcredit_Rep"/>
                          
                        </span>
                      </td>
                      <td>
                        <button  *ngIf="i!=Row" type="button" (click)="edit(item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                        <button [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('TCRED',i,item)" *ngIf="i==Row" type="button"><i class="fa fa-save"></i> Save</button>
                        <button class="btn btn-warning btn-sm " (click)="reset()" *ngIf="i==Row" type="button"><i class="fa fa-times"></i> Cancel</button>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModal1Labe" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  
                   
                  <div class="modal-body">

                  </div>
                  
                  <!-- <div class="modal-body">
                    <p class="modal-title" id="exampleModalLabel1" style="margin-left: 100px;"></p>
  
                  </div> -->
                
                </div>
              </div>
            </div>
            
           
              
      
            </div></div>
      </div>      
        
            </div>
          </div>
      </div>

      <!-- The Modal -->
      
                
      <div *ngIf="checkval=='0'" class="tab-pane fade show active in" id="pills-Close" role="tabpanel" aria-labelledby="pills-Close-tab" style="border: 1px solid #ced4da;
      margin-top: -17px;">
        <button  type="button" (click)="addRow('closeforms')" class="btn btn-info btn-sm float-right m-2" [disabled]='Row==0'><i class="fa fa-plus"></i> Add</button>
   
     <div class="table-responsive">
       
       <table  class="table table-striped thnone">
         <thead>
           <tr>
             <th style="width:5%">
               TCR
             </th>
             
             <th style="width:20%">
                Date of Contact
             </th>
             <!-- <th *ngIf="isCustomer">
               Call Activity
            </th> -->
             <th>
                Dialogue
             </th>
             <th>
            Recall Purpose
             </th>
             <th>Action</th>
           </tr>
         </thead>
         <tbody>
           
           <tr id="closeforms{{i}}" *ngFor="let item of ProspectsData?.closeforms; let i=index"  >
        
             <td [ngStyle]="{'color': (item.auto === 1) ? getColorBasedOnText(item.dialogue) : ''}">
               <span *ngIf="i!=Row">
                 {{item?.tcr}}
               </span>
               <span *ngIf="i==Row">
                 <input id="tcr" [value]="item.tcr" type="text" class="form-control" placeholder="" style="width:63px;"  disabled/>
                 <!-- <p style="font-weight: bold;">{{user.GetAccess()?.Name[0]}}{{user.GetAccess()?.Lname[0]}}</p> -->
               </span>
               
             </td>
             <td [ngStyle]="{'color': (item.auto === 1) ? getColorBasedOnText(item.dialogue) : ''}">
    
               <span *ngIf="i!=Row">
                 {{item?.condate}}
               </span>
               <span *ngIf="i==Row">
                 <mat-form-field *ngIf="datepic=='1'" appearance="fill">
                   <input matInput [matDatepicker]="picker123"  placeholder="mm/dd/yyyy" [value]="item.condate" id="condate"  
                    >
                     <mat-datepicker-toggle matSuffix [for]="picker123"></mat-datepicker-toggle>
                     <mat-datepicker #picker123></mat-datepicker>
                     </mat-form-field>
                 <mat-form-field *ngIf="datepic=='2'" appearance="fill">
                   <input matInput [matDatepicker]="picker23" [value]="dis.value" id="condate"  
                    >
                     <mat-datepicker-toggle matSuffix [for]="picker23"></mat-datepicker-toggle>
                     <mat-datepicker #picker23></mat-datepicker>
                     </mat-form-field>
                 <!-- <input id="condate" [value]="item.condate" type="text" class="form-control" placeholder="MM-dd-yyyy"/> -->
                 <!-- <input id="condate" [value]="item.condate" type="date" class="form-control dateofcontact" placeholder="Date of Contact"/> -->
                 
               </span>
             </td>
             <!-- <td *ngIf="isCustomer"  class="cboxList{{i}}">
               <div class="form-check" style="width:200px;">
                 <label >
                   <input [disabled]='i!=Row' class="cbox" value="voice" [checked]='item.voice' type="checkbox" > Voice Mail </label>
               </div>
               <div class="form-check">
                 <label >
                   <input [disabled]='i!=Row' class="cbox" value="wrongcon" [checked]='item.wrongcon' type="checkbox" checked > Wrong Contact </label>
               </div>
               <div class="form-check">
                 <label >
                   <input [disabled]='i!=Row' class="cbox" value="callback" [checked]='item.callback' type="checkbox" checked >Call Back</label>
               </div>
               <div class="form-check">
                 <label >
                   <input [disabled]='i!=Row' class="cbox" value="noint" [checked]='item.noint' type="checkbox" checked >No Interest </label>
               </div>
               <div class="form-check">
                 <label >
                   <input [disabled]='i!=Row' class="cbox" value="comp" [checked]='item.comp' type="checkbox" checked> Competitor </label>
               </div>
            </td> -->
          
            <td [ngStyle]="{'color': (item.auto === 1) ? getColorBasedOnText(item.dialogue) : ''}">
             <span *ngIf="i!=Row">
               {{item?.dialogue}}
             </span>
             <span *ngIf="i==Row">
               
               <!-- <input id="dialogue" [value]="item.dialogue" type="text" class="form-control formm" placeholder="Dialogue" style="width:203px;"/> -->
               <textarea id="dialogue" [value]="item.dialogue" type="text" class='form-control formm auto-expand'  placeholder="Dialogue" rows='1'></textarea>
             </span>
           
           </td>
         
           <td [ngStyle]="{'color': (item.auto === 1) ? getColorBasedOnText(item.dialogue) : ''}">
             <span *ngIf="i!=Row">
               {{item?.recall}}
             </span>
             <span *ngIf="i==Row">
               <input id="recall" [value]="item.recall" type="text" class="form-control" placeholder="Recall Purpose"/>
               
             </span>
           </td>
           <td style="white-space: nowrap!important;">
             <button  *ngIf="i!=Row &&  item.auto!== 1" type="button" (click)="edit('closeforms',item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
             <button  [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('closeforms',i,item)" *ngIf="i==Row" type="button"><i class="fa fa-save"></i> Save</button>
             <button class="btn btn-warning btn-sm " (click)="reset()" *ngIf="i==Row" type="button"><i class="fa fa-times"></i> Cancel</button>
           </td>

           </tr>
         
         </tbody>
       </table>
     </div>

   </div>

      


     


      
      <div id="compeditionbox" class="Modal-newcompetition  container-fluid-well"   cdkDrag cdkDragRootElement=".cdk-overlay-pane"  *ngIf="compeditionn==1 &&  dialoguesavedisable==0 ||compedition=='1'  &&  dialoguesavedisable==0 "  onclick="focuscompedition()">
        
        <div class="modal-header" cdkDragHandle >
        <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                    text-align: center;
                    left: 11px;
                    width: 100%;
                    top: -3px;
                    font-size: inherit;" >Competition</h5>

<button  type="button" (click)="addRow('competition')" class="add-btn btn btn-info btn-sm float-right m-2" [disabled]='showcompetition==true'><i class="fa fa-plus"></i> Add</button>

                    <button *ngIf="popcompetition==0" type="button" (click)="cancel('13');compeditionn=0||compedition='0'" class="close" data-dismiss="modal" aria-label="Close"  style="padding: 23px 0px 12px 16px;outline: none;" >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <button *ngIf="popcompetition==1" type="button" (click)="cancelWithAlert('Competition');" class="close" data-dismiss="modal" aria-label="Close"  style="padding: 23px 0px 12px 16px;outline: none;" >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    
                  </div> 
                
       
                  <div class="scl-hdr tab-pane fade show  in" id="pills-Competition" role="tabpanel" aria-labelledby="pills-Competition-tab">
                   
                    <div class="table-responsive">
                    <table  class="table table-striped thnone">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Competitor</th>
                          <th>Service & Cost</th>
                          <th>Expires</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr id="competition{{i}}" *ngFor="let item of ProspectsData?.competition; let i=index" >
                          
                          <td>
                 
                            <span *ngIf="i!=Row2">
                              {{item?.compdate}}
                            </span>
                            <span *ngIf="i==Row2">
                              <mat-form-field *ngIf="datepic=='1'" appearance="fill">
                                <input matInput [matDatepicker]="picker124"  placeholder="mm/dd/yyyy" [value]="item.compdate" id="compdate"  
                                 >
                                  <mat-datepicker-toggle matSuffix [for]="picker124"></mat-datepicker-toggle>
                                  <mat-datepicker #picker124></mat-datepicker>
                                  </mat-form-field>
                              <mat-form-field *ngIf="datepic=='3'" appearance="fill">
                                <input matInput [matDatepicker]="picker24" [value]="dis1.value" id="compdate"  
                                 >
                                  <mat-datepicker-toggle  matSuffix [for]="picker24"></mat-datepicker-toggle>
                                  <mat-datepicker #picker24></mat-datepicker>
                                  </mat-form-field> 
                              
                            </span>
                          </td>
                          
                          <td>
                     
                            <span *ngIf="i!=Row2">
                              {{item?.competitor}}
                            </span>
                            <span *ngIf="i==Row2">
                              <input id="competitor" [value]="item.competitor" type="text" class="form-control" placeholder="Competitor"/>
                              
                            </span>
                          </td>
                          <td>
                           
                            <span *ngIf="i!=Row2">
                              {{item?.sercost}}
                            </span>
                            <span *ngIf="i==Row2">
                              <input id="sercost" [value]="item.sercost" type="number" class="form-control" placeholder="Service & Cost"/>
                              
                            </span>
                          </td>
                          <td>
                          
                            <span *ngIf="i!=Row2">
                              {{item?.expdate}}
                            </span>
                            <span *ngIf="i==Row2">
                              <mat-form-field *ngIf="datepic=='1'" appearance="fill">
                                <input matInput [matDatepicker]="picker125"  placeholder="mm/dd/yyyy"  [value]="item.expdate" id="expdate"  
                                 >
                                  <mat-datepicker-toggle matSuffix [for]="picker125"></mat-datepicker-toggle>
                                  <mat-datepicker #picker125></mat-datepicker>
                                  </mat-form-field>
                              <mat-form-field *ngIf="datepic=='3'" appearance="fill">
                                <input matInput [matDatepicker]="picker25" [value]="dis2.value" id="expdate"  
                                 >
                                  <mat-datepicker-toggle matSuffix [for]="picker25"></mat-datepicker-toggle>
                                  <mat-datepicker #picker25></mat-datepicker>
                                  </mat-form-field> 
                               <!-- <mat-form-field appearance="fill" (click)="picker3.open()" style="    width: 147px;"> 
    
                                <input readonly matInput [matDatepicker]="picker3"
                                
                                placeholder="mm/dd/yyyy" (click)="picker3.open()" [value]="expdate"
                                
                                id="expdate" dateFormat="mm/dd/yy">
                                
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                
                                <mat-datepicker #picker3></mat-datepicker>
                                
                                </mat-form-field> 
                               <input id="expdate" [value]="item.expdate" type="text" class="form-control" placeholder="MM-dd-yyyy"/> 
                              <input id="expdate" [value]="item.expdate" type="date" class="form-control dateofcontact" placeholder="Expires"/>  -->
                              
                            </span>
                          </td>
                          <td style="word-break: break-word;
                          white-space: nowrap!important;
                          text-align: justify !important;">
                            <button  *ngIf="i!=Row2" type="button" (click)="edit('competition',item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                            <button [disabled]="disabled==1"  class="btn btn-success btn-sm mr-1" (click)="ContactSave('competition',i,item)" *ngIf="i==Row2" type="button"><i class="fa fa-save"></i> Save</button>
                            <button class="btn btn-warning btn-sm " (click)="reset2()" *ngIf="i==Row2" type="button"><i class="fa fa-times"></i> Cancel</button>
                          </td>
    
                        </tr>
                      </tbody>
                    </table>
                  </div>
      
                  </div>



      </div>
      <!-- The Modal end --> 

      <div id="marketbox" class="Modal-newmarket  container-fluid-well" cdkDrag cdkDragRootElement=".cdk-overlay-pane" *ngIf="markett==1  &&  dialoguesavedisable==0||market=='1'  &&  dialoguesavedisable==0" onclick="focusmarket()">
        <div class="modal-header" cdkDragHandle>
        <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                    text-align: center;
                    left: 11px;
                    width: 100%;
                    top: -3px;
                    font-size: inherit;">Marketing</h5>
      <button  type="button" (click)="addRow('Marketing')" class="add-btn btn btn-info btn-sm float-right m-2" [disabled]='showmarket==true'><i class="fa fa-plus"></i> Add</button>

                    <button *ngIf="popmarket==0" type="button" (click)="cancel('14');markett=0||market='0'" class="close" data-dismiss="modal" aria-label="Close"  style="padding: 23px 0px 12px 16px;outline: none;">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <button *ngIf="popmarket==1" type="button" (click)="cancelWithAlert('Marketing');" class="close" data-dismiss="modal" aria-label="Close"  style="padding: 23px 0px 12px 16px;outline: none;">
                      <span aria-hidden="true">&times;</span>
                    </button>
                 
                  </div> 
                
       
                  <div   class="scl-hdr tab-pane fade show  in" id="pills-Marketing" role="tabpanel" aria-labelledby="pills-Marketing-tab">
                  
                  <div class="table-responsive">
                    <table  class="table table-striped thnone">
                      <thead>
                        <tr>
                          
                          <th>Date</th>
                          <th>Delivered Via</th>
                          <th>Marketing or Demos Completed</th>
                      
                      
                      <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                        <tr id="Marketing{{i}}" *ngFor="let item of ProspectsData?.marketing; let i=index" >
                           
                         
                          <td>
                 
                            <span *ngIf="i!=Row3">
                              {{item?.markdate}}
                            </span>
                            <span *ngIf="i==Row3">
                               <mat-form-field *ngIf="datepic=='1'" appearance="fill">
                                <input matInput [matDatepicker]="picker193"  placeholder="mm/dd/yyyy" [value]="item.markdate" id="markdate"  
                                 >
                                  <mat-datepicker-toggle matSuffix [for]="picker193"></mat-datepicker-toggle>
                                  <mat-datepicker #picker193></mat-datepicker>
                                  </mat-form-field>
                              <mat-form-field *ngIf="datepic=='4'" appearance="fill">
                                <input matInput [matDatepicker]="picker26" [value]="dis3.value" id="markdate"  
                                 >
                                  <mat-datepicker-toggle matSuffix [for]="picker26"></mat-datepicker-toggle>
                                  <mat-datepicker #picker26></mat-datepicker>
                                  </mat-form-field> 
                              
                            </span>
                          </td>
                          <td>
                         
                            <span *ngIf="i!=Row3">
                              {{item?.delivered}}
                            </span>
                            <span *ngIf="i==Row3">
                              <input id="delivered" [value]="item.delivered" type="text" class="form-control" placeholder="Delivered Via"/>
                              
                            </span>
                          </td>
                          <td>
                     
                            <span *ngIf="i!=Row3">
                              {{item?.markcomp}}
                            </span>
                            <span *ngIf="i==Row3">
                              <input id="markcomp" [value]="item.markcomp" type="text" class="form-control" placeholder="Marketing or Demos Completed"/>
                              
                            </span>
                         
                          
                          <td>
                            <button  *ngIf="i!=Row3" type="button" (click)="edit('Marketing',item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                            <button [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('Marketing',i,item)" *ngIf="i==Row3" type="button"><i class="fa fa-save"></i> Save</button>
                            <button class="btn btn-warning btn-sm cancelbtn1" (click)="reset3()" *ngIf="i==Row3" type="button"><i class="fa fa-times"></i> Cancel</button>
                          </td>
    
                        </tr>
                      </tbody>
                    </table>
                  </div>
    
                </div>



      </div>


      <div id="contactbox" class="Modal-newcontact  container-fluid-well"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" *ngIf="contactt==1 &&  dialoguesavedisable==0||contact=='1' &&  dialoguesavedisable==0" onclick="focuscontact()">
        <div class="modal-header" cdkDragHandle>
        <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                    text-align: center;
                    left: 11px;
                    width: 100%;
                    top: -3px;
                    font-size: inherit;" >Contact</h5>
                    <button   type="button" (click)="addRow('contact')" class="add-btn btn btn-info btn-sm float-right m-2" [disabled]='Row4==0'><i class="fa fa-plus"></i> Add</button>
                    <button *ngIf="popcontact==0" type="button" (click)="cancel('12');contactt=0||contact='0'" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                      <span aria-hidden="true">&times;</span>
                    </button>
                 
                    <button *ngIf="popcontact==1" type="button" (click)="cancelWithAlert('Contact');" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                      <span aria-hidden="true">&times;</span>
                    </button>


                  
                  </div> 
                
       
                  <div class="scl-hdr tab-pane fade show  in" id="pills-Contact" role="tabpanel" aria-labelledby="pills-Contact-tab">
                    
    
                    <div  class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                           
                            
                            <th style="border-bottom-width: 0px;">
                           Contact Name
                            </th>
                            <th>
                          Position
                            </th>
                            <th>
                      Phone
                            </th>
                           
                                    <th>
                                      Extension
                                            </th>
                                            <th>
                                              Fax
                                                    </th>
                                                    <th>
                                                      Email
                                                            </th>
                                                            <th>
                                                              Toll Free
                                                                    </th>
                                                                    <th>
                                                                     Action
                                                                            </th>
                          </tr>
                        </thead>
                        <tbody>
                     
                          <tr id="contact{{i}}" *ngFor="let item of ProspectsData?.contactform; let i=index" >
                           
                           
                            <td>
                   
                              <span *ngIf="i!=Row4">
                                {{item?.name}}
                              </span>
                              <span *ngIf="i==Row4">
                                <input id="name" [value]="item.name" type="text" class="form-control" placeholder="Contact Name"/>
                                
                              </span>
                            </td>
                            <td>
                           
                              <span *ngIf="i!=Row4">
                                {{item?.position}}
                              </span>
                              <span *ngIf="i==Row4">
                                <input id="position" [value]="item.position" type="text" class="form-control" placeholder="Position"/>
                                
                              </span>
                            </td>
                            <td style="width: 124px;">
                       
                              <span *ngIf="i!=Row4">
                                {{item?.phone}}
                              </span>
                              <span *ngIf="i==Row4">
                               
                                <input   id="phone" [value]="item.phone" type="tel"  class="form-control" placeholder="Phone" maxlength="10"   pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
                                
                              </span>
                            </td>
                           
                            <td>
                            
                              <span *ngIf="i!=Row4">
                                {{item?.extension}}
                              </span>
                              <span *ngIf="i==Row4">
                                <input id="extension" [value]="item.extension" type="text" class="form-control" placeholder="Extension"/>
                                
                              </span>
                            </td>
                            <td style="width: 124px;">
                             
                              <span *ngIf="i!=Row4">
                                {{item?.fax}}
                              </span>
                              <span *ngIf="i==Row4">
                                <input  id="fax" [value]="item.fax" type="tel" class="form-control" placeholder="Fax" maxlength="10"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
                                
                              </span>
                            </td>
                            <td>
                           
                              <span *ngIf="i!=Row4">
                                {{item?.email}}
                              </span>
                              <span *ngIf="i==Row4">
                                <input id="email" [value]="item.email"  type="text" class="form-control" placeholder="Email"/>
                                
                              </span>
                            </td>
                            <td style="width: 124px;">
                             
                              <span *ngIf="i!=Row4">
                                {{item?.toll}}
                              </span>
                              <span *ngIf="i==Row4">
                                <input id="toll" [value]="item.toll" type="tel" class="form-control" placeholder="Toll Free" maxlength="10"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
                                
                              </span>
                            </td>
                            <td style="word-break: break-word;
                            white-space: nowrap!important;
                            text-align: justify !important;">
                              <button  *ngIf="i!=Row4" type="button" (click)="edit('contact',item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                              <button [disabled]="disabled==1" class="btn btn-success btn-sm mr-1" (click)="ContactSave('contact',i,item)" *ngIf="i==Row4" type="button"><i class="fa fa-save"></i> Save</button>
                              <button class="btn btn-warning btn-sm " (click)="reset4()" *ngIf="i==Row4" type="button"><i class="fa fa-times"></i> Cancel</button>
                            </td>
    
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>



      </div>


      <div id="programbox" class="Modal-newprogram  container-fluid-well"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" *ngIf="programm==1 &&  dialoguesavedisable==0||programing=='1' &&  dialoguesavedisable==0"  onclick="focusprogram()">
        <div class="modal-header" cdkDragHandle>
        <h5 class="modal-title" id="exampleModalLabel1" style="    position: relative;
                    text-align: center;
                    left: 11px;
                    width: 100%;
                    top: -3px;
                    font-size: inherit;">Program</h5>
                    <button  type="button" (click)="cancel('15');programm=0||programing='0'" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <!-- <button *ngIf="pop==1" type="button" (click)="cancelWithAlert('Program');" class="close" data-dismiss="modal" aria-label="Close" style="padding: 23px 0px 12px 16px;outline: none;">
                      <span aria-hidden="true">&times;</span>
                    </button> -->


                    
                  </div> 
                
       
                  <div class="scl-hdr tab-pane fade show  in" id="pills-Programs" role="tabpanel" aria-labelledby="pills-Programs-tab">
                    <!-- <button  type="button" (click)="addRow('Programs')" class="btn btn-info btn-sm float-right m-2" [disabled]='Row==0'><i class="fa fa-plus"></i> Add</button> -->
                    <div class="table-responsive">
                      <table  class="table table-striped thnone">
                        <thead>
                          <tr>
                            <th>Created Date</th>
                            <th>Program Type</th>
                            <th>Products</th>
                            <th>Expiration</th>
                            <th>Price</th>
                            
      
                            <th>New</th>
                            <th>Update</th>
                            <th>INFILE</th> 
                              <th>eREPT</th>
                            <th>COL%</th>   
                            <th>Status</th>  
                            
                          
                            <!-- <th>Action</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="Programs{{i}}" *ngFor="let item of ProspectsData?.programs; let i=index" >
                           
                            <td>
                              <span *ngIf="i!=Row">
                                {{item?.pgmdate}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="pgmdate" [value]="item.pgmdate"  type="date" class="form-control dateofcontact" placeholder="Date"/>
                              </span>
                            </td>
                            <td>
                   
                              <span *ngIf="i!=Row">
                                {{item?.pgmtype}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="pgmtype" [value]="item.pgmtype" type="text" class="form-control" placeholder="Program Type"/>
                                
                              </span>
                            </td>
                            <td>
                           
                              <span *ngIf="i!=Row">
                                {{item?.products}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="products" [value]="item.products" type="text" class="form-control" placeholder="Products"/>
                                
                              </span>
                            </td>
                            <td>
                       
                              <span *ngIf="i!=Row">
                                {{item?.expdate}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="expdate" [value]="item.expdate" type="date" class="form-control dateofcontact" placeholder="Expiration"/>
                                
                              </span>
                            </td>
    
                            <td>
                              <span *ngIf="i!=Row">
                                ${{item?.price}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="yearly" [value]="item.price"  type="text" class="form-control" placeholder="Yearly"/>
                              </span>
                            </td>
                            <!-- <td>
                   
                              <span *ngIf="i!=Row">
                                {{item?.monthly}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="monthly" [value]="item.monthly" type="text" class="form-control" placeholder="MO"/>
                                
                              </span>
                            </td> -->
                            <td>
                           
                              <span *ngIf="i!=Row">
                                ${{item?.new}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="new" [value]="item.new" type="text" class="form-control" placeholder="New"/>
                                
                              </span>
                            </td>
                            <td>
                       
                              <span *ngIf="i!=Row">
                                ${{item?.update}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="update" [value]="item.update" type="text" class="form-control" placeholder="Update"/>
                                
                              </span>
                            </td>
    
                            <td>
                           
                              <span *ngIf="i!=Row">
                                ${{item?.online}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="online" [value]="item.online" type="text" class="form-control" placeholder="Online"/>
                                
                              </span>
                            </td>
                            <td>
                       
                              <span *ngIf="i!=Row">
                                ${{item?.report}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="report" [value]="item.report" type="text" class="form-control" placeholder="eREPT"/>
                                
                              </span>
                            </td>
    
                            <td>
                              <span *ngIf="i!=Row">
                                {{item?.coll}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="coll" [value]="item.coll"  type="text" class="form-control" placeholder="COL%"/>
                              </span>
                            </td>
                            <td>
                              <span *ngIf="i!=Row">
                                {{item?.status}}
                              </span>
                              <span *ngIf="i==Row">
                                <input id="status" [value]="item.status"  type="text" class="form-control" placeholder="Status"/>
                              </span>
                            </td>
                            
    <!--                        
                            <td>
                              <button  *ngIf="i!=Row" type="button" (click)="edit(item,i,item.id)" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i> Edit</button>
                              <button class="btn btn-success btn-sm mr-1" (click)="ContactSave('Programs',i,item)" *ngIf="i==Row" type="button"><i class="fa fa-save"></i> Save</button>
                              <button class="btn btn-warning btn-sm" (click)="reset()" *ngIf="i==Row" type="button"><i class="fa fa-times"></i> Cancel</button>
                            </td> -->
      
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>



      </div>




        <div id="full-width" class="modal hide fade in" tabindex="-1">
          <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h1 id="myModalLabel">Modal Heading</h1>
          </div>
          <div class="modal-body ">
            <table class="table table-hover table-bordered datos">
              <thead >
                <tr>
                  <th><span class="fix">ITEM1</span></th>
                  <th><span class="fix">ITEM2</span></th>
                
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>subitem1</td>
                    <td>subitem2</td>
                    <td>subitem3</td>
                   
                </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button data-dismiss="modal" class="btn">Close</button>
            <button class="btn btn-primary">Save changes</button>
          </div>
        </div>

      <div class="d-flex justify-content-center mb-3">
        <!-- [routerLink]="isCustomer?'/prospectmanage':'/prospectcust'" -->
        <button *ngIf= "Add!=0" [disabled]="displaynext!=1 || savedisable==1" type="submit"  (click)="onSubmit1()" class="btn btn-success mr-2 btn-sm"><i class="mdi mdi-file-document"></i>Save</button>
        <button *ngIf= "Add==0"  type="submit"  (click)="onSubmit1()" class="btn btn-success mr-2 btn-sm"><i class="mdi mdi-file-document"></i>Save</button>
        <div  *ngIf= "user.GetAccess().RoleID!=3 && Add!=0"> <button [disabled]="savedisable==1"   type="submit"  (click)="onSubmit2()" class="btn btn-primary mr-2 btn-sm">Save & Convert to Customer</button></div>
        
        
        
        <button  *ngIf="Add!=0"  [disabled]="g==1 || displaynext!=1 || savedisable==1" (click)="getprevious()"  type="button" class="btn btn-success mr-2 btn-sm" >Previous</button>
        <button   *ngIf="Add!=0 "  [disabled]="diablenext==true || displaynext!=1 || savedisable==1" (click)="getnext()"  type="button" class="btn btn-success mr-2 btn-sm" >Next</button>
      
        <!-- <button   [disabled]="g2==1 || g==1"  (click)="sampleprevious()"   type="button" class="btn btn-success mr-2 btn-sm">Previous</button>
        <button  [disabled]="g1==1"  (click)="samplenext()"   type="button" class="btn btn-success mr-2 btn-sm">Next</button> -->

      <button *ngIf="Add!=0"  [disabled]="savedisable==1"     (click)="button1()"   type="button" class="btn btn-secondary mr-2 btn-sm">Back to Prospects</button>
      
      <button  *ngIf="Add!=0 && dropcheck ==1"   [disabled]="savedisable==1"   (click)="button2()"   type="button" class="btn btn-secondary mr-2 btn-sm">Back to recall</button>

      <button  *ngIf="Add==0"   [disabled]="savedisable==1"  routerLink="/prospectmanage"  type="button" class="btn btn-secondary mr-2 btn-sm">Back to Prospects</button>
    
     
      
  </div> 

  </form>

     
