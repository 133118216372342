<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
  <div class="card">
    <div class="card-header" style="    padding-bottom: 29px;
    padding-top: 6px;">   
      <h4 class="card-title" style="font-size: 16px;position: relative;top: 10px;">Manage Users</h4>     <button routerLink="/forms" class="position-absolute left-50 btn btn-success mr-2" style="    margin-left: 82%;
         margin-top: -15px;
      font-size: small;
      background-color: #0c7cd5;
      min-width: 86px;"
      >+Add User</button>     </div>
    <div class="card-body">
      <div class="row justify-content-start">
        <div class="col-lg-2">
            <h6 class="card-description aligright mb-0" >Search by</h6>
        </div>
            <div class="col-lg-2">
                          <select #drop class="form-control drpdwn" [(ngModel)]="dropValue">
                            <option value="0">Select</option>
                            <option value="1">Name</option>
                            <option value="2">Role</option>
                          </select>
                        </div>
                        <div class="col-sm-4">
                          <input #sera type="text" placeholder="Search" #searchInput  class="form-control search searchbox" aria-label="..." [(ngModel)]="searchValue">
                        </div>
                        <div class="col-sm-2">
                <button (click)="Search(drop.value,sera.value)" class="btn btn-secondary btn-sm" ><i class="fa fa-search
                   "></i>Search</button>
                  </div>
                  <div class="col-sm-2">
                    <button (click)="reset()" class="btn btn-outline-secondary btn-sm" ><i class="fa fa-refresh
                      "></i>Reset</button>
                  </div>
    </div>  </div><!-- /.col-lg-6 -->

              <!-- Modal -->
              <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      
                      <h5 class="modal-title" id="exampleModalLabel" style="justify-content: center;margin-top: 5px;    margin-left: 80px;" >Are You Sure You Want To Delete?</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                 
                    <div class="modal-footer">
                     <a  type="button" (click)="delete(dlete)" href="#myModal" class="trigger-btn  btn btn-success btn-sm confrimwidt  btn btn-success okbtn" data-dismiss="modal" data-toggle="modal" style="min-width: 67px;    margin-left: -31
                     px
                     ;">Confirm</a>
                      <!--<button id="#myModal" type="button" class="  btn btn-success btn-sm confrimwidt"  data-toggle="modal" data-target="#confirmModal">Confirm</button>-->
                      <button type="button" class="btn btn-danger btn-sm canclwidt okbtn"  data-dismiss="modal" style="min-width: 67px;    margin-right: 160px ;    margin-top: -1px;">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
             

    
      <div class="table-responsive" style="margin-left: 20px;width:96%">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
                  First name
              </th>
              
              <th>
                  Last name
              </th>
              <th>
                PhoneNo
              </th>
              <th>
                  Email
              </th>
              <th>
          Role
            </th>
            <th>
       Status
                         </th>
              <th *ngIf="user.GetAccess()?.delete_user=='True'">
   Delete
              </th>
              <!-- <th>
                  Edit
                        </th>
                        <th>
                          View
                                </th> -->
            </tr>
          </thead>
          <tbody>
           
              <tr *ngFor="let item of data_trans; let i = index">
              <td>
             <a  [routerLink]="['/forms']" [queryParams]="{userid:  item.Userid}">  {{(item.Firstname )}} </a> 
              </td>
              <td>
                {{(item.Lastname )}}
              </td>
              <td>
                {{(item.PhoneNo )}}
              </td>
              <td>
                {{(item.EmailAddress )}}
              </td>
              <td>
                {{(item.Role )}}
              </td>

              <td>

                 
                  <label class="switch"> 
                    <input (change)=StatusChange(item.Userid,$event) type="checkbox" id="togBtn" style="    margin-right: 0.5rem;
                    position: relative;
                    top: 5px;
                    right: -14px;" [checked]="item.status==1">
                    <div class="slider round"></div>
                    </label>
                  
                </td>

              <td *ngIf="user.GetAccess()?.delete_user=='True'">

                 
                 <i  data-toggle="modal" data-target="#exampleModal"  (click)="onclickdelete(item.Userid)" class="fa fa-trash-o" style="font-size:24px ;color:red"></i>
                </td>
          
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
