import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { UserService } from '../service/user.service';
import { CookieService } from '../../../node_modules/ngx-cookie-service'
import { AuthService } from 'src/app/service/auth.service';
declare var $;
import * as moment from 'moment';
@Component({
  selector: 'app-proscustform',
  templateUrl: './proscustform.component.html',
  styleUrls: ['./proscustform.component.scss']
})
export class ProscustformComponent implements OnInit {
  // dragPosition = {x: 0, y: 0};
  addForm: FormGroup;
  automation: number = 0;
  dataDisplay: number;
  SP_Name: any;
  Brok_Carr: any;
  submitted = false;
  rows: FormArray;
  itemForm: FormGroup;
  ss: number = 0;
  optionValue: any;
  optionValue1: any;
  displaynext: number = 0;
  Tablename1: any
  currentPage: any;
  currentdate: any;
  tcr1 = this.user.GetAccess()?.Name[0] + this.user.GetAccess()?.Lname[0];
  groups = [
    {
      "name": "pencils",
      "items": "red pencil"
    },
    {
      "name": "rubbers",
      "items": "big rubber"
    },
  ];
  @ViewChild('dataTable') table;
  dataTable: any;
  dtOptions: any = {};
  isCustomer: boolean = true;
  userDetail: string;
  Customer: FormGroup;
  CustomerData: any;
  Docket_No: any;
  customerid: any;
  //  submitted: boolean;
  d: any;
  ds: any;
  currentPage1: any;
  CustomerrData: any;
  countyy: any;
  t: any;
  k: any;
  k1: string;
  t1: any;
  country1: any;
  m: any;
  clisttcr1 = this.user.GetAccess()?.Name[0] + this.user.GetAccess()?.Lname[0];
  ccr: any;
  suspend: any;
  a: any;
  b: any;
  c: any;
  nextdocnmr: any;
  nextprosnmr: any;
  nextindex: any;
  localprostlist: any[];
  tt: any;
  r: any;
  datepic: string = "0";
  data_trans: any;
  dis: any;
  doc: any;
  cus: any;
  d1: string;
  d2: string;
  date: any;
  d3: string;
  d4: any;
  d5: any;
  d6: any;
  initials: any;
  clinitials: any;
  dis1: any;
  dis2: any;
  dis3: any;
  checkval: string = "0";
  MailAddress: any;
  MailCity: any;
  MailState: any;
  MailZip: any;
  MailCountry: any;
  website: any;
  Clicus: any;
  zip: any;
  Pin: any;
  CustID: any;
  ss2: any;
  ss1: any;
  g: number;
  ach: any;
  check: any;
  arr1: [];
  dropval: number;
  dropval1: number;
  k2: any;
  diss: any;
  diss1: any;
  diss2: any;
  diss3: any;
  inputvalue1: any;
  inputvalue: any;
  s: Date;
  dropcheck: any;
  dropcheck1: any;
  paytrend: any;
  creditscore: any;
  currentdate1: string;
  hours: number;
  min: number;
  sec: number;
  cspt: any;
  disabled: number;
  OldDocket_No: any;
  newDoc: void;
  show: number;
  txt: any;
  split: any;
  splitted: any;
  index: number;
  classdrop: any;
  dro: any;
  selectedProduct: any;
  duplicate: any;
  selectedDay: any;
  classdrop1: any;
  diablenext: boolean;
  name: any;
  bttn: any;
  val: any;
  val1: any;
  suspendreason: any;
  Suspended: any;
  ob: any;
  o: number;
  TableName: any;
  AccountStatus: any;
  prosid: any;
  TableNamee: any;
  shw: number;
  close: any;
  closee: any;
  contactt: any;
  contact: any;
  compedition: any;
  compeditionn: any
  market: any;
  markett: any;
  programm: any;
  programing: any;
  Row1: any;
  showclose: boolean;
  Row2: any;
  showcompetition: boolean;
  Row3: any;
  showmarket: boolean;
  showcontact: boolean;
  Row4: any;
  disply: number = 0;
  drag: number = 0;
  drag1: number = 0;
  drag2: number = 0;
  drag3: number = 0;

  condition: number = 0;
  value: number = 0;
  value1: number = 0;


  SelectedProductList: any = '';
  SelectedProgramList: any = '';
  redirect_to_tcb_key: boolean = true;
  Tablename: any;
  programlist: any;
  productlist: any = [];
  Makepayment: boolean = true;
  sendInvoice: number = 0;
  pricelist: any;
  RepEmail: any;
  fax: any;
  phone: any;
  dba: any;
  SelectedPriceList: any = '';
  invoice: any;
  saveandcovert: any;
  sendcontractContact: any = '';
  TotalCount: any = 0;
  constructor(private toastr: ToastrService, public user: UserService, private route: ActivatedRoute, public authservice: AuthService, private cookieService: CookieService, private router: Router, private fb: FormBuilder, private http: HttpClient) {

  }
  jsonData: any = [{
    playerName: 'Cristiano Ronaldo',
    playerCountry: 'Pourtgal',
    playerClub: 'Juventus'
  },
  {
    playerName: 'Lionel Messi',
    playerCountry: 'Argentina',
    playerClub: 'Barcelona'
  }];

  ngOnInit(): void {
    this.show = 0;
    this.classification();
    this.ProductAPICall();




    if (localStorage.getItem('close#*') == '1') {
      this.close = 1;
    }
    if (localStorage.getItem('close#*') == '0') {
      this.close = 0;
    }
    if (localStorage.getItem('contact#*') == '1') {
      this.contact = 1;
    }
    if (localStorage.getItem('contact#*') == '0') {
      this.contact = 0;
    }
    if (localStorage.getItem('compedition#*') == '1') {
      this.compedition = 1;
    }
    if (localStorage.getItem('compedition#*') == '0') {
      this.compedition = 0;
    }
    if (localStorage.getItem('market#*') == '1') {
      this.market = 1;
    }
    if (localStorage.getItem('market#*') == '0') {
      this.market = 0;
    }
    // if(localStorage.getItem('program#*')=='1'){
    //   this.programing=1;
    // }
    // if(localStorage.getItem('program#*')=='0'){
    //   this.programing=0;
    // }
    ///var curDate=new Date();
    this.dropcheck = localStorage.getItem("dropcheck1pros");

    this.currentdate = new Date();
    this.hours = new Date().getHours()
    this.min = new Date().getMinutes();
    this.sec = new Date().getSeconds();

    this.currentdate1 = this.hours + ':' + this.min + ':' + this.sec;
    console.log('current date' + this.currentdate);
    if (this.route.snapshot.queryParams['username']) {




    }

    this.dropdown();
    this.dropdown1();
    this.dropdown2();
    this.type();
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.Docket_No = params.Docket_No;
        this.prosid = params.prosid;
        this.customerid = params.cusid;
        this.TableName = params.TN;
        this.Tablename1 = params.AS;
        this.AccountStatus = params.AS
        this.currentPage = (parseInt(params.currentPage));
        this.TotalCount = params.totalCount;
        localStorage.setItem("currentpage2pros", this.currentPage);

        var value = JSON.parse(localStorage.getItem("filteridpros"));
        console.log('val', value);
        this.callapi_source2(value).subscribe((response: any) => {
          this.dataDisplay = 1;
          this.data_trans = response.Data[0].formfileds;
          this.dataDisplay = 1;
          if (this.data_trans.length == this.ss && this.data_trans.length != 31) {
            this.diablenext = true;
          }
          if (response.status == 1) {
            this.dataDisplay = 0;
            this.displaynext = 1;
          }
          console.log('nextdata1', this.data_trans);
        })





        // if(parseInt(params.currentPage)>1){
        //   this.ss= ((parseInt(params.currentPage)-1)*parseInt(params.itemsPerPage))+(parseInt(params.index)+1);
        //   console.log("TTTTTTTTTTTTTTTTT");
        //   console.log(this.ss);

        // }
        //   else{
        //     this.ss=parseInt(params.index);
        //     this.ss=this.ss+1;
        //     console.log("TTTTTTTTTTTTTTTTT");
        //     console.log(this.ss);
        //   }
        this.ss = parseInt(params.index);
        this.ss2 = parseInt(params.index);
        this.index = parseInt(params.index);
        this.ss = this.ss + 1;
        this.ss1 = this.ss2;
        console.log("TTTTTTTTTTTTTTTTT");
        console.log(this.ss);

        this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
        if (this.currentPage == 1 && this.ss2 == 0) {
          this.g = 1
        }
      })

    if (this.AccountStatus == 'Prospect') {
      this.shw = 1;

    } else {
      this.shw = 2;
    }
    this.Customer = this.fb.group({
      automation: [0],
      Bus_Address: ['', Validators.required],
      Bus_City: ['', Validators.required],
      Bus_Country: ['', Validators.required],
      Bus_Fax: ['', Validators.required],
      paytrend: ['', Validators.required],
      creditscore: ['', Validators.required],
      Bus_Phone: ['', Validators.required],
      Bus_State: ['', Validators.required],
      CCRecall: [''],
      DBAName: ['', Validators.required],
      Email: ['', Validators.required],
      trucks: ['', Validators.required],
      otherloc: ['', Validators.required],
      //   Established: ['', Validators.required],    //removable
      Legal_Name: ['', Validators.required],
      Mail_Address: ['', Validators.required],
      Mail_City: ['', Validators.required],
      Mail_State: ['', Validators.required],
      Mail_Zip: ['', Validators.required],

      Website: ['', Validators.required],
      Zip: ['', Validators.required],
      Brok_Carr: ['', Validators.required],
      Cont_Auth: ['', Validators.required],

      // Bus_County: ['', Validators.required],  //removable
      Web: ['', Validators.required],

      clicus: ['', Validators.required],
      startdate: ['', Validators.required],
      Bus_Toll: ['', Validators.required],
      WebID: ['', Validators.required],
      contact: [''],
      Mail_Fax: ['', Validators.required],
      Docket_No: ['', Validators.required],

      Suspended: [''],
      Comments: ['', Validators.required],
      Pin: ['', Validators.required],       //removable
      CustID: ['', Validators.required],



      SP_Name: ['', Validators.required],
      //   Mail_County:['',Validators.required],  //removable
      Mail_Country: ['', Validators.required],
      ACH: ['', Validators.required],
      CreditCard: ['', Validators.required],
      Cheque: ['', Validators.required],
      prosid: [this.prosid],
      Initials: ['', Validators.required],
      CollectInitials: ['', Validators.required],

      CreditRecall: [''],
      CollRecall: [''],
      CRUserID: ['', Validators.required],
      clUserid: ['', Validators.required],
      Userid: ['', Validators.required],
      ccUserid: ['', Validators.required],
      OldDocket_No: ['', Validators.required],
      Docket_Nodrp: [this.split],
      Docket_Notxt: [this.splitted],
      BCCodeID: [0],
      BCCode: [''],
      SuspendedReason: [''],
      OB: [''],
      TableName: [],
      AccountStatus: [],
      SaveOption: ["Save"],
      customerid: [this.customerid],
      program: [''],
      product: ['']
    });

    this.copybilling();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  get f() { return this.Customer.controls; }


  callapi_dropdown() {

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GetSalesPerson?Role=AllActive&userid=SPUserid');
  }
  button1() {
    this.bttn = '1';
    localStorage.setItem("bttnpros", this.bttn);
  }
  button2() {
    this.bttn = '2';
    localStorage.setItem("bttnpros", this.bttn);
  }
  elements: any;
  dropdown() {
    console.log("dropdown working");
    this.callapi_dropdown().subscribe(response => {
      console.log('dropdown working', response);
      this.elements = response.salesperson;
      console.log('wa', response.salesperson[0].SPUserid)

      // response.salesperson.forEach((element: any) => {
      //   // console.log('element',element);
      //   this.elements=element;
      console.log('elements', this.elements);
      // });
      // let salesperson=this.data_trans1.salesperson;
      return this.elements;

    })
  }

  callapi_dropdown1() {

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GetSalesPerson?Role=AllActive&userid=CLUserid');
  }
  elements1: any;
  dropdown1() {
    console.log("dropdown working");
    this.callapi_dropdown1().subscribe(response => {
      console.log('dropdown working1', response);
      this.elements1 = response.salesperson;


      // response.salesperson.forEach((element: any) => {
      //   // console.log('element',element);
      //   this.elements=element;
      console.log('elements', this.elements);
      // });
      // let salesperson=this.data_trans1.salesperson;
      return this.elements;

    })
  }

  elements2: any;
  callapi_dropdown2() {

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GetSalesPerson?Role=AllActive&userid=CCUserid');
  }

  dropdown2() {
    console.log("dropdown working");
    this.callapi_dropdown2().subscribe(response => {
      console.log('dropdown working2', response);
      this.elements2 = response.salesperson;
      console.log('wa', response.salesperson[0].SPUserid)

      // response.salesperson.forEach((element: any) => {
      //   // console.log('element',element);
      //   this.elements=element;
      console.log('elements', this.elements);
      // });
      // let salesperson=this.data_trans1.salesperson;
      return this.elements;

    })
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true,
      searching: false, paging: false, info: false
    };
  }


  public addItem(): void {

    this.groups.push({
      name: 'foo', items: 'bar'
    });
  }
  getProspect(Docket_No, prosid, TableName, AccountStatus) {
    this.dataDisplay = 1;
    let js = { 'Docket_No': Docket_No, 'prosid': prosid, 'TableName': TableName, 'AccountStatus': AccountStatus };
    this.callapi_source(js).subscribe((response: any) => {
      this.dataDisplay = 1;
      if (response.msg == 'Successful') {
        this.dataDisplay = 0;
      }
      if (response) {
        this.CustomerData = response?.Data[0];
        if (this.CustomerData) {
          if (this.CustomerData?.contactform) {
            this.CustomerData.contactform.reverse();
          }
          if (this.CustomerData?.competition) {
            this.CustomerData.competition.reverse();
          }
          if (this.CustomerData?.marketing) {
            this.CustomerData.marketing.reverse();
          }
          if (this.CustomerData?.tcrep) {
            this.CustomerData.tcrep.reverse();
          }
          if (this.CustomerData?.closeforms) {
            this.CustomerData.closeforms.reverse();
          }
          if (this.CustomerData?.programs) {
            this.CustomerData.programs.reverse();
          }
        }
        this.CustomerData.contactform.forEach((number, index) => {

          number.phone.toString();

          if (number.phone.length > 1) {

            number.phone = number.phone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');

          }  // number.phone=number.phone+" 1";

          console.log('Index: ' + index + ' Value: ' + number.phone);

        });


        this.CustomerData.contactform.forEach((number, index) => {


          number.toll.toString();

          if (number.toll.length > 1) {

            number.toll = number.toll.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');

          }  // number.phone=number.phone+" 1";

          console.log('Index: ' + index + ' Value: ' + number.phone);

        });


        this.CustomerData.contactform.forEach((number, index) => {

          number.fax.toString();

          if (number.fax.length > 1) {

            number.fax = number.fax.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');

          }  // number.phone=number.phone+" 1";

          console.log('Index: ' + index + ' Value: ' + number.phone);

        });
        console.log("final check", this.CustomerData.contactform);
        this.bind();
      }
      console.log('Data', this.CustomerData)


    })
  }
  callapi_source2(namee) {

    //var body =   JSON.stringify(this.registerForm.value,null,4);
    var body = namee;

    console.log('xx', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospectcustomer/GetProspectsCustomersUsingSearch', body, { headers: headerOptions });

  }

  getnext() {


    this.Apiforclssification().subscribe((response: any) => {
      console.log(response)

      this.classdrop1 = response.Codelist;
      // for (var i = 0; i < this.classdrop.length; i++) {
      //   if (this.classdrop[i].Codeid == this.Prospects.value.BCCodeID) {
      //     x = this.classdrop[i].Code;   
      //   }
      // }

      if (this.Customer.value.BCCodeID != 0) {
        this.selectedProduct = this.classdrop1[this.Customer.value.BCCodeID - 1].Code;
        this.Customer.value.BCCode = this.selectedProduct
        this.Customer.value.BCCodeID = parseInt(this.Customer.value.BCCodeID);
      }

      this.Customer.value.OldDocket_No = this.newDoc;
      this.Customer.value.AccountStatus = this.AccountStatus;
      this.Customer.value.TableName = this.TableName;
      if (this.Customer.value.OB == '1') {
        this.Customer.value.OB = true;
      }
      else {
        this.Customer.value.OB = false;
      }


      if (this.show == 1) {
        this.Customer.value.Docket_No = this.Customer.value.Docket_Nodrp + this.Customer.value.Docket_Notxt
      }
      var pattern = '^[a-zA-Z ]*$';
      console.log(this.Customer.value.Bus_Phone);
      console.log('y', this.Customer.value);
      console.log('data22222222222222222', this.Customer.value.Brok_Carr)
      this.submitted = true;
      if (this.Customer.value.invalid) {
        return false;

      }

      if (this.Customer.value.Legal_Name == '' || this.Customer.value.Docket_No == '' || this.Customer.value.clUserid == null || this.Customer.value.Brok_Carr == '') {
        this.toastr.error('Please Fill Mandatory Fields');
      }

      else {
        this.automation = 0;
        this.callapi_source1(this.Customer.value, this.automation).subscribe((response: any) => {

          this.CustomerrData = response;
          //this.bind();

          console.log('saved', this.CustomerrData)
          if (response.status == 1) {
            // this.toastr.success(response.msg);
            // if (this.Customer.value.OB == '1') { this.router.navigateByUrl('/proscust'); }
            if (this.data_trans.length == this.ss && this.data_trans.length != 31) {
              this.diablenext = true;
            }
            if (this.data_trans.length == this.ss) {

              var value = JSON.parse(localStorage.getItem("filteridpros"));
              this.currentPage = this.currentPage + 1;


              value['Index'] = this.currentPage;
              localStorage.setItem("filteridpros", JSON.stringify(value));

              this.callapi_source2(value).subscribe((response: any) => {
                this.dataDisplay = 1;
                this.data_trans = response.Data[0].formfileds;
                this.dataDisplay = 1;
                this.data_trans = response.Data[0].formfileds;
                if (response.status == 1) {
                  this.doc = this.data_trans[0].Docket_No;
                  this.cus = this.data_trans[0].prosid;
                  this.AccountStatus = this.data_trans[0].AccountStatus;
                  this.TableName = this.data_trans[0].Tablename;
                  this.customerid = this.data_trans[0].customerid;
                  this.dataDisplay = 0;
                  this.displaynext = 1;


                  var redirecturl = '/proscustform?' + 'Docket_No=' + this.doc + '&prosid=' + this.cus + '&index=' + 0 + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage + '&AS=' + this.AccountStatus + '&TN=' + this.TableName + '&cusid=' + this.customerid;
                  window.location.assign(redirecturl + "#form-reload");
                }


              });


            }
            else {
              if (this.Customer.value.OB == true) {
                if (this.data_trans.length - 1 > this.ss) {
                  this.ss = this.ss + 1;
                }
              }
              //this.ss++
              this.doc = this.data_trans[this.ss].Docket_No;
              this.cus = this.data_trans[this.ss].prosid;
              this.AccountStatus = this.data_trans[this.ss].AccountStatus;
              this.TableName = this.data_trans[this.ss].Tablename;
              this.customerid = this.data_trans[this.ss].customerid;
              var redirecturl = '/proscustform?' + 'Docket_No=' + this.doc + '&prosid=' + this.cus + '&index=' + this.ss + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage + '&AS=' + this.AccountStatus + '&TN=' + this.TableName + '&cusid=' + this.customerid;;
              window.location.assign(redirecturl + "#form-reload");
              console.log();
              // this.ss++
              // alert(this.ss);

            }
          }
          else {
            this.toastr.error('Not able to Save, Please try again');

          }


        })

      }

    })

  }
  getprevious() {

    this.Apiforclssification().subscribe((response: any) => {
      console.log(response)

      this.classdrop1 = response.Codelist;
      // for (var i = 0; i < this.classdrop.length; i++) {
      //   if (this.classdrop[i].Codeid == this.Prospects.value.BCCodeID) {
      //     x = this.classdrop[i].Code;   
      //   }
      // }
      if (this.Customer.value.BCCodeID != 0) {
        this.selectedProduct = this.classdrop1[this.Customer.value.BCCodeID - 1].Code;
        this.Customer.value.BCCode = this.selectedProduct
        this.Customer.value.BCCodeID = parseInt(this.Customer.value.BCCodeID);
      }

      if (this.Customer.value.OB == '1') {
        this.Customer.value.OB = true;
      }
      else {
        this.Customer.value.OB = false;
      }
      this.Customer.value.AccountStatus = this.AccountStatus;
      this.Customer.value.TableName = this.TableName;
      this.Customer.value.OldDocket_No = this.newDoc;
      if (this.show == 1) {
        this.Customer.value.Docket_No = this.Customer.value.Docket_Nodrp + this.Customer.value.Docket_Notxt
      }
      var pattern = '^[a-zA-Z ]*$';
      console.log(this.Customer.value.Bus_Phone);
      console.log('y', this.Customer.value);
      console.log('data22222222222222222', this.Customer.value.Brok_Carr)
      this.submitted = true;
      if (this.Customer.value.invalid) {
        return false;

      }

      if (this.Customer.value.Legal_Name == '' || this.Customer.value.Docket_No == '' || this.Customer.value.clUserid == null || this.Customer.value.Brok_Carr == '') {
        this.toastr.error('Please Fill Mandatory Fields');
      }

      else {
        this.automation = 0;
        this.callapi_source1(this.Customer.value, this.automation).subscribe((response: any) => {
          if (response) {
            this.CustomerrData = response;
            //this.bind();
          }
          console.log('saved', this.CustomerrData)
          if (response.status == 1) {
            // this.toastr.success(response.msg);

            if (this.data_trans.length == this.ss && this.data_trans.length != 31) {
              this.diablenext = true;
            }
            if (this.ss2 == 0) {

              var value = JSON.parse(localStorage.getItem("filteridpros"));
              this.currentPage = this.currentPage - 1;


              value['Index'] = this.currentPage;
              localStorage.setItem("filteridpros", JSON.stringify(value));

              this.callapi_source2(value).subscribe((response: any) => {
                this.dataDisplay = 1;
                this.data_trans = response.Data[0].formfileds;
                this.dataDisplay = 1;
                this.data_trans = response.Data[0].formfileds;
                if (response.status == 1) {
                  this.doc = this.data_trans[29].Docket_No;
                  this.cus = this.data_trans[29].prosid;
                  this.AccountStatus = this.data_trans[29].AccountStatus;
                  this.TableName = this.data_trans[29].Tablename;
                  this.customerid = this.data_trans[29].customerid;
                  this.dataDisplay = 0;
                  this.displaynext = 1;

                  var redirecturl = '/proscustform?' + 'Docket_No=' + this.doc + '&prosid=' + this.cus + '&index=' + 29 + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage + '&AS=' + this.AccountStatus + '&TN=' + this.TableName + '&cusid=' + this.customerid;;
                  window.location.assign(redirecturl + "#form-reload");
                }


              });


            }
            else {

              // if (this.Customer.value.OB == true) { this.ss1 = this.ss1 - 1 }
              if (this.Customer.value.OB == true) {
                if (this.data_trans.length - 1 >= this.ss1) {
                  this.ss1 = this.ss1 - 1;
                }
              }
              //this.ss++
              this.doc = this.data_trans[this.ss1].Docket_No;
              this.cus = this.data_trans[this.ss1].prosid;
              this.AccountStatus = this.data_trans[this.ss1].AccountStatus;
              this.TableName = this.data_trans[this.ss1].Tablename;
              this.customerid = this.data_trans[this.ss1].customerid;

              var redirecturl = '/proscustform?' + 'Docket_No=' + this.doc + '&prosid=' + this.cus + '&index=' + this.ss1 + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage + '&AS=' + this.AccountStatus + '&TN=' + this.TableName + '&cusid=' + this.customerid;;
              window.location.assign(redirecturl + "#form-reload");
              console.log();
              // this.ss++
              // alert(this.ss);
            }
          }
          else {
            this.toastr.error('Not able to Save, Please try again');
          }
        })
      }
    })

  }
  callapi_source(namee) {

    //var body =   JSON.stringify(this.CustomerData.value,null,4);
    var body = namee;
    console.log('xx', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospectcustomer/ClientProspectsAutoPopulate', body, { headers: headerOptions });
  }
  onSubmit() {
    // this.Brok_Carr= this.Customer.get('Brok_Carr').setValue(this.CustomerData.formfileds[0].Brok_Carr);
    this.SP_Name = this.Customer.get('SP_Name').setValue(this.CustomerData.formfileds[0].SP_Name);
    console.log('y', this.CustomerData);

    this.submitted = true;
    if (this.CustomerData.invalid) {
      return false;
    }
    this.callapi_source(this.CustomerData.value).subscribe((response: any) => {
      if (response) {
        this.CustomerData = response.Data;
      }
      console.log('saved', this.CustomerData)
    })
  }
  next(Docket_No, prosid) {
    let js = { 'Docket_No': Docket_No, 'prosid': prosid };
    this.callapi_source(js).subscribe((response: any) => {
      console.log('y1', response);
      // var t =(p-1)*5 + (i);
      console.log('kk333', js)
    })
  }
  copybilling() {



    this.Customer.get('Mail_Address').setValue(this.Customer.get('Bus_Address').value);
    this.Customer.get('Mail_City').setValue(this.Customer.get('Bus_City').value);
    this.Customer.get('Mail_State').setValue(this.Customer.get('Bus_State').value);
    this.Customer.get('Mail_Zip').setValue(this.Customer.get('Zip').value);
    //   this.Customer.get('Mail_County').setValue(this.Customer.get('Bus_County').value);     //removable
    this.Customer.get('Mail_Country').setValue(this.Customer.get('Bus_Country').value);
  }
  bind() {
    this.suspendreason = this.CustomerData.formfileds[0].SuspendedReason;
    this.name = this.CustomerData.formfileds[0].Legal_Name;

    if (this.CustomerData.formfileds[0].Email != '') {
      this.val = this.CustomerData.formfileds[0].contact;
      this.val1 = this.CustomerData.formfileds[0].Email;
    }
    else if (this.CustomerData.contactform.length == 2) {
      if (this.CustomerData.contactform[0].email != '') {
        this.val = this.CustomerData.contactform[0].name;
        this.val1 = this.CustomerData.contactform[0].email;
      }
      else {
        this.val = this.CustomerData.contactform[1].name;
        this.val1 = this.CustomerData.contactform[1].email;
      }
    } else if (this.CustomerData.contactform.length == 1) {
      this.val = this.CustomerData.contactform[0].name;
      this.val1 = this.CustomerData.contactform[0].email;
    }
    else {
      this.val = this.CustomerData.formfileds[0].contact;
      this.val1 = this.CustomerData.formfileds[0].Email;
    }


    this.date = this.CustomerData.formfileds[0].CCRecall
    let dateString = this.CustomerData.formfileds[0].CCRecall;
    let momentVariable = moment(dateString, 'MM-DD-YYYY');
    let stringvalue = momentVariable.format('YYYY-MM-DD');
    console.log(stringvalue);
    this.d = stringvalue;
    console.log('am d from another', this.d);



    let dateString1 = this.CustomerData.formfileds[0].CreditRecall;
    let momentVariable1 = moment(dateString1, 'MM-DD-YYYY');
    let stringvalue1 = momentVariable1.format('YYYY-MM-DD');
    console.log(stringvalue1);
    this.d1 = stringvalue1;
    console.log('am d from another', this.d);

    let dateString2 = this.CustomerData.formfileds[0].CollRecall;
    let momentVariable2 = moment(dateString2, 'MM-DD-YYYY');
    let stringvalue2 = momentVariable2.format('YYYY-MM-DD');
    console.log(stringvalue2);
    this.d2 = stringvalue2;
    console.log('am d from another', this.d);

    let dateString3 = this.CustomerData.formfileds[0].Suspended;
    let momentVariable3 = moment(dateString3, 'MM-DD-YYYY');
    let stringvalue3 = momentVariable3.format('YYYY-MM-DD');
    console.log(stringvalue2);
    this.d3 = stringvalue3;




    // this.t=this.Customer.value.Bus_Phone;

    // this.r=this.t.replace("-","");
    // console.log('xx',this.r);
    // alert(this.r);
    //console.log('brokerformgroup',this.CustomerData.formfileds[0].CreditCard) 
    console.log('doc', this.CustomerData.formfileds[0].Docket_No)
    this.cspt = this.CustomerData.cspt[0].creditscore;
    console.log("*******", this.cspt);
    this.diss = "";
    this.diss1 = "";
    this.diss2 = "";
    this.diss3 = "";



    this.diss = new Date(this.CustomerData.formfileds[0].CCRecall);
    this.diss1 = new Date(this.CustomerData.formfileds[0].CollRecall);
    this.diss2 = new Date(this.CustomerData.formfileds[0].CreditRecall);
    this.diss3 = new Date(this.CustomerData.formfileds[0].Suspended);


    if (this.CustomerData.formfileds[0].CCRecall == null) {
      this.diss = 'mm/dd/yy'
    }
    else {
      this.diss = new Date(this.CustomerData.formfileds[0].CCRecall);
    }
    if (this.CustomerData.formfileds[0].CreditRecall == null) {
      this.diss2 = 'mm/dd/yy'
    }
    else {
      this.diss2 = new Date(this.CustomerData.formfileds[0].CreditRecall);
    }
    if (this.CustomerData.formfileds[0].CollRecall == null) {
      this.diss1 = 'mm/dd/yy'
    }
    else {
      this.diss1 = new Date(this.CustomerData.formfileds[0].CollRecall);
    }
    if (this.CustomerData.formfileds[0].Suspended == null) {
      this.diss3 = 'mm/dd/yy'
    }
    else {
      this.diss3 = new Date(this.CustomerData.formfileds[0].Suspended);
    }


    this.a = this.CustomerData.formfileds[0].ACH;
    this.b = this.CustomerData.formfileds[0].CreditCard;
    this.c = this.CustomerData.formfileds[0].Cheque;
    this.d4 = this.CustomerData.formfileds[0].Userid;
    this.d5 = this.CustomerData.formfileds[0].clUserid;
    this.d6 = this.CustomerData.formfileds[0].ccUserid;
    this.doc = this.CustomerData.formfileds[0].Docket_No;
    this.initials = this.CustomerData.formfileds[0].Initials;
    this.clinitials = this.CustomerData.formfileds[0].CollectInitials;
    this.MailAddress = this.CustomerData.formfileds[0].Mail_Address;
    this.MailCity = this.CustomerData.formfileds[0].Mail_City;
    this.MailState = this.CustomerData.formfileds[0].Mail_State;
    this.MailZip = this.CustomerData.formfileds[0].Mail_Zip;
    this.MailCountry = this.CustomerData.formfileds[0].Mail_Country;
    this.website = this.CustomerData.formfileds[0].Website;
    this.Clicus = this.CustomerData.formfileds[0].clicus;
    this.zip = this.CustomerData.formfileds[0].Zip;
    this.Pin = this.CustomerData.formfileds[0].Pin;
    this.CustID = this.CustomerData.formfileds[0].CustID;
    this.paytrend = this.CustomerData.cspt[0].paytrend;
    this.creditscore = this.CustomerData.cspt[0].creditscore;
    this.c = this.CustomerData.formfileds[0].CreditCard;
    this.ach = this.CustomerData.formfileds[0].ACH;
    this.check = this.CustomerData.formfileds[0].Cheque;
    this.newDoc = this.CustomerData.formfileds[0].Docket_No;
    this.Suspended = this.CustomerData.formfileds[0].Suspended;
    this.ob = this.CustomerData.formfileds[0].OB;

    this.RepEmail = this.CustomerData.formfileds[0].RepEmail;
    this.SP_Name = this.CustomerData.formfileds[0].SP_Name;
    // this.Tablename1 = this.CustomerData.formfileds[0].Tablename;
    this.dba = this.CustomerData.formfileds[0].DBAName;
    this.fax = this.CustomerData.formfileds[0].Bus_Fax;
    this.phone = this.CustomerData.formfileds[0].Bus_Phone;
    this.sendcontractContact = this.CustomerData.formfileds[0].contact


    this.splitted = this.CustomerData.formfileds[0].Docket_No.split('FF', 3);
    this.split = this.CustomerData.formfileds[0].Docket_No.substring(0, 2);

    if (this.split == 'FF') {
      var re = /FF/gi;
      var str = this.CustomerData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
    else if (this.split == 'MC') {
      var re = /MC/gi;
      var str = this.CustomerData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
    else if (this.split == 'MX') {
      var re = /MX/gi;
      var str = this.CustomerData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }


    //   this.Customer.get('Bus_County').setValue(this.CustomerData.formfileds[0].Bus_County);   //removable
    this.Customer.get('WebID').setValue(this.CustomerData.formfileds[0].WebID);
    this.Customer.get('contact').setValue(this.CustomerData.formfileds[0].contact);
    this.Customer.get('Mail_Fax').setValue(this.CustomerData.formfileds[0].Mail_Fax);
    this.Customer.get('Bus_Toll').setValue(this.CustomerData.formfileds[0].Bus_Toll);
    this.Customer.get('Brok_Carr').setValue(this.CustomerData.formfileds[0].Brok_Carr);
    this.Customer.get('Bus_Address').setValue(this.CustomerData.formfileds[0].Bus_Address);
    this.Customer.get('Bus_City').setValue(this.CustomerData.formfileds[0].Bus_City);
    this.Customer.get('Bus_Country').setValue(this.CustomerData.formfileds[0].Bus_Country);
    this.Customer.get('Bus_Phone').setValue(this.CustomerData.formfileds[0].Bus_Phone);
    this.Customer.get('Bus_State').setValue(this.CustomerData.formfileds[0].Bus_State);
    this.Customer.get('Bus_Fax').setValue(this.CustomerData.formfileds[0].Bus_Fax);
    this.Customer.get('paytrend').setValue(this.CustomerData.cspt[0].paytrend);

    this.Customer.get('BCCodeID').setValue(this.CustomerData.formfileds[0].BCCodeID);
    this.Customer.get('BCCode').setValue(this.CustomerData.formfileds[0].BCCode);

    //  console.log("pay",this.CustomerData.cspt.paytrend);
    this.Customer.get('creditscore').setValue(this.CustomerData.cspt[0].creditscore);
    //    console.log("pay",this.CustomerData.cspt.creditscore);
    this.Customer.get('trucks').setValue(this.CustomerData.formfileds[0].trucks);
    this.Customer.get('otherloc').setValue(this.CustomerData.formfileds[0].otherloc);

    this.Customer.get('CCRecall').setValue(this.diss);
    this.Customer.get('CreditRecall').setValue(this.diss2);
    this.Customer.get('CollRecall').setValue(this.diss1);
    this.Customer.get('Suspended').setValue(this.CustomerData.formfileds[0].Suspended);
    // this.Customer.get('CCRecall').setValue(this.CustomerData.formfileds[0].CCRecall);
    this.Customer.get('DBAName').setValue(this.CustomerData.formfileds[0].DBAName);
    this.Customer.get('Email').setValue(this.val1);
    //  this.Customer.get('Established').setValue(this.CustomerData.formfileds[0].Established);   //removable
    this.Customer.get('Legal_Name').setValue(this.CustomerData.formfileds[0].Legal_Name);
    this.Customer.get('CollectSlsp').setValue(this.CustomerData.formfileds[0].CollectSlsp);
    this.Customer.get('Mail_Address').setValue(this.CustomerData.formfileds[0].Mail_Address);
    this.Customer.get('Mail_City').setValue(this.CustomerData.formfileds[0].Mail_City);
    this.Customer.get('Mail_State').setValue(this.CustomerData.formfileds[0].Mail_State);
    this.Customer.get('Mail_Zip').setValue(this.CustomerData.formfileds[0].Mail_Zip);
    this.Customer.get('Website').setValue(this.CustomerData.formfileds[0].Website);
    this.Customer.get('Zip').setValue(this.CustomerData.formfileds[0].Zip);
    this.Customer.get('Docket_No').setValue(this.CustomerData.formfileds[0].Docket_No);
    //   this.Customer.get('Mail_County').setValue(this.CustomerData.formfileds[0].Mail_County);    //removable
    this.Customer.get('Mail_Country').setValue(this.CustomerData.formfileds[0].Mail_Country);


    this.Customer.get('Comments').setValue(this.CustomerData.formfileds[0].Comments);
    this.Customer.get('Pin').setValue(this.CustomerData.formfileds[0].Pin);                     //removable
    this.Customer.get('CustID').setValue(this.CustomerData.formfileds[0].CustID);
    this.Customer.get('Userid').setValue(this.CustomerData.formfileds[0].Userid.toString());
    this.Customer.get('CRUserID').setValue(this.CustomerData.formfileds[0].Userid.toString());
    // this.Customer.value.CRUserID = this.CustomerData.formfileds[0].Userid.toString()
    this.Customer.get('clUserid').setValue(this.CustomerData.formfileds[0].clUserid.toString());
    this.Customer.get('ccUserid').setValue(this.CustomerData.formfileds[0].ccUserid.toString());
    this.Customer.get('clicus').setValue(this.CustomerData.formfileds[0].clicus);

    this.Customer.get('ACH').setValue(this.CustomerData.formfileds[0].ACH);
    this.Customer.get('CreditCard').setValue(this.CustomerData.formfileds[0].CreditCard);
    this.Customer.get('Cheque').setValue(this.CustomerData.formfileds[0].Cheque);

    this.Customer.get('Initials').setValue(this.CustomerData.formfileds[0].Initials);
    this.Customer.get('CollectInitials').setValue(this.CustomerData.formfileds[0].CollectInitials);

    this.Customer.get('SuspendedReason').setValue(this.CustomerData.formfileds[0].SuspendedReason);
    this.Customer.get('OB').setValue(this.CustomerData.formfileds[0].OB);


  }

  callapi_source1(namee, automation) {

    if (this.Customer.value.CCRecall == 'Invalid date' || this.Customer.value.CCRecall == undefined || this.Customer.value.CCRecall == '' || this.Customer.value.CCRecall == 'mm/dd/yy') {
      this.Customer.value.CCRecall = null;
    } else {
      this.Customer.value.CCRecall = this.Customer.value.CCRecall;
      this.ccr = this.Customer.value.CCRecall;
      var ccrdate = new Date(Date.parse(this.ccr));
      var ccrmonth = ccrdate.getMonth() + 1;
      var ccrecalldate = ccrdate.getDate();
      var ccryear = ccrdate.getFullYear();
      var finalccrecalldate = ccrmonth + '/' + ccrecalldate + '/' + ccryear;
      console.log('finalccrecalldate', finalccrecalldate);
      this.Customer.value.CCRecall = finalccrecalldate;
    }

    if (this.Customer.value.CreditRecall == 'Invalid date' || this.Customer.value.CreditRecall == undefined || this.Customer.value.CreditRecall == '' || this.Customer.value.CreditRecall == 'mm/dd/yy') {
      this.Customer.value.CreditRecall = null;
    } else {


      this.Customer.value.CreditRecall = this.Customer.value.CreditRecall;
      this.ccr = this.Customer.value.CreditRecall;
      var ccrdate = new Date(Date.parse(this.ccr));
      var ccrmonth = ccrdate.getMonth() + 1;
      var ccrecalldate = ccrdate.getDate();
      var ccryear = ccrdate.getFullYear();
      var finalccrecalldate = ccrmonth + '/' + ccrecalldate + '/' + ccryear;
      console.log('finalccrecalldate', finalccrecalldate);
      this.Customer.value.CreditRecall = finalccrecalldate;
    }

    if (this.Customer.value.CollRecall == 'Invalid date' || this.Customer.value.CollRecall == undefined || this.Customer.value.CollRecall == '' || this.Customer.value.CollRecall == 'mm/dd/yy') {
      this.Customer.value.CollRecall = null;
    }
    else {
      this.Customer.value.CollRecall = this.Customer.value.CollRecall;
      this.ccr = this.Customer.value.CollRecall;
      var ccrdate = new Date(Date.parse(this.ccr));
      var ccrmonth = ccrdate.getMonth() + 1;
      var ccrecalldate = ccrdate.getDate();
      var ccryear = ccrdate.getFullYear();
      var finalccrecalldate = ccrmonth + '/' + ccrecalldate + '/' + ccryear;
      console.log('finalccrecalldate', finalccrecalldate);
      this.Customer.value.CollRecall = finalccrecalldate;
    }


    if (this.Customer.value.Suspended == 'Invalid date' || this.Customer.value.Suspendeds == undefined || this.Customer.value.Suspendeds == '' || this.Customer.value.Suspendeds == 'mm/dd/yy') {
      this.Customer.value.Suspended = null;
    }
    else {
      this.Customer.value.Suspended = this.Customer.value.Suspended;
      this.suspend = this.Customer.value.Suspended;
      var suspendedDate = new Date(Date.parse(this.suspend));
      var suspendmonth = suspendedDate.getMonth() + 1;
      var suspenddate = suspendedDate.getDate();
      var suspendyear = suspendedDate.getFullYear();
      var finalsuspendeddate = suspendmonth + '/' + suspenddate + '/' + suspendyear;
      console.log('finalsuspendeddate', finalsuspendeddate);
      this.Customer.value.Suspended = finalsuspendeddate;

    }
    this.Customer.value.automation = automation;
    var body = JSON.stringify(this.Customer.value, null, 4);
    //var body =   namee;
    console.log('xx1', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospectcustomer/UpdateProspectCustomer', body, { headers: headerOptions });
  }
  //  selectChangeHandler (event: any) {
  //   //update the ui
  //   this.selectedDay = event.target.value;

  // }
  onSubmit1() {
    this.Customer.value.SaveOption = 'Save';
    this.Customer.value.AccountStatus = this.AccountStatus;
    this.Customer.value.TableName = this.TableName;
    this.Apiforclssification().subscribe((response: any) => {
      console.log(response)
      this.classdrop1 = response.Codelist;
      // for (var i = 0; i < this.classdrop.length; i++) {
      //   if (this.classdrop[i].Codeid == this.Prospects.value.BCCodeID) {
      //     x = this.classdrop[i].Code;   
      //   }
      // }
      if (this.Customer.value.BCCodeID != 0) {
        this.selectedProduct = this.classdrop1[this.Customer.value.BCCodeID - 1].Code;
        this.Customer.value.BCCode = this.selectedProduct
        this.Customer.value.BCCodeID = parseInt(this.Customer.value.BCCodeID);
      }

      if (this.show == 1) {
        this.Customer.value.Docket_No = this.Customer.value.Docket_Nodrp + this.Customer.value.Docket_Notxt
      }

      this.Customer.value.OldDocket_No = this.newDoc;

      if (this.Customer.value.OB == '1') {
        this.Customer.value.OB = true;
        this.o = 1
      }
      else {
        this.Customer.value.OB = false;
        this.o = 0
      }

      if (this.Customer.value.Legal_Name == '' || this.Customer.value.Docket_No == '' || this.Customer.value.clUserid == null || this.Customer.value.Brok_Carr == '') {
        this.toastr.error('Please Fill Mandatory Fields');
      }

      else if (this.Customer.value.Docket_No == '' && this.show == 1) {
        this.toastr.error('Please Fill Mandatory Fields');
        console.log('Please Fill Mandatory Fields')

      }
      else if (this.Customer.value.Docket_No.length != 10 && this.show == 1) {
        this.toastr.error('MC Field Need 8 Numbers');

      }
      else {
        this.automation = 0;
        this.callapi_source1(this.Customer.value, this.automation).subscribe((response: any) => {

          this.CustomerrData = response;
          //this.bind();
          console.log('saved', this.CustomerrData)
          if (response.status == 1) {
            this.Docket_No = this.Customer.value.Docket_No;
            this.toastr.success(response.msg);
            if (this.Customer.value.OB != '1') {

              this.Docket_No = this.Customer.value.Docket_No;
              var redirecturl = '/proscustform?' + 'Docket_No=' + this.Docket_No + '&prosid=' + this.prosid + '&index=' + this.index + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage + '&AS=' + this.AccountStatus + '&TN=' + this.TableName + '&cusid=' + this.customerid;;


              window.location.href = redirecturl;
            } else {
              if (this.TotalCount > 1) {
                if (this.TotalCount == this.ss) {
                  this.getprevious();
                }else {
                  this.getnext()
                }
              } else {
                window.location.href = '/proscust';
              }

            }

          }

          else if (response.status == 2) {
            this.toastr.error(response.msg);

          }
          else {
            //  this.toastr.error('Not able to Save, Please try again');
            document.getElementById("poppup").click()
          }

        })
      }
    })
  }



  Row: number = null;
  saveEdit(namee, type) {

    //var body =   JSON.stringify(this.CustomerData.value,null,4);
    var body = namee;
    console.log('xx', body);
    //*PROSPECT*//
    if ((this.TableName == "Prospect" && this.AccountStatus == "Prospect") || (this.TableName == "Customer" && this.AccountStatus == "Prospect")) {
      var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post<any>(environment.apiURL + 'api/Prospect/' + type, body, { headers: headerOptions });
    }
    //*CUSTOMER*//
    else {
      var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post<any>(environment.apiURL + 'api/Customer/' + type, body, { headers: headerOptions });
    }
  }


  edit_p(item, i) {
    var redirecturl = environment.tcbURL + 'Accountid=' + this.CustomerData.formfileds[0].WebID + '&salusername=' + this.cookieService.get('cookieUserName_pros') + '&salpassword=' + this.cookieService.get('cookieUserPassword_pros') + '&doc=' + this.Docket_No + '&cust=' + this.prosid + '&from=customer';
    window.location.href = redirecturl;
  }
  edit(type, item, i) {
    //*PROSPECT*//
    if ((this.TableName == "Prospect" && this.AccountStatus == "Prospect") || (this.TableName == "Customer" && this.AccountStatus == "Prospect")) {
      if (item == this.CustomerData?.closeforms[i] && type == 'closeforms') {
        this.drag = 1;
        this.checkval = "0";
        this.datepic = "2"
        this.dis = new FormControl(new Date(item?.condate));
        console.log(item, i)
        console.log('dd', item?.condate.split('T')[0])
        this.Row1 = i;
        this.Row = i;
        this.showclose == true;
      }
      else if (item == this.CustomerData?.competition[i] && type == 'competition') {
        this.drag1 = 1;
        this.checkval = "1";
        this.datepic = "3";
        this.dis1 = new FormControl(new Date(item?.compdate));
        this.dis2 = new FormControl(new Date(item?.expdate));
        console.log(item, i)

        this.Row2 = i;
        this.showcompetition == true;
      }
      else if (item == this.CustomerData?.marketing[i] && type == 'Marketing') {
        this.drag2 = 1;
        this.checkval = "2";
        this.datepic = "4";
        this.dis3 = new FormControl(new Date(item?.markdate));
        console.log(item, i)
        this.Row3 = i;
        this.showmarket == true;
      }
      else if (item == this.CustomerData?.contactform[i] && type == 'contact') {
        this.drag3 = 1;
        this.checkval = "3";
        console.log(item, i)

        this.showcontact == true;
        this.Row4 = i;
      }

    }
    //*CUSTOMER*//
    else {
      if (item == this.CustomerData?.closeforms[i] && type == 'closeforms') {
        this.drag = 1;
        this.checkval = "0";
        this.datepic = "2"
        this.dis = new FormControl(new Date(item?.condate));
        console.log(item, i)
        this.Row1 = i;
        this.Row = i;
        this.showclose == true;
      }
      else if (item == this.CustomerData?.competition[i] && type == 'competition') {
        this.drag1 = 1;
        this.checkval = "1";
        this.datepic = "3";
        this.dis1 = new FormControl(new Date(item?.compdate));
        this.dis2 = new FormControl(new Date(item?.expdate));
        console.log(item, i)
        this.Row2 = i;
        this.showcompetition == true;
      }
      else if (item == this.CustomerData?.marketing[i] && type == 'Marketing') {
        this.drag2 = 1;
        this.checkval = "2";
        this.datepic = "4";
        this.dis3 = new FormControl(new Date(item?.markdate));
        console.log(item, i)
        this.Row3 = i;
        this.showmarket == true;
      }
      else if (item == this.CustomerData?.contactform[i] && type == 'contact') {
        this.drag3 = 1;
        this.checkval = "3";
        console.log(item, i)

        this.showcontact == true;
        this.Row4 = i;
      }
    }
  }
  ContactSave(type, i, item) {
    this.disply = 1;

    //*PROSPECT*//
    if ((this.TableName == "Prospect" && this.AccountStatus == "Prospect") || (this.TableName == "Customer" && this.AccountStatus == "Prospect")) {
      console.log('type i prosid', type, i);
      console.log('item?.prosid', item?.prosid);
      let arr = [];
      var value: any = {};
      value['Tablename'] = this.CustomerData.formfileds[0].Tablename;


      $('#' + type + i + ' td').each(function () {
        var customerId = $(this).find("input").val();
        let Id = $(this).find("input").attr('id');
        if (Id) {
          value[Id] = customerId ? customerId : '';

        }
      });
      if (type == 'closeforms') {
        var dialogue = $('#dialogue').val();
        value['dialogue'] = dialogue;
        this.currentdate = value['condate'];

        value['condate'] = this.currentdate + ' ' + this.currentdate1;
        console.log('closeforms', value);
        // :checked
        $('input[class="cbox"]').each(function (index) {
          var end = ((i + 1) * 5) - 1;
          var start = (((i + 1) * 5) - 4) - 1;
          console.log('start,end:', start, end);
          if (index >= start && index <= end) {
            if ($(this).prop('checked')) {
              console.log('checked val', $(this).val())
              value[$(this).val()] = $(this).val() ? true : false;
            } else {
              console.log('unchecked val', $(this).val())
              value[$(this).val()] = !$(this).val() ? true : false;
            }
          }
        });
        console.log('after cbox', value);
      }
      if (item?.prosid) {
        value["prosid"] = this.prosid;
      } else {
        value["id"] = item?.id;
      }

      if (value && type == 'contact') {
        this.drag3 = 0;
        this.disabled = 1;
        this.CustomerData.contactform[i] = value;
        this.val = (<HTMLInputElement>document.getElementById('email')).value;
        this.val1 = (<HTMLInputElement>document.getElementById('name')).value;
        this.saveEdit(value, 'ProspectContact').subscribe((response: any) => {


          // this.onSubmit1();



          //    setTimeout(() => {
          //     window.location.reload();
          // }, 1000);

          if (response.status == 1) {

            this.Row4 = null;

            console.log('edit response', response);
            this.disabled = 0;
            this.showcontact == false;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
            // this.toastr.success(response.msg);  
            // window.location.reload();

            //this.bind();
          } else if (response.status == 0) {
            this.toastr.warning(response.msg);
            this.disabled = 0;
          }
        }, error => {
          this.toastr.warning(error.msg, error.data);
          this.disabled = 0;
        });

      }

      if (value && type == 'competition') {
        this.drag1 = 0;
        this.disabled = 1;
        this.CustomerData.competition[i] = value;

        this.saveEdit(value, 'ProspectCompetition').subscribe((response: any) => {



          if (response.status == 1) {


            this.Row2 = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            this.disabled = 0;
            this.showcompetition = false;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
            // window.location.reload();
            //this.bind();
          } else if (response.status == 0) {
            this.toastr.warning(response.msg);
            this.disabled = 0;
          }
        }, error => {
          this.toastr.warning(error.msg, error.data);
          this.disabled = 0;
        });
      }
      if (value && type == 'Marketing') {
        this.drag2 = 0;
        this.disabled = 1;
        this.CustomerData.marketing[i] = value;
        this.saveEdit(value, 'ProspectMarketing').subscribe((response: any) => {



          if (response.status == 1) {
            this.Row3 = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            this.disabled = 0;
            this.showmarket = false;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
            // window.location.reload();
            //this.bind();
          } else if (response.status == 0) {
            this.disabled = 0;
            this.toastr.warning(response.msg);

          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
      if (value && type == 'TCRED') {
        this.disabled = 1;
        this.CustomerData.tcrep[i] = value;
        this.saveEdit(value, 'ProspectTCRep').subscribe((response: any) => {



          if (response.status == 1) {
            this.Row = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            window.location.reload();
            //this.bind();
          } else if (response.status == 0) {
            this.disabled = 0;
            this.toastr.warning(response.msg);
          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
      if (value && type == 'closeforms') {
        this.drag = 0;
        this.disabled = 1;
        this.CustomerData.closeforms[i] = value;
        console.log('i', i);
        console.log('value', value);
        this.saveEdit(value, 'ProspectCloseforms').subscribe((response: any) => {




          if (response.status == 1) {
            this.Row1 = null;
            this.Row = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            this.disabled = 0;
            this.showclose = false;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
            //  window.location.reload();
            //this.bind();
          } else if (response.status == 0) {
            this.toastr.warning(response.msg);
            this.disabled = 0;
          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
      if (value && type == 'Programs') {
        this.disabled = 1;
        this.CustomerData.programs[i] = value;
        this.saveEdit(value, 'ProspectPrograms').subscribe((response: any) => {




          if (response.status == 1) {
            this.Row = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            this.disabled = 0;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
            // window.location.reload();
            //this.bind();
          } else if (response.status == 0) {
            this.disabled = 0;
            this.toastr.warning(response.msg);
          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
    }
    //*CUSTOMER*//
    else {
      console.log('type i customerid', type, i);
      console.log('item?.customerid', item?.id);
      let arr = [];
      var value: any = {};
      $('#' + type + i + ' td').each(function () {
        var customerId = $(this).find("input").val();
        let Id = $(this).find("input").attr('id');
        if (Id) {
          value[Id] = customerId ? customerId : '';
        }
      });
      if (type == 'closeforms') {
        var dialogue = $('#dialogue').val();
        value['dialogue'] = dialogue;
        this.currentdate = value['condate'];

        value['condate'] = this.currentdate + ' ' + this.currentdate1;
        console.log('closeforms', value);
        // :checked
        $('input[class="cbox"]').each(function (index) {
          var end = ((i + 1) * 5) - 1;
          var start = (((i + 1) * 5) - 4) - 1;
          console.log('start,end:', start, end);
          if (index >= start && index <= end) {
            if ($(this).prop('checked')) {
              console.log('checked val', $(this).val())
              value[$(this).val()] = $(this).val() ? true : false;
            } else {
              console.log('unchecked val', $(this).val())
              value[$(this).val()] = !$(this).val() ? true : false;
            }
          }
        });
        console.log('after cbox', value);
      }
      if (item?.customerid) {
        value["customerid"] = this.customerid;
      } else {
        value["id"] = item?.id;
      }

      if (value && type == 'contact') {
        this.drag3 = 0;
        this.CustomerData.contactform[i] = value;
        this.saveEdit(value, 'CustomerContact').subscribe((response: any) => {
          this.disabled = 1;


          if (response.status == 1) {
            this.Row4 = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            this.disabled = 0;

            this.showcontact == false;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
            // window.location.reload(); 
            //this.bind();
          } else if (response.status == 0) {
            this.toastr.warning(response.msg);
            this.disabled = 0;
          }
        }, error => {
          this.toastr.warning(error.msg, error.data);
          this.disabled = 0;
        });
      }

      if (value && type == 'competition') {
        this.drag1 = 0;
        this.disabled = 1;
        this.CustomerData.competition[i] = value;
        this.saveEdit(value, 'CustomerCompetition').subscribe((response: any) => {


          if (response.status == 1) {
            this.Row2 = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            // window.location.reload();
            this.disabled = 0;
            this.showcompetition = false;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);

            //this.bind();
          } else if (response.status == 0) {
            this.disabled = 0;
            this.toastr.warning(response.msg);
          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
      if (value && type == 'Marketing') {
        this.drag2 = 0;
        this.disabled = 1;
        this.CustomerData.marketing[i] = value;
        this.saveEdit(value, 'CustomerMarketing').subscribe((response: any) => {

          // window.location.reload();

          if (response.status == 1) {
            this.Row3 = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            this.disabled = 0;
            this.showmarket = false;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);

            //this.bind();
          } else if (response.status == 0) {
            this.disabled = 0;
            this.toastr.warning(response.msg);
          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
      if (value && type == 'TCRED') {
        this.disabled = 1;
        this.CustomerData.tcrep[i] = value;
        this.saveEdit(value, 'CustomerTCRep').subscribe((response: any) => {



          if (response.status == 1) {
            this.Row = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            window.location.reload();
            //this.bind();
          } else if (response.status == 0) {
            this.disabled = 0;
            this.toastr.warning(response.msg);
          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
      if (value && type == 'closeforms') {
        this.drag = 0;
        this.disabled = 1;
        this.CustomerData.closeforms[i] = value;
        this.saveEdit(value, 'CustomerCloseforms').subscribe((response: any) => {



          if (response.status == 1) {
            this.Row1 = null;
            this.Row = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            //  window.location.reload();
            this.disabled = 0;
            this.showclose = false;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
            //this.bind();
          } else if (response.status == 0) {
            this.disabled = 0;
            this.toastr.warning(response.msg);
          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
      if (value && type == 'Programs') {
        this.disabled = 1;
        this.CustomerData.programs[i] = value;
        this.saveEdit(value, 'CustomerPrograms').subscribe((response: any) => {


          if (response.status == 1) {
            this.Row = null;
            console.log('edit response', response);
            this.toastr.success(response.msg);
            this.disabled = 0;
            this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);
            // window.location.reload();
            //this.bind();
          } else if (response.status == 0) {
            this.disabled = 0;
            this.toastr.warning(response.msg);
          }
        }, error => {
          this.disabled = 0;
          this.toastr.warning(error.msg, error.data);
        });
      }
    }
  }


  compeditiontabchecking() {
    localStorage.setItem('compedition#*', '1');
    this.checkval = "1";
  }
  dialoguetabchecking() {
    localStorage.setItem('close#*', '1')
    this.checkval = "0";
  }
  marketingtabchecking() {
    localStorage.setItem('market#*', '1');
    this.checkval = "2";
  }
  contacttabchecking() {
    localStorage.setItem('contact#*', '1');
    this.checkval = "3";
  }
  program() {
    localStorage.setItem('program#*', '1');
  }
  addRow(type) {
    //*PROSPECT*//
    if ((this.TableName == "Prospect" && this.AccountStatus == "Prospect") || (this.TableName == "Customer" && this.AccountStatus == "Prospect")) {
      console.log('this.ProspectsData.contactform.length', this.CustomerData.contactform.length);
      if (type == 'contact') {
        this.drag3 = 1;
        this.CustomerData.contactform.unshift({
          //billing: "",
          email: "",
          extension: "",
          fax: "",
          // mcnumber: "",
          name: "",
          phone: "",
          position: "",
          toll: "",
          'prosid': this.prosid
        });
        this.Row4 = 0;
        this.showcontact == true;
        console.log('row added', this.CustomerData.contactform);
        console.log('this.ProspectsData.contactform.length after', this.CustomerData.contactform.length);
      }
      else if (type == 'competition') {
        this.drag1 = 1;
        this.datepic = "1";
        this.CustomerData.competition.unshift({
          //mcnumber: "",
          compdate: this.currentdate,
          // department: "",
          competitor: "",
          sercost: "",
          expdate: this.currentdate,
          'prosid': this.prosid
        });
        this.Row2 = 0;
        this.showcompetition = true
        console.log('row added', this.CustomerData.competition);
        console.log('thiscompetition after', this.CustomerData.competition.length);
      }
      else if (type == 'Marketing') {
        this.drag2 = 1;
        this.datepic = "1";
        this.CustomerData.marketing.unshift({
          // mcnumber: "",
          markdate: this.currentdate,
          delivered: "",
          markcomp: "",
          // sercost:'',
          // expdate:'',
          'prosid': this.prosid
        });
        this.Row3 = 0;
        this.showmarket = true;
        console.log('row added', this.CustomerData.marketing);
        console.log('marketing after', this.CustomerData.marketing.length);
      }
      else if (type == 'TCRED') {
        this.CustomerData.tcrep.unshift({
          //mcnumber: "",
          initials: "",
          dep: "",
          repname: "",
          'prosid': this.prosid
        });
        this.Row = 0;
        console.log('row added', this.CustomerData.tcrep);
        console.log('TCRED after', this.CustomerData.tcrep.length);
      }
      else if (type == 'closeforms') {
        this.drag = 1;
        this.datepic = "1";
        console.log('this.ProspectsData.closeforms', this.CustomerData.closeforms)
        this.CustomerData.closeforms.unshift({
          tcr: this.tcr1,
          condate: this.currentdate,
          voice: false,
          wrongcon: false,
          callback: false,
          noint: false,
          comp: false,
          dialogue: "", recall: "",
          'prosid': this.prosid
        });
        this.Row1 = 0;
        this.Row = 0;
        this.showclose = true
        console.log('row added', this.CustomerData.closeforms);
        console.log('closeforms after', this.CustomerData.closeforms.length);
      }


      else if (type == 'Programs') {
        this.CustomerData.programs.unshift({
          pgmdate: "",
          pgmtype: "",
          products: "",
          expdate: this.currentdate,
          yearly: '',
          //monthly: "",
          new: "",
          update: "",
          online: "",
          report: '',
          coll: "",
          //moncoll: "",
          //ffast: "",
          'prosid': this.prosid
        });
        this.Row = 0;
        console.log('row added', this.CustomerData.programs);
        console.log('programs after', this.CustomerData.programs.length);
      }
    }
    //*CUSTOMER*//
    else {
      console.log('this.CustomerData.contactform.length', this.CustomerData.contactform.length);
      if (type == 'contact') {
        this.drag3 = 1;
        this.CustomerData.contactform.unshift({
          //billing: "",
          email: "",
          extension: "",
          fax: "",
          // mcnumber: "",
          name: "",
          phone: "",
          position: "",
          toll: "",
          'customerid': this.customerid.toString()
        });
        this.Row4 = 0;
        this.showcontact == true;
        console.log('row added', this.CustomerData.contactform);
        console.log('this.CustomerData.contactform.length after', this.CustomerData.contactform.length);
      }
      else if (type == 'competition') {
        this.drag1 = 1;
        this.datepic = "1";
        this.CustomerData.competition.unshift({
          //mcnumber: "",
          compdate: this.currentdate,
          //department: "",
          competitor: "",
          sercost: "",
          expdate: this.currentdate,
          'customerid': this.customerid.toString()
        });
        this.Row2 = 0;
        this.showcompetition = true
        console.log('row added', this.CustomerData.competition);
        console.log('thiscompetition after', this.CustomerData.competition.length);
      }
      else if (type == 'Marketing') {
        this.drag2 = 1;
        this.datepic = "1";
        this.CustomerData.marketing.unshift({
          //mcnumber: "",
          markdate: this.currentdate,
          delivered: "",
          markcomp: "",
          // sercost:'',
          //expdate:'',
          'customerid': this.customerid.toString()
        });
        this.Row3 = 0;
        this.showmarket = true;
        console.log('row added', this.CustomerData.marketing);
        console.log('marketing after', this.CustomerData.marketing.length);
      }
      else if (type == 'TCRED') {
        this.CustomerData.tcrep.unshift({
          //mcnumber: "",
          initials: "",
          dep: "",
          repname: "",
          'customerid': this.customerid.toString()
        });
        this.Row = 0;
        console.log('row added', this.CustomerData.tcrep);
        console.log('TCRED after', this.CustomerData.tcrep.length);
      }
      else if (type == 'closeforms') {
        this.drag = 1;
        this.datepic = "1";
        console.log('this.CustomerData.closeforms', this.CustomerData.closeforms)
        this.CustomerData.closeforms.unshift({
          tcr: this.clisttcr1,
          condate: this.currentdate,
          voice: false,
          wrongcon: false,
          callback: false,
          noint: false,
          comp: false,
          dialogue: "", recall: "",
          'customerid': this.customerid.toString()
        });
        this.Row1 = 0;
        this.Row = 0;
        this.showclose = true
        console.log('row added', this.CustomerData.closeforms);
        console.log('closeforms after', this.CustomerData.closeforms.length);
      }
      else if (type == 'IG') {

        //var redirecturl =  environment.tcbURL +'Accountid='+ this.CustomerData.formfileds[0].WebID+'&salusername='+ this.cookieService.get('cookieUserName_pros')+ '&salpassword=' + this.cookieService.get('cookieUserPassword_pros') + '&doc='+this.Docket_No +'&cust=' +this.customerid +'&from=customer';

        //var redirecturl1 = 'https://tcobapp.rcktechiees.com/RenewalInvoice.aspx';
        var redirecturl1 = environment.igurl;
        window.location.href = redirecturl1;
        // this.CustomerData.programs.unshift({
        //    pgmdate: "",
        // pgmtype: "",
        // products: "",
        // expdate: "",
        // yearly: '',
        // monthly: "",
        // new: "",
        // update: "",
        // online: "",
        // report: '',
        // coll: "",
        // moncoll: "",
        // ffast: "",
        // 'customerid':this.customerid.toString()
        // });
        // this.Row=0;
        // console.log('row added',this.CustomerData.programs);
        // console.log('programs after',this.CustomerData.programs.length);
      }

      else if (type == 'Programs') {
        var redirecturl = environment.tcbURL + 'Accountid=' + this.CustomerData.formfileds[0].WebID + '&salusername=' + this.cookieService.get('cookieUserName_pros') + '&salpassword=' + this.cookieService.get('cookieUserPassword_pros') + '&doc=' + this.Docket_No + '&cust=' + this.customerid + '&from=customer';
        window.location.href = redirecturl;
        // this.CustomerData.programs.unshift({
        //    pgmdate: "",
        // pgmtype: "",
        // products: "",
        // expdate: "",
        // yearly: '',
        // monthly: "",
        // new: "",
        // update: "",
        // online: "",
        // report: '',
        // coll: "",
        // moncoll: "",
        // ffast: "",
        // 'customerid':this.customerid.toString()
        // });
        // this.Row=0;
        // console.log('row added',this.CustomerData.programs);
        // console.log('programs after',this.CustomerData.programs.length);
      }

    }
  }
  clear() {
    this.Row = null;
  }
  reset() {
    this.disply = 1
    console.log('row', this.Row)
    if (this.Row != null) {
      this.Row = null;
      this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);

    }
  }

  reset1() {
    this.drag = 0;
    this.disply = 1
    console.log('row', this.Row)
    if (this.Row1 != null) {
      this.Row1 = null;
      this.showclose = false;
      this.showcompetition = false;
      this.showmarket = false;
      this.showcontact == false;
      this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);

    }
  }
  reset2() {
    this.drag1 = 0;
    this.disply = 1
    console.log('row', this.Row)
    if (this.Row2 != null) {
      this.Row2 = null;
      this.showclose = false;
      this.showcompetition = false;
      this.showmarket = false;
      this.showcontact == false;
      this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);

    }
  }
  reset3() {
    this.drag2 = 0;
    this.disply = 1
    console.log('row', this.Row)
    if (this.Row3 != null) {
      this.Row3 = null;
      this.showclose = false;
      this.showcompetition = false;
      this.showmarket = false;
      this.showcontact == false;
      this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);

    }
  }
  reset4() {
    this.drag3 = 0;
    this.disply = 1
    console.log('row', this.Row)
    if (this.Row4 != null) {
      this.Row4 = null;
      this.showclose = false;
      this.showcompetition = false;
      this.showmarket = false;
      this.showcontact == false;
      this.getProspect(this.Docket_No, this.prosid, this.TableName, this.AccountStatus);

    }
  }


  isDisable = true;

  onDisableUser() {
    this.isDisable = false;
  }




  api() {
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/User/GetStateCountyList?state=Alaska');
  }
  county() {
    this.api().subscribe((response: any) => {
      console.log('countyresponse', response.data);
      this.t = response.data.State;
      this.t1 = response.data;
      const channelArray: string[] = response.data.State;

      console.log('t', this.t)
      this.k1 = this.Customer.value.Bus_State;
      this.k2 = this.Customer.value.Mail_State;
      this.arr1 = this.t;
      console.log("####", this.arr1);
      console.log("****", channelArray.indexOf(this.k1) > -1);
      if (channelArray.indexOf(this.k1) > -1) {
        this.dropval = 0;

      } else {
        if (this.k1 != null && this.k1 != '') {
          this.dropval = 1;
          // let value = (<HTMLSelectElement>document.getElementById('Bus_State')).value;
          // console.log("AAAAAA",value);
        }
      }


      if (channelArray.indexOf(this.k2) > -1) {
        this.dropval1 = 0;

      } else {
        if (this.k2 != null && this.k2 != '') {
          this.dropval1 = 1;
          //  let value = (<HTMLSelectElement>document.getElementById('Mail_State')).value;
          //  console.log("AAAAAA",value);
        }
      }
      // this.api1(this.k).subscribe((county:any)=>{
      //   console.log('county',county);

      //     this.t1=county.data.County;
      //     console.log('t1',this.t1)
      //     this.country1=this.t1;
      // })
    }
    )
  }

  // api1(i){
  //   this.m=this.Customer.value.Bus_State
  //   console.log('o',this.m)
  //   var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.get<any>(environment.apiURL + 'api/User/GetStateCountyList?state='+this.m);
  // }


  // county1(){
  //   this.api1().subscribe((response: any) => {
  //     console.log('county',response.data)
  //     this.s=response.data.State;
  //   this.k =this.Prospects.value.Bus_State
  //     this.t1=response.data.County;
  //     console.log('t',this.t1)
  //     this.country1=this.t1;
  //   }
  //   )
  // }
  // changeCounty(item) {
  //   this.t1 = this.t.find(con => con.Bus_State == item).County;
  // }

  apifortype() {
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GetType?prosorcust=2');
  }
  type() {
    this.apifortype().subscribe((response: any) => {
      console.log('countyresponse', response.data);
      this.tt = response.Typelist;
      console.log('type', this.tt)
      var Prospect = 1;
    })

  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  cancel(data: any) {
    this.show = 0;
    if (data == '1') {
      localStorage.setItem('close#*', '0');
    }
    if (data == '12') {
      localStorage.setItem('contact#*', '0');
    }
    if (data == '13') {
      localStorage.setItem('compedition#*', '0');
    }
    if (data == '14') {
      localStorage.setItem('market#*', '0');
    }
    if (data == '15') {
      localStorage.setItem('program#*', '0');
    }
  }
  editfnct5() {
    this.show = 1;

    //   var re = /FF/gi; 
    // var str = this.CustomerData.formfileds[0].Docket_No;
    // this. splitted = str.replace(re,'');
    // this.splitted = this.CustomerData.formfileds[0].Docket_No.split('FF',3); 
    this.split = this.CustomerData.formfileds[0].Docket_No.substring(0, 2);
    if (this.split == 'FF') {
      var re = /FF/gi;
      var str = this.CustomerData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
    else if (this.split == 'MC') {
      var re = /MC/gi;
      var str = this.CustomerData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
    else if (this.split == 'MX') {
      var re = /MX/gi;
      var str = this.CustomerData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
  }
  Apiforclssification() {
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/ClassificationCode');
  }
  classification() {
    this.Apiforclssification().subscribe((response: any) => {
      console.log(response)
      this.classdrop = response.Codelist;


    })
  }

  ProgramAPI(req) {
    var programname = req;
    return this.http.get<any>(environment.apiURL + 'api/Prospect/programlist?Program=' + programname);
  }
  ProductAPI() {
    return this.http.get<any>(environment.apiURL + 'api/Prospect/productlist');
  }
  ProductAPICall() {
    this.ProductAPI().subscribe((response: any) => {
      if (response.msg == 'Successful') {
        this.productlist = response.productlist;
        console.log('response data program', response);
      }
    })
  }
  addprogram() {


    var url = "https://tcobapp.rcktechiees.com/ReninvPopup.aspx/" + 'proscustform?Docket_No=' + this.Docket_No + '&customerid=' + this.customerid + '&index=' + 0 + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage;
    window.location.href = url;
  }

  SendInvAPI(product, program, price, mcnumber, Account_id) {
    console.log(this.TableName)
    var body = {
      "Product": product,
      "Program": program,
      "Docket_No": mcnumber,
      "price": price,
      "TCR": this.user.GetAccess().Initials,
      "Account_id": this.TableName != "Customer" ? Account_id : this.CustomerData?.formfileds[0]?.WebID,
      "Report_id": this.CustID ? this.CustID : null,
      "acc_name": this.name,
      "pc": this.Tablename1 == "Prospect" ? 1 : 0,
      "status": 0,
      "tablename": this.Tablename1,
      "pros_id": this.Tablename1 == "Customer" ? this.prosid : this.CustomerData?.formfileds[0]?.prosid,
      "address": this.Customer.value.Bus_Address,
      "city": this.Customer.value.Bus_City,
      "state": this.Customer.value.Bus_State,
      "zipcode": this.Customer.value.Zip,
      "Email": this.Customer.value.Email,
      "RepEmail": this.RepEmail,
      "SP_name": this.SP_Name,
      "phone": this.phone,
      "fax": this.fax,
      "dba": this.dba,
      "contact": this.sendcontractContact,
      "userid": this.user.GetAccess().User_id,
      "Lname": this.user.GetAccess().Lname,
      "Fname": this.user.GetAccess().Name,
      "useremail": this.user.GetAccess().useremail,
      "association": this.user.GetAccess().association,
      "extention": this.user.GetAccess().extention

    }
    return this.http.post(environment.apiURL + 'api/Prospect/sendmailinvoice', body);
  }
  ProgramAPICall(val) {
    //console.log(this.SelectedProductList,'jsdhg',this.SelectedProgramList)
    // this.SelectedProductList = val;
    this.programlist = '';
    this.ProgramAPI(val).subscribe(res => {
      this.programlist = res.Programlist;
      //console.log('program response',res)
    })
  }
  PriceAPICall() {
    //console.log(this.SelectedProductList,'jsdhg',this.SelectedProgramList)
    // this.SelectedProgramList = val;
    this.pricelist = '';
    var test = this.SelectedProductList;
    var test1 = this.SelectedProgramList.replace("+", ":").replace("&", ";");
    this.priceAPI(test, test1).subscribe(res => {
      this.pricelist = res.getpricelist;

      //console.log('program response',res)
    })
  }
  selectProgram() {
    // this.SelectedPriceList=val;
    if (this.SelectedProductList == '' && this.SelectedProgramList == '' && this.SelectedPriceList == '') {
      this.Makepayment = true;
    } else {
      this.Makepayment = false;
    }
  }
  sendinvoice() {
    //console.log(this.Customer)
    this.Customer.value.SaveOption = 'SaveCC';
    this.saveandcovert = 1;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValidEmail = emailPattern.test(this.Customer.value.Email);

    if (!isValidEmail) {
      this.toastr.error('Please Fill Valid Email');
      this.SelectedProductList = '';
      this.SelectedProgramList = '';
    } else {
      this.sendInvoice = 1;
      // this.Makepayment = true;  
      this.redirect_to_tcb_key = false;
      this.Tablename = this.data_trans[0].TableName;
      this.Customer.value.AccountStatus = this.AccountStatus;
      this.Customer.value.TableName = this.TableName;
      console.log(this.Customer.value);
      this.Apiforclssification().subscribe((response: any) => {
        //console.log(response)

        this.classdrop1 = response.Codelist;
        // for (var i = 0; i < this.classdrop.length; i++) {
        //   if (this.classdrop[i].Codeid == this.Prospects.value.BCCodeID) {
        //     x = this.classdrop[i].Code;   
        //   }
        // }
        if (this.Customer.value.BCCodeID != 0) {
          this.selectedProduct = this.classdrop1[this.Customer.value.BCCodeID - 1].Code;
          this.Customer.value.BCCode = this.selectedProduct
          this.Customer.value.BCCodeID = parseInt(this.Customer.value.BCCodeID);
        }

        if (this.show == 1) {
          this.Customer.value.Docket_No = this.Customer.value.Docket_Nodrp + this.Customer.value.Docket_Notxt
        }

        this.Customer.value.OldDocket_No = this.newDoc;

        if (this.Customer.value.OB == '1') {
          this.Customer.value.OB = true;
          this.o = 1
        }
        else {
          this.Customer.value.OB = false;
          this.o = 0
        }

        if (this.Customer.value.Legal_Name == '' || this.Customer.value.Docket_No == '' || this.Customer.value.clUserid == null || this.Customer.value.Brok_Carr == '') {
          this.toastr.error('Please Fill Mandatory Fields');
        }

        else if (this.Customer.value.Docket_No == '' && this.show == 1) {
          this.toastr.error('Please Fill Mandatory Fields');
          console.log('Please Fill Mandatory Fields')

        }
        else if (this.Customer.value.Docket_No.length != 10 && this.show == 1) {
          this.toastr.error('MC Field Need 8 Numbers');

        }
        else {
          this.automation = 1;
          this.dataDisplay = 1;
          this.Customer.value.CRUserID = this.Customer.value.Userid.toString();
          this.callapi_source1(this.Customer.value, this.automation).subscribe((response: any) => {

            this.CustomerrData = response;
            //this.bind();
            console.log('saved', this.CustomerrData)
            if (response.status == 1) {
              this.Docket_No = this.Customer.value.Docket_No;

              this.SendInvAPI(this.SelectedProductList, this.SelectedProgramList, this.SelectedPriceList, this.Docket_No, response.data[0].AccountID).subscribe((data: any) => {
                console.log(data);
                this.invoice = data;
                if (data.status == 1) {
                  this.SelectedProductList = '';
                  this.SelectedProgramList = '';
                  this.toastr.success('Contract sent successfully');
                  setTimeout(() => {
                    var redirecturl = `/proscustform?Docket_No=${this.Docket_No}&prosid=${response.msg === "Updated and Converted to Customer" ? data.cust : this.prosid}&index=1&itemsPerPage=30&currentPage=1&AS=${this.AccountStatus}&TN=${response.msg === "Updated and Converted to Customer" ? "Customer" : this.TableName}&cusid=${response.msg === "Updated and Converted to Customer" ? data.cust : this.customerid}`;
                    window.location.href = redirecturl;
                  }, 1500);
                } else {
                  this.sendInvoice = 0;
                  this.SelectedProductList = '';
                  this.SelectedProgramList = '';
                  this.toastr.error('Contract is not Send')
                }
                this.dataDisplay = 0;
              }, (error) => {
                this.dataDisplay = 0;
                this.sendInvoice = 0;
                console.error('Error:', error);
                this.toastr.error('An error occurred while sending the contract');
              });
              //   this.toastr.success(response.msg);
              //   if(this.Customer.value.OB != '1'){

              // this.Docket_No=this.Customer.value.Docket_No;
              //   var redirecturl = '/proscustform?'+'Docket_No='+this.Docket_No+'&prosid='+this.prosid+'&index='+this.index +'&itemsPerPage='+30+'&currentPage='+this.currentPage+'&AS='+this.AccountStatus+'&TN='+ this.TableName+'&cusid='+this.customerid;;


              //   window.location.href = redirecturl;
              //   }

            }
            else if (response.status == 2) {
              this.toastr.error(response.msg);
              this.dataDisplay = 0;
            }
            else {
              //  this.toastr.error('Not able to Save, Please try again');
              document.getElementById("poppup").click()
            }
            // this.dataDisplay= 0;
          })
        }
      })
















    }
  }
  priceAPI(req, res) {
    var programname = req;
    var product = res
    return this.http.get<any>(environment.apiURL + 'api/Prospect/pricelist?Program=' + programname + '&product=' + product);
  }
  getColorBasedOnText(text: string): string {
    if (text.includes('The contract has been emailed') || text.includes('Customer Monitoring Price - $199')) {
      return '#0662bb';
    } else if (text.includes('Account has been activated') || text.includes('Invoice Paid') || text.includes('New program has been added')) {
      return '#137b2a';
    } else if (text.includes('There is an error in sending the contract')) {
      return '#ff1515';
    } else if (text.includes('Payment received for the invoice') || text.includes('Payment received for the invoice using Credit Card - $100')) {
      return '#ffaf33';
    }

    return 'Black';
  }
  refreshPage() {
    location.reload();
  }
}
