import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup,ReactiveFormsModule, NgForm, Validators, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { BuiltinTypeName, identifierModuleUrl } from '@angular/compiler';
import { UserService } from 'src/app/service/user.service';
import { AuthService } from 'src/app/service/auth.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import 'rxjs/add/operator/map'
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
@Component({
  selector: 'app-proscust',
  templateUrl: './proscust.component.html',
  styleUrls: ['./proscust.component.scss']
})
export class ProscustComponent implements OnInit {

  searchValue:string = '';
 events: string[] = [];
 events1: string[] = [];
 tempsearchval:any;
 submitted = false;
 ers=false;
 btn2filter: any;
dropValue:Number=null;
dropValue1:Number=null;
search:FormGroup;
search1:FormGroup;
 p: any = 0;
 count: Number = 30;
  registerForm: FormGroup;
  date:FormGroup;
data_trans: any;
 searchingdate:any;
 fromdate:any;
 todate:any;
 finaltodate:any;
 finalfromdate:any;
 programexpiredate:any;
 filterID:string;
 filter={
 active:1
 }
dataDisplay: number;
 roomsFilter: any;
 sear:number=1;
  dropValue2: any;
  bck: any;
  resleng: any;
  fil: number;
  mandatorycheck: string="0";
  bind: string;
  bind1: string;
  filterID2: any;
  sorttype1: string;
  text: any;
  dropremove: any;
 constructor(private router: Router,public user:UserService,private toastr: ToastrService,private route: ActivatedRoute, private formBuilder: FormBuilder,private http: HttpClient) { }

  ngOnInit(): void {
    
    localStorage.removeItem("bttn1");
    localStorage.removeItem("bttn");
    this.sorttype1='';
    if(localStorage.getItem("bttnpros")=='2'){
    if(localStorage.getItem("currentpagepros")!=null){
      this.p=parseInt(localStorage.getItem("currentpagepros"))-1;     
      }
    }
      localStorage.removeItem('currentpagepros');

    this.registerForm = this.formBuilder.group({
     
      search:['', []],
   });
   this.search1 = this.formBuilder.group({
    searchh: ['', Validators.required],
    SearchBy: ['', Validators.required]
  });
   this.filterID = 'AllData';
   this.searchfunction1('');
   

    this.ers=false;

    this.search = this.formBuilder.group({
      searchh: ['', Validators.required]
    });
    
    
    this.date = this.formBuilder.group({
      from: '',
      to: '',
      from1: '',
      to1: '',
      from2: '',
      to2: '',
    });
    this.route.queryParams
    .subscribe(params => {
      console.log(params);
    this.bck=params.bck
   
    })
    // this.bind=(localStorage.getItem("searchtext1"));
    // this.registerForm.get('search').setValue(localStorage.getItem("searchtext1"))
   }
   currentpage(){
   
    localStorage.setItem("currentpagepros",this.p);
  
 
  }
  SetFilter(arg,id){

    this.filter={active:arg};
    this.filterID=id;
    localStorage.setItem("fildernamepros", this.filterID);
   if(this.filterID=='Company_Name'||this.filterID=='DBA_Name'||this.filterID=='Docket_No'||this.filterID=='ProgramType')
   {
     this.fil=1;
   } 
   this.text=null;
   if( this.filterID=='ProgramExpire'||this.filterID=='RecallDate'||this.filterID=='CreditRecall'||this.filterID=='CollectRecall'||this.filterID=='Suspended'){
   
    localStorage.setItem("dropcheck1pros",'1');
    }
    else{
      localStorage.setItem("dropcheck1pros",'0'); 
    }
  }
  
  callapi_source(namee)
{
  //var body =   JSON.stringify(this.registerForm.value,null,4);
  var body =   namee;
  
  console.log('xx',body);
  
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospectcustomer/GetProspectsCustomersUsingSearch' , body, { headers: headerOptions });

}
showError() {
  this.toastr.error('Username and/or password incorrect.');
}
addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
 this.events.push(`${type}: ${event.value}`);
 this.fromdate=event.value;
 var fromdatevalue = new Date(this.fromdate);
 var fromdatemonth=fromdatevalue.getMonth()+1;
 var fromdatedate=fromdatevalue.getDate();
 var fromdateyear=fromdatevalue.getFullYear();
 this.finalfromdate=fromdatemonth+'/'+fromdatedate+'/'+fromdateyear;
 console.log("fromdate",this.fromdate);
 console.log("final from date",this.finalfromdate);
}
addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
 this.events1.push(`${type}: ${event.value}`);
 this.todate=event.value;
 var todatevalue = new Date(this.todate);
 var todatemonth=todatevalue.getMonth()+1;
 var todatedate=todatevalue.getDate();
 var todateyear=todatevalue.getFullYear();
 this.finaltodate=todatemonth+'/'+todatedate+'/'+todateyear;
 console.log("todate",this.todate);
 console.log("final to date",this.finaltodate);
 
}
get f() { return this.registerForm.controls; }

pageChange($event) {
  // this.p = $event;
   this.searchfunction1(this);
 }
 pageChange1($event) {
   // this.p = $event;
    this.searchfunction2(this);
  }

searchfunction(e){
  // alert(this.registerForm.value.search)
  // alert(this.filterID)
  // if(this.filterID!='AllData' && this.registerForm.value.search==undefined){
  //   this.toastr.warning('Please search by some Text');
  // }
  // else{
  this.dataDisplay=1;
  this.submitted = true;
  
  var value = {};
 this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
 console.log('programexpire search',this.programexpiredate);
  if(this.filter.active==10 || this.filter.active==12||this.filter.active==14||this.filter.active==15||this.filter.active==16||this.filter.active==17){this.searchingdate=this.registerForm.value.search;
    var searchvalue = new Date(this.searchingdate);
    var searchmonth=searchvalue.getMonth()+1;
    var searchdate=searchvalue.getDate();
    var searchyear=searchvalue.getFullYear();
    var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
    console.log('finalresult prospectcust page',finalsearchdate);
 this.filter.active==12||14||15||16||17?this.registerForm.value.search=this.programexpiredate:this.registerForm.value.search=finalsearchdate;
 console.log('checking for which tab value is active',this.registerForm.value.search);
   console.log('filterID filterID',this.filterID)
   //this.registerForm.value.search='';
  // value[this.filterID] = (this.filter.active==10 || this.filter.active==11)? this.registerForm.value.search:'';
  value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
  value['PerPageSize']=31;
  value['NoofPages']=1;
 
   value['Index']=1;
  value['RoleID']=this.user.GetAccess().RoleID;
  value['UserID']=this.user.GetAccess().User_id;
  value['view_allcustomer']=this.user.GetAccess().view_customers;
  console.log('filterID filterID',value)
  this.tempsearchval=value[this.filterID];
  localStorage.setItem("filteridpros",JSON.stringify(value));
  console.log('****',this.filterID)

 this.callapi_source(value).subscribe((response: any) => {
 
  this.dataDisplay=0;
   if(response.status==1){
    this.data_trans= response.Data[0].formfileds;
    this.resleng=response.Data[0].Searchcount;
  
    this.dataDisplay=0;
    console.log('response data1',this.data_trans)
    this.data_= response.Data[0].formfileds;
    localStorage.setItem("prospectlist",JSON.stringify(this.data_));
    var localprostlist;
    localprostlist=JSON.parse(localStorage.getItem("prospectlist"));
    console.log('pros',localprostlist)
    console.log('response data',this.data_trans)
    console.log('response data',this.data_)
    console.log('response broke',response.Brok_Auth)
    if(this.tempsearchval.length ==0){
      this.ers=true;
    }else{
  
    this.ers=false;}
   
     this.dropValue1=null;
   }
   else{
     this.dataDisplay=0;
    // this.toastr.error('Failed');
     this.dropValue1=null;
   }

 })
  // }
 }
 
 
 
 //hide recently//
 localStorage.setItem("searchtext1pros",this.registerForm.value.search);

 value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
 value['PerPageSize']=31;
 value['NoofPages']=1;

  value['Index']=1;
 value['RoleID']=this.user.GetAccess().RoleID;
 value['UserID']=this.user.GetAccess().User_id;
 value['view_allcustomer']=this.user.GetAccess().view_customers;
 console.log('filterID filterID',value)
 localStorage.setItem("filteridpros",JSON.stringify(value));
 this.tempsearchval=value[this.filterID];
 console.log('****',this.filterID)
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=0;

  if(response.status==1){
    this.data_trans= response.Data[0].formfileds;
    this.resleng=response.Data[0].Searchcount;
  
    this.dataDisplay=0;
    console.log('response data1',this.data_trans)
    this.data_= response.Data[0].formfileds;
    localStorage.setItem("prospectlist",JSON.stringify(this.data_));
    var localprostlist;
    localprostlist=JSON.parse(localStorage.getItem("prospectlist"));
    console.log('pros',localprostlist)
    console.log('response data',this.data_trans)
    console.log('response data',this.data_)
    console.log('response broke',response.Brok_Auth)
    if(this.tempsearchval.length ==0){
      this.ers=true;
    }else{
  
    this.ers=false;}
    //this.registerForm.get('search').setValue(this.registerForm.value.Name);
  
    // if(response.Brok_Auth=='A'){
    //   this.dat=['Broker',[]]
    //   console.log('cc',this.dat)
    // }
    // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
    //   this.dat=['carrier',[]]
    //   console.log('oo',this.dat)
    // }
     this.dropValue1=null;
 } 
  
  else{
    this.dataDisplay=0;
    this.toastr.warning('Select Valid data')
    this.dropValue1=null;
  }
})
// localStorage.removeItem('searchtext1');
// localStorage.removeItem('fildername');

}
searchfunction1(e){
  this.p=parseInt(this.p)+1;
  localStorage.setItem("nexttpros", this.p);
  this.dataDisplay=1;

  var value = {};
  console.log('filterID filterID',this.filterID)
 if(localStorage.getItem("bttnpros")=='2'){
if(localStorage.getItem("searchtext2pros")!=null){
  this.btn2filter=localStorage.getItem("btn2filter3pros");

value[this.btn2filter]=localStorage.getItem("searchh3pros");;
  }
  if(localStorage.getItem("searchtext2pros")==null) {
    value[this.btn2filter]=this.search1.value.searchh;
  }
if(localStorage.getItem("searchtext1pros")!=null){
  this.filterID= localStorage.getItem("fildernamepros");
   value[this.filterID] =localStorage.getItem("searchtext1pros");
  }
  if(localStorage.getItem("searchtext1pros")==null) {
  value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
  }
  if(localStorage.getItem("sortfilter1pros")!=null){
    value['SortBy'] =localStorage.getItem("sortfilter1pros");
     value['Orderby']=localStorage.getItem("sorttype1pros");
   }
  }
  else{
  value['SortBy']=this.filterID2;
  value['Orderby']=this.sorttype1;

   value[this.btn2filter]=this.search1.value.searchh;
   value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
  }
  
  value['PerPageSize']=31;
  value['NoofPages']=1;
 
  value['Index']=this.p;
  value['UserID']=this.user.GetAccess().User_id;
  value['RoleID']=this.user.GetAccess().RoleID;
  value['view_allcustomer']=this.user.GetAccess().view_customers;
  console.log('req',value);
 localStorage.setItem("filteridpros",JSON.stringify(value));
 this.tempsearchval=value[this.filterID];
 
this.callapi_source(value).pipe(
  catchError(error => {
    // Handle the error here
    document.getElementById("poppup").click()
    console.error('Error occurred:', error);
    return of({ status: 0 }); // Assuming status 0 represents an error
  })
).subscribe((response: any) => {
  if(response.status==1){ this.dataDisplay=0;
    this.data_trans= response.Data[0].formfileds;
    console.log('response data1',this.data_trans)
  
    this.data_= response.Data[0].formfileds;
    this.resleng=response.Data[0].Searchcount;
    
    localStorage.setItem("prospectlist",JSON.stringify(this.data_));
    var localprostlist;
    localprostlist=JSON.parse(localStorage.getItem("prospectlist"));
    console.log('pros',localprostlist)
    console.log('response data',this.data_trans)
    console.log('response data',this.data_)
    console.log('response broke',response.Brok_Auth)
    if(this.tempsearchval.length ==0){
      this.ers=true;
    }else{
  
    this.ers=false;}}
  else{
    this.dataDisplay=0;
    document.getElementById("poppup").click()
  }
 
  //this.registerForm.get('search').setValue(this.registerForm.value.Name);

  // if(response.Brok_Auth=='A'){
  //   this.dat=['Broker',[]]
  //   console.log('cc',this.dat)
  // }
  // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
  //   this.dat=['carrier',[]]
  //   console.log('oo',this.dat)
  // }
 
})

//  localStorage.removeItem('searchtext1');
//  localStorage.removeItem('searchtext2');
//localStorage.removeItem('fildername');
}
searchfunction2(e){
  this.p=this.p-1;
  this.dataDisplay=1;

  var value = {};
  console.log('filterID filterID',this.filterID)
  if(localStorage.getItem("bttnpros")=='2'){
  if(localStorage.getItem("searchtext2pros")!=null){
    this.btn2filter=localStorage.getItem("btn2filter3pros");
  
  value[this.btn2filter]=localStorage.getItem("searchh3pros");;
    }
    if(localStorage.getItem("searchtext2pros")==null) {
      value[this.btn2filter]=this.search1.value.searchh;
    }
  if(localStorage.getItem("searchtext1pros")!=null){
    this.filterID= localStorage.getItem("fildernamepros");
     value[this.filterID] =localStorage.getItem("searchtext1pros");
    }
    if(localStorage.getItem("searchtext1pros")==null) {
    value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
    }
    if(localStorage.getItem("sortfilter1pros")!=null){
      value['SortBy'] =localStorage.getItem("sortfilter1pros");
       value['Orderby']=localStorage.getItem("sorttype1pros");
     }
    }
    else{
      value['SortBy']=this.filterID2;
      value['Orderby']=this.sorttype1;
    
      value[this.btn2filter]=this.search1.value.searchh;
      value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
    }

    
    value['PerPageSize']=31;
    value['NoofPages']=1;
   
    value['Index']=this.p;
    value['UserID']=this.user.GetAccess().User_id;
    value['RoleID']=this.user.GetAccess().RoleID;
    value['view_allcustomer']=this.user.GetAccess().view_customers;
   
 localStorage.setItem("filteridpros",JSON.stringify(value));
 this.tempsearchval=value[this.filterID];
 
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=0;
  this.data_trans= response.Data[0].formfileds;
  this.resleng=response.Data[0].Searchcount;
  
  console.log('response data1',this.data_trans)
  this.data_= response.Data[0].formfileds;
  localStorage.setItem("prospectlist",JSON.stringify(this.data_));
  var localprostlist;
  localprostlist=JSON.parse(localStorage.getItem("prospectlist"));
  console.log('pros',localprostlist)
  console.log('response data',this.data_trans)
  console.log('response data',this.data_)
  console.log('response broke',response.Brok_Auth)
  if(this.tempsearchval.length ==0){
    this.ers=true;
  }else{

  this.ers=false;}
  //this.registerForm.get('search').setValue(this.registerForm.value.Name);

  // if(response.Brok_Auth=='A'){
  //   this.dat=['Broker',[]]
  //   console.log('cc',this.dat)
  // }
  // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
  //   this.dat=['carrier',[]]
  //   console.log('oo',this.dat)
  // }
 
})

// localStorage.removeItem('searchtext1');
// localStorage.removeItem('searchtext2');
// localStorage.removeItem('fildername');
}
dropnull()
{
  this.dropremove=null;
}

data_: any = [];
Search(drop){
  
    this.dataDisplay=1;
  this.sear=0;
  var value = {};
  this.data_trans=this.data_;
  if(drop=='' && this.search1.value.searchh.length!=0){
    this.toastr.warning("select any dropdown");
  }
  
  if( (drop=='1'|| drop=='2'||drop=='6'|| drop=='7') && this.search1.value.searchh.length==0)
  {
    
    this.mandatorycheck="1";
      
  }
  else{
    this.mandatorycheck="0";
  }
  if (drop=='1') {
    this.btn2filter='CodeFilter'
       
    value['CodeFilter']=this.search1.value.searchh;
    localStorage.setItem("searchtext2pros",this.search1.value.searchh);
    localStorage.setItem("dropcheck1pros",'0');
  }if (drop=='2'){
    this.btn2filter='NameFilter'
   
    value['NameFilter']=this.search1.value.searchh;
    localStorage.setItem("searchtext2pros",this.search1.value.searchh);
    localStorage.setItem("dropcheck1pros",'0');
  }
  if (drop=='6'){
    this.btn2filter='StateFilter'
   
    value['StateFilter']=this.search1.value.searchh;
    localStorage.setItem("searchtext2pros",this.search1.value.searchh);
    localStorage.setItem("dropcheck1pros",'0');
  }
  if (drop=='7'){
    this.btn2filter='CRFilter'
  
    value['CRFilter']=this.search1.value.searchh;
    localStorage.setItem("searchtext2pros",this.search1.value.searchh);
    localStorage.setItem("dropcheck1pros",'0');
  }
  if (drop=='8'){
    this.btn2filter='StateFilter'
  
    value['StateFilter']=this.search1.value.searchh;
    localStorage.setItem("searchtext2pros",this.search1.value.searchh);
    localStorage.setItem("dropcheck1pros",'0');
  }
  if (drop=='9'){
    this.btn2filter='Status'
  
    value['Status']=this.search1.value.searchh;
    localStorage.setItem("searchtext2pros",this.search1.value.searchh);
    localStorage.setItem("dropcheck1pros",'0');
  }
  if(drop=='3'){
    localStorage.setItem("dropcheck1pros",'1');
    this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
  // this.programexpiredate=this.finalfromdate;
     
       var searchvalue = new Date(this.searchingdate);
       var searchmonth=searchvalue.getMonth()+1;
       var searchdate=searchvalue.getDate();
       var searchyear=searchvalue.getFullYear();
       var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
       console.log('finalresult prospectcust page',finalsearchdate);
    
       this.search1.value.searchh=this.programexpiredate;
   
       this.btn2filter='CCRecallFilter'
       value['CCRecallFilter']=this.search1.value.searchh;
       localStorage.setItem("searchtext2pros",this.search1.value.searchh);
       value['SortBy']='CCRecall';

       value['Orderby']='DES'
     }
     if(drop=='4'){
      localStorage.setItem("dropcheck1pros",'1');
      this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
    // this.programexpiredate=this.finalfromdate;
       
         var searchvalue = new Date(this.searchingdate);
         var searchmonth=searchvalue.getMonth()+1;
         var searchdate=searchvalue.getDate();
         var searchyear=searchvalue.getFullYear();
         var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
         console.log('finalresult prospectcust page',finalsearchdate);
      
         this.search1.value.searchh=this.programexpiredate;
     
         this.btn2filter='CreditRecallFilter'
         value['CreditRecallFilter']=this.search1.value.searchh;
         localStorage.setItem("searchtext2pros",this.search1.value.searchh);
         value['SortBy']='CreditRecall';

         value['Orderby']='DES'
       }
       if(drop=='5'){
        localStorage.setItem("dropcheck1pros",'1');
        this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
      // this.programexpiredate=this.finalfromdate;
         
           var searchvalue = new Date(this.searchingdate);
           var searchmonth=searchvalue.getMonth()+1;
           var searchdate=searchvalue.getDate();
           var searchyear=searchvalue.getFullYear();
           var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
           console.log('finalresult prospectcust page',finalsearchdate);
        
           this.search1.value.searchh=this.programexpiredate;
       
           this.btn2filter='CollectRecallFilter'
           value['CollectRecallFilter']=this.search1.value.searchh;
           localStorage.setItem("searchtext2pros",this.search1.value.searchh);
           value['SortBy']='CollectRecall';

           value['Orderby']='DES'
         }
  console.log('filterID filterID',this.filterID);
  //Recently hided//
   localStorage.setItem("btn2filter3pros",(this.btn2filter));
 localStorage.setItem("searchh3pros",(this.search1.value.searchh));

 
 value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
 value['PerPageSize']=31;
 value['NoofPages']=1;

  value['Index']=1;
 
 value['UserID']=this.user.GetAccess().User_id;
 value['RoleID']=this.user.GetAccess().RoleID;
 value['view_allcustomer']=this.user.GetAccess().view_customers;
 localStorage.setItem("filteridpros",JSON.stringify(value));
//  this.bind1=(localStorage.getItem("searchh3"));
//  this.search1.get('searchh').setValue(localStorage.getItem("searchh3"));
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=0;

  if(response.status==1){
    this.dataDisplay=0;
    this.data_trans= response.Data[0].formfileds;
    this.data_= response.Data[0].formfileds;
    this.resleng=response.Data[0].Searchcount;
  
  }
  else{
    this.dataDisplay=0;
    this.toastr.warning('Select valid data');
 
  } 
})

console.log('data_trans',drop,this.data_trans);
}
resetfnt(e){
  var value = {};

  value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
  

 
    
  
  value['PerPageSize']=31;
  value['NoofPages']=1;
 
  value['Index']=this.p;
  value['UserID']=this.user.GetAccess().User_id;
  value['RoleID']=this.user.GetAccess().RoleID;
  value['view_allcustomer']=this.user.GetAccess().view_customers;
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=0;
  this.data_trans= response.Data[0].formfileds;
  this.data_= response.Data[0].formfileds;
  this.resleng=response.Data[0].Searchcount;
  
  this.dropValue2=null;
})
 localStorage.removeItem('searchtext1pros');
 localStorage.removeItem('searchtext2pros');
localStorage.removeItem('fildernamepros');
localStorage.removeItem('sortfilter1pros');
  localStorage.removeItem('sorttype1pros');
  localStorage.removeItem('dropcheck1pros');
  
}
reset1(){
  localStorage.removeItem('searchtext1pros');
  localStorage.removeItem('searchtext2pros');
  localStorage.removeItem('fildernamepros');
  localStorage.removeItem('sortfilter1pros');
  localStorage.removeItem('sorttype1pros');
  localStorage.removeItem('dropcheck1pros');
  // this.resetfnt(this);
  window.location.reload(); 
//   this.data_trans=this.data_;
//   this.searchValue = null;
//  this.dropValue=null;
//  this.btn2filter='';
 
}
reset(){
  localStorage.removeItem('searchtext1pros');
  localStorage.removeItem('searchtext2pros');
  localStorage.removeItem('fildernamepros');
  localStorage.removeItem('sortfilter1pros');
  localStorage.removeItem('sorttype1pros');
  localStorage.removeItem('dropcheck1pros');
  // this.resetfnt(this);
  window.location.reload(); 
//   this.data_trans=this.data_;
//   this.searchValue = null;
//  this.dropValue=null;



//  window.location.reload(); 
//  this.searchfunction(this);
}
sortt(arg,id){
  this.dataDisplay=1;
  this.filter={active:arg};
    
  this.filterID2=id;
// alert(this.filterID2)
  var value={};
  if(localStorage.getItem("bttnpros")=='2'){
  if(localStorage.getItem("searchtext2pros")!=null){
    this.btn2filter=localStorage.getItem("btn2filter3pros");
  
  value[this.btn2filter]=localStorage.getItem("searchh3pros");;
    }
    if(localStorage.getItem("searchtext2pros")==null) {
      value[this.btn2filter]=this.search1.value.searchh;
    }
  if(localStorage.getItem("searchtext1pros")!=null){
    this.filterID= localStorage.getItem("fildernamepros");
     value[this.filterID] =localStorage.getItem("searchtext1pros");
    }
    if(localStorage.getItem("searchtext1pros")==null) {
    value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
    }
  }
  else{
    value[this.btn2filter]=this.search1.value.searchh;
    value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
  }
  

    
    value['PerPageSize']=31;
    value['NoofPages']=1;
   
    value['Index']=this.p;
    value['UserID']=this.user.GetAccess().User_id;
    value['RoleID']=this.user.GetAccess().RoleID;
    value['view_allcustomer']=this.user.GetAccess().view_customers;


    value['SortBy']=this.filterID2;
 

    if(localStorage.getItem("sortfilter1pros")==this.filterID2){
      // alert(this.filterID2)
      // alert(localStorage.getItem("sortfilter1"))
     if(localStorage.getItem("sorttype1pros")=='DES'){
      // alert('ASC')
      this.sorttype1='ASC'
    value['Orderby']=this.sorttype1
    localStorage.setItem("sorttype1pros",(this.sorttype1));
    }
    
    else if(localStorage.getItem("sorttype1pros")=='ASC'){
      // alert('DES')
      this.sorttype1='DES'
    value['Orderby']=this.sorttype1
    localStorage.setItem("sorttype1pros",(this.sorttype1));
    }
    
     else if(this.sorttype1==''||localStorage.getItem("sorttype1pros")==null){
      // alert(' @Des')
       this.sorttype1='DES'
     value['Orderby']= this.sorttype1;
     localStorage.setItem("sorttype1pros",(this.sorttype1));
     }
    }
    else{
      // alert(' @else')
      this.sorttype1='DES'
      value['Orderby']= this.sorttype1;
      localStorage.setItem("sorttype1pros",(this.sorttype1));
    }
    
    
      
    localStorage.setItem("sortfilter1pros",(this.filterID2));


    console.log('req',value);
   localStorage.setItem("filteridpros",JSON.stringify(value));
   this.tempsearchval=value[this.filterID];
   
  this.callapi_source(value).subscribe((response: any) => {
   
    this.data_trans= response.Data[0].formfileds;
    console.log('response data1',this.data_trans)
  
    this.data_= response.Data[0].formfileds;
    // alert(response.Data[0].Searchcount)
    this.resleng=response.Data[0].Searchcount;
    this.dataDisplay=0;
    
    localStorage.setItem("prospectlist",JSON.stringify(this.data_));
    var localprostlist;
    localprostlist=JSON.parse(localStorage.getItem("prospectlist"));
    console.log('pros',localprostlist)
    console.log('response data',this.data_trans)
    console.log('response data',this.data_)
    console.log('response broke',response.Brok_Auth)
    if(this.tempsearchval.length ==0){
      this.ers=true;
    }else{
  
    this.ers=false;}
    //this.registerForm.get('search').setValue(this.registerForm.value.Name);
  
    // if(response.Brok_Auth=='A'){
    //   this.dat=['Broker',[]]
    //   console.log('cc',this.dat)
    // }
    // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
    //   this.dat=['carrier',[]]
    //   console.log('oo',this.dat)
    // }
   
  })
}
refreshPage() {
  location.reload();
}
}

