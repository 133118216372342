import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,Validators,FormArray} from '@angular/forms';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import {ExcelService} from 'src/app/service/excel.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'
import { UserService } from 'src/app/service/user.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-spreport',
  templateUrl: './spreport.component.html',
  styleUrls: ['./spreport.component.scss']
})
export class SpreportComponent implements OnInit {

  dataDisplay: number
  dropValue:Number=null;
  p: any = 1;
  count: Number = 30;
  myForm: FormGroup;
  myForms: FormGroup;
  minDate:any;
  maxDate:any;
  name = 'Angular 6';
data:any;
  date: Date;
  data_trans: any;
  data1: any;
  programlist: any;

  fromdate: any;
  finalfromdate: string;
  events: string[] = [];
  events1: string[] = [];
  todate: Date;
  finaltodate: string;
  programexpiredate: string;
  latest_date: string;
  c: string;
  g: Date;
  g1: Date;
  show: number;
  resleng: any;
  xl: number=0;
  SearchDisableButton: boolean = false;
  constructor(private http: HttpClient,public user:UserService,public excelService:ExcelService,private toastr: ToastrService,public datepipe: DatePipe) { }

  

  ngOnInit(): void {

    this.maxDate = new Date();
    this.getDropdown();
    this.program();
    var d = new Date();
    
    // var startdate = moment();
    // startdate = startdate.subtract(342, "days");
    // this.minDate = startdate.format("-MM-DD");
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 1, 11, 1);
    this.maxDate=new Date(currentYear-1 , 11, 31);
    console.log('min_date',this.minDate);
    console.log('max_date',this.maxDate);
    var year = d.getFullYear();
    var month = d.getMonth();
    var date1=1;
    this.g  = new Date(year, month, date1)
    console.log("fromdate", this.g)
    
    var year1 = d.getFullYear();
    var month1 = d.getMonth();
    var date2=30;
    this.g1  = new Date(year1, month1, date2)
    console.log("fromdate", this.g1)
    
    this.date=new Date();
  this.latest_date    =this.datepipe.transform(this.date, 'MM-dd-yyyy');
this. c=this.datepipe.transform(this.g, 'MM-dd-yyyy');



    this.myForm = new FormGroup({    
      'fromdate': new FormControl(this.g),
      'todate': new FormControl(this.g1),
      'SearchBy': new FormControl((''), Validators.required),
      'value': new FormControl((''), Validators.required),
     });
     console.log(this.myForm)     
 
this.searchfunction('')
console.log('sponinit');
}

printPage() {
  window.print();
}
addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  this.show=1
  this.events.push(`${type}: ${event.value}`);
  this.fromdate=event.value;
  console.log('swathi', this.fromdate)
  var fromdatevalue = new Date(this.fromdate);
  var fromdatemonth=fromdatevalue.getMonth()+1;
  var fromdatedate=fromdatevalue.getDate();
  var fromdateyear=fromdatevalue.getFullYear();
  this.finalfromdate=fromdatemonth+'/'+fromdatedate+'/'+fromdateyear;
  
  console.log("fromdate",this.fromdate);
  console.log("final from date",this.finalfromdate);
 }
 addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
  this.events1.push(`${type}: ${event.value}`);
  this.todate=event.value;
  var todatevalue = new Date(this.todate);
  var todatemonth=todatevalue.getMonth()+1;
  var todatedate=todatevalue.getDate();
  var todateyear=todatevalue.getFullYear();
  this.finaltodate=todatemonth+'/'+todatedate+'/'+todateyear;
  console.log("todate",this.todate);
  console.log("final to date",this.finaltodate);
  
 }
callapi_source(namee)
{
  
  //var body =   JSON.stringify(this.registerForm.value,null,4);
  var body =   namee;
  console.log('xx',body);
  
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Reports/GetSalesReportsDetailsUsingSearch' , body, { headers: headerOptions });

}
pageChange($event) {
  // this.p = $event;
   this.Next_Button(this);
 }
 pageChange1($event) {
   // this.p = $event;
    this.Pre_Button(this);
  }
data_: any = [];
searchfunction(e){

  if(this.xl!=1){
    if(this.show==1){
      this.myForm.value.fromdate=this.g

    }
      this.dataDisplay=1;
      this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
      // this.programexpiredate=this.finalfromdate;
         
           var searchvalue = new Date(this.myForm.value.fromdate);
           var searchmonth=searchvalue.getMonth()+1;
           var searchdate=searchvalue.getDate();
           var searchyear=searchvalue.getFullYear();
           var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
           console.log('finalresult prospectcust page',finalsearchdate);
    
           var searchvalue1 = new Date(this.myForm.value.todate);
           var searchmonth1=searchvalue1.getMonth()+1;
           var searchdate1=searchvalue1.getDate();
           var searchyear1=searchvalue1.getFullYear();
           var finalsearchdate1=searchmonth1+'/'+searchdate1+'/'+searchyear1;
           console.log('finalresult prospectcust page',finalsearchdate1);
        
     this.myForm.value.fromdate=finalsearchdate;
     this.myForm.value.todate=finalsearchdate1;
      localStorage.setItem("reportsearch",(this.myForm.value.value));

    this.myForm.get('SearchBy').value

      var value =this.myForm.value;
      value['PerPageSize']=this.count;
      value['NoofPages']=1;
      value['Index']=1;
      // value['Index']=this.p;
      value['UserID']=this.user.GetAccess().User_id;
      value['RoleID']=this.user.GetAccess().RoleID;
      console.log('request######3',value);
      var key = "AllData";
delete value[key];
console.log('request#####',value);
      // {"fromdate":"2020-11-01","todate":"2021-10-28","SearchBy":'Name',"value":"HONOLULU"}
      localStorage.setItem("exalparam",JSON.stringify(value));
     console.log('filterIDddd',this.myForm.value.value)
    this.callapi_source(value).subscribe((response: any) => {
      this.dataDisplay=1;
      if(response.status==1){
    
      this.dataDisplay=0;
      this.resleng=response.Searchcount;
    
      console.log('response data',response)
      console.log('oo',response.Data.Legal_Name)
      this.data =  response.Data
      this.dropValue=null;
      console.log('oo1',this.data);
      this.SearchDisableButton=true;
     this.data_trans=response.Data[response.Data.length-1].Searchcount;
    
     
      }
      else{
        this.dataDisplay=0; 
        // this.toastr.error('Search Using Text Or From Dropdown');
      }
    })
    console.log('xl clicked')
  }
  else{
    this.xl=0
  }




}
Next_Button(e){
  console.log('spnxt');
  this.dataDisplay=1;
  this.p=parseInt(this.p)+1;
  var searchvalue = new Date(this.myForm.value.fromdate);
  var searchmonth=searchvalue.getMonth()+1;
  var searchdate=searchvalue.getDate();
  var searchyear=searchvalue.getFullYear();
  var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
  console.log('finalresult prospectcust page',finalsearchdate);

  var searchvalue1 = new Date(this.myForm.value.todate);
  var searchmonth1=searchvalue1.getMonth()+1;
  var searchdate1=searchvalue1.getDate();
  var searchyear1=searchvalue1.getFullYear();
  var finalsearchdate1=searchmonth1+'/'+searchdate1+'/'+searchyear1;
  console.log('finalresult prospectcust page',finalsearchdate1);

this.myForm.value.fromdate=finalsearchdate;
this.myForm.value.todate=finalsearchdate1;
this.myForm.get('SearchBy').value
  var value =this.myForm.value;
  value['value']=localStorage.getItem("reportsearch")
  value['PerPageSize']=this.count;
  value['NoofPages']=1;
 
  value['Index']=this.p;
  value['UserID']=this.user.GetAccess().User_id;
  value['RoleID']=this.user.GetAccess().RoleID;
  // {"fromdate":"2020-11-01","todate":"2021-10-28","SearchBy":'Name',"value":"HONOLULU"}
  
 console.log('filterID filterID',value)
this.callapi_source(value).subscribe((response: any) => {
  if(response.status==1){
    this.resleng=response.Searchcount;
  this.dataDisplay=0;

  console.log('response data',response)
  console.log('oo',response.Data.Legal_Name)
  this.data =  response.Data
  this.dropValue=null;
  console.log('oo',this.data);
  }
  else{
    this.dataDisplay=0; 
   // this.toastr.error('Search Using Text Or From Dropdown');
  }
})


}
Pre_Button(e){
  console.log('spnxt');
  this.dataDisplay=1;
  this.p=parseInt(this.p)-1;
  var searchvalue = new Date(this.myForm.value.fromdate);
  var searchmonth=searchvalue.getMonth()+1;
  var searchdate=searchvalue.getDate();
  var searchyear=searchvalue.getFullYear();
  var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
  console.log('finalresult prospectcust page',finalsearchdate);

  var searchvalue1 = new Date(this.myForm.value.todate);
  var searchmonth1=searchvalue1.getMonth()+1;
  var searchdate1=searchvalue1.getDate();
  var searchyear1=searchvalue1.getFullYear();
  var finalsearchdate1=searchmonth1+'/'+searchdate1+'/'+searchyear1;
  console.log('finalresult prospectcust page',finalsearchdate1);

this.myForm.value.fromdate=finalsearchdate;
this.myForm.value.todate=finalsearchdate1;
this.myForm.get('SearchBy').value
  var value =this.myForm.value;
  value['value']=localStorage.getItem("reportsearch")
  value['PerPageSize']=this.count;
  value['NoofPages']=1;
  value['UserID']=this.user.GetAccess().User_id;
  value['RoleID']=this.user.GetAccess().RoleID;
 
  value['Index']=this.p;
  // {"fromdate":"2020-11-01","todate":"2021-10-28","SearchBy":'Name',"value":"HONOLULU"}

 console.log('filterID filterID',value)
this.callapi_source(value).subscribe((response: any) => {
  if(response.status==1){
    this.resleng=response.Searchcount;
  this.dataDisplay=0;

  console.log('response data',response)
  console.log('oo',response.Data.Legal_Name)
  this.data =  response.Data
  this.dropValue=null;
  console.log('oo',this.data);
  }
  else{
    this.dataDisplay=0; 
   // this.toastr.error('Search Using Text Or From Dropdown');
  }
})


}
dropdown: any = [];
xll(){

}
exportAsXLSX():void {
  this.xl=1;
  console.log('spxl');
  this.dataDisplay=1;
  if(this.show==1){
    this.myForm.value.fromdate=this.g
  }
    this.dataDisplay=1;
    this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
    // this.programexpiredate=this.finalfromdate;
       
         var searchvalue = new Date(this.myForm.value.fromdate);
         var searchmonth=searchvalue.getMonth()+1;
         var searchdate=searchvalue.getDate();
         var searchyear=searchvalue.getFullYear();
         var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
         console.log('finalresult prospectcust page',finalsearchdate);
  
         var searchvalue1 = new Date(this.myForm.value.todate);
         var searchmonth1=searchvalue1.getMonth()+1;
         var searchdate1=searchvalue1.getDate();
         var searchyear1=searchvalue1.getFullYear();
         var finalsearchdate1=searchmonth1+'/'+searchdate1+'/'+searchyear1;
         console.log('finalresult prospectcust page',finalsearchdate1);
      
   this.myForm.value.fromdate=finalsearchdate;
   this.myForm.value.todate=finalsearchdate1;
    localStorage.setItem("reportsearch",(this.myForm.value.value));
  this.myForm.get('SearchBy').value
    var value1 =this.myForm.value;
    value1['PerPageSize']=this.count;
    value1['NoofPages']=1;
   
    value1['Index']=this.p;
    value1['UserID']=this.user.GetAccess().User_id;
    value1['RoleID']=this.user.GetAccess().RoleID;
  value1['AllData']=''
  console.log('requestxl',value1);
  this.callapi_source(value1).subscribe((response: any) => {
    this.dataDisplay=1;
    if(response.status==1){
    this.dataDisplay=0;
    this.data_= response.Data;
    this.excelService.exportAsExcelFile(this.data_, 'Excel');
    console.log('report',response)
 
   } })

}

getDropdown(){
this.dropdownApi().subscribe((response: any) => {
  if (response.msg=='Successful') {
    this.dropdown=response.salesperson;
    console.log('response data',response);
  }
})


}
dropdownApi()
{
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GetSalesPerson?Role=salesperson&userid=SPUserid');
}

programapi()
{
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Customer/GetProgramList');
}
program(){
  this.programapi().subscribe((response: any) => {
    if (response.msg=='Successful') {
      this.programlist=response.programlist;
      console.log('response data program', response);
    }
  })
}

reset(){
  window.location.reload(); 
  this.dropValue=null;
  localStorage.removeItem('exalparam');
   
 }
}
