import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  GetName(){
    let name;
    let type=localStorage.getItem('role');
    switch (localStorage.getItem('role')) {
      case "1":
        name="Super Administrator"
        break;
        case "2":
          name="Administrator"
          break;
          case "3":
            name="Sales Person"
            break;
            case "4":
              name="Customer"
        break;
    
      default:
        name='Welcome'
        break;
    }
    return {'name':name,'type':type};
  }
  GetAccess(){
    let userDetails=localStorage.getItem('userDetails');
    return JSON.parse(userDetails);
  }
}
