import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsComponent } from './pages/forms/forms.component';
import { ManageUserComponent } from './pages/manage-user/manage-user.component';
import { ProspectFormComponent } from './prospect-form/prospect-form.component';
import { ProspectmanageComponent } from './prospectmanage/prospectmanage.component';
import {DataTablesModule} from 'angular-datatables';
import { ReportsComponent } from './pages/reports/reports.component';
import { ProspectCustComponent } from './prospect-cust/prospect-cust.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService} from './service/user.service';

import { DatePipe } from '@angular/common'
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthService} from './service/auth.service';
import { AuthService1} from './service/auth.service1';
import { AuthGuard } from './service/auth.guard';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { ConversionComponent } from './conversion/conversion.component';
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from '@angular/http';
import {MatIconModule} from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
import { PermissionComponent } from './pages/permission/permission.component';
import { ProspectEditFormComponent} from './prospectsEdit-form/prospectsEdit-form.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExcelService } from './service/excel.service';
import {NgxPrintModule} from 'ngx-print';
import { PhoneMaskDirective } from './phone-mask.directive'; 

import { SpreportComponent } from './pages/spreport/spreport.component'; 

//import { authInterceptorProviders } from './service/auth.interceptor';



























import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';

import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';

import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';


import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';

import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { ProscustComponent } from './proscust/proscust.component';
import { ProscustformComponent } from './proscustform/proscustform.component'; 
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FormsComponent,
    ManageUserComponent,
    ProspectFormComponent,
    ProspectEditFormComponent,
    ProspectmanageComponent,
    ReportsComponent,
    ProspectCustComponent,
    SpreportComponent,
    DashboardComponent,
    ConversionComponent,
    PermissionComponent,
    PhoneMaskDirective,
    ProscustComponent,
    ProscustformComponent
    
  
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    FormsModule,
    MatCheckboxModule,
    NgxPaginationModule,
    MatFormFieldModule,
    NgxPrintModule,


    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    DataTablesModule.forRoot() ,
    NgxEchartsModule.forRoot({
      echarts:() => import('echarts')
    }),
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,


























    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,

    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
  ],
  providers: [UserService,AuthService,ExcelService,AuthService1,DatePipe,AuthGuard],
  exports:[
    DataTablesModule,PhoneMaskDirective
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
