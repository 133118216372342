<div class="row mb-5">
    
 
         
               
              
  
  <form class="form-sample w-100 container-fluid" [formGroup]="registerForm"  (ngSubmit)="onSubmit()">
    <div class="col-12 grid-margin">
      <div class="card pb-4">
       
        <div class="card-header" style="padding:13px;">
          <h4 class="card-title" style="font-size: 16px;">Permission</h4>
          
        </div>
      <div class="card-body"> 
     
       
     
       
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row rolediv">
            <label class="col-sm-1 col-form-label rolee">Role</label>
            <div class="col-sm-2">
              <div class="form-radio" >
                <label  >
                  <input type="radio" value=1 [(ngModel)]="role"  formControlName="role_id"  name="role_id" id="membershipRadios1" (change)="onclickradio(this)" > Super Admin</label>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-radio" >
                <label  >
                  <input type="radio" value=2 [(ngModel)]="role"  formControlName="role_id"  name="role_id" id="membershipRadios1" (change)="onclickradio(this)" > Admin </label>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-radio">
                <label >
                  <input type="radio" value=3 [(ngModel)]="role" formControlName="role_id"   name="role_id" id="membershipRadios2" (change)="onclickradio(this)"> Sales Person </label>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-radio">
                <label >
                  <input type="radio"  value=4 [(ngModel)]="role" formControlName="role_id"  name="role_id" id="membershipRadios2" (change)="onclickradio(this)"  > Customer Representative</label>
              </div>
            </div>
   
          </div>
        </div>
      </div>
       <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Permissions</label>
            <div class="col-sm-3">
              <div class="col-sm-12">
                
                 <div class="form-check">
                  <label>
                    <input type="checkbox"   formControlName="change_com"  onclick="return true"> Change Comm </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"   formControlName="account_trans" onclick="return true"> Account Trans</label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"    formControlName="assign_prospect" onclick="return true">  Reassign prospects </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"  formControlName="view_lead" onclick="return true"> view all Lead </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"  formControlName="delete_user" onclick="return true">  Delete User </label>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"   formControlName="lock_lead" onclick="return true">  Lock the Leads </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"    formControlName="manage_user" onclick="return true"> Manage Users </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"    formControlName="lock_sales_person" onclick="return true"> Lock SalesPerson </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"    formControlName="view_sales_person" onclick="return true"> view all SalesPerson</label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"    formControlName="view_rawdata" onclick="return true">View Raw Data</label>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"  formControlName="cancel_acc" onclick="return true">  Cancel Account</label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"    formControlName="change_initials" onclick="return true">Change Initials</label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"    formControlName="edit_program" onclick="return true">Edit Program</label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"    formControlName="view_customers" onclick="return true">view all Customers</label>
                </div>
              </div>
              
            </div>
       
          
          </div>
        </div>
      </div> 
       <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Modules</label>
            <div class="col-sm-6">
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox" formControlName="user_management" onclick="return true"> User Management</label>
                </div>
              </div>
            <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox" formControlName="prospect_list" onclick="return true">Prospect Management</label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"  formControlName="cus_management" onclick="return true"> Customer Management </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"   formControlName="dashboard" onclick="return true"> Dashboard </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"   formControlName="permission_access" onclick="return true"> Permissions </label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <label >
                    <input type="checkbox"   formControlName="reports" onclick="return true">Reports </label>
                </div>
              </div>
          
          </div></div>
        </div>
      </div>
    </div>
     </div> 
    

<div class="position-relative">
  <button  type="submit" class="position-absolute left-250 btn btn-success mr-2 submitbtn">Submit</button>
          
        </div>





</div>
  </form>  </div> 
       



