<div class="row mb-5">
    
  <!-- <div class="col-md-8 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic form</h4>
        <p class="card-description"> Basic form elements </p>
        <form class="forms-sample">
          <div class="form-group">
            <label for="exampleInputName1">Name</label>
            <input type="text" class="form-control" id="exampleInputName1" placeholder="Name">
          </div>
          <div class="form-group">
            <label for="exampleInputEmail3">Email address</label>
            <input type="email" class="form-control" id="exampleInputEmail3" placeholder="Email">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword4">Password</label>
            <input type="password" class="form-control" id="exampleInputPassword4" placeholder="Password">
          </div>
          <div class="form-group">
            <label>File upload</label>
            <input type="file" name="img[]" class="file-upload-default">
            <div class="input-group col-xs-12">
              <input type="text" class="form-control file-upload-info" disabled placeholder="Upload Image">
              <span class="input-group-append">
                <button class="file-upload-browse btn btn-info" type="button">Upload</button>
              </span>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputCity1">City</label>
            <input type="text" class="form-control" id="exampleInputCity1" placeholder="Location">
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Textarea</label>
            <textarea class="form-control" id="exampleTextarea1" rows="2"></textarea>
          </div>
          <button type="submit" class="btn btn-success mr-2">Submit</button>
          <button class="btn btn-light">Cancel</button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-5 d-flex align-items-stretch">
    <div class="row flex-grow">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Basic input groups</h4>
            <p class="card-description"> Basic bootstrap input groups </p>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">@</span>
                </div>
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                <div class="input-group-append">
                  <span class="input-group-text">.00</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <div class="input-group-prepend">
                  <span class="input-group-text">0.00</span>
                </div>
                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Colored input groups</h4>
            <p class="card-description"> Input groups with colors </p>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend bg-info">
                  <span class="input-group-text bg-transparent">
                    <i class="mdi mdi-shield-outline text-white"></i>
                  </span>
                </div>
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="colored-addon1">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend bg-primary border-primary">
                  <span class="input-group-text bg-transparent">
                    <i class="mdi mdi mdi-menu text-white"></i>
                  </span>
                </div>
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="colored-addon2">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="colored-addon3">
                <div class="input-group-append bg-primary border-primary">
                  <span class="input-group-text bg-transparent">
                    <i class="mdi mdi-menu text-white"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend bg-primary border-primary">
                  <span class="input-group-text bg-transparent text-white">$</span>
                </div>
                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                <div class="input-group-append bg-primary border-primary">
                  <span class="input-group-text bg-transparent text-white">.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Input size</h4>
        <p class="card-description"> This is the default bootstrap form layout </p>
        <div class="form-group">
          <label>Large input</label>
          <input type="text" class="form-control form-control-lg" placeholder="Username" aria-label="Username">
        </div>
        <div class="form-group">
          <label>Default input</label>
          <input type="text" class="form-control" placeholder="Username" aria-label="Username">
        </div>
        <div class="form-group">
          <label>Small input</label>
          <input type="text" class="form-control form-control-sm" placeholder="Username" aria-label="Username">
        </div>
      </div>
      <div class="card-body">
        <h4 class="card-title">Selectize</h4>
        <div class="form-group">
          <label for="exampleFormControlSelect1">Large select</label>
          <select class="form-control form-control-lg" id="exampleFormControlSelect1">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect2">Default select</label>
          <select class="form-control" id="exampleFormControlSelect2">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect3">Small select</label>
          <select class="form-control form-control-sm" id="exampleFormControlSelect3">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Checkbox Controls</h4>
        <p class="card-description">Checkbox and radio controls</p>
        <form class="forms-sample">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input"> Default </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" checked> Checked </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" disabled> Disabled </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" disabled checked> Disabled checked </label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="form-radio">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="" checked> Option one </label>
                </div>
                <div class="form-radio">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2"> Option two </label>
                </div>
              </div>
              <div class="form-group">
                <div class="form-radio disabled">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="optionsRadios2" id="optionsRadios3" value="option3" disabled> Option three is disabled </label>
                </div>
                <div class="form-radio disabled">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="optionsRadio2" id="optionsRadios4" value="option4" disabled checked> Option four is selected and disabled </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Checkbox Flat Controls</h4>
        <p class="card-description">Checkbox and radio controls with flat design</p>
        <form class="forms-sample">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="form-check form-check-flat">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input"> Default </label>
                </div>
                <div class="form-check form-check-flat">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" checked> Checked </label>
                </div>
                <div class="form-check form-check-flat">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" disabled> Disabled </label>
                </div>
                <div class="form-check form-check-flat">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" disabled checked> Disabled checked </label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="form-radio form-radio-flat">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="flatRadios1" id="flatRadios1" value="" checked> Option one </label>
                </div>
                <div class="form-radio form-radio-flat">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="flatRadios2" id="flatRadios2" value="option2"> Option two </label>
                </div>
              </div>
              <div class="form-group">
                <div class="form-radio form-radio-flat disabled">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="flatRadios3" id="flatRadios3" value="option3" disabled> Option three is disabled </label>
                </div>
                <div class="form-radio form-radio-flat disabled">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="flatRadios4" id="flatRadios4" value="option4" disabled checked> Option four is selected and disabled </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div> -->
  
  <form class="form-sample w-100 container-fluid" [formGroup]="registerForm"  (ngSubmit)="onSubmit()">
    <div class="col-12 grid-margin">
      <div class="card pb-4">
       
        <div class="card-header">
        <h4 class="card-title">User Info</h4>

        <button routerLink="/mangeuser" class="position-absolute left-50 btn btn-success mr-2" style="    margin-left: 35%;
        margin-top: -28px;
        font-size: small;
        background-color: #0c7cd5;
        min-width: 86px;">
            << Back</button>

              <!-- style="background: blue;margin-left: 51%!important;margin-top:-27px;width:8%;
        color:white;font-size: 50px;" -->
        </div>
        <div class="card-body">
          <h6 class="card-description">Personal info</h6>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">First Name</label>

                <div class="col-sm-9">
                  <div *ngIf="userid!=null">
                    <input type="text" formControlName="fname" class="form-control" placeholder="e.g. john"
                      [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" disabled />
                  </div>
                  <div *ngIf="userid==null">
                    <input type="text" formControlName="fname" class="form-control" placeholder="e.g. john"
                      [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" />
                  </div>
                  <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                    <div *ngIf="f.fname.errors.required">First Name is required</div>
                  </div>

                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Last Name</label>

                <div class="col-sm-9">
                  <div *ngIf="userid!=null">
                    <input type="text" formControlName="lname" class="form-control" placeholder="e.g. smith"
                      [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" disabled />
                  </div>
                  <div *ngIf="userid==null">
                    <input type="text" formControlName="lname" class="form-control" placeholder="e.g. smith"
                      [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" />
                  </div>
                  <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                    <div *ngIf="f.lname.errors.required">Last Name is required</div>
                  </div>
                </div>


         

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Phone Number</label>
                <div class="col-sm-9">
                  <input type="number" onwheel="this.blur()" (input)="inputValidator($event)" formControlName="phone_no"
                    maxlength="10" class="form-control " id="PhoneNumber" placeholder="e.g. +1 21 345 3245"
                    [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" />
                  <div *ngIf="submitted && f.phone_no.errors" class="invalid-feedback">
                    <div *ngIf="f.phone_no.errors.required">phoneNumer is required</div>
                    <div *ngIf="f.phone_no.errors.pattern">Invalid phone number</div>
                    <!-- <div *ngIf="f.phone_no.errors.pattern">Please, Enter 10 digit Mobile Number.</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Email address</label>
                <div validator class="col-sm-9">
                  <input type="email" formControlName="email" class="form-control" id="exampleInputEmail3"
                    placeholder="e.g. example@email.com" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                  </div>
                  <div
                    *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)"
                    style="color:red;font-size: 13px;">Please enter valid email</div>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">User Name</label>
                <div class="col-sm-9">
                  <input type="text" autocomplete="off" formControlName="user_name" class="form-control" name="usr352"
                    id="UserName1232" placeholder="e.g. john123"
                    [ngClass]="{ 'is-invalid': submitted && f.user_name.errors }">
                  <div *ngIf="submitted && f.user_name.errors" class="invalid-feedback">
                    <div *ngIf="f.user_name.errors.required">UserName is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9">
                  <input matInput [type]="password ? 'text' : 'password'" autocomplete="off" class="form-control"
                    id="exampleInputEmail3" formControlName="pass_word" placeholder="Password"
                    [ngClass]="{ 'is-invalid': submitted && f.pass_word.errors }">
                  <span Suffix (click)="password = !password">
                    <mat-icon style="cursor:pointer;" class="iconhide " matSuffix (click)="hide = !hide"
                      [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ? 'visibility_off' :
                      'visibility'}}</mat-icon></span>
                  <div *ngIf="submitted && f.pass_word.errors" class="invalid-feedback">
                    <div *ngIf="f.pass_word.errors.required">Password is required</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Confirm Password</label>
            <div class="col-sm-9">
              <input type="password" autocomplete="off"   class="form-control" id="exampleInputEmail3" formControlName="confirm_pass_word" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.pass_word.errors }">
              <div *ngIf="submitted && f.pass_word.errors" class="invalid-feedback">
                <div *ngIf="f.pass_word.errors.required">Password is required</div>
            </div>
            </div>
          </div>
        </div> -->
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Association</label>
                <div class="col-sm-9">
                  <input type="text" autocomplete="off" formControlName="association" class="form-control" name="usr352"
                    id="association" [ngClass]="{ 'is-invalid': submitted && f.association.errors }">
                  <div *ngIf="submitted && f.association.errors" class="invalid-feedback">
                    <div *ngIf="f.association.errors.required">Association is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Extention</label>
                <div class="col-sm-9">
                  <input type="text" autocomplete="off" formControlName="extention" class="form-control" name="usr352"
                    id="extention" [ngClass]="{ 'is-invalid': submitted && f.extention.errors }">
                  <div *ngIf="submitted && f.extention.errors" class="invalid-feedback">
                    <div *ngIf="f.extention.errors.required">Extention is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Role</label>
                <div class="col-sm-2">
                  <div class="form-radio">
                    <label>
                      <input type="radio" value=1 [(ngModel)]="role" formControlName="role_id" name="role_id"
                        id="membershipRadios1" (change)="onclickradio(this)">Super Admin </label>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-radio">
                    <label>
                      <input type="radio" value=2 [(ngModel)]="role" formControlName="role_id" name="role_id"
                        id="membershipRadios1" (change)="onclickradio(this)"> Admin </label>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-radio">
                    <label>
                      <input type="radio" value=3 [(ngModel)]="role" formControlName="role_id" name="role_id"
                        id="membershipRadios2" (change)="onclickradio(this)"> Sales Person </label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-radio">
                    <label>
                      <input type="radio" value=4 [(ngModel)]="role" formControlName="role_id" name="role_id"
                        id="membershipRadios2" (change)="onclickradio(this)"> Customer Representative</label>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Permissions</label>
                <div class="col-sm-3">
                  <div class="col-sm-12">

                    <div class="form-check">
                      <label>
                        <!-- <input type="checkbox"   formControlName="change_com"  onclick="return false"> Change Comm </label> -->
                        <input type="checkbox" formControlName="change_com"> Change Comm </label>
                    </div>

                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="account_trans"> Account Trans</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="assign_prospect"> Reassign prospects </label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="view_lead"> view all Lead </label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="delete_user"> Delete User </label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="lock_lead"> Lock the Leads </label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="manage_user"> Manage Users </label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="lock_sales_person"> Lock SalesPerson </label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="view_sales_person"> view all SalesPerson</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="view_rawdata">View Raw Data</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="cancel_acc"> Cancel Account</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="change_initials">Change Initials</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="edit_program">Edit Program</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="view_customers">view all Customers</label>
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Modules</label>
                <div class="col-sm-6">
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="user_management"> User Management</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="prospect_list">Prospect Management</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="cus_management"> Customer Management </label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="dashboard"> Dashboard </label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="permission_access"> Permissions </label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <label>
                        <input type="checkbox" formControlName="reports">Reports </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title"> Address </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Address 1</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="address1" class="form-control"
                      placeholder="e.g. 50 al henderson drive" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Address 2</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="address2" class="form-control"
                      placeholder="e.g. virginia beach" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">City</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="city" class="form-control" placeholder="e.g. virginia beach" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">State</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="state" class="form-control" placeholder="e.g. va" />
                  </div>
                </div>

              </div>
            </div>
            <div class="row">

              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Country</label>
                  <div class="col-sm-9">
                    <select class="form-control" formControlName="country">
                      <option>USA</option>
                      <option>CN</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Postcode</label>
                  <div class="col-sm-9">
                    <input type="text" formControlName="postcode" class="form-control" placeholder="e.g. 32929" />
                  </div>
                </div>
              </div>
            </div>

    </div>
  </div>
</div>

<div class="position-relative">
  <button   type="submit" class="position-absolute left-50 btn btn-success mr-2">Submit</button>
          
        </div>





    </div>
  </form>
</div>