<div id="grid">
   <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
         <div class="card">
            <div class="card-header" style="padding: 13px;">
               <h4 class="card-title" style="font-size: 16px;">Prospects Management</h4><span id="poppup" data-toggle="modal" data-target="#exampleModal" ></span>
               <button routerLink="/prospectedit" class="position-absolute left-50 btn btn-success mr-2" 
                  style=" margin-left: 82%;
                  margin-top: -27px;
                  font-size: smaller;
                  background-color: #0c7cd5;" [queryParams]="{Add:0}">+Add Prospect</button>
            </div>
            <div class="card-body filterdiv">
               <p class="card-description">
                  Filters:
               </p>
               <div class="panel filterss" >
                  <div class="d-flex justify-content-center flex-wrap filter">
                     <!-- *ngIf="user.GetAccess()?.permission_access=='True'" -->
                     <button *ngIf="user.GetAccess()?.view_rawdata=='True'" [routerLink]="['/conversion']" [ngClass]="filter.active==0 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(0,'Raw-Data')" class="btn btn-sm" id=Raw-Data>Raw Data Menu</button>
                     <button [ngClass]="filter.active==2 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(2,'Company_Name')" class="btn btn-sm" id=Company_Name>Company Name</button>
                     <button [ngClass]="filter.active==3 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(3,'DBA_Name')" class="btn btn-sm" id=DBA_Name>DBA</button>
                     <button [ngClass]="filter.active==4 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(4,'Docket_No')" class="btn btn-sm" id=Docket_No>MC#</button>
                     <button [ngClass]="filter.active==5 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(5,'State')" class="btn btn-sm" id=State>State</button>
                     <button [ngClass]="filter.active==6 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(6,'Suspended')" class="btn btn-sm" id=NotContacted >Suspended</button>
                    
                     <button [ngClass]="filter.active==7 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(7,'CreditRecall')" class="btn btn-sm" id=CreditRecall >Credit Recall</button>
                     <!-- <button [ngClass]="filter.active==8 ? 'btn-primary' : 'btn-default'" (click)="SetFilter(8,'CollectRecall')" class="btn btn-sm" id=CollectRecall>Collect Recall</button> -->
                  </div>
                  <form class="form-sample w-100 container-fluid row justify-content-center" [formGroup]="registerForm"  (ngSubmit)="searchfunction(this)" >
                  <div class="row justify-content-center" >
                     <div class="col-lg-9 mt-4">
                        <div   [ngClass]="filter.active==2 ||filter.active==3 || filter.active==4||filter.active==5||filter.active==9  ? 'hided' : 'vis'" class="">
                           <input type="text" placeholder="Search" formControlName="search"  class="form-control" aria-label="..." style="    display: block;
                              margin-left: auto;
                              margin-right: auto;
                              width: 237px;" [(ngModel)]="text" [ngClass]="{ 'is-invalid ': submitted && f.search.errors }" >
                           <div *ngIf="submitted && f.search.errors" class="invalid-feedback">
                              <div *ngIf="f.search.errors.required "  style=" margin-left: 44px;">Search By Text</div>
                           </div>
                        </div>
                        <div *ngIf="filter.active==6||filter.active==7 || filter.active==8"  class="">
                           <!-- <input type="text" placeholder="Search" formControlName="search" class="form-control" aria-label="..." style="    display: block;
                              margin-left: auto;
                              margin-right: auto;
                              width: 237px;" [ngClass]="{ 'is-invalid ': submitted && f.search.errors }" >
                                <div *ngIf="submitted && f.search.errors" class="invalid-feedback">
                                  <div *ngIf="f.search.errors.required "  style=" margin-left: 11px;">Search By Text</div>
                              </div> -->
                           <form [formGroup]="date1" style=" margin-top: -40px;">
                              <label>From Date</label>
                              <mat-form-field appearance="fill">
                                 <input matInput readonly  [matDatepicker]="picker6" 
                                 (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)"  formControlName="from">
                                 <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                 <mat-datepicker #picker6></mat-datepicker>
                              </mat-form-field>
                              <label>To Date</label>
                              <mat-form-field appearance="fill">
                                 <input matInput readonly  [matDatepicker]="picker7" 
                                 (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)"  formControlName="to">
                                 <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
                                 <mat-datepicker #picker7></mat-datepicker>
                              </mat-form-field>
                           </form>
                        </div>
                     </div>
                     <div class="col-lg-2"> <button #btn  data-toggle="modal" data-target="#reassignModal1" class="btn btn-secondary btn-fw btn-sm mt-4" style="margin-left:-33px;"><i class="fa fa-search "></i>Search</button>
                      <button (click)="reset1()" class="btn btn-outline-secondary btn-sm"  style=" position: relative;
                        left: 108px;
                        top: -32px;"><i class="fa fa-refresh
                        "></i>Refresh</button> </div>
                  </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div *ngIf="dataDisplay==1">
      <div class="d-flex justify-content-center ">
         <div class="spinner-border" role="status" style="position: relative;
            left: -61px;top:-15px;">
            <span class="sr-only" id="loading"></span>
         </div>
      </div>
      <div class="d-flex justify-content-center mb-3"></div>
      <div class="spinner_overlay"></div>
   </div>
   <div class="row">
      <div class="col-lg-12 grid-margin stretch-card" style="margin-top: -18px;">
         <div class="card">
            <div class="card-body" style="padding: 17px;">
               <!-- <h4 class="card-title" style="font-size: 16px;">Manage Users</h4> -->
               <!-- <p class="card-description">
                  Add class
                  <code>.table-striped</code>
                  </p> -->
               <div>
                  <form class="form-sample w-100 container-fluid row justify-content-center" [formGroup]="search"  (ngSubmit)="Search(drop.value)" >
                  <div class="row justify-content-start">
                     <div class="col-lg-2">
                        <h6 class="card-description aligright mb-0" style="position: relative;
                           left: -70px;" >Search by</h6>
                     </div>
                     <div class="col-lg-2">
                        <select #drop class="form-control drpdwn" formControlName="SearchBy"   style="   position: relative;
                           left: -93px;
                           width: 110px;" >
                           <option value="" disabled selected hidden>Select</option>
                           <option value="1">Classification Code</option>
                           <option value="2">Sales Person  </option>
                           <option value="3">Credit Recall </option>
                           <!-- <option value="4">Collect Recall </option> -->
                           <option value="6">Customer Care Recall </option>
                           <option value="5">State</option>
                        </select>
                     </div>
                     <div class="col-sm-4">
                        <div *ngIf="search.get('SearchBy').value!='3'&& search.get('SearchBy').value!='4' && search.get('SearchBy').value!='6'">
                           <input  type="text" placeholder="Search" formControlName="searchh" #searchInput class="form-control search searchbox" aria-label="..." >
                           <span style="font-size: 80%; color: #dc3545;" *ngIf="mandatorycheck==1">Search by text</span>
                        </div>
                        <form [formGroup]="date">
                           <div *ngIf="search.get('SearchBy').value=='3'||search.get('SearchBy').value=='4'||search.get('SearchBy').value=='6'||this.storefildata==1">
                              <div class="col-lg-3 mr-2" style="    margin-left: -130px;">
                                 <label style="    font-weight: 500;
                                    font-size: 13px;
                                    position: relative;
                                    top: 7px;
                                    left: 7px;">From</label>
                                 <mat-form-field appearance="fill"  style="    position: relative;
                                    text-align: left;
                                    top: -37px;
                                    left: 63px;
                                    width: 141px;">
                                    <input matInput readonly [matDatepicker]="picker" formControlName="from"
                                    (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                 </mat-form-field>
                              </div>
                              <div class="col-lg-3" style="    margin-left: 127px;">
                                 <label style="    font-weight: 500;
                                    font-size: 12px;
                                    position: relative;
                                    top: 7px;
                                    left: -24px;">To</label>
                                 <mat-form-field appearance="fill" style="      position: relative;
                                    text-align: left;
                                    top: -37px;
                                    left: 9px;
                                    width: 141px;">
                                    <input matInput readonly [matDatepicker]="picker1" formControlName="to"
                                    (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                 </mat-form-field>
                              </div>
                           </div>
                        </form>
                     </div>
                     <div  class="col-sm-2" >
                        <button   *ngIf="search.get('SearchBy').value=='3'||search.get('SearchBy').value=='4'||search.get('SearchBy').value=='6'" class="btn btn-secondary btn-sm" style="    position: relative;
                           left: 48px;"><i class="fa fa-search
                           "></i>Search</button>
                        <button *ngIf="search.get('SearchBy').value!='3' && search.get('SearchBy').value!='4' && search.get('SearchBy').value!='6'"  class="btn btn-secondary btn-sm" style="  position: relative;
                           left: -6px;"><i class="fa fa-search
                           "></i>Search</button>
                     </div>
                     <div class="col-sm-2" style="    position: relative;
                        left: 22px;">
                        <button (click)="reset1()" class="btn btn-outline-secondary btn-sm"  ><i class="fa fa-refresh
                           "></i>Reset</button>
                     </div>
                  </div>
                  </form>
               </div>
               <div class="table-responsive">
                  <table class="table table-striped">
                     <thead>
                        <tr >
                           <!-- (click)="sortt(11,'Name')" id=Name -->
                           <th  (click)="sortt(11,'Name')" id=Name >
                              Prospects Name <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th>
                           <th (click)="sortt(12,'MCNumber')" id=MCNumber>
                              MC# <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz "></i>
                           </th>
                           <th (click)="sortt(13,'Code')" id=Code>
                              Classification Code <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th>
                           <th (click)="sortt(14,'CreditRecall')" id=CreditRecall >
                              Credit Recall <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th>
                           <!-- <th (click)="sortt(15,'Suspended')" id=Suspended>
                             Suspended <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th> -->
                           <th (click)="sortt(16,'CCRecall')" id=CCRecall>
                              Customer Care Recall <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th> 
                             <!-- <th (click)="sortt(17,'CollectRecall')" id=CollectRecall>
                              CollRecall <i *ngIf="data_trans.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th>  -->
                           <th *ngIf="Sp=='3'">
                              Customer/Prospect
                           </th>
                           <th *ngIf="user.GetAccess().RoleID!=3"  (click)="sortt(18,'SalesPerson')" id=SalesPerson >
                              Sales Person <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th>
                           <th (click)="sortt(19,'State')" id=State>
                              State <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th>
                           <th (click)="sortt(20,'Trucks')" id=Trucks >
                              Truck# <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                           </th>
                           <!-- <th>
                              Start Date
                           </th> -->
                           <th (click)="sortt(15,'Suspended')" id=Suspended>
                              Suspended <i *ngIf="data_trans?.length>1" class="fa fa-fw fa-sort fa-lg fontz"></i>
                            </th>
                           <th *ngIf="user.GetAccess().RoleID!=3 && user.GetAccess().RoleID!=4 && user.GetAccess().assign_prospect=='True'">
                              Reassign
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let item of data_trans; let i=index"   >
                           <td *ngIf="Sp!='3'" >
                              <a [routerLink]="['/prospectedit']"  (click)="currentpage()" [queryParams]="{Docket_No: item.Docket_No,prosid:item.prosid,index:i,itemsPerPage:count,currentPage:p}">
                              {{item.Legal_Name}}</a>
                           </td>
                           <td *ngIf="Sp=='3'" routerLink="/prospectedit" [queryParams]="{Docket_No:  item.Docket_No}" >
                           <div *ngFor="let checkbox of checkboxes"  >
                              <input type="checkbox" [(ngModel)]="checkbox.checked" [value]="checkbox.value">
                              <a [routerLink]="['/prospectedit']" [queryParams]="{Docket_No:  item.Docket_No}"> {{checkbox.name}}</a> 
                           </div>
                           </td>
                           <td>
                              {{item.Docket_No}}
                           </td>
                           <td>
                              {{item.BCCode}}
                           </td>
                           <td>
                              {{item.CreditRecall}}
                           </td>
                           <!-- <td>
                              {{item.Suspended}}
                           </td> -->
                           <td>
                              {{item.CCRecall}}
                           </td>
                           <!-- <td>
                              {{item.CollRecall}}
                           </td> -->
                           <td  *ngIf="Sp=='3'">
                              prospect
                           </td>
                           <td *ngIf="user.GetAccess().RoleID!=3">
                              {{item.SP_Name}}
                           </td>
                           <td class="statecenter">
                              {{item.Bus_State}} 
                           </td>
                           <td class="statecenter">
                              {{item.trucks}} 
                           </td>
                           <!-- <td>
                              {{item.StartDate}}
                              
                              </td> -->
                              <td>
                                 {{item.Suspended}}
                              </td>
                           <td  *ngIf="user.GetAccess().RoleID!=3 && user.GetAccess().RoleID!=4 && user.GetAccess().assign_prospect=='True'" >
                              <!-- Button trigger modal -->
                              <button type="button" (click)="gettabledata(i)"  class="btn btn-warning btn-fw btn-sm" data-toggle="modal" data-target="#reassignModal"  style="min-width: 80px;">
                              Reassign
                              </button>
                              <div class="modal fade" id="reassignModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                 <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                       <div class="modal-header">
                                          <h5 class="modal-title" id="exampleModalLabel" style="margin-left: 100px;" ></h5>
                                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-left: 353px;">
                                          <span class="close1" aria-hidden="true">&times;</span>
                                          </button>
                                          <label class="labelsp" style="justify-content: center;margin-top: 18px;margin-left: -30px;" >
                                             <h5> Do you want to reassign:</h5>
                                          </label>
                                          <select name="sale_per"  [formControl]="Assign" class="btn btn-warning btn-fw btn-sm drpbtn" style="height: 32px; width: 150px;     position: relative;left: -211px;margin-top: 10px;">
                                             <option disabled selected value="Select">Select</option>
                                             <option *ngFor="let state of elements " value= {{state.SPUserid}}>{{state.salesperson}}</option>
                                          </select>
                                       </div>
                                       <div class="modal-footer">
                                          <a  type="button" (click)="assignbuttonclick(i)" href="#myModal" class="trigger-btn  btn btn-success btn-sm confrimwidt  btn btn-success btn-block okbtn1" data-dismiss="modal" data-toggle="modal">Confirm</a>
                                          <!--<button id="#myModal" type="button" class="  btn btn-success btn-sm confrimwidt"  data-toggle="modal" data-target="#confirmModal">Confirm</button>-->
                                          <button type="button" class="btn btn-danger btn-sm canclwidt"  data-dismiss="modal" style="min-width: 67px;">Cancel</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <!-- Modal HTML -->
                              <div *ngIf="rcheck==0">
                                 <div id="myModal" class="modal fade">
                                    <div class="modal-dialog modal-confirm">
                                       <div class="modal-content modal-cont">
                                          <div class="modal-header">
                                             <div class="icon-box">
                                                <i class="material-icons">&#xE876;</i>
                                             </div>
                                             <h4 class="modal-title w-100">Successfully Asssigned</h4>
                                          </div>
                                          <div class="modal-footer">
                                             <button (click)="btnClick()" class="btn btn-success btn-block okbtn" data-dismiss="modal">OK</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <!-- Modal -->
                              <!-- </div> -->
                           </td>
                           <!-- <td>
                              <button routerLink="/prospectform" class="btn"><i class="fa fa-edit"></i></button>
                              </td>
                              <td>
                              <button class="btn"><i class="fa fa-eye"></i></button>
                              </td> -->
                        </tr>
                     </tbody>
                     <tr *ngIf="data_trans?.length==0" class="w-100 text-center">
                        <td ></td>
                        <td class="noData"  style=" margin-left: -227px;">No Data Available..!</td>
                     </tr>
                  </table>
               </div>
               <div >
                  <div class="text-right">

                     <p ></p> <label class="recordfound" style="text-align:left;float: left;">Total  number of records found : {{resleng}}</label> 
                     <div  >
                     <button  [disabled]="p<2"  class="btn btn-info btn-sm  " (click)="pageChange1($event)"  ><<</button>&nbsp;
                     <button [disabled]="data_trans?.length <=30"  class="btn btn-info btn-sm " (click)="pageChange($event)"  >>></button></div>
                     <!-- <pagination-controls (pageChange)="pageChange($event)"
                        previousLabel=""
                        nextLabel=""
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        ></pagination-controls> -->
                  </div>
               </div>
            </div>
         </div>
         
      </div>
   </div>


 </div>
 <div>
 <div>
   <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
           <div class="modal-header">
             
             <h5 class="modal-title"   style="position: relative;
             text-align: center;
             left: 11px;width: 100%;;
            "  id="exampleModalLabel" >Data Fetching Error</h5>
           </div>
          <div class="modal-body">
           <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;font-size: medium;">Oops! Something went wrong. It seems there was an issue in fetching the data. To resolve this, please click the Refresh button.</h5>

          </div>
          <div class="row">
       
          </div>

           <div class="modal-footer">
           
             <!--<button id="#myModal" type="button" class="  btn btn-success btn-sm confrimwidt"  data-toggle="modal" data-target="#confirmModal">Confirm</button>-->
             <button type="button" (click)="refreshPage()"  class="btn btn-success  btn-sm canclwidt"   style="min-width: 67px;">Refresh</button>
           </div>
         </div>
       </div>
    </div>