import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/service/user.service';
import { AuthService } from 'src/app/service/auth.service';

import { CookieService } from '../../../node_modules/ngx-cookie-service'
import { DatePipe, formatDate } from '@angular/common';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

declare var $;
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { param } from 'jquery';
import { concat } from 'rxjs-compat/operator/concat';
import { env } from 'echarts';
@Component({
  selector: 'app-prospectEdit-form',
  templateUrl: './ProspectsEdit-form.component.html',
  styleUrls: ['./ProspectsEdit-form.component.scss'],
  providers: [DatePipe]
})

export class ProspectEditFormComponent implements OnInit {



  // dragPosition = {x: 0, y: 0};
  mySelect = '2';
  disply:number=0
  selectedValue: any;
  dropval: number = 0;
  dropval1: number = 0;
  dropcheck: any;
  nextClicked = false;
  addForm: FormGroup;
  txt: number;
 contactsave:number=0;
  submitted = false;
  rows: FormArray;
  arr1: [];
  itemForm: FormGroup;
  Prospect: Number = 1;
  displaynext: number = 0;
  pop:number=0
  popmarket:number=0
  popcompetition:number=0;
  popcontact:number=0
  dis: any;
  dis1: any;
  dis2: any;
  dis3: any;
  programlist: any;
  pricelist:any;
  productlist :any=[];
  checkval: string = "0";
  optionValue: any;
  optionValue1: any;
  inputvalue1: any;
  inputvalue: any;
  sendcontractContact:any;
  groups = [
    {
      "name": "pencils",
      "items": "red pencil"
    },
    {
      "name": "rubbers",
      "items": "big rubber"
    },
  ];
  @ViewChild('dataTable') table;
  automation:number = 0;
  dataDisplay: number = 0;
  dataTable: any;
  dtOptions: DataTables.Settings = {};
  isCustomer: boolean = true;
  userDetail: string;
  Prospects: FormGroup;
  Contacts: FormGroup;
  search: FormGroup;
  ProspectsData: any;
  prosid: number;
  countyy: any;
  Tablename:any;
  RepEmail:any;
  SP_Name:any;
  fax:any;
  phone:any;
  dba:any;
  t: any;
  ccr: any;
  a: any;
  countyy1: any;
  country: any;
  country1: any;
  s: any;
  t1: any;
  k: any;
  m: any;
  urlval: any;
  currentdate: any;
  maxDate: Date;
  minDate: string;
  datePipe1: string;
  date: any;
  serializedDate = new FormControl(new Date());
  d: any;
  tcr1 = this.user.GetAccess()?.Name[0] + this.user.GetAccess()?.Lname[0];
  localcustomerlist: any;
  nextdocnmr: any;
  nextcutnmr: any;
  nextindex: number;
  nextprosnmr: any;
  tt: any;
  r: any;
  ss: number = 1;
  datepic: string = "0";
  data_trans: any;
  dataDisplay1: number;
  index: any;
  currentPage: any;
  nexttt: string;
  p: number = 0;
  filterID: string;
  doc: any;
  pros: any;
  d1: string;
  d2: string;
  Add: any;
  d5: any;
  d6: any;
  clinitials: any;
  sp: void;
  name: void;
  suspended: any;
  Suspended: any;
  MC: any;
  show: number;
  show1: number;
  ini: any;
  chec: number;
  ob: any;
  dockt: any;
  ss1: number;
  ss2: number;
  g: number;
  shw: number;
  o: number;
  cj: number;
  k1: string;
  k2: any;
  diss: any;
  diss1: any;
  diss2: any;
  diss3: any;
  inputValue: string;
  val: string;
  val1: any;
  currentdate1: string;
  hours: number;
  min: number;
  sec: number;
  cspt: any;
  dat: number;
  in: number;
  vali: number;
  currentPage1: any;
  g1: number = 0;
  g2: number = 0;
  gg: number;
  disable: number = 0;
  indexx: any;
  dasbled: number;
  disabled: number;
  newDoc: any;
  split: string;
  splitted: any;
  showw: number;
  classdrop: any;
  selectedProduct: any;
  datas: any;
  price:any;
  classdrop1: any;
  diablenext: boolean;
  bttn1: any;
  suspendreason: any;
  close: any;
  closee:any;
  contactt:any;
  contact: any;
  compedition: any;
  compeditionn:any
  market: any;
  markett:any;
 programm:any;
  programing: any;
  Row1: any;
  Row2: any;
  Row3: any;
  showcompetition: boolean;
  showclose: boolean;
  showmarket: boolean;
  Row4: any;
  showcontact: boolean;
  showclose1: boolean;
  drag: number=0;
  drag1: number=0;
  drag2: number=0;
  drag3: number=0;
  proscus: any;
  compeditionx: number;
  savedisable: number=0;
  dialoguesavedisable: number=0;
  SelectedProductList:any='';
  SelectedProgramList:any='';
  SelectedPriceList:any='';
  Makepayment: boolean=true;
  redirect_to_tcb_key: boolean=true;
  CRUserID:any;


  constructor(private toastr: ToastrService, private _location: Location, private datePipe: DatePipe, public user: UserService, private cookieService: CookieService, public authservice: AuthService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private http: HttpClient) {



    let fname = this.user.GetAccess().Name
    let Lname = ' ' + this.user.GetAccess().Lname

    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required]
    });

    this.rows = this.fb.array([]);
    this.Prospects = this.fb.group({
      automation:[0],
      Bus_Address: ['', Validators.required],
      Bus_City: ['', Validators.required],
      Bus_Country: ['', Validators.required],
      Bus_Fax: ['', Validators.required],
      Bus_Phone: ['', Validators.required,],
      Bus_State: ['', Validators.required],
      CCRecall: ['', Validators.required],
      DBAName: ['', Validators.required],
      trucks: [0, Validators.required],
      otherloc: ['', Validators.required],
      // Email: ['', [Validators.required, this.emailValidator, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      Email: ['', Validators.required],
      // Established: ['', Validators.required],       //removable
      Legal_Name: ['', Validators.required],
      Mail_Address: ['', Validators.required],
      Mail_City: ['', Validators.required],
      Mail_State: ['', Validators.required],
      Mail_Zip: ['', Validators.required],

      Website: ['', Validators.pattern('/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/')],
      Zip: ['', Validators.required],
      Brok_Carr: ['', Validators.required],
      Cont_Auth: ['', Validators.required],
      CreditRecall: ['', Validators.required],
      CollRecall: ['', Validators.required],
      //  Bus_County: ['', Validators.required],      //removable
      Web: ['', Validators.required],

      clicus: ['', Validators.required],
      startdate: ['', Validators.required],
      Bus_Toll: ['', Validators.required],
      WebID: ['', Validators.required],
      contact: [''],
      Mail_Fax: ['', Validators.required],
      SaveOption: ['', Validators.required],
      Docket_No: ['', Validators.required],
      Docket_No1: ['', Validators.required],
      CRUserID: [this.user.GetAccess().User_id, Validators.required],
      Userid: [this.user.GetAccess().User_id, Validators.required],
      Name: [fname.concat(Lname)],
      //   Mail_County:['',Validators.required],        //removable
      Mail_Country: ['', Validators.required],

      clUserid: [this.user.GetAccess().User_id, Validators.required],
      CollectInitials: [this.user.GetAccess().Initials, Validators.required],
      SP_Name: ['', Validators.required],
      Suspended: ['', Validators.required],
      gener: ['', Validators.required],
      OB: [''],
      creditscore: [0, Validators.required],
      paytrend: [0, Validators.required],
      OldDocket_No: [''],
      Docket_Nodrp: [this.split],
      Docket_Notxt: [this.splitted],
      BCCodeID: [0],
      BCCode: [''],
      SuspendedReason:[''],
      program:[''],
      product:['']
    });

    this.Contacts = this.fb.group({
      email: ['', Validators.required],
      billing: ['', Validators.required],
      fax: ['', Validators.required],
      extension: ['', Validators.required],
      mcnumber: ['', Validators.required],
      name: ['', Validators.required],
      phone: ['', Validators.required],
      position: ['', Validators.required],
      toll: ['', Validators.required],
    });

  }


  jsonData: any = [{
    playerName: 'Cristiano Ronaldo',
    playerCountry: 'Pourtgal',
    playerClub: 'Juventus'
  },
  {
    playerName: 'Lionel Messi',
    playerCountry: 'Argentina',
    playerClub: 'Barcelona'
  }];
  get f() { return this.Prospects.controls; }
  Docket_No: any;
  ngOnInit(): void {
    this.ProductAPICall();
    this.classification();
    if(localStorage.getItem('close')=='1'){
      this.close=1;
    }
    if(localStorage.getItem('close')=='0'){
      this.close=0;
    }
    if(localStorage.getItem('contact')=='1'){
      this.contact=1;
    }
    if(localStorage.getItem('contact')=='0'){
      this.contact=0;
    }
    if(localStorage.getItem('compedition')=='1'){
      this.compedition=1;
    }
    if(localStorage.getItem('compedition')=='0'){
      this.compedition=0;
    }
    if(localStorage.getItem('market')=='1'){
      this.market=1;
    }
    if(localStorage.getItem('market')=='0'){
      this.market=0;
    }
    // if(localStorage.getItem('program')=='1'){
    //   this.programing=1;
    // }
    // if(localStorage.getItem('program')=='0'){
    //   this.programing=0;
    // }
    this.show = 0;
    this.show1 = 0;
    this.showw = 0;
    this.currentdate = new Date();
    this.hours = new Date().getHours()
    this.min = new Date().getMinutes();
    this.sec = new Date().getSeconds();

    this.currentdate1 = this.hours + ':' + this.min + ':' + this.sec;
    console.log('current date' + this.currentdate);
    this.dropcheck = localStorage.getItem("dropcheck");

    console.log('roleee', this.user.GetAccess().RoleID)
    //  this.datePipe1 = this.datePipe.transform(this.Prospects.get('CCRecall').value, 'yyyy-MM-dd')
    this.addForm.get("items").valueChanges.subscribe(val => {
      if (val === true) {
        this.addForm.get("items_value").setValue("yes");

        this.addForm.addControl('rows', this.rows);
      }
      if (val === false) {
        this.addForm.get("items_value").setValue("no");
        this.addForm.removeControl('rows');
      }
    });
    this.dat = 0
    this.chec = 1;
    // this.submitted = false;
    this.dropdown();
    this.county();
    this.type();
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.Add = params.Add;
        this.proscus=params.procus;
        this.Docket_No = params.Docket_No;
        this.prosid = params.prosid;
        this.index = params.index;

        this.currentPage = (parseInt(params.currentPage));
        this.ss = parseInt(params.index);
        this.ss2 = parseInt(params.index);
        // this.ss = this.ss + 1;
     
        if(localStorage.getItem('OB')=='1' ){
         
          this.ss1 = this.ss2 - 2;
          
        }
        else{
     
          this.ss1 = this.ss2 - 1;
     
        }
        if(localStorage.getItem('OBnext')=='1'){
          //  alert('true')
          this.ss = this.ss;
        }
        else{
          //  alert('false')
          this.ss = this.ss + 1;
        }


        // alert(localStorage.getItem('predisable'))
        

        // this.localcustomerlist=JSON.parse(localStorage.getItem("customertlist"));
        // console.log('cus', this.localcustomerlist);
        // this.nextdocnmr=this.localcustomerlist[parseInt(params.index)+1].Docket_No;
        // this. nextprosnmr=this.localcustomerlist[parseInt(params.index)+1].prosid;
        //  this.nextindex=parseInt(params.index)+1;


        if (this.currentPage == 1 && this.ss2 == 0 || localStorage.getItem('predisable')=='1' && this.ss2==1 && this.currentPage == 1) {
          this.g = 1
        }
        console.log("TTTTTTTTTTTTTTTTT");
        console.log(localStorage.getItem("filterid1"));
        console.log(this.ss);

      })
    this.getProspect(this.Docket_No, this.prosid);

    var value = JSON.parse(localStorage.getItem("filterid1"));
    this.dataDisplay1 = 1;
    this.Prospects.value.automation=0;
    console.log('val', value)
    this.callapi_source2(value).subscribe((response: any) => {
      this.dataDisplay1 = 1;
      this.data_trans = response.Data[0].formfileds;
      console.log(this.data_trans);
      if(this.data_trans.length==this.ss && this.data_trans.length!=31){
        this.diablenext=true;
      }
      if (response.status == 1) {

        this.dataDisplay1 = 0;
        this.displaynext = 1;
      }
    });

    this.submitted = false;



  }


  public numbersOnlyValidator(event: any) {
    const pattern = /^[0-9\-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\-]/g, "");
    }
  }
  add() {

    this.Prospects.value.Legal_Name = null;

  }
  button1() {
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    this.router.navigateByUrl('/prospectmanage');
    this.bttn1='1';
    localStorage.setItem("bttn1",this.bttn1);
 }
  }
  button2() {
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    this.router.navigateByUrl('/prospectmanage');
    this.bttn1='2';
    localStorage.setItem("bttn1",this.bttn1);
  }}

  GetUserClaims() {
    this.authservice.GetUserClaims()
      .subscribe((data: any) => {
        if (data) {
          console.log('GetUserClaims', data);
          let str = JSON.stringify(data);
          console.log('str', str);
          localStorage.setItem('userDetails', str);
          this.toastr.success('Welcome ', data.Name);
          console.log('accc', this.user.GetAccess().User_id);

          this.router.navigate(['/dashboard']);


        }


      },
        error => {

          this.toastr.warning(error, '');
        })
      }
  ngAfterViewInit(): void {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    $("body").scrollTop(300);
  }


  public addItem(): void {

    this.groups.push({
      name: 'foo', items: 'bar'
    });
  }
  onAddRow() {
    this.rows.push(this.createItemFormGroup());
  }

  onRemoveRow(rowIndex: number) {
    this.rows.removeAt(rowIndex);
  }

  createItemFormGroup(): FormGroup {
    return this.fb.group({
      name: null,
      description: null,
      qty: null
    });
  }

  isDisable = true;

  onDisableUser() {
    this.isDisable = false;
  }
  callapi_dropdown() {

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GetSalesPerson?Role=AllActive&userid=CLUserid');
  }
  elements: any;
  dropdown() {
    console.log("dropdown working");
    this.callapi_dropdown().subscribe(response => {
      console.log('dropdown working', response);
      this.elements = response.salesperson;
      console.log('wa', response.salesperson[0].SPUserid)

      // response.salesperson.forEach((element: any) => {
      //   // console.log('element',element);
      //   this.elements=element;
      console.log('elements', this.elements);
      // });
      // let salesperson=this.data_trans1.salesperson;
      return this.elements;

    })
  }


  getProspect(Docket_No, prosid) {
    this.dataDisplay1 = 1;
    let js = { 'Docket_No': Docket_No, 'prosid': prosid };
    this.callapi_source(js).subscribe((response: any) => {
      console.log('auto', response);
      this.dataDisplay1 = 1;
      if (response.msg == 'Successful') {
        this.dataDisplay1 = 0;
      }
      if (response) {
        this.ProspectsData = response?.Data[0];
        if (this.ProspectsData) {
          if (this.ProspectsData?.contactform) {
            this.ProspectsData.contactform.reverse();
          }
          if (this.ProspectsData?.competition) {
            this.ProspectsData.competition.reverse();
          }
          if (this.ProspectsData?.marketing) {
            this.ProspectsData.marketing.reverse();
          }
          if (this.ProspectsData?.tcrep) {
            this.ProspectsData.tcrep.reverse();
          }
          if (this.ProspectsData?.closeforms) {
            this.ProspectsData.closeforms.reverse();
          }
          if (this.ProspectsData?.programs) {
            this.ProspectsData.programs.reverse();
          }
        }
        this.ProspectsData.contactform.forEach((number, index) => {
          number.phone.toString();

          if (number.phone.length > 1) {
            number.phone = number.phone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');

          }  // number.phone=number.phone+" 1";
          console.log('Index: ' + index + ' Value: ' + number.phone);
        });

        this.ProspectsData.contactform.forEach((number, index) => {

          number.fax.toString();

          if (number.fax.length > 1) {

            number.fax = number.fax.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');

          }  // number.phone=number.phone+" 1";
          console.log('Index: ' + index + ' Value: ' + number.phone);
        });

        this.ProspectsData.contactform.forEach((number, index) => {

          number.toll.toString();
          if (number.toll.length > 1) {

            number.toll = number.toll.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
          }  // number.phone=number.phone+" 1";
          console.log('Index: ' + index + ' Value: ' + number.phone);
        });

        console.log("final check", this.ProspectsData.contactform);

        this.bind();
      }
      console.log('Data', this.ProspectsData)


    })
  }



  callapi_source(namee) {


    var body = namee;
    console.log('xx', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospect/ProspectsAutoPopulate', body, { headers: headerOptions });
  }
  callapi_source6(namee) {


    var body = namee;
    console.log('xx', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospect/ProspectsAutoPopulate', body, { headers: headerOptions });
  }
  onSubmit() {
    console.log('y', this.ProspectsData);

    this.submitted = true;
    if (this.ProspectsData.invalid) {
      return false;
    }
    this.callapi_source(this.ProspectsData.value).subscribe((response: any) => {
      if (response) {
        this.ProspectsData = response.Data;

        //this.bind();
      }
      console.log('saved', this.ProspectsData)
      this.submitted = false;

    })



  }

  callapi_source1(namee,automation) {
    
    if (this.Prospects.value.CollRecall == 'Invalid date' || this.Prospects.value.CollRecall == undefined || this.Prospects.value.CollRecall == '' || this.Prospects.value.CollRecall == 'mm/dd/yy') {
      this.Prospects.value.CollRecall = null
    }
    else {
      this.Prospects.value.CollRecall = this.Prospects.value.CollRecall;
      this.ccr = this.Prospects.value.CollRecall;
      var ccrDate = new Date(Date.parse(this.ccr));
      var ccrmonth = ccrDate.getMonth() + 1;
      var ccrecalldate = ccrDate.getDate();
      var ccryear = ccrDate.getFullYear();
      var finalccrdate = ccrmonth + '/' + ccrecalldate + '/' + ccryear;
      console.log('finalccrdate prospectedit page', finalccrdate);
      this.Prospects.value.CollRecall = finalccrdate;
    }

    if (this.Prospects.value.CCRecall == 'Invalid date' || this.Prospects.value.CCRecall == undefined || this.Prospects.value.CCRecall == '' || this.Prospects.value.CCRecall == 'mm/dd/yy') {
      this.Prospects.value.CCRecall = null

    }
    else {
      this.Prospects.value.CCRecall = this.Prospects.value.CCRecall;
      this.ccr = this.Prospects.value.CCRecall;
      var ccrDate = new Date(Date.parse(this.ccr));
      var ccrmonth = ccrDate.getMonth() + 1;
      console.log('ccrecall', ccrmonth);
      console.log('ccr', this.ccr);
      var ccrecalldate = ccrDate.getDate();
      var ccryear = ccrDate.getFullYear();
      var finalccrdate = ccrmonth + '/' + ccrecalldate + '/' + ccryear;
      console.log('finalccrdate prospectedit page', finalccrdate);

      this.Prospects.value.CCRecall = finalccrdate;
    }
    if (this.Prospects.value.CreditRecall == 'Invalid date' || this.Prospects.value.CreditRecall == undefined || this.Prospects.value.CreditRecall == '' || this.Prospects.value.CreditRecall == 'mm/dd/yy') {
      this.Prospects.value.CreditRecall = null
    }
    else {
      this.Prospects.value.CreditRecall = this.Prospects.value.CreditRecall;
      this.ccr = this.Prospects.value.CreditRecall;
      var ccrDate = new Date(Date.parse(this.ccr));
      var ccrmonth = ccrDate.getMonth() + 1;
      var ccrecalldate = ccrDate.getDate();
      var ccryear = ccrDate.getFullYear();
      var finalccrdate = ccrmonth + '/' + ccrecalldate + '/' + ccryear;
      console.log('finalccrdate prospectedit page', finalccrdate);
      this.Prospects.value.CreditRecall = finalccrdate;
    }

    this.Prospects.value.automation = automation;
    var body = JSON.stringify(this.Prospects.value, null, 4)
    console.log('xx', body);
    console.log('ccrecall', this.Prospects.value.CCRecall);
    console.log('automation', this.Prospects.value.automation);
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospect/UpdateProspect', body, { headers: headerOptions });
  }
  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }

  onSubmit1() {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValidEmail = emailPattern.test(this.Prospects.value.Email);
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    if(this.Prospects.value.OB==true){
      localStorage.setItem('OB','1');
      localStorage.setItem('predisable','1');
      localStorage.setItem('OBnext','1');
      this.getnext();
    
    }
    else{
    this.Apiforclssification().subscribe((response: any) => {
      console.log(response)
    
      this.classdrop1 = response.Codelist;

      // let smallCost =  this.classdrop1.filter(function(costItem){ return costItem.Codeid== this.Prospect.value.BCCodeID
      // });
      // console.log('***',smallCost[0].Code)

      
      // for (var i = 0; i < this.classdrop.length; i++) {
      //   if (this.classdrop[i].Codeid == this.Prospects.value.BCCodeID) {
      //     x = this.classdrop[i].Code;   
      //   }
      // }

if(this.Prospects.value.BCCodeID!=0){
     console.log("thth", this.classdrop1[this.Prospects.value.BCCodeID-1].Code );
      this.selectedProduct =this.classdrop1[this.Prospects.value.BCCodeID-1].Code;
      this.datas=this.selectedProduct;
      this.Prospects.value.BCCode= this.selectedProduct
      this.Prospects.value.BCCodeID= parseInt(this.Prospects.value.BCCodeID);}


    console.log("iu",this.selectedProduct);
    if (this.Add != 0) {
      this.Prospects.value.OldDocket_No = this.newDoc;
      if (this.Prospects.value.OB == '1') {
        this.Prospects.value.OB = true;
        this.o = 1
      }
      else {
        this.Prospects.value.OB = false;
        this.o = 0
      }
    }
    if (this.Add == 0) {
      this.Prospects.value.OB = false;
      this.chec = 1;
    }
    var t = "[0-9]*";
    var mailformat = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    var webformat = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/


    if (this.show == 0 && this.Add == 0) {
      //  alert('god')
      this.Prospects.value.Docket_No = this.Prospects.value.Docket_No1 + this.Prospects.value.Docket_No;
    }
    if (this.showw == 1 && this.Add != 0) {
      this.Prospects.value.Docket_No = this.Prospects.value.Docket_Nodrp + this.Prospects.value.Docket_Notxt
    }



    var pattern = '^[a-zA-Z ]*$';
    if (this.Add == 0) {

      this.Prospects.value.SaveOption = 'Add';
    }
    else {
      this.Prospects.value.SaveOption = 'Save';
    }
    var page = JSON.parse(localStorage.getItem("currentpage"));
    console.log('bus', this.Prospects.value.Docket_No1);
    this.nextClicked = true;

    console.log('***1***');
    console.log('55', this.Prospects.value.clUserid);

    this.submitted = true;
    if (this.Prospects.value.invalid) {
      return false;

    }
    this.submitted = true;
    if (this.Prospects.value.Legal_Name == '' || this.Prospects.value.Docket_No == undefined || this.Prospects.value.Docket_No == '' ||
      this.Prospects.value.clUserid == null || this.Prospects.value.Brok_Carr == '' ) {
      this.toastr.error('Please Fill Mandatory Fields');
      console.log('s', this.Prospects.value.Legal_Name);
      console.log('s', this.Prospects.value.Docket_No1);
      console.log('s', this.Prospects.value.Docket_No);
      console.log('s', this.Prospects.value.Brok_Carr);
      console.log('s', this.Prospects.value.Bus_State);
      console.log('s', this.Prospects.value.Bus_City);

      console.log('s', this.Prospects.value.Email);
      console.log('s', this.Prospects.value.Website);
      console.log('s', this.Prospects.value.contact);
      console.log('s', this.Prospects.value.clUserid);
    }
    // else if (!isValidEmail) {
    //   this.toastr.error('Please Fill Valid Email');
    // } 
    else if (this.Prospects.value.Docket_No1 == '' && this.Add == 0 && this.show == 0 || this.Prospects.value.Docket_No == '' && this.Add == 0 && this.show == 0) {
      this.toastr.error('Please Fill Mandatory Fields');
      //alert('y')
    }

    else if (this.Prospects.value.Docket_No.length != 10 && this.Add == 0 && this.show == 0) {
      this.toastr.error('MC Field Need 8 Numbers');
      //alert('y')
    }


    else if (this.Prospects.value.Docket_No == '' && this.Add != 0 && this.showw == 1) {
      this.toastr.error('Please Fill Mandatory Fields');
      //alert('y')
    }
    else if (this.Prospects.value.Docket_No.length != 10 && this.Add != 0 && this.showw == 1) {
      this.toastr.error('MC Field Need 8 Numbers');
    }


  

    // else if((!this.Prospects.value.Docket_No.match(t))){
    //   this.toastr.error('Please Fill Mandatory Fieldssss');
    //   //alert('y')
    // }
    // else if(!this.Prospects.value.Email.match(mailformat)){
    //   this.toastr.error('Email not in correct Format');
    // }
    // else if(!this.Prospects.value.Website.match(webformat)){
    //   this.toastr.error('Website not in correct Format');
    // }



    else {
     
      this.automation=0;
      this.callapi_source1(this.Prospects.value,this.automation).subscribe((response: any) => {
        this.ProspectsData = response.Data;
        if (response.status == 1) {
          this.toastr.success(response.msg);
          if (this.Add != 0 && this.Prospects.value.OB != '1') {

            this.Docket_No = this.Prospects.value.Docket_No;
            var redirecturl = '/prospectedit?' + 'Docket_No=' + this.Docket_No + '&prosid=' + this.prosid + '&index=' + this.index + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage;

             window.location.href = redirecturl;
          }

          if (this.Add == 0 && this.proscus == undefined ) {
            this.router.navigateByUrl('/prospectmanage');
          }
          if (this.Add == 0 && this.proscus == 0) {
            this.router.navigateByUrl('/proscust');
          }
          // if (this.Prospects.value.OB == '1') { this.router.navigateByUrl('/prospectmanage'); }
        }
        else if (response.status == 2) {
          this.toastr.error(response.msg);
        }
        else {
          this.toastr.error('Not able to Save, Please try again');
        }




        console.log('saved1', this.ProspectsData)



      })
    }
  })
}}
  }


  onSubmit2() {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValidEmail = emailPattern.test(this.Prospects.value.Email);
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    if(this.Prospects.value.OB==true){
      this.getnext();
    }
    if (this.showw == 1 && this.Add != 0) {
      this.Prospects.value.Docket_No = this.Prospects.value.Docket_Nodrp + this.Prospects.value.Docket_Notxt
    }
    if (this.show == 0 && this.Add == 0) {
      //  alert('god')
      this.Prospects.value.Docket_No = this.Prospects.value.Docket_No1 + this.Prospects.value.Docket_No;
    }
    if (this.Add == 0) {
      this.Prospects.value.OldDocket_No = '';
      this.Prospects.value.SaveOption = 'Add';
    }
    else {
      this.Prospects.value.OldDocket_No = this.newDoc;
      this.Prospects.value.SaveOption = this.redirect_to_tcb_key ? 'SaveCC':this.Tablename != 'Customer' ? 'SaveCC':'Save';
    }

    this.nextClicked = false;

    console.log('***2****');
    this.submitted = true;
    // if (this.Prospects.value.invalid){    

    // return false;

    // }
    this.submitted = true;
  //    if (!isValidEmail) {
  //     this.toastr.error('Please Fill Valid Email');
  //     // this.SelectedProductList = '';
  //     // this.SelectedProgramList = '';
  //     // this.SelectedPriceList = '';
  //   } 
  //  else 
   if (this.Prospects.value.Legal_Name == '' || this.Prospects.value.Docket_No == undefined || this.Prospects.value.Docket_No == '' ||

      this.Prospects.value.clUserid == null || this.Prospects.value.Brok_Carr == '' || this.Prospects.value.Email == '' ) {

      this.toastr.error('Please Fill Mandatory Fields');
      this.SelectedProductList = '';
      this.SelectedProgramList = '';
      this.SelectedPriceList = '';

    }

    else {
      if(this.redirect_to_tcb_key){
      this.automation = 0;
    }
    else{
      this.automation = 1;
    }

      this.dataDisplay = 1;
      this.callapi_source1(this.Prospects.value,this.automation).subscribe((response: any) => {

        this.ProspectsData = response.Data;

        if (response.status == 1) {

          if(this.redirect_to_tcb_key){
            this.dataDisplay = 0;
            // var redirecturl = environment.tcbURL + 'Accountid=' + this.Tablename =='Customer' ? this.Prospects.value.WebID :response.data[0].AccountID + '&salusername=' + this.cookieService.get('cookieUserName_pros') + '&salpassword=' + this.cookieService.get('cookieUserPassword_pros');
            var redirecturl = `${environment.tcbURL}Accountid=${this.Tablename =='Customer' ? this.Prospects.value.WebID : response.data[0].AccountID}&salusername=${this.cookieService.get('cookieUserName_pros')}&salpassword=${this.cookieService.get('cookieUserPassword_pros')}`;
            window.location.href = redirecturl;
          } else{
            this.SendInvAPI(this.SelectedProductList,this.SelectedProgramList,this.SelectedPriceList,this.dockt,response.data[0].AccountID,this.Prospects.value.WebID).subscribe(res => {
              if(res.status == 1){
                this.contactsave=0
                  this.dataDisplay = 0;
                this.SelectedProductList = '';
                this.SelectedProgramList = '';
                this.SelectedPriceList = '';
                this.toastr.success('Contract sent successfully')
                setTimeout(() => {
                var redirecturl = '/prospectedit?' + 'Docket_No=' + this.Docket_No + '&prosid=' + res.cust + '&index=' + this.index + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage;
                window.location.href = redirecturl;
              }, 1500); 
              }else{
                this.contactsave=0
                this.dataDisplay = 0;
                this.SelectedProductList = '';
                this.SelectedProgramList = '';
                this.SelectedPriceList = '';
                this.toastr.error('Contract is not Send')
                //this.router.navigateByUrl('/prospectmanage');
              }
            },error => {
              this.contactsave=0
              this.dataDisplay = 0;
              this.SelectedProductList = '';
              this.SelectedProgramList = '';
              this.SelectedPriceList = '';
              this.toastr.error('Contract is not Send')
              
              //this.router.navigateByUrl('/prospectmanage');
            })
          }
          //this.router.navigateByUrl('/prospectmanage');
     
       
        }
        else {
          this.dataDisplay = 0;
          this.contactsave=0
          this.toastr.error(response.msg);
        }
        console.log('saved2', this.ProspectsData)



      })
    }};
  }

  public onSubmit3(): void {
    if (this.nextClicked) {

    } else {

    }

  }
  callapi_source2(namee) {


    var body = namee;
    console.log('xx', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospect/GetProspectsUsingSearch', body, { headers: headerOptions });

  }


  getnext(): void {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValidEmail = emailPattern.test(this.Prospects.value.Email);
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    if(this.Prospects.value.OB==true){
       localStorage.setItem('OB','1');
      localStorage.setItem('predisable','1');
      localStorage.setItem('OBnext','1');
    }
    else{
      localStorage.setItem('OBnext','2');
    }
    this.Apiforclssification().subscribe((response: any) => {
      console.log(response)
    
      this.classdrop1 = response.Codelist;
      if(this.Prospects.value.BCCodeID!=0){
      this.selectedProduct =this.classdrop1[this.Prospects.value.BCCodeID-1].Code;
      this.datas=this.selectedProduct;
      this.Prospects.value.BCCode= this.selectedProduct
      this.Prospects.value.BCCodeID= parseInt(this.Prospects.value.BCCodeID);}

    if (this.Add != 0) {
      this.Prospects.value.OldDocket_No = this.newDoc;
      if (this.Prospects.value.OB == '1') {
        this.Prospects.value.OB = true;
      }
      else {
        this.Prospects.value.OB = false;
      }
    }
    if (this.Add == 0) {
      this.Prospects.value.OB = false;
      this.chec = 1;
    }
    var t = "[0-9]*";
    var mailformat = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    var webformat = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/


    if (this.show == 0 && this.Add == 0) {
      //  alert('god')
      this.Prospects.value.Docket_No = this.Prospects.value.Docket_No1 + this.Prospects.value.Docket_No;
    }
    if (this.showw == 1 && this.Add != 0) {
      this.Prospects.value.Docket_No = this.Prospects.value.Docket_Nodrp + this.Prospects.value.Docket_Notxt
    }



    var pattern = '^[a-zA-Z ]*$';
    if (this.Add == 0) {

      this.Prospects.value.SaveOption = 'Add';
    }
    else {
      this.Prospects.value.SaveOption = 'Save';
    }
    var page = JSON.parse(localStorage.getItem("currentpage"));
    console.log('bus', this.Prospects.value.Docket_No1);
    this.nextClicked = true;

    console.log('***1***');
    console.log('55', this.Prospects.value.clUserid);

    this.submitted = true;
    // if (this.Prospects.value.invalid){    
    // return false;

    // }
    this.submitted = true;
    if (this.Prospects.value.Legal_Name == '' || this.Prospects.value.Docket_No == undefined || this.Prospects.value.Docket_No == '' ||
      this.Prospects.value.clUserid == null || this.Prospects.value.Brok_Carr == '') {
      this.toastr.error('Please Fill Mandatory Fields');
      console.log('s', this.Prospects.value.Legal_Name);
      console.log('s', this.Prospects.value.Docket_No1);
      console.log('s', this.Prospects.value.Docket_No);
      console.log('s', this.Prospects.value.Brok_Carr);
      console.log('s', this.Prospects.value.Bus_State);
      console.log('s', this.Prospects.value.Bus_City);

      console.log('s', this.Prospects.value.Email);
      console.log('s', this.Prospects.value.Website);
      console.log('s', this.Prospects.value.contact);
      console.log('s', this.Prospects.value.clUserid);
    }
    else if (this.Prospects.value.Docket_No1 == '' && this.Add == 0 && this.show == 0) {
      this.toastr.error('Please Fill Mandatory Fields');
      //alert('y')
    }
    // else if((!this.Prospects.value.Docket_No.match(t))){
    //   this.toastr.error('Please Fill Mandatory Fieldssss');
    //   //alert('y')
    // }
    // else if(!this.Prospects.value.Email.match(mailformat)){
    //   this.toastr.error('Email not in correct Format');
    // }
    // else if(!this.Prospects.value.Website.match(webformat)){
    //   this.toastr.error('Website not in correct Format');
    // }
    // else if (!isValidEmail) {
    //   this.toastr.error('Please Fill Valid Email');
    // } 


    else {
      this.automation = 0;
      this.callapi_source1(this.Prospects.value,this.automation).subscribe((response: any) => {

        this.ProspectsData = response.Data;
        if (response.status == 1) {
          // alert(this.ss)
          // this.toastr.success(response.msg);
          if(this.data_trans.length==this.ss && this.data_trans.length!=31 && this.Prospects.value.OB==true){
            this.router.navigateByUrl('/prospectmanage');
          }
          else{
          if(this.data_trans.length==this.ss && this.data_trans.length!=31){
            this.diablenext=true;
          }
          if (this.ss == this.data_trans.length) {


            var value = JSON.parse(localStorage.getItem("filterid1"));
            this.currentPage = this.currentPage + 1;

            value['Index'] = this.currentPage;
            //  alert(this.currentPage)
            console.log('values', value);

            localStorage.setItem("filterid1", JSON.stringify(value));

            this.dataDisplay1 = 1;
            this.callapi_source2(value).subscribe((response: any) => {
              this.dataDisplay1 = 1;
              this.data_trans = response.Data[0].formfileds;
            
              if (response.status == 1) {
                this.doc = this.data_trans[0].Docket_No;
                this.pros = this.data_trans[0].prosid;
                console.log('if doc', this.data_trans[0].prosid);
                this.dataDisplay1 = 0;
                this.displaynext = 1;
                var redirecturl = '/prospectedit?' + 'Docket_No=' + this.doc + '&prosid=' + this.pros + '&index=' + 0 + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage;
                window.location.assign(redirecturl + "#form-reload");
              }
            });


          }


          else {
            // alert('hii')
            //  this.ss++
            this.doc = this.data_trans[this.ss].Docket_No;
            this.pros = this.data_trans[this.ss].prosid;
            console.log('else doc', this.data_trans[this.ss].prosid);

            var redirecturl = '/prospectedit?' + 'Docket_No=' + this.doc + '&prosid=' + this.pros + '&index=' + this.ss + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage;
            window.location.assign(redirecturl + "#form-reload");
          }

          
        }}
        else if (response.status == 2) {
          this.toastr.error(response.msg);
        }
        else {
          this.toastr.error('Not able to Save, Please try again');
        }




        console.log('saved1', this.ProspectsData)
        console.log(this.ss);
        console.log(this.data_trans);







      })
    }


  })
 }
  }
  getprevious(): void {
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    this.Apiforclssification().subscribe((response: any) => {
      console.log(response)
    
      this.classdrop1 = response.Codelist;
      if(this.Prospects.value.BCCodeID!=0){
      this.selectedProduct =this.classdrop1[this.Prospects.value.BCCodeID-1].Code;
      this.datas=this.selectedProduct;
      this.Prospects.value.BCCode= this.selectedProduct
      this.Prospects.value.BCCodeID= parseInt(this.Prospects.value.BCCodeID);}
    if (this.Add != 0) {
      this.Prospects.value.OldDocket_No = this.newDoc;
      if (this.Prospects.value.OB == '1') {
        this.Prospects.value.OB = true;
      }
      else {
        this.Prospects.value.OB = false;
      }
    }
    if (this.Add == 0) {
      this.Prospects.value.OB = false;
      this.chec = 1;
    }
    var t = "[0-9]*";
    var mailformat = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    var webformat = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/


    if (this.show == 0 && this.Add == 0) {
      //  alert('god')
      this.Prospects.value.Docket_No = this.Prospects.value.Docket_No1 + this.Prospects.value.Docket_No;
    }
    if (this.showw == 1 && this.Add != 0) {
      this.Prospects.value.Docket_No = this.Prospects.value.Docket_Nodrp + this.Prospects.value.Docket_Notxt
    }



    var pattern = '^[a-zA-Z ]*$';
    if (this.Add == 0) {

      this.Prospects.value.SaveOption = 'Add';
    }
    else {
      this.Prospects.value.SaveOption = 'Save';
    }
    var page = JSON.parse(localStorage.getItem("currentpage"));
    console.log('bus', this.Prospects.value.Docket_No1);
    this.nextClicked = true;

    console.log('***1***');
    console.log('55', this.Prospects.value.clUserid);

    this.submitted = true;
    // if (this.Prospects.value.invalid){    
    // return false;

    // }
    this.submitted = true;
    if (this.Prospects.value.Legal_Name == '' || this.Prospects.value.Docket_No == undefined || this.Prospects.value.Docket_No == '' ||
      this.Prospects.value.clUserid == null || this.Prospects.value.Brok_Carr == '' || this.Prospects.value.Email == '') {
      this.toastr.error('Please Fill Mandatory Fields');

    }
    else if (this.Prospects.value.Docket_No1 == '' && this.Add == 0 && this.show == 0) {
      this.toastr.error('Please Fill Mandatory Fields');
      //alert('y')
    }
    // else if((!this.Prospects.value.Docket_No.match(t))){
    //   this.toastr.error('Please Fill Mandatory Fieldssss');
    //   //alert('y')
    // }
    // else if(!this.Prospects.value.Email.match(mailformat)){
    //   this.toastr.error('Email not in correct Format');
    // }
    // else if(!this.Prospects.value.Website.match(webformat)){
    //   this.toastr.error('Website not in correct Format');
    // }



    else {
      this.automation = 0;
      this.callapi_source1(this.Prospects.value,this.automation).subscribe((response: any) => {

        this.ProspectsData = response.Data;
        if (response.status == 1) {
          // this.toastr.success(response.msg);
          if(this.data_trans.length==this.ss && this.data_trans.length!=31){
            this.diablenext=true;
          }
       
          if (this.ss2 == 0) {
            // alert('hi');

            var value = JSON.parse(localStorage.getItem("filterid1"));
            this.currentPage = this.currentPage - 1;

            value['Index'] = this.currentPage;
            // alert(this.currentPage)
            console.log('values', value);

            localStorage.setItem("filterid", JSON.stringify(value));

            this.dataDisplay1 = 1;
            this.callapi_source2(value).subscribe((response: any) => {
              
              this.dataDisplay1 = 1;
              this.data_trans = response.Data[0].formfileds;
              if (response.status == 1) {
                this.doc = this.data_trans[29].Docket_No;
                this.pros = this.data_trans[29].prosid;
                console.log('if', this.data_trans);
                this.dataDisplay1 = 0;
                this.displaynext = 1;
                var redirecturl = '/prospectedit?' + 'Docket_No=' + this.doc + '&prosid=' + this.pros + '&index=' + 29 + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage;
                window.location.assign(redirecturl + "#form-reload");
              }
            });


          }


          else {
            //alert('bye');
            //  this.ss++
            this.doc = this.data_trans[this.ss1].Docket_No;
            this.pros = this.data_trans[this.ss1].prosid;
            console.log('else doc', this.data_trans[this.ss1].prosid);

            var redirecturl = '/prospectedit?' + 'Docket_No=' + this.doc + '&prosid=' + this.pros + '&index=' + this.ss1 + '&itemsPerPage=' + 30 + '&currentPage=' + this.currentPage;
            window.location.assign(redirecturl + "#form-reload");


          }

        }
        else if (response.status == 2) {
          this.toastr.error(response.msg);
        }
        else {
          this.toastr.error('Not able to Save, Please try again');
        }




        console.log('saved1', this.ProspectsData)

        console.log(this.ss);
        console.log(this.data_trans);





      })
    }



  })
  localStorage.setItem('OB','2');}
  }

  copybilling() {
    this.Prospects.get('Mail_Address').setValue(this.Prospects.get('Bus_Address').value);
    this.Prospects.get('Mail_City').setValue(this.Prospects.get('Bus_City').value);
    this.Prospects.get('Mail_State').setValue(this.Prospects.get('Bus_State').value);
    this.Prospects.get('Mail_Zip').setValue(this.Prospects.get('Zip').value);
    //this.Prospects.get('Mail_County').setValue(this.Prospects.get('Bus_County').value);     //removable
    this.Prospects.get('Mail_Country').setValue(this.Prospects.get('Bus_Country').value);
    console.log('oo', this.d)
  }
  bind() {
  
     this.suspendreason=this.ProspectsData.formfileds[0].SuspendedReason;
      this.name=this.ProspectsData.formfileds[0].Legal_Name;

      if(this.ProspectsData.formfileds[0].Email != ''){
        this.val = this.ProspectsData.formfileds[0].contact;
        this.val1 = this.ProspectsData.formfileds[0].Email;
      }
      else if(this.ProspectsData.contactform.length == 2){
        
        if (this.ProspectsData.contactform[0].email !=''){
          this.val = this.ProspectsData.contactform[0].name;
          this.val1 = this.ProspectsData.contactform[0].email;
        }
        else{
          this.val = this.ProspectsData.contactform[1].name;
          this.val1 = this.ProspectsData.contactform[1].email;
          
        }

      }else if(this.ProspectsData.contactform.length == 1){
        this.val = this.ProspectsData.contactform[0].name;
        this.val1 = this.ProspectsData.contactform[0].email;
      }
      else{
        this.val = this.ProspectsData.formfileds[0].contact;
        this.val1 = this.ProspectsData.formfileds[0].Email;
      }
      
      
    console.log('data', this.ProspectsData.formfileds[0])
    console.log('bus_state', this.ProspectsData.formfileds[0].Bus_State);
    console.log("LIST", this.t);

    //this.dropval=this.ProspectsData.formfileds[0].Bus_State;
    // if(this.ProspectsData.formfileds[0].Bus_State !=null && this.ProspectsData.formfileds[0].Bus_State !=''){
    // alert(this.ProspectsData.formfileds[0].Bus_State);
    //   for(let i=0;i<this.t.length;i++)
    // if(this.ProspectsData.formfileds[0].Bus_State !=this.t[i]){

    //   this.dropval=1;

    // }
    // else{
    //   //alert(this.ProspectsData.formfileds[0].Bus_State);
    //   this.dropval=0;
    //  //alert(this.dropval);
    // }}
    console.log('f', this.ProspectsData.formfileds[0].CCRecall)
    this.date = this.ProspectsData.formfileds[0].CCRecall
    let dateString = this.ProspectsData.formfileds[0].CCRecall;
    let momentVariable = moment(dateString, 'MM-DD-YYYY');
    let stringvalue = momentVariable.format('YYYY-MM-DD');
    console.log(stringvalue);
    this.d = stringvalue;
    console.log('am d', this.d);
    console.log(stringvalue);
    console.log('f.ccrecallsss', this.ProspectsData.formfileds[0].Brok_Carr)
    console.log('f.ccrecallsss222', this.Prospects.value.Brok_Carr)


    let dateString1 = this.ProspectsData.formfileds[0].CreditRecall;
    let momentVariable1 = moment(dateString1, 'MM-DD-YYYY');
    let stringvalue1 = momentVariable1.format('YYYY-MM-DD');

    this.d1 = stringvalue1;
    console.log('am d from another', this.d);

    let dateString2 = this.ProspectsData.formfileds[0].CollRecall;
    let momentVariable2 = moment(dateString2, 'MM-DD-YYYY');
    let stringvalue2 = momentVariable2.format('YYYY-MM-DD');

    this.d2 = stringvalue2;
    console.log('am d from another', this.d);
    this.RepEmail = this.ProspectsData.formfileds[0].RepEmail;
    this.SP_Name = this.ProspectsData.formfileds[0].SP_Name;
   this.Tablename = this.ProspectsData.formfileds[0].Tablename;
   this.dba=this.ProspectsData.formfileds[0].DBAName;
   this.fax=this.ProspectsData.formfileds[0].Bus_Fax;
   this.sendcontractContact=this.ProspectsData.formfileds[0].contact;
   this.phone=this.ProspectsData.formfileds[0].Bus_Phone;
    this.d5 = this.ProspectsData.formfileds[0].clUserid;
    this.clinitials = this.ProspectsData.formfileds[0].CollectInitials;
    this.sp = this.ProspectsData.formfileds[0].SP_Name
    this.Suspended = this.ProspectsData.formfileds[0].Suspended;
    this.ob = this.ProspectsData.formfileds[0].OB;
    console.log('om', this.ProspectsData.formfileds[0].OB)
    this.dockt = this.ProspectsData.formfileds[0].Docket_No;
    this.newDoc = this.ProspectsData.formfileds[0].Docket_No;
    console.log('doccc', this.ProspectsData.formfileds[0].Docket_No)
    this.arr1 = this.t;
    console.log("aar1 value", this.arr1);



    this.splitted = this.ProspectsData.formfileds[0].Docket_No.split('FF', 3);
    this.split = this.ProspectsData.formfileds[0].Docket_No.substring(0, 2);




    if (this.split == 'FF') {
      var re = /FF/gi;
      var str = this.ProspectsData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
    else if (this.split == 'MC') {
      var re = /MC/gi;
      var str = this.ProspectsData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
    else if (this.split == 'MX') {
      var re = /MX/gi;
      var str = this.ProspectsData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }




    this.diss = "";
    this.diss1 = "";
    this.diss2 = "";
    this.diss3 = "";



    this.diss = new Date(this.ProspectsData.formfileds[0].CCRecall);
    this.diss1 = new Date(this.ProspectsData.formfileds[0].CollRecall);
    this.diss2 = new Date(this.ProspectsData.formfileds[0].CreditRecall);
    this.diss3 = new Date(this.ProspectsData.formfileds[0].Suspended);


    if (this.ProspectsData.formfileds[0].CCRecall == null) {
      this.diss = 'mm/dd/yy'
    }
    else {
      this.diss = new Date(this.ProspectsData.formfileds[0].CCRecall);
    }
    if (this.ProspectsData.formfileds[0].CreditRecall == null) {
      this.diss2 = 'mm/dd/yy'
    }
    else {
      this.diss2 = new Date(this.ProspectsData.formfileds[0].CreditRecall);
    }
    if (this.ProspectsData.formfileds[0].CollRecall == null) {
      this.diss1 = 'mm/dd/yy'
    }
    else {
      this.diss1 = new Date(this.ProspectsData.formfileds[0].CollRecall);
    }
    if (this.ProspectsData.formfileds[0].Suspended == null) {
      this.diss3 = 'mm/dd/yy'
    }
    else {
      this.diss3 = new Date(this.ProspectsData.formfileds[0].Suspended);
    }


    // this.t=this.Prospects.value.Bus_Phone;
    // alert(this.t);
    // this.r=this.t.replace("(","").replace(")","").replace("-","").replace(" ","");
    // console.log('xx',this.r);
    // alert(this.r);
    // this.d=this.ProspectsData.formfileds[0].CCRecall
    //this.Prospects.get('Bus_County').setValue(this.ProspectsData.formfileds[0].Bus_County);       //removable
    this.Prospects.get('WebID').setValue(this.ProspectsData.formfileds[0].WebID);
    this.Prospects.get('contact').setValue(this.ProspectsData.formfileds[0].contact);
    this.Prospects.get('Mail_Fax').setValue(this.ProspectsData.formfileds[0].Mail_Fax);
    this.Prospects.get('Bus_Toll').setValue(this.ProspectsData.formfileds[0].Bus_Toll);
    this.Prospects.get('Brok_Carr').setValue(this.ProspectsData.formfileds[0].Brok_Carr);
    this.Prospects.get('trucks').setValue(this.ProspectsData.formfileds[0].trucks);
    this.Prospects.get('otherloc').setValue(this.ProspectsData.formfileds[0].otherloc);

    this.Prospects.get('Bus_Address').setValue(this.ProspectsData.formfileds[0].Bus_Address);

    this.Prospects.get('Bus_City').setValue(this.ProspectsData.formfileds[0].Bus_City);
    this.Prospects.get('Bus_Country').setValue(this.ProspectsData.formfileds[0].Bus_Country);
    this.Prospects.get('Bus_Phone').setValue(this.ProspectsData.formfileds[0].Bus_Phone);
    this.Prospects.get('Bus_State').setValue(this.ProspectsData.formfileds[0].Bus_State);
    this.Prospects.get('Bus_Fax').setValue(this.ProspectsData.formfileds[0].Bus_Fax);
    this.Prospects.get('CCRecall').setValue(this.diss);
    this.Prospects.get('CreditRecall').setValue(this.diss2);
    this.Prospects.get('CollRecall').setValue(this.diss1);
    this.Prospects.get('OB').setValue(this.ProspectsData.formfileds[0].OB);
    this.Prospects.get('SuspendedReason').setValue(this.ProspectsData.formfileds[0].SuspendedReason);


    //  this.Prospects.get('CCRecall').setValue(this.ProspectsData.formfileds[0].CCRecall);
    this.Prospects.get('DBAName').setValue(this.ProspectsData.formfileds[0].DBAName);
    this.Prospects.get('Email').setValue(this.val1);
    //  this.Prospects.get('Established').setValue(this.ProspectsData.formfileds[0].Established);         //removable
    this.Prospects.get('Legal_Name').setValue(this.ProspectsData.formfileds[0].Legal_Name);
    this.Prospects.get('Mail_Address').setValue(this.ProspectsData.formfileds[0].Mail_Address);
    this.Prospects.get('Mail_City').setValue(this.ProspectsData.formfileds[0].Mail_City);
    this.Prospects.get('Mail_State').setValue(this.ProspectsData.formfileds[0].Mail_State);
    this.Prospects.get('Mail_Zip').setValue(this.ProspectsData.formfileds[0].Mail_Zip);
    this.Prospects.get('Website').setValue(this.ProspectsData.formfileds[0].Website);
    this.Prospects.get('Zip').setValue(this.ProspectsData.formfileds[0].Zip);
    this.Prospects.get('Docket_No').setValue(this.ProspectsData.formfileds[0].Docket_No);
    //  this.Prospects.get('Mail_County').setValue(this.ProspectsData.formfileds[0].Mail_County);         //removable
    this.Prospects.get('Mail_Country').setValue(this.ProspectsData.formfileds[0].Mail_Country);
    this.Prospects.get('creditscore').setValue(this.ProspectsData.cspt[0].creditscore);
    this.Prospects.get('paytrend').setValue(this.ProspectsData.cspt[0].paytrend);
    this.Prospects.get('BCCodeID').setValue(this.ProspectsData.formfileds[0].BCCodeID);
    this.Prospects.get('BCCode').setValue(this.ProspectsData.formfileds[0].BCCode);
    
    

    this.Prospects.get('clUserid').setValue(this.ProspectsData.formfileds[0].clUserid);
    this.Prospects.get('Userid').setValue(this.ProspectsData.formfileds[0].Userid);
    this.Prospects.get('CollectInitials').setValue(this.ini);
    this.Prospects.get('SP_Name').setValue(this.ProspectsData.formfileds[0].SP_Name);
    this.Prospects.get('Suspended').setValue(this.ProspectsData.formfileds[0].Suspended);
  }
  Row: number = null;
  saveEdit(namee, type) {

    //var body =   JSON.stringify(this.ProspectsData.value,null,4);
    var body = namee;
    console.log('1xx', body);

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospect/' + type, body, { headers: headerOptions });
  }
  edit(type,item, i) {

   
   
    if (item == this.ProspectsData?.closeforms[i] && type=='closeforms') {
      this.dialoguesavedisable=1;
      this.savedisable=0
      this.drag=1;
      this.checkval = "0";
      this.datepic = "2"
      this.dis = new FormControl(new Date(item?.condate));
      console.log(item, i)
      console.log('dd', item?.condate.split('T')[0])
      this.Row = i;
       this.Row1 = i;
      this.showclose==true;
      this.showclose1==true;
   
   
    }
    else if (item == this.ProspectsData?.competition[i] && type=='competition') {
      this.popcompetition=1;
      this.savedisable=1
      this.drag1=1;
      this.checkval = "1";
      this.datepic = "3";
      this.dis1 = new FormControl(new Date(item?.compdate));
      this.dis2 = new FormControl(new Date(item?.expdate));
      console.log(item, i)

      // this.Row = i;
      this.Row2 = i;
      this.showcompetition==true;

    }
    else if (item == this.ProspectsData?.marketing[i] && type=='Marketing') {
      this.popmarket=1;
      this.savedisable=1
      this.drag2=1;
      this.checkval = "2";
      this.datepic = "4";
      this.dis3 = new FormControl(new Date(item?.markdate));
      console.log(item, i)
      this.Row3 = i;
      this.showmarket==true;
    }
    else if (item == this.ProspectsData?.contactform[i] && type=='contact') {
      this.popcontact=1;
      this.savedisable=1
      this.drag3=1;
      this.checkval = "3";
      console.log(item, i)
      this.showcontact==true;
      this.Row4 = i;
    }


  }
  // edit1(item1,i1){
  //   if(item1==this.ProspectsData?.competition[i1]){
  //     this.checkval="1";
  //       alert('2');
  //       this.dis1 = new FormControl(new Date(item1?.compdate).toISOString());
  //       this.dis2 = new FormControl(new Date(item1?.expdate).toISOString());
  //       console.log(item1,i1)

  //     this.Row=i1;
  //     }
  // }
  ContactSave(type, i, item) {
    this.disply=1;

    console.log('type i prosid', type, i);
    console.log('item?.prosid', item?.prosid);
    let arr = [];
    var value: any = {};
    value['Tablename'] = this.ProspectsData.formfileds[0].Tablename;


    $('#' + type + i + ' td').each(function () {
      var customerId = $(this).find("input").val();
      let Id = $(this).find("input").attr('id');
      if (Id) {
        value[Id] = customerId ? customerId : '';

      }
    });
    if (type == 'closeforms') {
      var dialogue = $('#dialogue').val();
      value['dialogue'] = dialogue;
      this.currentdate = value['condate'];

      value['condate'] = this.currentdate + ' ' + this.currentdate1;
      console.log('closeforms', value);
      // :checked
      $('input[class="cbox"]').each(function (index) {
        var end = ((i + 1) * 5) - 1;
        var start = (((i + 1) * 5) - 4) - 1;
        console.log('start,end:', start, end);
        if (index >= start && index <= end) {
          if ($(this).prop('checked')) {
            console.log('checked val', $(this).val())
            value[$(this).val()] = $(this).val() ? true : false;
          } else {
            console.log('unchecked val', $(this).val())
            value[$(this).val()] = !$(this).val() ? true : false;
          }
        }
      });
      console.log('after cbox', value);
    }
    if (item?.prosid) {
      value["prosid"] = this.prosid;
    } else {
      value["id"] = item?.id;
    }

    if (value && type == 'contact') {
      this.drag3=0;
      this.popcontact=0
      this.disabled = 1;
      this.ProspectsData.contactform[i] = value;
      this.val = (<HTMLInputElement>document.getElementById('email')).value;
      this.val1 = (<HTMLInputElement>document.getElementById('name')).value;
      this.saveEdit(value, 'ProspectContact').subscribe((response: any) => {


        // this.onSubmit1();


      
        //    setTimeout(() => {
        //     window.location.reload();
        // }, 1000);

        if (response.status == 1) {
          this.Row4 = null;
        
      

          console.log('edit response', response);
          // this.toastr.success(response.msg);  
          // window.location.reload();  
          this.showcontact==false;
          this.disabled = 0;
          this.getProspect(this.Docket_No, this.prosid);
          //this.bind();
        } else if (response.status == 0) {
          this.toastr.warning(response.msg);
          this.disabled = 0;
        }
      }, error => {
        this.toastr.warning(error.msg, error.data);
        this.disabled = 0;
      });

    }

    if (value && type == 'competition') {
      this.popcompetition=0
      this.disabled = 1;
      this.ProspectsData.competition[i] = value;
      this.drag1=0;
      this.saveEdit(value, 'ProspectCompetition').subscribe((response: any) => {



        if (response.status == 1) {


          // this.Row = null;
          this.Row2 = null;
          console.log('edit response', response);
          this.toastr.success(response.msg);
          this.showcompetition=false;
          this.disabled = 0;
          this.getProspect(this.Docket_No, this.prosid);
          // window.location.reload();
          //this.bind();
        } else if (response.status == 0) {
          this.toastr.warning(response.msg);
          this.disabled = 0;
        }
      }, error => {
        this.toastr.warning(error.msg, error.data);
        this.disabled = 0;
      });
    }
    if (value && type == 'Marketing') {
      this.popmarket=0;
      this.disabled = 1;
      this.drag2=0;
      this.ProspectsData.marketing[i] = value;
      this.saveEdit(value, 'ProspectMarketing').subscribe((response: any) => {



        if (response.status == 1) {
          this.Row3 = null;
          console.log('edit response', response);
          this.toastr.success(response.msg);
          this.showmarket=false;  
          this.disabled = 0;
          this.getProspect(this.Docket_No, this.prosid);
          // window.location.reload();
          //this.bind();
        } else if (response.status == 0) {
          this.disabled = 0;
          this.toastr.warning(response.msg);

        }
      }, error => {
        this.disabled = 0;
        this.toastr.warning(error.msg, error.data);
      });
    }
    if (value && type == 'TCRED') {
      this.disabled = 1;
      this.ProspectsData.tcrep[i] = value;
      this.saveEdit(value, 'ProspectTCRep').subscribe((response: any) => {



        if (response.status == 1) {
          this.Row = null;
          console.log('edit response', response);
          this.toastr.success(response.msg);
          window.location.reload();
          //this.bind();
        } else if (response.status == 0) {
          this.disabled = 0;
          this.toastr.warning(response.msg);
        }
      }, error => {
        this.disabled = 0;
        this.toastr.warning(error.msg, error.data);
      });
    }
    if (value && type == 'closeforms') {
      this.dialoguesavedisable=0;
      this.drag=0;
      this.disabled = 1;
      this.ProspectsData.closeforms[i] = value;
      console.log('i', i);
      console.log('value', value);
      this.saveEdit(value, 'ProspectCloseforms').subscribe((response: any) => {
        if (response.status == 1) {
          this.Row = null;
           this.Row1 = null;
          console.log('edit response', response);
          this.toastr.success(response.msg);
          this.showclose=false;
          this.disabled = 0;
          this.getProspect(this.Docket_No, this.prosid);
          // window.location.reload();
          //this.bind();
        } else if (response.status == 0) {
          this.toastr.warning(response.msg);
          this.disabled = 0;
        }
      }, error => {
        this.disabled = 0;
        this.toastr.warning(error.msg, error.data);
      });
    }
    if (value && type == 'Programs') {
      
      this.disabled = 1;
      this.ProspectsData.programs[i] = value;
      this.saveEdit(value, 'ProspectPrograms').subscribe((response: any) => {




        if (response.status == 1) {
          this.Row = null;
          console.log('edit response', response);
          this.toastr.success(response.msg);
          window.location.reload();
          //this.bind();
        } else if (response.status == 0) {
          this.disabled = 0;
          this.toastr.warning(response.msg);
        }
      }, error => {
        this.disabled = 0;
        this.toastr.warning(error.msg, error.data);
      });
    }


  }
  canDrop() {

    return this.drag=1
  }
  compeditiontabchecking() {
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    localStorage.setItem('compedition','1');
    this.compedition=1;
    this.compeditionx=0;
    this.checkval = "1";
  }}
  dialoguetabchecking() {

  localStorage.setItem('close','1')
    this.displayStyle = "block";
    this.checkval = "0";
  }
  marketingtabchecking() {
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    localStorage.setItem('market','1');
    this.checkval = "2";
  }}
  contacttabchecking() {
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    localStorage.setItem('contact','1');
    this.checkval = "3";
  }}
  program(){
    if(this.dialoguesavedisable==1){
      this.toastr.warning('Please Save Or Cancel the Dialogue');
    }
 else{
    localStorage.setItem('program','1');
    this.checkval = "";
  }}
  addRow(type) {


    console.log('this.ProspectsData.contactform.length', this.ProspectsData.contactform.length);
    if (type == 'contact') {
      this.popcontact=1;
      this.savedisable=1;
      this.drag3=1;
      this.ProspectsData.contactform.unshift({
        //billing: "",
        email: "",
        extension: "",
        fax: "",
        // mcnumber: "",
        name: "",
        phone: "",
        position: "",
        toll: "",
        'prosid': this.prosid
      });
      this.Row4 = 0;
      this.showcontact==true;
      console.log('row added', this.ProspectsData.contactform);
      console.log('this.ProspectsData.contactform.length after', this.ProspectsData.contactform.length);
    }
    else if (type == 'competition') {
      this.popcompetition=1;
      this.savedisable=1;
      this.drag1=1;
      this.datepic = "1";
      this.ProspectsData.competition.unshift({
        //mcnumber: "",
        compdate: this.currentdate,
        // department: "",
        competitor: "",
        sercost: "",
        expdate: this.currentdate,
        'prosid': this.prosid
      });
      // this.Row = 0;
      this.Row2 = 0;
      this.showcompetition=true
      console.log('row added', this.ProspectsData.competition);
      console.log('thiscompetition after', this.ProspectsData.competition.length);
    }
    else if (type == 'Marketing') {
      this.popmarket=1;
      this.savedisable=1;
      this.drag2=1;
      this.datepic = "1";
      this.ProspectsData.marketing.unshift({
        // mcnumber: "",
        markdate: this.currentdate,
        delivered: "",
        markcomp: "",
        // sercost:'',
        // expdate:'',
        'prosid': this.prosid
      });
      this.Row3 = 0;
      this.showmarket=true;
      console.log('row added', this.ProspectsData.marketing);
      console.log('marketing after', this.ProspectsData.marketing.length);
    }
    else if (type == 'TCRED') {
      this.ProspectsData.tcrep.unshift({
        //mcnumber: "",
        initials: "",
        dep: "",
        repname: "",
        'prosid': this.prosid
      });
      this.Row = 0;
      console.log('row added', this.ProspectsData.tcrep);
      console.log('TCRED after', this.ProspectsData.tcrep.length);
    }
    else if (type == 'closeforms') {
      this.dialoguesavedisable=1
      this.savedisable=0;
      this.drag=1;
      this.datepic = "1";
      console.log('this.ProspectsData.closeforms', this.ProspectsData.closeforms)
      this.ProspectsData.closeforms.unshift({
        tcr: this.tcr1,
        condate: this.currentdate,
        voice: false,
        wrongcon: false,
        callback: false,
        noint: false,
        comp: false,
        dialogue: "", recall: "",
        'prosid': this.prosid
      });
      this.Row = 0;
      this.Row1 = 0;
      this.showclose=true
      console.log('row added', this.ProspectsData.closeforms);
      console.log('closeforms after', this.ProspectsData.closeforms.length);
    }

    else if (type == 'Programs') {
      this.savedisable=1;
      this.ProspectsData.programs.unshift({
        pgmdate: "",
        pgmtype: "",
        products: "",
        expdate: this.currentdate,
        yearly: '',
        //monthly: "",
        new: "",
        update: "",
        online: "",
        report: '',
        coll: "",
        //moncoll: "",
        //ffast: "",
        'prosid': this.prosid
      });
      this.Row = 0;
      console.log('row added', this.ProspectsData.programs);
      console.log('programs after', this.ProspectsData.programs.length);
    }
  }
  clear() {
    this.Row = null;
    this.Row1 = null;
    this.Row2 = null;
    this.Row3 = null;
    this.Row4 = null;
  }
  reset() {
    this.dialoguesavedisable=0;
    this.pop=0;
    this.disply=1
    console.log('row', this.Row)
    if (this.Row != null) {
      this.Row = null;
      this.getProspect(this.Docket_No, this.prosid);
    }

 
  }
   reset1() {
    this.pop=0;
    this.drag=0;
    this.disply=1
    if (this.Row1 != null) {
      this.Row1 = null;
      this.showclose=false;
      this.showcompetition=false;
      this.showmarket=false;  
      this.showcontact==false;
      this.getProspect(this.Docket_No, this.prosid);

    }
   }
   reset2(){
    this.popcompetition=0;
    this.drag1=0;
    this.disply=1
    if (this.Row2 != null) {
      this.Row2 = null;
      this.showclose=false;
      this.showcompetition=false;
      this.showmarket=false;
      this.showcontact==false;
      this.getProspect(this.Docket_No, this.prosid);

    }
    
   }
   reset3(){
    this.popmarket=0;
    this.drag2=0;
    this.disply=1
    if (this.Row3 != null) {
      this.Row3 = null;
      this.showclose=false;
      this.showcompetition=false;
      this.showmarket=false;
      this.showcontact==false;
      this.getProspect(this.Docket_No, this.prosid);

    }
   
   }
   reset4() {
    this.popcontact=0;
    this.drag3=0;
    this.disply=1
    console.log('row', this.Row)
    if (this.Row4 != null) {
      this.Row4 = null;
      this.showclose=false;
      this.showcompetition=false;
      this.showmarket=false;
      this.showcontact==false;
      this.getProspect(this.Docket_No, this.prosid);
    }}
  api() {
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/User/GetStateCountyList?state=Alaska');
  }
  county() {
    this.api().subscribe((response: any) => {
      console.log('countyresponse', response.data);
      const channelArray: string[] = response.data.State;
      this.t = response.data.State;
      console.log('t', this.t)
      this.k = this.Prospects.value.Bus_State;
      this.k1 = this.Prospects.value.Bus_State;
      this.k2 = this.Prospects.value.Mail_State;
      this.arr1 = this.t;
      console.log("####", this.arr1);

      console.log("****", channelArray.indexOf(this.k1) > -1);
      if (channelArray.indexOf(this.k1) > -1) {
        this.dropval = 0;


      } else {
        if (this.k1 != null && this.k1 != '') {
          this.dropval = 1;
          // let value = (<HTMLSelectElement>document.getElementById('Bus_State')).value;
          // console.log("AAAAAA",value);
        }
      }



      if (channelArray.indexOf(this.k2) > -1) {
        this.dropval1 = 0;


      } else {
        if (this.k2 != null && this.k2 != '') {
          this.dropval1 = 1;
          //  let value = (<HTMLSelectElement>document.getElementById('Mail_State')).value;
          //  console.log("AAAAAA",value);
        }
      }
      // this.api1(this.k).subscribe((county:any)=>{
      //   console.log('county',county);

      //     this.t1=county.data.County;
      //     console.log('t1',this.t1)
      //     this.country1=this.t1;

      // })
    }
    )
  }


  apifortype() {
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GetType?prosorcust=2');
  }
  type() {
    this.apifortype().subscribe((response: any) => {
      console.log('countyresponse', response.data);
      this.tt = response.Typelist;
      console.log('type', this.tt)
      var Prospect = 1;
    })

  }
  Api_generateMC() {
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/Prospect/GenerateMCNumber');
  }
  generateMC() {
    this.Api_generateMC().subscribe((response: any) => {
      this.show = 1;

      this.MC = response.Data;
      this.Prospects.get("Docket_No").setValue(this.MC);
      console.log('33', this.MC);
      if (response.status == 1) {
      this.compeditionx=1;
      this.toastr.success(response.msg);
      }
    })
  }
  cancelWithAlert(data:any){
    this.savedisable=1;
      this.toastr.warning(data,'Please Save Or Cancel the' );
  }
  cancel(data:any) {
    this.savedisable=0;

    if(data=='1'){
      localStorage.setItem('close','0');
      this.dialoguesavedisable=0;
    }
    if(data=='12'){
      localStorage.setItem('contact','0');
    }
    if(data=='13'){
      localStorage.setItem('compedition','0');
      this.compeditionx=1;
    }
    if(data=='14'){
      localStorage.setItem('market','0');
    }
    if(data=='15'){
      localStorage.setItem('program','0');
    }
    // this.displayStyle = "none";
    // this.showw = 0;
  }
  editfnct5() {
    this.showw = 1;
    this.split = this.ProspectsData.formfileds[0].Docket_No.substring(0, 2);
    if (this.split == 'FF') {
      var re = /FF/gi;
      var str = this.ProspectsData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
    else if (this.split == 'MC') {
      var re = /MC/gi;
      var str = this.ProspectsData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
    else if (this.split == 'MX') {
      var re = /MX/gi;
      var str = this.ProspectsData.formfileds[0].Docket_No;
      this.splitted = str.replace(re, '');
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  generateMC1() {
    this.Api_generateMC().subscribe((response: any) => {


      this.MC = response.Data;
      this.Prospects.get("Docket_No").setValue(this.MC);
      console.log('33', this.MC);
      if (response.status == 1) {
        this.toastr.success(response.msg);
      }
    })

  }
  editfnct() {
    this.show = 0;
    this.txt = null;

  }
  collectslp() {
    // alert('hi');
    this.ini = this.Prospects.value.clUserid
    //  alert(this.ini);
  }
  Apiforclssification() {
    return this.http.get<any>(environment.apiURL + 'api/Prospect/ClassificationCode');
  }
  ProgramAPI(req)
  {   
    var programname = req;
      return this.http.get<any>(environment.apiURL + 'api/Prospect/programlist?Program='+programname);
  }
  priceAPI(req,res){
    var programname = req;
    var product= res
      return this.http.get<any>(environment.apiURL + 'api/Prospect/pricelist?Program='+programname+'&product='+product);
  }
  ProductAPI()
{
    return this.http.get<any>(environment.apiURL + 'api/Prospect/productlist');
}
ProductAPICall(){
  this.ProductAPI().subscribe((response: any) => {
    if (response.msg=='Successful') {
      this.productlist=response.productlist;
      console.log('response data program', response);
    }
  })
}

addprogram() {
  var url = "https://tcobapp.rcktechiees.com/ReninvPopup.aspx" + 
            '/prospectedit?' + 
            'Docket_No=' + this.Docket_No + 
            '&prosid=' + this.prosid + 
            '&index=' + this.index + 
            '&itemsPerPage=' + 30 + 
            '&currentPage=' + this.currentPage;
            window.location.replace(url);
}

ProgramAPICall(val){
  console.log(this.SelectedProductList,'jsdhg',this.SelectedProgramList)
  this.programlist='';
this.ProgramAPI(val).subscribe(res=>{
  this.programlist=res.Programlist;
console.log('program response',res)
})
}
PriceAPICall(){
  console.log(this.SelectedProductList,'jsdhg',this.SelectedProgramList)
  this.pricelist='';
  var test = this.SelectedProductList;
  var test1 = this.SelectedProgramList.replace("+",":").replace("&",";");
this.priceAPI(test,test1).subscribe(res=>{
  this.pricelist=res.getpricelist;

console.log('program response',res)
})
}
selectProgram() {
  if(this.SelectedProductList=='' && this.SelectedProgramList=='' && this.SelectedPriceList==''){    
    this.Makepayment = true;
  }else{
    this.Makepayment = false;
  }
}
sendinvoice() {
  this.contactsave=1
// this.Makepayment = true;  
this.redirect_to_tcb_key = false;
this.onSubmit2();

}
SendInvAPI(product,program,price,mcnumber,Account_id,webID):Observable<any> {
  
  var body = {
    "Product": product,
    "Program": program,
    "price":price,
    "Docket_No": mcnumber,
    "TCR":this.user.GetAccess().Initials,
    "Account_id":this.Tablename =='Customer' ? webID:Account_id,
    "Report_id":null,
    "acc_name":this.name,
    "pc": 1,
    "status":0,
    "tablename":this.Tablename,
    "pros_id":this.prosid,
    "address":this.Prospects.value.Bus_Address,
    "city":this.Prospects.value.Bus_City,
    "state":this.Prospects.value.Bus_State,
    "zipcode":this.Prospects.value.Zip,
    "Email":this.Prospects.value.Email,
    "SP_name":this.SP_Name,
    "phone":this.phone,
    "fax":this.fax,
    "dba":this.dba,
    "RepEmail":this.RepEmail,
    "contact":this.sendcontractContact,
    "userid":this.user.GetAccess().User_id,
    "Lname": this.user.GetAccess().Lname,
    "Fname":this.user.GetAccess().Name,
    "useremail": this.user.GetAccess().useremail,
    "association":this.user.GetAccess().association,
    "extention":this.user.GetAccess().extention
  }
  return this.http.post(environment.apiURL+'api/Prospect/sendmailinvoice',body);
}
  classification() {
    var x;
    this.Apiforclssification().subscribe((response: any) => {
      console.log(response)
    
      this.classdrop = response.Codelist;
      // for (var i = 0; i < this.classdrop.length; i++) {
      //   if (this.classdrop[i].Codeid == this.Prospects.value.BCCodeID) {
      //     x = this.classdrop[i].Code;   
      //   }
      // }

    
    });}           
    classification1() {
      var x;
      this.Apiforclssification().subscribe((response: any) => {
        console.log(response)
      
        this.classdrop = response.Codelist;
        // for (var i = 0; i < this.classdrop.length; i++) {
        //   if (this.classdrop[i].Codeid == this.Prospects.value.BCCodeID) {
        //     x = this.classdrop[i].Code;   
        //   }
        // }
  
       console.log("thth", this.classdrop[this.Prospects.value.BCCodeID-1].Code );
        this.selectedProduct =this.classdrop[this.Prospects.value.BCCodeID-1].Code;
        this.datas=this.selectedProduct;
      });   


  }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }


  getColorBasedOnText(text: string): string {
    if (text.includes('The contract has been emailed') || text.includes('Customer Monitoring Price - $199')) {
      return '#0662bb';
    } else if (text.includes('Account has been activated') || text.includes('Invoice Paid') || text.includes('New program has been added')) {
      return '#137b2a';
    } else if (text.includes('There is an error in sending the contract')) {
      return '#ff1515';
    } else if (text.includes('Payment received for the invoice') || text.includes('Payment received for the invoice using Credit Card - $100')) {
      return '#ffaf33';
    }
   
    return 'Black';
  }
  
  
}




