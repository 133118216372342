<div class="container-scroller">
  <!-- partial:../../partials/_navbar.html -->
  <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row" style="border: 0;">
    <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
      <a class="navbar-brand brand-logo " style="align-items: center;">
  <!-- <img src="\assets\images\transcredit-logo.png" /> -->
 
   
    
      </a>
      <a class="navbar-brand brand-logo-mini">
           <h4>SalesCRM</h4> </a>
    </div>
    <div class="navbar-menu-wrapper pt-1">
      <img style="width:200px;margin-top: -0.8em;" class="float-right" src="https://i.imgur.com/RY1JYZp.png">
      <img style="width:105px" src="\assets\images\transcredit-logo.png" class="float-right" />    
     
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
        
   
      </button>
    </div>
  </nav>
  <!-- partial -->
  <div class="container-fluid page-body-wrapper pl-0" >
    <!-- partial:../../partials/_sidebar.html -->
    <nav class="sidebar sidebar-offcanvas" style="position: fixed;" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile">
          <a href="#" class="nav-link">
            <div class="profile-image">
              <!-- <img class="img-xs rounded-circle" src="../../assets/images/faces/face8.jpg" alt="profile image"> -->
              <div class="dot-indicator bg-success"></div>
            </div>
            <div class="text-wrapper">
              <p class="profile-name">{{auth.getToken()?user.GetAccess()?.Name:'Welcome'}}&nbsp;{{auth.getToken()?user.GetAccess()?.Lname:''}}</p>
              <p class="designation">{{user.GetAccess()?.Role}}</p>
            </div>
          </a>
        </li>
        <li *ngIf="router.url!='/'" class="nav-item nav-category">Main Menu</li>
        <li *ngIf="user.GetAccess()?.dashboard=='True' && router.url!='/'" class="nav-item">
          <a [ngClass]="activetabstatus=='1'? 'activeURL' : ''"  class="nav-link" [routerLink]="[ '/dashboard']">
            <i class="menu-icon typcn typcn-document-text"></i>
            <span class="menu-title">Dashboard</span>
          </a>
        </li>
       
        <li *ngIf="user.GetAccess()?.user_management=='True' && router.url!='/'" class="nav-item">
          <a [ngClass]="activetabstatus=='2'? 'activeURL' : ''"  [routerLink]="[ '/mangeuser']" class="nav-link" >
            <i class="menu-icon typcn typcn-shopping-bag"></i>
            <span class="menu-title">Users</span>
          </a>
        </li>
        <li *ngIf="user.GetAccess()?.view_rawdata=='True' && router.url!='/'" class="nav-item">
          <a [ngClass]="activetabstatus=='7'? 'activeURL' : ''"  [routerLink]="[ '/conversion']" class="nav-link" >
            <i class="menu-icon typcn typcn-user-outline"></i>
            <span class="menu-title">Raw Data</span>
          </a>
        </li>
        <li *ngIf="user.GetAccess()?.prospect_list=='True' && router.url!='/' && this.user.GetAccess()?.RoleID!=3" class="nav-item">
          <a [ngClass]="activetabstatus=='3'? 'activeURL' : ''" [routerLink]="[ '/prospectmanage']" class="nav-link" >
            <i class="menu-icon typcn typcn-th-large-outline"></i>
            <span class="menu-title"> Assigned Prospects</span>
          </a>
        </li>
        <li *ngIf="user.GetAccess()?.cus_management=='True' && router.url!='/' && this.user.GetAccess()?.RoleID!=3" class="nav-item">
          <a [ngClass]="activetabstatus=='4'? 'activeURL' : ''" [routerLink]="[ '/prospectcust']" class="nav-link" >
            <i class="menu-icon typcn typcn-bell"></i>
            <span class="menu-title">Customer/Client</span>
          </a>
        </li>

        <li *ngIf="this.user.GetAccess()?.RoleID==3" class="nav-item">
          <a [ngClass]="activetabstatus=='9'? 'activeURL' : ''" [routerLink]="[ '/proscust']" class="nav-link" >
            <i class="menu-icon typcn typcn-bell"></i>
            <span class="menu-title">ProspectCustomerClient</span>
          </a>
        </li>
        
        <li *ngIf="user.GetAccess()?.reports=='True' && router.url!='/'||this.user.GetAccess()?.RoleID==1||this.user.GetAccess()?.RoleID==3" class="nav-item">
          <a  class="nav-link" >
            <i class="menu-icon typcn typcn-user-outline"></i>
            <span class="menu-title">Reports</span>
          </a>
        </li>
        
        <li *ngIf="user.GetAccess()?.reports=='True' && router.url!='/'" class="nav-item" >
          <a [ngClass]="activetabstatus=='5'? 'activeURL' : ''" [routerLink]="[ '/reports']" class="nav-link" style=" position: relative;
          left: 57px;" >
            <i class="menu-icon typcn typcn-user-outline"></i>
            <span class="menu-title" style="font-size: 12px;">Annual Activity Report</span>
          </a>
        </li>
        <li *ngIf="this.user.GetAccess()?.RoleID==1||this.user.GetAccess()?.RoleID==3" class="nav-item"  style=" position: relative;
        left: 57px;">
          <a [ngClass]="activetabstatus=='8'? 'activeURL' : ''" [routerLink]="[ '/spreport']" class="nav-link" >
            <i class="menu-icon typcn typcn-user-outline"></i>
            <span class="menu-title" style="font-size: 12px;">Sales Person Report</span>
          </a>
        </li>
        <!-- <li *ngIf="!auth.getToken()" class="nav-item">
          <a  [routerLink]="[ '/']" class="nav-link" >
            <i class="menu-icon typcn typcn-user-outline"></i>
            <span class="menu-title">Login </span>
          </a>
        </li> -->
        <li *ngIf="user.GetAccess()?.permission_access=='True' && router.url!='/'" class="nav-item">
          <a [ngClass]="activetabstatus=='6'? 'activeURL' : ''"  [routerLink]="[ '/permission']" class="nav-link" >
            <i class="menu-icon typcn typcn-user-outline"></i>
            <span class="menu-title">Permission</span>
          </a>
        </li>
       
        <li *ngIf="auth.getToken()" class="nav-item">
          <a (click)="logout()" class="nav-link"  [routerLink]="[ '/']" >
            <i class="menu-icon typcn typcn-user-outline"></i>
            <span class="menu-title">Log Out</span>
          </a>
        </li>
      </ul>
    </nav>
    <!-- partial -->
    <div class="main-panel">
        <div class="content-wrapper" style="margin-left: 273px;">


<router-outlet></router-outlet>
<footer class="footer">
        <div class="container-fluid clearfix text-center">
          <span class="text-muted d-block text-center text-sm-center d-sm-inline-block">Only authorized TransCredit Inc.Representatives have permission to access the contents within.<br>

            Copyright TransCredit Inc. 2022.
            
           </span>            
        </div>
      </footer>
    </div>
    <!-- main-panel ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>