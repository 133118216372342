import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup,ReactiveFormsModule, NgForm, Validators, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { BuiltinTypeName, identifierModuleUrl } from '@angular/compiler';
import { UserService } from 'src/app/service/user.service';
import { AuthService } from 'src/app/service/auth.service';
import { CookieService } from '../../../node_modules/ngx-cookie-service'
import { AuthService1 } from 'src/app/service/auth.service1';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import { catchError } from 'rxjs/operators';

import { of } from 'rxjs';
@Component({
  
  selector: 'app-prospectmanage',
  templateUrl: './prospectmanage.component.html',
  styleUrls: ['./prospectmanage.component.scss']
})
export class ProspectmanageComponent implements OnInit {
  dataDisplay: number;
   searchValue:string = '';
   rcheck:Number=0;
   fromdate:any;
   todate:any;
   programexpiredate:any;
   searchingdate:any;
   finaltodate:any;
   finalfromdate:any;
  dropValue:string = '';
  dropValue1:Number=null;
  registerForm: FormGroup;
  date: FormGroup;
  date1: FormGroup;
  search:FormGroup;
  submitted = false;
  p: any = 0;
  count: Number = 30;
  events: string[] = [];
 events1: string[] = [];
  data_trans: any;

  filterID:string;
  filterID1:string;
  
  

  checkbox:any
  checkboxes: any[] = [
  { name: ' Herman Beck', value: ' John',name2:'Nicky', checked:true },

]

filter={
  active:1
}
Sp:string='';
  data_trans1: any;
  elements: any;
  dNo: any;
  Assign=new FormControl('');
  tempsearchval: any;
  UserID: any;
  RoleID: any;
  index: any;
  y: number=1;
  buttn:any;
  private allItems: any[];
sear:number=1;
  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];
  public labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
};
  show: number=0;
  next: any;
  flag: any;
  btn2filter: any;
  resleng: any;
  bind: string;
   mandatorycheck: string="0";
  sortdata: string;
  sorttype: string;
  storefildata: number=0;
  text: any;
  constructor(private router: Router,private http1: Http,public user:UserService,private cookieService: CookieService,public authservice:AuthService,private pagerService: AuthService1,private toastr: ToastrService,private route: ActivatedRoute, private formBuilder: FormBuilder,private http: HttpClient) { }

  ngOnInit(): void {
  
    localStorage.removeItem("bttn");
this.sear=1;
this.sorttype='';
if(localStorage.getItem("bttn1")=='2'){
if(localStorage.getItem("currentpage")!=null){

this.p=parseInt(localStorage.getItem("currentpage"))-1;
}
}
localStorage.removeItem('currentpage');
   
    this.submitted = false;
this.Assign.setValue('Select');
    this.registerForm = this.formBuilder.group({
     
      search:['', Validators.required],
 
   });

   this.search = this.formBuilder.group({
    searchh: ['', Validators.required],
    SearchBy: ['', Validators.required]
  });
  
  this.date = this.formBuilder.group({
    from: '',
    to: '',
  });

  this.date1 = this.formBuilder.group({
    from: '',
    to: '',
  });

    this.filterID = 'AllData';
    this.searchfunction1('');
    this.bind=(localStorage.getItem("searchtext"));
  




  // localStorage.setItem("btn2filter",(this.btn2filter));
  // localStorage.setItem("searchh",(this.search.value.searchh));




    this.CheckAllOptions();
    console.log('before' ,this.filter);
    this.filter={active:1};
    console.log('after' ,this.filter);
    let user=localStorage.getItem('role');
    console.log('user',user);

    this.Sp=user;
    if (this.route.snapshot.queryParams['username'])
    {
     
    this.route.queryParams
      .subscribe(params => {
        console.log(params); 
        let mobile=params.username;
 let password=params.password;
      
        this.authservice.userAuthentication(
          mobile ,
         password 
        )
          .subscribe(data => {
            console.log('login data',data)
            if (data.access_token) {
              this.authservice.saveToken(data.access_token);
            //  localStorage.setItem('userToken', data.access_token);
              this.GetUserClaims();
             setTimeout(() =>  1000);
            //  this.loader=false;
            }
          //  if (this.loginForm.value.rememberMe1 == true) {
              console.log('set rm');
              // set cookies
              this.cookieService.set('cookieUserName',mobile);
              this.cookieService.set('cookieUserPassword',password);
         //   } else {
          //    console.log('not set rm');
          //    this.cookieService.deleteAll();
         //   }
          },
          error => {
            let er=JSON.parse(error._body);
              this.toastr.warning(er.error_description, '');  
          //  this.loader=false;
          })
       
      }
    
    );
    }
  //  alert(this.user.GetAccess().RoleID);
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    this.fromdate=event.value;
    var fromdatevalue = new Date(this.fromdate);
    var fromdatemonth=fromdatevalue.getMonth()+1;
    var fromdatedate=fromdatevalue.getDate();
    var fromdateyear=fromdatevalue.getFullYear();
    this.finalfromdate=fromdatemonth+'/'+fromdatedate+'/'+fromdateyear;
    console.log("fromdate",this.fromdate);
    console.log("final from date",this.finalfromdate);
   }
   addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    this.todate=event.value;
    var todatevalue = new Date(this.todate);
    var todatemonth=todatevalue.getMonth()+1;
    var todatedate=todatevalue.getDate();
    var todateyear=todatevalue.getFullYear();
    this.finaltodate=todatemonth+'/'+todatedate+'/'+todateyear;
    console.log("todate",this.todate);
    console.log("final to date",this.finaltodate);
    
   }
currentpage(){
   
    localStorage.setItem("currentpage",this.p);
    localStorage.setItem('predisable','2')
    localStorage.setItem('OBnext','2');
    // alert(localStorage.getItem("currentpage"));
 
  }

  GetUserClaims() {
    this.authservice.GetUserClaims()
      .subscribe((data: any) => {
        if (data) {
          console.log('GetUserClaims',data);
          let str=JSON.stringify(data);
          console.log('str',str);
          localStorage.setItem('userDetails',str);
        //  this.toastr.success('Welcome ', data.Name);  
          this.toastr.success('Converted to customer successfully ', "");  
          console.log('accc',this.user.GetAccess().User_id);
          // this.router.navigate(['auth/login']); 
        // if (data.user_type !=1 ) { //super admin , dealer admin
        //   console.log('admin');
        //   this.setcookie(data);
        //   this.router.navigate(['/admin']); 
        //   localStorage.setItem('withoutveryfy','false');
        // }else { //user
        //   console.log('dealer');
        //   localStorage.setItem('withoutveryfy','false');
        //   this.setcookie(data);
 // this.router.navigate(['/prospectmanage']);
          
        // }
        //this.loader=false;
        }
        // else if (data.Status_id==2) {
        //   this.toastr.success(data.Status, '');  
        //   localStorage.setItem('withoutveryfy','true');
        //   this.router.navigate(['auth/verify-otp']);
        //   this.setcookie(data);
        // }else if (data.Status_id==3) {
        //   localStorage.setItem('withoutveryfy','true');
        //   this.router.navigate(['auth/verify-otp']);
        //   this.toastr.success(data.Status, '');
        //   this.setcookie(data);
        // }
     
      },
        error => {
       //   this.loader=false;
          this.toastr.warning(error,'');
        })
  }

  SetFilter(arg,id){

    this.filter={active:arg};
    
    this.filterID=id;
    localStorage.setItem("fildername", this.filterID);
   
    this.text=null;
    if( this.filterID=='CreditRecall'||this.filterID=='Suspended'||this.filterID=='CollectRecall'){
   
    localStorage.setItem("dropcheck",'1');
    }
    else{
      localStorage.setItem("dropcheck",'0'); 
    }
  }
  get f() { return this.registerForm.controls; }
  btnClick() {
    //alert("2");
    this.searchfunction(this.tempsearchval);
    this.toastr.success(' Successfully Assigned');
  };

CheckAllOptions() {
  if (this.checkboxes.every(val => val.checked == true))
    this.checkboxes.forEach(val => { val.checked = false });
  else
    this.checkboxes.forEach(val => { val.checked = true });
}
callapi_source(namee)
{
  var value = {};
  value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';




  //var body =   JSON.stringify(this.registerForm.value,null,4);
  var body =  namee;
  console.log('xx',body);
  
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/Prospect/GetProspectsUsingSearch',body, { headers: headerOptions });

}
 refreshPage() {
  location.reload();
}
searchfunction1(i){

  this.p=parseInt(this.p)+1;
 localStorage.setItem("nextt", this.p);
  this.y=i;
  console.log('indexxx',i);
  console.log('PerPageSize',this.count);
  console.log('NoofPages',this.p);
  var dumval=this.p
  this.dataDisplay=1;
  this.tempsearchval=i;
  var value = {};
  console.log('filterID filterID  button',this.filterID);
  
var t=this.registerForm.value.search?this.registerForm.value.search:'';
localStorage.setItem("fil", t);


if(localStorage.getItem("bttn1")=='2'){
if(localStorage.getItem("searchtext2")!=null){
  this.btn2filter=localStorage.getItem("btn2filter");

value[this.btn2filter]=localStorage.getItem("searchh");;
  }
  if(localStorage.getItem("searchtext2")==null) {
    value[this.btn2filter]=this.search.value.searchh;
  }
  
// value[this.btn2filter]=this.search.value.searchh;
 if(localStorage.getItem("searchtext")!=null){
 this.filterID= localStorage.getItem("fildername");
  value[this.filterID] =localStorage.getItem("searchtext");
 }
 if(localStorage.getItem("searchtext")==null){
 value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
 }

 if(localStorage.getItem("sortfilter")!=null){
   value['SortBy'] =localStorage.getItem("sortfilter");
    value['Orderby']=localStorage.getItem("sorttype");
  }
}
else{
value[this.btn2filter]=this.search.value.searchh;
 value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
 value['SortBy']=this.filterID1;
 value['Orderby']=this.sorttype
}

 value['PerPageSize']=31;
 value['NoofPages']=1;

 value['Index']=this.p;
 
 value['UserID']=this.user.GetAccess().User_id;
 value['RoleID']=this.user.GetAccess().RoleID;
 value['view_allprospect']=this.user.GetAccess().view_sales_person;

 localStorage.setItem("filterid1",JSON.stringify(value));
console.log(localStorage.getItem("filterid"));
this.callapi_source(value).pipe(
  catchError(error => {
    // Handle the error here
    document.getElementById("poppup").click()
    console.error('Error occurred:', error);
    return of({ status: 0 }); // Assuming status 0 represents an error
  })
).subscribe((response: any) => {
  
  if(response.status==1){
    this.dataDisplay=0;
     this.data_trans= response.Data[0].formfileds;
 
    console.log('response data#',this.data_trans)
    this.resleng=response.Data[0].Searchcount;
    
    console.log('response broke',response.Brok_Auth)
    
    this.data_= response.Data[0].formfileds;
  
    console.log('response data',this.data_)
    localStorage.setItem("customertlist",JSON.stringify(this.data_));
    var localcustomerlist;
    
    localcustomerlist=JSON.parse(localStorage.getItem("customertlist"));
  
    console.log('cus1',localcustomerlist)
   
  if( localStorage.getItem("btn2filter")=='CCRecallFilter'){
    // this.storefildata=1
  }}
 else{
this.dataDisplay=0;
document.getElementById("poppup").click()
// this.toastr.error('An error occurred while fetching data');
 }
 
})


this.p=dumval;
this.
dropdown()
//localStorage.removeItem('searchtext');
//localStorage.removeItem('fildername');
// localStorage.removeItem('searchtext');
//  localStorage.removeItem('fildername');
}

searchfunction2(i){
  
 
  this.p=this.p-1;

  this.y=i;
  console.log('indexxx',i);
  console.log('PerPageSize',this.count);
  console.log('NoofPages',this.p);
  var dumval=this.p
  this.dataDisplay=1;
  this.tempsearchval=i;
  var value = {};
  console.log('filterID filterID  button',this.filterID);
  
  if(localStorage.getItem("bttn1")=='2'){
    if(localStorage.getItem("searchtext2")!=null){
      this.btn2filter=localStorage.getItem("btn2filter");
    
    value[this.btn2filter]=localStorage.getItem("searchh");;
      }
      if(localStorage.getItem("searchtext2")==null) {
        value[this.btn2filter]=this.search.value.searchh;
      }
      
    // value[this.btn2filter]=this.search.value.searchh;
     if(localStorage.getItem("searchtext")!=null){
     this.filterID= localStorage.getItem("fildername");
      value[this.filterID] =localStorage.getItem("searchtext");
     }
     if(localStorage.getItem("searchtext")==null){
     value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
     }
    
     if(localStorage.getItem("sortfilter")!=null){
       value['SortBy'] =localStorage.getItem("sortfilter");
        value['Orderby']=localStorage.getItem("sorttype");
      }
    }
    else{
    value[this.btn2filter]=this.search.value.searchh;
     value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
     value['SortBy']=this.filterID1;
     value['Orderby']=this.sorttype
    }
    
 value['PerPageSize']=31;
 value['NoofPages']=1;

 value['Index']=this.p;
 
 value['UserID']=this.user.GetAccess().User_id;
 value['RoleID']=this.user.GetAccess().RoleID;
 value['view_allprospect']=this.user.GetAccess().view_sales_person;

 localStorage.setItem("filterid1",JSON.stringify(value));
console.log('see',localStorage.getItem("filterid"));
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=0;
  this.data_trans= response.Data[0].formfileds;
  this.resleng=response.Data[0].Searchcount;
  console.log('response data#',this.data_trans)
  console.log('response broke',response.Brok_Auth)
  
  this.data_= response.Data[0].formfileds;

  console.log('response data',this.data_)
  localStorage.setItem("customertlist",JSON.stringify(this.data_));
  var localcustomerlist;
  localcustomerlist=JSON.parse(localStorage.getItem("customertlist"));

  console.log('cus1',localcustomerlist)
 
 // this.registerForm.get('search').setValue(this.registerForm.value.Name);

  // if(response.Brok_Auth=='A'){
  //   this.dat=['Broker',[]]
  //   console.log('cc',this.dat)
  // }
  // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
  //   this.dat=['carrier',[]]
  //   console.log('oo',this.dat)
  // }
 
})
this.p=dumval;
this.
dropdown()
 localStorage.removeItem('searchtext');
 localStorage.removeItem('searchtext2');
localStorage.removeItem('fildername');
  
}
pageChange($event) {
 // this.p = $event;
  this.searchfunction1(this);
}
pageChange1($event) {
  // this.p = $event;
   this.searchfunction2(this);
 }
searchfunction(i){

 
  this.tempsearchval=i;

console.log('indexxx',this.index);
console.log('PerPageSize',this.count);
console.log('NoofPages',this.p);
  this.dataDisplay=1;
  this.submitted =true;
 
  var value = {};
  console.log('filterID filterID',this.filterID);




  this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
  console.log('programexpire search',this.programexpiredate);
   if(this.filter.active==6||this.filter.active==7||this.filter.active==8){this.searchingdate=this.registerForm.value.search;
     var searchvalue = new Date(this.searchingdate);
     var searchmonth=searchvalue.getMonth()+1;
     var searchdate=searchvalue.getDate();
     var searchyear=searchvalue.getFullYear();
     var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
     console.log('finalresult prospectcust page',finalsearchdate);
  (this.filter.active==6||this.filter.active==7||this.filter.active==8)?this.registerForm.value.search=this.programexpiredate:this.registerForm.value.search=finalsearchdate;
  console.log('checking for which tab value is active',this.registerForm.value.search);
    console.log('filterID filterID',this.filterID)}
    //this.registerForm.value.search='';
   // value[this.filterID] = (this.filter.active==10 || this.filter.active==11)? this.registerForm.value.search:'';
  
   value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';


//recent hide//
localStorage.setItem("searchtext",this.registerForm.value.search);






value[this.btn2filter]=this.search.value.searchh;
 value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
 value['PerPageSize']=31;
 value['NoofPages']=1;

  value['Index']=1;
 
 value['UserID']=this.user.GetAccess().User_id;
 value['RoleID']=this.user.GetAccess().RoleID;
 value['view_allprospect']=this.user.GetAccess().view_sales_person;

 console.log('filterID filterID',value)
 localStorage.setItem("filterid1",JSON.stringify(value));
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=0;

  if(response.status==1){
    this.dataDisplay=0;
    this.data_trans= response.Data[0].formfileds;
    this.resleng=response.Data[0].Searchcount;
    this.data_= response.Data[0].formfileds;
    console.log('response dataaa3',this.data_)
    localStorage.setItem("customertlist",JSON.stringify(this.data_));
    var localcustomerlist;
    localcustomerlist=JSON.parse(localStorage.getItem("customertlist"));
    console.log('cus',localcustomerlist);
    this.p=1;
  }
  else{
    this.dataDisplay=0;
    this.toastr.warning('Select Valid data')
 
  }

 // this.registerForm.get('search').setValue(this.registerForm.value.Name);

  // if(response.Brok_Auth=='A'){
  //   this.dat=['Broker',[]]
  //   console.log('cc',this.dat)
  // }
  // else if(response.Comm_Auth   =='A'||response.Cont_Auth=='A'){
  //   this.dat=['carrier',[]]
  //   console.log('oo',this.dat)
  // }
 
})
this.dropValue1=null;
this.
dropdown()
// localStorage.removeItem('searchtext');
// localStorage.removeItem('fildername');
}

callapi_dropdown()
{
  var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.http.get<any>(environment.apiURL + 'api/Prospect/GetSalesPerson?Role=salesperson&userid=SPUserid');
}
dropdown(){
  console.log("dropdown working");
  this.callapi_dropdown().subscribe(response => {
    console.log('dropdown working',response);
      this.elements=response.salesperson;
      console.log('wa',response.salesperson[0].Userid)
    // response.salesperson.forEach((element: any) => {
    //   // console.log('element',element);
    //   this.elements=element;
      console.log('elements',this.elements);      
    // });
    // let salesperson=this.data_trans1.salesperson;
return this.elements;
   
})}
gettabledata(i) {
  console.log(i);
  this.dNo = this.data_trans[i].Docket_No;
  console.log(this.dNo);
  this.index=i;
  console.log('index',this.index);
}
assigned;
assign(val){
this.assigned=val.target.value;
console.log('ass',val)
}
callapi_assign(i)
{
  console.log('d',i)
console.log('yyyy',this.dNo)

if(i=='Select'){
  this.rcheck=1;
  this.toastr.error(' Select Any One Salesperson');
}else{
  this.rcheck=0;
  var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.http.get<any>(environment.apiURL + 'api/Prospect/UpdateSalesPerson?McNumber='+this.dNo +'&'+'userid='+i)
  
}
 

}
assignbuttonclick(i){
  console.log('***',i)
  
  this.callapi_assign(this.Assign.value).subscribe((response: any) => {
   // this.data_trans= response.Data;
    console.log('assign',response)
    // if(response.status == 1 )
    //  {
    //     this.toastr.success(response.msg);
           
    //     }else 
    //     {
     
    //       this.toastr.success( response.msg);
    //    }
  })
}
data_: any = [];
Search(drop){
  
  this.dataDisplay=1;
  this.sear=0;
   this.data_trans=this.data_;
  var value = {};
  if( (drop=='1'|| drop=='2'||drop=='5') && this.search.value.searchh.length==0)
  {
    
    this.mandatorycheck="1";
      
  }
  else{
    this.mandatorycheck="0";
  }
  if(drop==''&& this.search.value.searchh.length !='0' )

 {

  this.toastr.warning("Select Valid data");

 }
 
  if (drop=='1') {
    this.btn2filter='CodeFilter'
    value['CodeFilter']=this.search.value.searchh;
    localStorage.setItem("searchtext2",this.search.value.searchh);
    localStorage.setItem("dropcheck",'0');
      
  }
  if(drop=='2') {
    this.btn2filter='SPFilter'
    value['SPFilter']=this.search.value.searchh;
    localStorage.setItem("searchtext2",this.search.value.searchh);
    localStorage.setItem("dropcheck",'0');
  

  }
  if(drop=='5') {
    this.btn2filter='StateFilter'
    value['StateFilter']=this.search.value.searchh;
    localStorage.setItem("searchtext2",this.search.value.searchh);
    localStorage.setItem("dropcheck",'0');
    // this.data_trans=this.data_trans.filter(x => x.SP_Name.toLowerCase().includes(search.toLowerCase()));    

  }

  if(drop=='3'){

     this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
   // this.programexpiredate=this.finalfromdate;
      
        var searchvalue = new Date(this.searchingdate);
        var searchmonth=searchvalue.getMonth()+1;
        var searchdate=searchvalue.getDate();
        var searchyear=searchvalue.getFullYear();
        var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
        console.log('finalresult prospectcust page',finalsearchdate);
     
        this.search.value.searchh=this.programexpiredate;
    
        this.btn2filter='CreditRecallFilter'
        value['CreditRecallFilter']=this.search.value.searchh;
        localStorage.setItem("searchtext2",this.search.value.searchh);
        value['SortBy']='CreditRecall';

        value['Orderby']='DES'
        localStorage.setItem("dropcheck",'1');
      }
      if(drop=='4'){

        this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
      // this.programexpiredate=this.finalfromdate;
         
           var searchvalue = new Date(this.searchingdate);
           var searchmonth=searchvalue.getMonth()+1;
           var searchdate=searchvalue.getDate();
           var searchyear=searchvalue.getFullYear();
           var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
           console.log('finalresult prospectcust page',finalsearchdate);
        
           this.search.value.searchh=this.programexpiredate;
       
           this.btn2filter='CollectRecallFilter'
           value['CollectRecallFilter']=this.search.value.searchh;
           localStorage.setItem("searchtext2",this.search.value.searchh);
           value['SortBy']='CollectRecall';

           value['Orderby']='DES'
           localStorage.setItem("dropcheck",'1');
         }
         if(drop=='6'){

          this.programexpiredate=this.finalfromdate + ',' + this.finaltodate;
        // this.programexpiredate=this.finalfromdate;
           
             var searchvalue = new Date(this.searchingdate);
             var searchmonth=searchvalue.getMonth()+1;
             var searchdate=searchvalue.getDate();
             var searchyear=searchvalue.getFullYear();
             var finalsearchdate=searchmonth+'/'+searchdate+'/'+searchyear;
             console.log('finalresult prospectcust page',finalsearchdate);
          
             this.search.value.searchh=this.programexpiredate;
         
             this.btn2filter='CCRecallFilter'
             value['CCRecallFilter']=this.search.value.searchh;
             localStorage.setItem("searchtext2",this.search.value.searchh);
             value['SortBy']='CCRecall';
  
             value['Orderby']='DES'
             localStorage.setItem("dropcheck",'1');
           }
    
  console.log('filterID filterID',this.filterID);
 
 localStorage.setItem("btn2filter",(this.btn2filter));
 localStorage.setItem("searchh",(this.search.value.searchh));

 value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
 value['PerPageSize']=31;
 value['NoofPages']=1;

  value['Index']=1;
 
 value['UserID']=this.user.GetAccess().User_id;
 value['RoleID']=this.user.GetAccess().RoleID;
 value['view_allprospect']=this.user.GetAccess().view_sales_person;

 console.log('filterID filterID',value)
 localStorage.setItem("filterid1",JSON.stringify(value));
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=0;

  if(response.status==1){
    this.dataDisplay=0;
    this.data_trans= response.Data[0].formfileds;
    this.data_= response.Data[0].formfileds; 
    this.resleng=response.Data[0].Searchcount;
  }
  else{
    this.dataDisplay=0;
    this.toastr.warning('Select Valid data')
  }
})

console.log('data_trans',drop,this.data_trans);
}
resetfun(e){
  var value = {};

 value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
 value['PerPageSize']=31;
 value['NoofPages']=1;

  value['Index']=1;
 
 value['UserID']=this.user.GetAccess().User_id;
 value['RoleID']=this.user.GetAccess().RoleID;
this.callapi_source(value).subscribe((response: any) => {
    this.data_trans= response.Data[0].formfileds;  
    this.data_= response.Data[0].formfileds;
    this.resleng=response.Data[0].Searchcount;
})
this.dropValue1=null;
localStorage.removeItem('searchtext');
localStorage.removeItem('searchtext2');
localStorage.removeItem('fildername');
localStorage.removeItem('sortfilter');
localStorage.removeItem('sorttype');
localStorage.removeItem('dropcheck');
}
reset1(){
  localStorage.removeItem('searchtext');
  localStorage.removeItem('searchtext2');
  localStorage.removeItem('fildername');
  localStorage.removeItem('sortfilter');
  localStorage.removeItem('sorttype');
  localStorage.removeItem('dropcheck');
  // this.resetfun(this);
  window.location.reload(); 
//   this.data_trans=this.data_;
//   this.searchValue = null;
//  this.dropValue=null;
//  this.btn2filter='';
 
}
reset(){
  localStorage.removeItem('searchtext');
  localStorage.removeItem('searchtext2');
  localStorage.removeItem('fildername');
  localStorage.removeItem('sortfilter');
  localStorage.removeItem('sorttype');
  localStorage.removeItem('dropcheck');
  // this.resetfun(this);
  window.location.reload(); 
//   this.data_trans=this.data_;
//   this.searchValue = null;
//  this.dropValue=null;



//  window.location.reload(); 
//  this.searchfunction(this);



}
sortt(arg,id){


  this.filter={active:arg};
    
  this.filterID1=id;
// alert(this.filterID1)
  var value = {};
  console.log('filterID filterID  button',this.filterID);
  
  if(localStorage.getItem("bttn1")=='2'){
  
 if(localStorage.getItem("searchtext")!=null){
 this.filterID= localStorage.getItem("fildername");
  value[this.filterID] =localStorage.getItem("searchtext");
 }
 if(localStorage.getItem("searchtext")==null) {
 value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
 }
 if(localStorage.getItem("searchtext2")!=null){
  this.btn2filter=localStorage.getItem("btn2filter");

value[this.btn2filter]=localStorage.getItem("searchh");;
  }
  if(localStorage.getItem("searchtext2")==null) {
    value[this.btn2filter]=this.search.value.searchh;
  }
  }
  else{
    value[this.btn2filter]=this.search.value.searchh;
    value[this.filterID] =this.registerForm.value.search?this.registerForm.value.search:'';
  }

 value['SortBy']=this.filterID1;
 this.sorttype='ASC';
 value['PerPageSize']=31;
 value['NoofPages']=1;

 value['Index']=this.p;
 
 value['UserID']=this.user.GetAccess().User_id;
 value['RoleID']=this.user.GetAccess().RoleID;
 value['view_allprospect']=this.user.GetAccess().view_sales_person;
 
 value['SortBy']=this.filterID1;
 

if(localStorage.getItem("sortfilter")==this.filterID1){
  // alert(this.filterID1)
  // alert(localStorage.getItem("sortfilter"))
 if(localStorage.getItem("sorttype")=='DES'){
  // alert('ASC')
  this.sorttype='ASC'
value['Orderby']=this.sorttype
localStorage.setItem("sorttype",(this.sorttype));
}

else if(localStorage.getItem("sorttype")=='ASC'){
  // alert('DES')
  this.sorttype='DES'
value['Orderby']=this.sorttype
localStorage.setItem("sorttype",(this.sorttype));
}

 else if(this.sorttype==''||localStorage.getItem("sorttype")==null){
  // alert(' @Des')
   this.sorttype='DES'
 value['Orderby']= this.sorttype;
 localStorage.setItem("sorttype",(this.sorttype));
 }
}
else{
  // alert(' @else')
  this.sorttype='DES'
  value['Orderby']= this.sorttype;
  localStorage.setItem("sorttype",(this.sorttype));
}


  
localStorage.setItem("sortfilter",(this.filterID1));
 localStorage.setItem("filterid1",JSON.stringify(value));
console.log('see',localStorage.getItem("filterid"));
this.callapi_source(value).subscribe((response: any) => {
  this.dataDisplay=0;
  this.data_trans= response.Data[0].formfileds;
  this.data_= response.Data[0].formfileds;

  console.log('response data',this.data_)
  localStorage.setItem("customertlist",JSON.stringify(this.data_));
  var localcustomerlist;
  localcustomerlist=JSON.parse(localStorage.getItem("customertlist"));

  console.log('cus1',localcustomerlist)
 

 
})



}

}