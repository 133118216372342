import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log('auth gurd',this.auth.isLogedIn() != null);
      console.log('auth cage without',this.auth.isLogedIn());

    if (this.auth.isLogedIn()) {
      return true;
    } else {  
        const itemToKeep =  localStorage.getItem('currenturlactive') != ''? localStorage.getItem('currenturlactive'): null;
      localStorage.clear();
      window.sessionStorage.clear();
      localStorage.setItem('currenturlactive', itemToKeep);
      this.router.navigate(['/']);
      // this.router.navigate(['/auth/login']);
      return false;
    }
    
  }
}
