import { NgModule } from '@angular/core';
import { AuthGuard } from './service/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { ConversionComponent } from './conversion/conversion.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsComponent } from './pages/forms/forms.component';
import { LoginComponent } from './pages/login/login.component';
import { ManageUserComponent } from './pages/manage-user/manage-user.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ProspectCustComponent } from './prospect-cust/prospect-cust.component';
import { ProspectFormComponent } from './prospect-form/prospect-form.component';
import { ProspectmanageComponent } from './prospectmanage/prospectmanage.component';
import { PermissionComponent } from './pages/permission/permission.component';
import { ProspectEditFormComponent } from './prospectsEdit-form/prospectsEdit-form.component';
import { SpreportComponent } from './pages/spreport/spreport.component'; 
import { ProscustComponent } from './proscust/proscust.component';
import { ProscustformComponent } from './proscustform/proscustform.component'; 

const routes: Routes = [
  {path: '', component:LoginComponent},
  {path: 'forms', component:FormsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'mangeuser', component:ManageUserComponent, pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'prospectform', component:ProspectFormComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'prospectmanage', component:ProspectmanageComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'reports', component:ReportsComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'prospectcust', component:ProspectCustComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'dashboard', component:DashboardComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'spreport', component:SpreportComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'conversion', component:ConversionComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'permission', component:PermissionComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'prospectedit', component:ProspectEditFormComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'proscust', component:ProscustComponent,pathMatch: 'full',canActivate: [AuthGuard]},
  {path: 'proscustform', component:ProscustformComponent,pathMatch: 'full',canActivate: [AuthGuard]},
];

// @NgModule({
// imports: [
//   RouterModule.forRoot(routes, {
//     scrollPositionRestoration: 'disabled',
   
//   })
// ],
// exports: [RouterModule]
// })
@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'disabled',})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
