import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup,ReactiveFormsModule, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { invalid } from '@angular/compiler/src/render3/view/util';
@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  hide=true;
  submitted = false;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  data: any;
  firstname: any;
  fname: any;
  lname: any;
  phone_no: any;
  email: any;
  user_name: any;
  pass_word: any;
  confirm_pass_word: any;
  role: any;
  change_com: any;
  account_trans: any;
  assign_prospect: any;
  lnview_leadame: any;
  delete_user: any;
  lock_lead: any;
  manage_user: any;
  lock_sales_person: any;
  view_sales_person: any;
  disable: any;
  cancel_acc: any;
  change_initials: any;
  cus_management: any;
  permissionaccess: any;
  prospect_list: any;
  dashboard: any;
  reports: any;
  address1: any;
  address2: any;
  city: any;
  state: any;
  postcode: any;
  country: any;
 userid:any;
  lastname: any;
  phn: any;
  emailad: any;
  roleid: any;
  status: any;
  changecom: any;
  accounttrans: any;
  assignprospect: any;
  viewlead: any;
  deleteuser: any;
  locklead: any;
  manageuser: any;
  locksalesperson: any;
  viewsalesperson: any;
  cancelacc: any;
  changeinitials: any;
  cusmanagement: any;
  prospectlist: any;
  address11: any;
  address22: any;
  cityy: any;
  statee: any;
  postcodee: any;
  countryy: any;
  value: any;
checkradio:any;

 
 
  cities: { optionDisplay: string; optionValue: string; cities: string; }[];
  password: any;
  confirm_password: any;
  username: any;
  usermanagement: any;
  editprogram: any;
  viewcustomers: any;
  viewrawdata: any;
  changecom1: any;
  accounttrans1: any;
  assignprospect1: any;
  deleteuser1: any;
  locklead1: any;
  manageuser1: any;
  locksalesperson1: any;
  viewsalesperson1: any;
  disable1: any;
  cancelacc1: any;
  changeinitials1: any;
  cusmanagement1: any;
  permissionaccess1: any;
  prospectlist1: any;
  dashboard1: any;
  reports1: any;
  usermanagement1: any;
  editprogram1: any;
  viewcustomers1: any;
  viewrawdata1: any;
  role1: any;
  viewleadd: any;
  viewleadd1: any;
  dat: (string | any[])[];
  association: any;
  extention: any;

  constructor( private router: Router,private toastr: ToastrService,private route: ActivatedRoute, private formBuilder: FormBuilder,private http: HttpClient) { }

  ngOnInit(): void {
   
  
    
    this.route.queryParams
    .subscribe(params => {
      console.log(params); 
      this.userid = params.userid;
     this.getuser(this.userid)
    }


    
  
  );
  
    this.registerForm = this.formBuilder.group({
      // plate: ['', Validators.required],
    
      fname:['',  Validators.required],
      lname:['', Validators.required],
      phone_no:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email:['',[Validators.required,this.emailValidator, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      user_name:['',  Validators.required],
      pass_word:['',  Validators.required],
      // confirm_pass_word:['',  Validators.required],
      role:['', [Validators.required]],


      change_com:['', [Validators.required]],
      account_trans:['', [Validators.required]],
      assign_prospect:['', [Validators.required]],
      view_lead:['', [Validators.required]],
      delete_user:['', [Validators.required]],
      lock_lead:['', [Validators.required]],
      manage_user:['',[Validators.required]],
      lock_sales_person:['', [Validators.required]],
      view_sales_person:['',[Validators.required]],
      disable:['', [Validators.required]],
      cancel_acc:['', [Validators.required]],
      change_initials:['', [Validators.required]],



      permission_access:['', [Validators.required]],
      cus_management:['', [Validators.required]],
      prospect_list:['', [Validators.required]],
      dashboard:['', [Validators.required]],
      reports:['', [Validators.required]],


      address1:['',  Validators.required],
      address2:['',  Validators.required],
      city:['',  Validators.required],
      state:['', Validators.required],
      postcode:['',  Validators.required],
      country:['',  Validators.required],
      role_id:[0 ,Validators.required],
      status:[1,''],

    
      user_management:['', [Validators.required]],
      edit_program:['', [Validators.required]],
      view_customers:['', [Validators.required]],
      view_rawdata:['', [Validators.required]],
      
      association: ['', [Validators.required]],
      extention: ['', [Validators.required]],

 

  
   // source  :['', []]
      
   });
   
   this.registerForm1 = this.formBuilder.group({
    // plate: ['', Validators.required],
   userid:['',[]],

    user_name:['',  Validators.required],
    




 // source  :['', []]
    
 });
  
  }
  // gottoEdit(){
  //   this.router.navigate(['mangeuser']);
  // }

  get f() { return this.registerForm.controls; }
  apicallforsubmit(registerForm)
  {
   // var body = this.registerForm.value.plate;
    var body =   JSON.stringify(this.registerForm.value, null, 4)
    console.log(body);
    
      var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post<any>(environment.apiURL + 'api/User/AddUser' , body, { headers: headerOptions });
    
  }
  
  callapi_source(Userid)
  {

    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<any>(environment.apiURL + 'api/User/UsersAutoPopulate?userid='+ Userid );
  
  }

  getuser(Userid){
  if(Userid!=0){
    this.callapi_source(Userid).subscribe((response: any) => {

     console.log('userdetails',response.Data[0]);
    
    //  this.data=response.data;
    this.userid = Userid;

 
    this.firstname = response.Data[0].Firstname;
    this.lastname = response.Data[0].Lastname;
    this.phn = response.Data[0].PhoneNo;
    this.emailad = response.Data[0].EmailAddress;
    this.roleid = response.Data[0].Role_id;
    this.username=response.Data[0].Username;
    this.password=response.Data[0].Password;
    this.confirm_password=response.Data[0].confirm_Password;
   // this.role =2;
    this.role1 = response.Data[0].Role;
    this.status = response.Data[0].status;
    this.changecom = response.Data[0].change_com;
    this.accounttrans = response.Data[0].account_trans;
    this.assignprospect = response.Data[0].assign_prospect;
    this.deleteuser = response.Data[0].delete_user;
    this.locklead = response.Data[0].lock_lead;
    this.manageuser = response.Data[0].manage_user;
    this.locksalesperson = response.Data[0].lock_sales_person;
    this.viewsalesperson = response.Data[0].view_sales_person;
    this.disable = response.Data[0].disable;
    this.cancelacc = response.Data[0].cancel_acc;
    this.viewleadd= response.Data[0].view_lead;
    this.changeinitials = response.Data[0].change_initials;
    this.cusmanagement = response.Data[0].cus_management;
    this.permissionaccess = response.Data[0].permission_access;
    this.prospectlist = response.Data[0].prospect_list;
    this.dashboard = response.Data[0].dashboard;
    this.reports = response.Data[0].reports;
    this.address11 = response.Data[0].address1;
     this.address22 = response.Data[0].address2;
     this.cityy = response.Data[0].city;
     this.statee = response.Data[0].state;
     this.postcodee = response.Data[0].postcode;
     this.countryy = response.Data[0].country;
     this.usermanagement= response.Data[0].user_management;
     this.editprogram= response.Data[0].edit_program;
     this.viewcustomers= response.Data[0].view_customers;
     this.viewrawdata= response.Data[0].view_rawdata;
     this.association = response.Data[0].association;
     this.extention = response.Data[0].extention;
     this.bind();
    



          })}
  }
  
  bind(){
   // this.callapi_source(Userid).subscribe((response: any) => {

   // if(this.userid!=null){

  
   
   
    this.registerForm.get('fname').setValue(this.firstname);
    this.registerForm.get('lname').setValue(this.lastname);
    this.registerForm.get('phone_no').setValue(this.phn);
    this.registerForm.get('email').setValue(this.emailad);
    this.registerForm.get('role_id').setValue(this.roleid.toString());
    this.registerForm.get('pass_word').setValue(this.password);
    // this.registerForm.get('confirm_pass_word').setValue(this.confirm_password);
    this.registerForm.get('user_name').setValue(this.username);
    this.registerForm.get('role').setValue(this.role1);
    this.registerForm.get('change_com').setValue(this.changecom);
    this.registerForm.get('account_trans').setValue(this.accounttrans);
    this.registerForm.get('assign_prospect').setValue(this.assignprospect);
    this.registerForm.get('view_lead').setValue(this.viewleadd);
    this.registerForm.get('delete_user').setValue(this.deleteuser);
    this.registerForm.get('lock_lead').setValue(this.locklead);
    this.registerForm.get('manage_user').setValue(this.manageuser);
    this.registerForm.get('lock_sales_person').setValue(this.locksalesperson);
    this.registerForm.get('view_sales_person').setValue(this.viewsalesperson);
    this.registerForm.get('disable').setValue(this.disable);
    this.registerForm.get('cancel_acc').setValue(this.cancelacc);
    this.registerForm.get('change_initials').setValue(this.changeinitials);
    this.registerForm.get('cus_management').setValue(this.cusmanagement);
    this.registerForm.get('permission_access').setValue(this.permissionaccess);
    this.registerForm.get('prospect_list').setValue(this.prospectlist);
    this.registerForm.get('dashboard').setValue(this.dashboard);
    

        this.registerForm.get('reports').setValue(this.reports);
        this.registerForm.get('address1').setValue(this.address11);
        this.registerForm.get('address2').setValue(this.address22);
        this.registerForm.get('city').setValue(this.cityy);
        this.registerForm.get('state').setValue(this.statee);
        this.registerForm.get('phone_no').setValue(this.phn);
        this.registerForm.get('postcode').setValue(this.postcodee);
        this.registerForm.get('country').setValue(this.countryy);


        this.registerForm.get('user_management').setValue(this.usermanagement);
        this.registerForm.get('edit_program').setValue(this.editprogram);
        this.registerForm.get('view_customers').setValue(this.viewcustomers);
        this.registerForm.get('view_rawdata').setValue(this.viewrawdata);
        

        this.registerForm.get('association').setValue(this.association);
        this.registerForm.get('extention').setValue(this.extention);
 

      


  //  }

  }

  public inputValidator(event: any) {
    const pattern = /^[0-9]*$/;   
  
  
    if(event.target.value > 100000){//to block user enter more than 1000000
      event.target.value = event.target.value.replace(event.target.value,event.target.value.slice(0,10));
    }
  }
  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }
 
  onSubmit() {
    var mailformat=/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
   console.log('y',this.registerForm.value.lock_lead)
    console.log('swathi',this.registerForm.value.role_id)
    var t=this.registerForm.value.email
    this.submitted = true;
    if (this.registerForm.value.invalid){
    return false;
    }


    this.registerForm.value.userid = this.userid;

    if(this.userid!=null)
{
  this.registerForm.value.userid = this.userid;

}


   if(this.registerForm.value.fname==''||this.registerForm.value.lname==''||this.registerForm.value.phone_no==''||
   this.registerForm.value.email==''||this.registerForm.value.user_name==''||this.registerForm.value.pass_word==''||!this.registerForm.value.email.match(mailformat)){
   this.toastr.error('Please Fill Mandatory Fields');
   console.log('phone',this.registerForm.value.phone_no);
   return false;
  }
  else {
this.apicallforexistaccount(name).subscribe((response: any) => {
  this.data= response.Data;
  if(response.Info == 'Username not exists' )
{

  if(this.registerForm.value.role_id == '4')

  {
    this.registerForm.value.role = "Customer Representative";
    this.registerForm.value. role_id = 4;
    
  } if(this.registerForm.value.role_id == '3')
  {
    this.registerForm.value.role = 'Sales Person';
    this.registerForm.value. role_id = 3;
  }
  if(this.registerForm.value.role_id == '2'){
    this.registerForm.value.role = 'Admin';
    this.registerForm.value. role_id = 2;
  }
  if(this.registerForm.value.role_id == '1'){
    this.registerForm.value.role = 'Super Admin';
    this.registerForm.value. role_id = 1;
  }
  
  
  console.log('form',this.registerForm.value)
  
  
  
  this.apicallforsubmit(this.registerForm.value ).subscribe((response: any) => {
  
  this.data=response.data;
  //alert(this.data);
  //alert(response.status);
  if(response.status == 1 ){

    this.toastr.success(response.msg);
      this.router.navigateByUrl('/mangeuser');
  }else
  {
    // this.toastr.error( 'Select One Role');
    this.toastr.error('Please Fill Mandatory Fields');
  }
  console.log("apioutput",this.data)
  
  
      })
    

  //  this.toastr.success(response.Info);
     
  }else 
  {

    this.toastr.error(response.Info);
  }



  
})
  }

 }

 callapi_role(roleid)
 {

   var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.http.get<any>(environment.apiURL + 'api/User/CheckPermission?roleid='+ roleid );

 }

onclickradio(e){
 
  this.callapi_role(e.role ).subscribe((response: any) => {
    //alert(e.role)
       console.log(this.registerForm.value.role_id)
        console.log('tt',response);
        console.log(response.Data.change_com);
		
		this.changecom1= response.Data.change_com;
    this.accounttrans1 = response.Data.account_trans;
    this.assignprospect1 = response.Data.assign_prospect;
    this.deleteuser1 = response.Data.delete_user;
    this.locklead1 = response.Data.lock_lead;
    this.manageuser1 = response.Data.manage_user;
    this.locksalesperson1 = response.Data.lock_sales_person;
    this.viewsalesperson1= response.Data.view_sales_person;
    this.disable1 = response.Data.disable;
    this.cancelacc1 = response.Data.cancel_acc;
    this.changeinitials1 = response.Data.change_initials;
    this.cusmanagement1 = response.Data.cus_management;
    this.permissionaccess1 = response.Data.permission_access;
    this.prospectlist1 = response.Data.prospect_list;
    this.dashboard1 = response.Data.dashboard;
    this.reports1 = response.Data.reports;
    this.viewleadd1= response.Data.view_lead;
	  this.usermanagement1= response.Data.user_management;
    this.editprogram1= response.Data.edit_program;
    this.viewcustomers1= response.Data.view_customers;
    this.viewrawdata1= response.Data.view_rawdata;
	 
	   this.bind1();
    })
    
}
 
 
 
 
	   
	   
	     bind1(){
   // this.callapi_source(Userid).subscribe((response: any) => {

   // if(this.roleid!=null){

  
   
   
    
    this.registerForm.get('change_com').setValue(this.changecom1);
    this.registerForm.get('account_trans').setValue(this.accounttrans1);
    this.registerForm.get('assign_prospect').setValue(this.assignprospect1);
    this.registerForm.get('view_lead').setValue(this.viewleadd1);
    this.registerForm.get('delete_user').setValue(this.deleteuser1);
    this.registerForm.get('lock_lead').setValue(this.locklead1);
    this.registerForm.get('manage_user').setValue(this.manageuser1);
    this.registerForm.get('lock_sales_person').setValue(this.locksalesperson1);
    this.registerForm.get('view_sales_person').setValue(this.viewsalesperson1);
    this.registerForm.get('disable').setValue(this.disable1);
    this.registerForm.get('cancel_acc').setValue(this.cancelacc1);
    this.registerForm.get('change_initials').setValue(this.changeinitials1);
    this.registerForm.get('cus_management').setValue(this.cusmanagement1);
    this.registerForm.get('permission_access').setValue(this.permissionaccess1);
    this.registerForm.get('prospect_list').setValue(this.prospectlist1);
    this.registerForm.get('dashboard').setValue(this.dashboard1);

        this.registerForm.get('reports').setValue(this.reports1);
        this.registerForm.get('user_management').setValue(this.usermanagement1);
        this.registerForm.get('edit_program').setValue(this.editprogram1);
        this.registerForm.get('view_customers').setValue(this.viewcustomers1);
        this.registerForm.get('view_rawdata').setValue(this.viewrawdata1);

 

      


  //  }

  }


apicallforexistaccount(name)
{
 // var body = this.registerForm.value.plate;
 //var body =   JSON.stringify(null)
 //this.registerForm.value.userid = this.userid;
 let data_value ={userid:this.userid,username:this.registerForm.value.user_name}  
 let body = JSON.stringify(data_value);  
  //var body =  name;
  console.log(body);
  console.log('existaccnt',body);
  
    var headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.apiURL + 'api/User/CheckUsername' , body, { headers: headerOptions });
  
}
// existaccnt(e){
 
//   this.apicallforexistaccount(name).subscribe((response: any) => {
//     this.data= response.Data;
//     if(response.status == 1 )
//  {
//       this.toastr.success(response.Info);
       
//     }else 
//     {
 
//       this.toastr.success( response.Info);
//     }


  
    
// })


// }
}
