
<!-- <div *ngIf="loader" class="preloader" id="preloader">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div> -->

<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
      <div class="row w-100">
        <div class="col-lg-6 mx-auto">
          <div class="auto-form-wrapper">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
              <div class="form-group">
                <label class="label"  style="color: #437EB8">Username</label>
                <div class="input-group">
                  <input formControlName="mobile" type="text" class="form-control" placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                  <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                    <div *ngIf="f.mobile.errors.required" class="man_field_sty">Username is required</div>
                </div>
                  <div class="input-group-append">
                    <!-- <span class="input-group-text">
                      <i class="mdi mdi-check-circle-outline"></i>
                    </span> -->
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label" style="color: #437EB8">Password</label>
                <div class="input-group">
                  <input formControlName="password" type="password" class="form-control" placeholder="*********" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required" class="man_field_sty">Password is required</div>
                </div>
                  <div class="input-group-append">
                    <!-- <span class="input-group-text">
                      <i class="mdi mdi-check-circle-outline"></i>
                    </span> -->
                  </div>
                </div>
              </div><br/>
           <div class="form-group">
  <button type="submit"  class="btn btn-primary submit-btn btn-block" (click)="login()" [disabled]="loader">
    <span *ngIf="loader" class="load">Logging in... <mat-progress-spinner diameter="20" mode="indeterminate"  ></mat-progress-spinner></span>
    <span *ngIf="!loader">Login</span>
  </button>
</div>

              <div class="form-group ">
                <div class="col-xs-8 remember-top" style="margin-top: 15px;">
                  <label > <input type="checkbox" formControlName="rememberMe1"   name="rememberMe1" id="rememberMe1"> Keep Me Signed In </label>
      
                </div>
                <!-- <a href="#" class="text-small forgot-password text-black">Forgot Password</a> -->
              </div>
             
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>