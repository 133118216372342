import { Injectable } from '@angular/core';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { Http, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiURL;
  constructor(private router: Router, private http: HttpClient,private HttpC : Http) { }
  userAuthentication(userName, password) {

    var data = "username="+userName + "&password="+password.toString()  + "&grant_type=password";


    var headerOptions = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.HttpC.post(environment.apiURL +'token', data, requestOptions).pipe(map(x => x.json()));
  }
  GetUserClaims() {
    
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " +   window.sessionStorage.getItem('userToken'));
    return this.http.get(environment.apiURL +'api/User/GetUserClaims' ,{ headers: headers_object});
   
  }
  signOut(): void {
    window.sessionStorage.clear(); 
    localStorage.getItem('userToken');
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem('userToken');
    localStorage.setItem('userToken',token);
    window.sessionStorage.setItem('userToken', token);
  }

  public getToken(): string | null {
    return localStorage.getItem('userToken');
  }
  public settoken(): string | null {
    return window.sessionStorage.getItem('userToken') && localStorage.getItem('userToken');
  }
  isLogedIn() {
    return this.settoken() !== null;
  }
}
